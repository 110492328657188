import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { APP_ROUTE, PATHS } from "../../const/path";
import { useHandleAPIStateContext } from "../../context/handle-api-state";
import { useUserContext } from "../../context/user/user.context";
import { ResearchType } from "../../enum/research";
import { getErrorMessageGetDataFail } from "../../helper/modal.message";
import { openWindowWithBlankPage } from "../../helper/open.window";
import { IHandleModal } from "../../interfaces/handle.modal";
import { IShortcut } from "../../interfaces/user";
import GenerateLinkService from "../../services/generate-sso-ekyc-link-service";
import ShortcutService from "../../services/shortcut.service";
import SSODBSVService from "../../services/sso-dbsv.service";
import { ReactComponent as PlantSustainabilityIcon } from "../../svgIcon/new-dashboard/plant-sustainability.svg";
import AdvanceShortcut from "../AdvanceShortcut";
import { Card } from "../Card";
import { FavoriteItem } from "./FavoriteItem";
import NoFavorite from "./NoFavorite";
import { SettingButton } from "./SettingButton";

const OTHER_SHORTCUTS = {
  FUNDAMENTAL_INSIGHT: "BrainBOX Fundamental Insight",
  TECHNICAL_INSIGHT: "BrainBOX Technical Insight",
  DBSV_WEALTHBOX: "DBSV WealthBOX",
  BLOCKTRADE_CALCULATOR: "Block Trade Calculator",
  MY_SUIT_TEST: "My Suitability Test",
  DBSV_VICKERS: "DBSVickers.com",
  REQUEST_FORM: "Personal Data Change/\nRequest Form",
};

const IN_APP_SHORTCUTS = {
  E_STATEMENT: "E-Statement",
  THAILAND_RESEARCH: "Thailand Research",
  GLOBAL_RESEARCH: "Global Research",
  CONFIRMATION_REPORT: "Confirmation Report",
  STOCK_STATEMENT: "Stock Statement",
  STOCK_MOVEMENT: "Stock Movement",
  CASH_MOVEMENT: "Cash Movement",
};

export const NewShortcuts = () => {
  const { ssoSTTLink, userInfo, onConfirmAdvanceShortcuts } = useUserContext();
  const {
    setGetDataEror,
  }: { setGetDataEror: (getDataError: IHandleModal) => void } =
    useHandleAPIStateContext();
  const { t: translate } = useTranslation();
  const history = useHistory();

  const [isShowAdvanceDialog, setShowAdvanceDialog] = useState<boolean>(false);
  const [allShortcuts, setAllShortcuts] = useState<IShortcut[]>([]);

  useEffect(() => {
    new ShortcutService().getShortcuts().then(setAllShortcuts);
  }, []);

  const setDialogState = (state: boolean) => {
    setShowAdvanceDialog(state);
  };

  const onConfirmShortcuts = (shortcuts: IShortcut[]) => {
    setShowAdvanceDialog(false);
    onConfirmAdvanceShortcuts(shortcuts);
  };

  const handleInAppShortcutAction = (shortcutName: string) => {
    const routes = {
      [IN_APP_SHORTCUTS.E_STATEMENT]: PATHS.E_STATEMENT,
      [IN_APP_SHORTCUTS.THAILAND_RESEARCH]: `${APP_ROUTE.DBSV_RESEARCH_ARCHIVE.path}?type=${ResearchType.THAI}`,
      [IN_APP_SHORTCUTS.GLOBAL_RESEARCH]: `${APP_ROUTE.DBSV_RESEARCH_ARCHIVE.path}?type=${ResearchType.OVERSEA}`,
      [IN_APP_SHORTCUTS.CONFIRMATION_REPORT]: PATHS.E_STATEMENT,
      [IN_APP_SHORTCUTS.STOCK_STATEMENT]: PATHS.E_STATEMENT,
      [IN_APP_SHORTCUTS.STOCK_MOVEMENT]: PATHS.E_STATEMENT,
      [IN_APP_SHORTCUTS.CASH_MOVEMENT]: PATHS.E_STATEMENT,
    };
    history.push(routes[shortcutName]);
  };

  const handleShortcutAction = async (shortcutName: string) => {
    const ssodbsvService = new SSODBSVService();
    const functor = {
      [OTHER_SHORTCUTS.TECHNICAL_INSIGHT]: ssodbsvService.getBrainBoxTILink,
      [OTHER_SHORTCUTS.FUNDAMENTAL_INSIGHT]: ssodbsvService.getBrainBoxVALink,
      [OTHER_SHORTCUTS.DBSV_WEALTHBOX]: ssodbsvService.getWealthBoxLink,
      [OTHER_SHORTCUTS.BLOCKTRADE_CALCULATOR]: ssodbsvService.getBlockTradeLink,
      [OTHER_SHORTCUTS.DBSV_VICKERS]: ssodbsvService.getDBSVOnline,
      [OTHER_SHORTCUTS.MY_SUIT_TEST]: () =>
        new GenerateLinkService().generateSSOKYCLink(),
      [OTHER_SHORTCUTS.REQUEST_FORM]: ssodbsvService.getRequestFormLink,
    };
    try {
      const link = await functor[shortcutName]();
      openWindowWithBlankPage({ link, isPopup: false });
    } catch (e) {
      setGetDataEror(getErrorMessageGetDataFail(translate));
    }
  };

  const onClickShortcut = (shortcut: IShortcut) => {
    if (shortcut.pageName) {
      ssoSTTLink.openLink({
        pageName: shortcut.pageName,
        isAdditionalLang: false,
        isPopup: true,
      });
    }
    if (isOtherShortcut(shortcut.nameEN)) {
      handleShortcutAction(shortcut.nameEN);
    }
    if (isInAppShortcut(shortcut.nameEN)) {
      handleInAppShortcutAction(shortcut.nameEN);
    }
  };

  const isOtherShortcut = (name: string) =>
    Object.values(OTHER_SHORTCUTS).includes(name);
  const isInAppShortcut = (name: string) =>
    Object.values(IN_APP_SHORTCUTS).includes(name);

  return (
    <>
      {isShowAdvanceDialog && (
        <AdvanceShortcut
          userShortcuts={userInfo.shortcuts}
          allShortcuts={allShortcuts}
          onCancel={() => setShowAdvanceDialog(false)}
          onConfirm={onConfirmShortcuts}
        />
      )}
      <Card
        icon={<PlantSustainabilityIcon />}
        title="My Favourite Menu"
        action={<SettingButton onClick={() => setDialogState(true)} />}
      >
        <div className="flex items-center w-full">
          {!userInfo.shortcuts.length && (
            <div className="flex items-center justify-center w-full">
              <NoFavorite onClick={setDialogState} />
            </div>
          )}
          <div className="flex flex-row flex-wrap gap-4 md:justify-start md:flex-nowrap md:overflow-hidden md:overflow-x-auto">
            {userInfo.shortcuts.map((shortcut, index) => (
              <FavoriteItem
                key={index}
                name={shortcut.nameEN}
                iconPath={shortcut.imagePath}
                onClick={() => onClickShortcut(shortcut)}
              />
            ))}
          </div>
        </div>
      </Card>
    </>
  );
};
