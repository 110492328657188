import styles from "./index.module.scss";

const LoadingCard = () => {
  return (
    <div
      className="flex items-center justify-center w-full py-4 pl-2 pr-4 mt-2 space-y-4 card-bg"
      data-testid="loading-card"
    >
      <div
        className={`${styles.loader} ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 my-3`}
      />
    </div>
  );
};

export default LoadingCard;
