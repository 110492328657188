import { IKeywordsSearch } from "../interfaces/keywords.search";
import ERewardService from "../services/e-reward.service";
import { PATHS } from "./path";

export const MENU_KEYWORDS: IKeywordsSearch[] = [
  {
    title: "sidebar.dashboard",
    keywords: ["dashboard", "หน้าแรก", "หน้ารวม", "หน้่าหลัก", "home"],
    route: PATHS.DASHBOARD,
  },
  {
    title: "sidebar.investment-tools",
    keywords: [
      "investment tools",
      "tools",
      "เครื่องมือ",
      "ตัวช่วย",
      "application",
      "แอปพลิเคชัน",
    ],
    route: PATHS.INVESTMENT_TOOLS,
  },
  {
    title: "sidebar.research",
    keywords: ["research", "บทวิเคราะห์", "บทความ"],
    route: PATHS.DBSV_RESEARCH,
  },
  {
    title: "sidebar.my-account",
    keywords: [
      "my account",
      "account",
      "information",
      "ข้อมูลส่วนตัว",
      "บัญชี",
    ],
    route: PATHS.MY_ACCOUNT,
  },
  {
    title: "sidebar.statement",
    keywords: ["statement", "reports", "ใบแจ้งยอด", "รายงาน"],
    route: PATHS.E_STATEMENT,
  },
  {
    title: "sidebar.rewards",
    keywords: ["rewards", "รางวัล", "แลกของ"],
    route: PATHS.DBSV_REWARDS,
    url: new ERewardService().getERewardUrl().then((res) => res.url),
  },
];
