export const SubMenuIcon = ({
  label,
  imgPath,
  onClick,
}: {
  label: string;
  imgPath: string;
  onClick: () => void;
}) => {
  return (
    <div
      id="menu-icon"
      className="flex flex-col items-center cursor-pointer group"
      onClick={onClick}
    >
      <div className="flex items-center justify-center h-20 px-6 mb-2 border border-gray-300 rounded-xl group-hover:bg-gray-100">
        <img className="w-8" src={imgPath} alt="" />
      </div>
      <div className="text-center text-gray-600 whitespace-pre w-max group-hover:text-black">
        {label}
      </div>
    </div>
  );
};
