import { IDisclaimer } from "../interfaces/cms";
import { IResearchDisclaimer } from "../interfaces/disclaimer";
import httpClient from "./http";

class DisclaimerService {
  private http = httpClient();
  getResearchRecentDisclaimer = async (): Promise<IResearchDisclaimer> => {
    return this.http
      .get<IResearchDisclaimer>("/disclaimer/recent")
      .then((res) => res.data);
  };

  async getDisclaimerToBeAccept(): Promise<IDisclaimer | undefined> {
    return this.http
      .get<IDisclaimer>("/disclaimer/to-accept")
      .then((res) => res.data);
  }
}

export default DisclaimerService;
