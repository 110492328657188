import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getDateInFormat } from "../../helper/date.time";
import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { isThaiLanguage } from "../../i18n/helper";
import { enUS, th } from "date-fns/locale";
import { DatePickerHeader } from "../DatePickerHeader/index";

export const FilterTradeDate = ({
  date,
  onSelectDate,
}: {
  date: Date | undefined;
  onSelectDate: (date: Date | undefined) => void;
}) => {
  const [openDate, setOpenDate] = useState<boolean>(false);
  useEffect(() => {
    document.addEventListener("click", (e) => {
      const { id } = e.target as HTMLElement;
      if (
        !id.includes("filter-tradedate") &&
        !["decreaseMonth", "increaseMonth"].includes(id)
      ) {
        setOpenDate(false);
      }
    });
    return document.removeEventListener("click", () => {});
  }, []);

  return (
    <div id="filter-tradedate" className="group relative">
      <div
        id="filter-tradedate-selection"
        className="flex w-max px-2 py-2 border border-gray-500 gap-x-10 cursor-pointer"
        onClick={() => setOpenDate(!openDate)}
      >
        <span id="filter-tradedate-label" className="font-light">
          {date ? getDateInFormat(date.toISOString()) : "Trade Date"}
        </span>
        <div>
          <svg
            id="filter-tradedate-icon"
            xmlns="http://www.w3.org/2000/svg"
            className={`h-6 w-6 ${date ? "group-hover:hidden block" : "block"}`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1}
              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`h-6 w-6 ${
              date ? "group-hover:block hidden" : "hidden"
            }`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            onClick={() => onSelectDate(undefined)}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
      </div>
      {openDate && (
        <div id={"filter-tradedate-date"} className="absolute z-10">
          <DatePicker
            renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
              <DatePickerHeader
                dateSelected={date}
                decreaseMonth={decreaseMonth}
                increaseMonth={increaseMonth}
              ></DatePickerHeader>
            )}
            inline
            id={"filter-tradedate-date-picker"}
            minDate={new Date(DateTime.now().minus({ years: 5 }).year, 1, 1)}
            maxDate={new Date()}
            locale={isThaiLanguage() ? th : enUS}
            selected={date}
            onChange={(selectedDate) =>
              onSelectDate(selectedDate as Date | undefined)
            }
            onSelect={() => setOpenDate(false)}
          />
        </div>
      )}
    </div>
  );
};
