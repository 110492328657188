export const useFeatureToggle = () => {
  return {
    consolidatePortFeat:
      process.env.REACT_APP_FEATURE_TOGGLE_CONSOLIDATE === "true",
    redeemReward: process.env.REACT_APP_FEATURE_TOGGLE_REDEEM_REWARD === "true",
    openOverseaTradingAccount:
      process.env.REACT_APP_FEATURE_TOGGLE_OPEN_OVERSEATRADING_ACCOUNT ===
      "true",
  };
};
