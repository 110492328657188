import { useEffect, useState } from "react";
import { initUserRewards } from "../../context/user/user.context";
import { getDateInFormat } from "../../helper/date.time";
import { currency } from "../../helper/format.number";
import { isThaiLanguage } from "../../i18n/helper";
import { IUserRewards } from "../../interfaces/user";
import { RewardsService } from "../../services/rewards.service";
import UserService from "../../services/user.service";
import { ReactComponent as GiftBoxIcon } from "../../svgIcon/new-dashboard/points/giftbox.svg";
import {
  BadgePointSummary,
  btfIconPath,
  btfName,
  btfTooltip,
  Bullet,
  PointExpire,
} from "../Points";
import Tooltip from "../Tooltip";

const RewardPoint = () => {
  const [reward, setReward] = useState<IUserRewards>(initUserRewards);
  const expirationDate = getDateInFormat(
    new Date(reward.pointExpirationDate).toISOString(),
    "dd/MM/yyyy"
  );
  const asOfDate = getDateInFormat(
    new Date(reward.asOfDate).toISOString(),
    "dd/MM/yyyy"
  );

  useEffect(() => {
    new UserService().getAccountInfo().then((accInfo) => {
      new RewardsService().getUserRewards(accInfo.custCodes[0]).then(setReward);
    });
  }, []);

  return (
    <div className="border-b border-dbsv-neutral-slate-10 w-full px-5 py-4">
      <div className="flex items-center justify-between pb-8 gap-x-5 w-full">
        <div className="flex items-center gap-x-3">
          <GiftBoxIcon />
          <div className="flex items-center gap-x-1">
            <div className="font-bold text-dbsv-global-black">
              <span className="block text-xs">Your Status</span>
            </div>
            <Tooltip tooltip={{ title: btfTooltip(reward) }} />
          </div>
        </div>
        <div className="flex items-center gap-x-3">
          {!!btfIconPath(reward) && (
            <img
              src={btfIconPath(reward)}
              alt="btf-icon"
              className="w-10 h-10"
            />
          )}
          <span className="font-bold">{btfName(reward)}</span>
        </div>
      </div>
      <div className="relative w-full">
        <div
          id="point-summary-card"
          className="relative w-full h-48 overflow-hidden border rounded-lg bg-dbsv-neutral-slate-10 md:h-60 border-dbsv-neutral-slate-20"
        >
          <img
            src="/images/Investment_Young_Point.png"
            alt="investment-point"
            className="absolute bottom-0 h-32 -right-4 md:right-0 md:h-48"
          />
          <div className="absolute flex flex-col p-5 pt-16 md:pt-24 gap-y-5">
            <div className="flex items-baseline font-bold gap-x-2 text-dbsv-neutral-slate-100">
              <p className="text-2xl md:text-4xl">
                {currency(reward.totalPoints)}
              </p>
              <p>pts.</p>
            </div>
            <div className="text-xs text-dbsv-neutral-slate-60 md:text-base">
              <div>
                <Bullet />
                <span>
                  {isThaiLanguage() ? "คะแนนอัพเดทล่าสุด ณ วันที่ " : "As of "}
                </span>
                <span className="font-bold">{asOfDate}</span>
              </div>
              <PointExpire
                expirationDate={expirationDate}
                userRewards={reward}
              />
            </div>
          </div>
        </div>
        <BadgePointSummary />
      </div>
    </div>
  );
};

export default RewardPoint;
