import { AxiosResponse } from "axios";
import { UpdateAdvanceShortcutsBody } from "../interfaces/advance.shortcut";
import { IAccountInfo, IUserInfo } from "../interfaces/user";
import httpClient from "./http";

class UserService {
  private http = httpClient();
  async getUserInfo(): Promise<IUserInfo> {
    return this.http.get<IUserInfo>("/user/info").then((res) => res.data);
  }

  async getAccountInfo(): Promise<IAccountInfo> {
    return this.http
      .get<IAccountInfo>("/user/accounts")
      .then((res) => res.data);
  }

  acceptDisclaimer = async (disclaimerId: string): Promise<AxiosResponse> => {
    return this.http
      .post(`/user/disclaimer/${disclaimerId}/accept`)
      .then((res) => res.data);
  };

  denyDisclaimer = async (disclaimerId: string): Promise<AxiosResponse> => {
    return this.http
      .post(`/user/disclaimer/${disclaimerId}/deny`)
      .then((res) => res.data);
  };

  checkUserShouldAcceptDisclaimer = async (): Promise<{
    shouldAcceptDisclaimer: boolean;
  }> => {
    return this.http
      .get("/user/disclaimer/should-accept")
      .then((res) => res.data);
  };

  updateUserAdvanceShortcuts = async (
    body: UpdateAdvanceShortcutsBody
  ): Promise<AxiosResponse> => {
    return this.http.put("/user/shortcut", body).then((res) => res.data);
  };
}

export default UserService;
