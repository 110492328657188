import { APP_ROUTE } from "../../const/path";
import { ResearchType } from "../../enum/research";
import { IResearch, IResearchGroupByCategory } from "../../interfaces/research";

export const groupResearchByCategory = (
  researches: IResearch[]
): IResearchGroupByCategory[] => {
  return researches
    .reduce<IResearchGroupByCategory[]>((prev, curr) => {
      const categoryIndex = prev.findIndex(
        (category) =>
          category.research_category.id === curr.research_category.id
      );
      if (categoryIndex >= 0) {
        prev[categoryIndex].researches.push(curr);
      } else {
        prev.push({
          research_category: curr.research_category,
          researches: [curr],
        });
      }
      return prev;
    }, [] as IResearchGroupByCategory[])
    .sort(
      (a, b) => a.research_category.priority - b.research_category.priority
    );
};

export const getPathResearchView = (
  researchId: number,
  isEService: boolean
) => {
  if (isEService)
    return `${APP_ROUTE.E_SERVICE_RESEARCH_VIEW.path}/${researchId}`;
  else return `${APP_ROUTE.DBSV_RESEARCH_PDF_VIEW.path}/${researchId}`;
};

export const getPathResearchArchive = ({
  isEService,
  researchType,
  categoryId,
}: {
  researchType: ResearchType;
  isEService: boolean;
  categoryId?: number;
}) => {
  let path = "";
  if (isEService) {
    path = `${APP_ROUTE.E_SERVICE_RESEARCH_ARCHIVE.path}?type=${researchType}`;
  } else {
    path = `${APP_ROUTE.DBSV_RESEARCH_ARCHIVE.path}?type=${researchType}`;
  }
  return categoryId ? path + `&category=${categoryId}` : path;
};
