import {
  SVG_CHEVRON_LEFT,
  SVG_CHEVRON_RIGHT,
} from "../../../../const/svg.icon";

const PageBar = ({
  pageCount,
  currentPage,
  setCurrentPage,
}: {
  pageCount: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}) => {
  const isNotFirstPage = () => currentPage !== 1;
  const isNotLastPage = () => currentPage !== pageCount;
  const isCurrentPage = (page: number) => page === currentPage;

  return (
    <div className="flex justify-center items-center gap-2">
      <button
        className={`focus:outline-none ${isNotFirstPage() ? "" : "invisible"}`}
        onClick={() => setCurrentPage(currentPage - 1)}
      >
        <div className="flex w-12 h-12 stroke-current text-red-500">
          <div className="m-auto">{SVG_CHEVRON_LEFT}</div>
        </div>
      </button>
      {[...Array(pageCount)].map((_, i) => {
        return (
          <button
            className="focus:outline-none"
            onClick={() => setCurrentPage(i + 1)}
          >
            <div
              key={`page-button-${i}`}
              className={`flex w-12 h-12 ${
                isCurrentPage(i + 1) ? "text-red-500" : ""
              }`}
            >
              <p className="m-auto">{i + 1}</p>
            </div>
          </button>
        );
      })}
      <button
        className={`focus:outline-none ${isNotLastPage() ? "" : "invisible"}`}
        onClick={() => setCurrentPage(currentPage + 1)}
      >
        <div className="flex w-12 h-12 stroke-current text-red-500">
          <div className="m-auto">{SVG_CHEVRON_RIGHT}</div>
        </div>
      </button>
    </div>
  );
};

export default PageBar;
