import { useTranslation } from "react-i18next";
import { openWindow, openWindowWithBlankPage } from "../../helper/open.window";
import { isThaiLanguage } from "../../i18n/helper";
import { IMissions } from "../../services/ats.service";
import MissionCardPopup from "./MissionCardPopup";

const YourMissionDialog = ({
  onClose,
  backHome,
  missions,
  isFullScreen = false,
  replaceUrl = false,
}: {
  onClose?: () => void;
  backHome?: () => void;
  isFullScreen?: boolean;
  missions: IMissions[];
  replaceUrl?: boolean;
}) => {
  const { t: translate } = useTranslation();

  return (
    <div
      className={`fixed ${
        isFullScreen
          ? "h-screen w-screen z-50"
          : "inset-0 z-50 flex items-center justify-center"
      } `}
    >
      <div
        className={`flex ${
          isFullScreen
            ? "h-screen w-screen"
            : "items-center justify-center min-h-screen p-4 text-center opacity-100 sm:p-10"
        }`}
      >
        <div
          className="fixed inset-0 transition-opacity"
          aria-hidden="true"
          onClick={onClose}
        >
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div
          style={{ minWidth: "295px", width: "fit-content" }}
          className={`${
            isFullScreen
              ? `w-screen h-screen transition-all transform bg-white flex flex-col sm:my-8 sm:align-middle justify-items-end`
              : `"inline-block w-11/12 overflow-hidden text-left transition-all transform bg-white sm:my-8 sm:align-middle justify-items-end"`
          }`}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          data-testid="mission-dialog"
        >
          <div className="flex items-center w-full py-2.5 px-8 gap-x-4">
            <InfoFaqIcon />
            <div className="flex-grow">
              <p
                className="text-sm font-normal md:text-base text-second-900"
                data-testid="mission-dialog-title"
              >
                Your Mission
              </p>
              <p
                className="text-xs font-medium md:text-sm text-missionBannerTitle"
                data-testid="mission-dialog-subtitle"
              >
                {translate("yourMission.subTitle")}
              </p>
            </div>
            <button
              data-testid="mission-dialog-close-button"
              onClick={onClose}
              className="flex-none"
            >
              <CloseIcon />
            </button>
          </div>

          <div className="flex flex-wrap items-center justify-center gap-4 p-5 border md:gap-x-10">
            <RemittanceProcessing />
            <div
              className={`grid flex-grow grid-cols-1 gap-2 ${
                missions.length > 1 ? "sm:grid-cols-2" : "sm:grid-cols-1"
              } overflow-y-auto max-h-96 sm:max-h-42`}
            >
              {Array.isArray(missions) &&
                missions.map((m, index) => (
                  <MissionCardPopup
                    key={index}
                    title={isThaiLanguage() ? m.titleTH : m.titleEN}
                    index={index}
                    icon={m.image}
                    onClick={() => {
                      if (replaceUrl) {
                        return openWindow({
                          link: m.callback,
                          isPopup: false,
                          target: "_self",
                        });
                      }
                      openWindowWithBlankPage({
                        link: m.callback,
                        isPopup: false,
                      });
                    }}
                  />
                ))}
            </div>
          </div>

          <div
            className={`flex flex-col  items-center pt-3 ${
              isFullScreen ? "justify-between h-full  p-4" : "h-20"
            } bg-dbsv-neutral-slate-00`}
          >
            <div
              className="flex items-center gap-1 text-missionBannerDescription text-9px"
              data-testid="mission-dialog-info"
            >
              <CircleInfomationIcon />
              <p className="text-2xs md:text-xs">
                {translate("yourMission.info")}
              </p>
            </div>

            <button
              data-testid="mission-dialog-return-to-main-button"
              onClick={backHome}
              className="flex items-center gap-1 mt-3 justify-items-center text-missionGoMainButton"
            >
              <HomeIcon />
              <p className="text-2xs md:text-xs">
                {translate("yourMission.backToHome")}
              </p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const InfoFaqIcon = () => {
  return (
    <img
      className="flex-none w-8 h-8"
      src="/icons/your-mission/info-fags.png"
      alt={""}
    />
  );
};

const RemittanceProcessing = () => {
  return (
    <img
      className="w-20 h-20 md:w-32 md:h-32"
      src="/icons/your-mission/remittance-processing.png"
      alt={""}
    />
  );
};

const HomeIcon = () => {
  return (
    <img
      className="flex-none w-3 h-3"
      src="/icons/your-mission/home.svg"
      alt={""}
    />
  );
};

const CircleInfomationIcon = () => {
  return (
    <img
      className="flex-none w-4 h-4"
      src="/icons/your-mission/circle-information.svg"
      alt={""}
    />
  );
};

const CloseIcon = () => {
  return (
    <img
      className="flex-none w-3 h-3"
      src="/icons/your-mission/close-icon.png"
      alt={""}
    />
  );
};

export default YourMissionDialog;
