export const PrimaryButton = ({
  label,
  onClick,
  className,
}: {
  label: string;
  onClick: () => void;
  className?: string;
}) => {
  return (
    <button
      className={`text-white text-lg py-2 px-5 rounded-md ${className}`}
      style={{ background: "rgba(215, 55, 54, 1)" }}
      onClick={onClick}
    >
      {label}
    </button>
  );
};
