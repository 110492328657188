import { useTranslation } from "react-i18next";
import { openWindow } from "../../helper/open.window";
import { IKeywordsSearch } from "../../interfaces/keywords.search";

const SearchResult = ({
  searchResult,
  onClick,
}: {
  searchResult: IKeywordsSearch[] | null;
  onClick: (route: string) => void;
}) => {
  const { t: translate } = useTranslation();

  const Result = ({
    result,
    index,
  }: {
    result: IKeywordsSearch;
    index: number;
  }) => {
    return (
      <div
        key={`search-result-${index}`}
        className="px-4 py-3 cursor-pointer hover:bg-gray-50"
        onClick={async () => {
          var url = await result.url;
          if (!!url) return openWindow({ link: url });
          onClick(result.route);
        }}
      >
        {result.title}
      </div>
    );
  };

  if (searchResult == null) return <></>;
  if (!searchResult || searchResult.length === 0)
    return (
      <div className="flex flex-col items-center">
        <img
          src="/images/empty-result-search.png"
          className="px-24 mx-auto"
          alt="empty-result-search"
        />
        <h1 className="font-semibold">{translate("search.no_value")}</h1>
        <p className="text-sm text-center text-gray-400 whitespace-pre-line">
          {translate("search.no_value_description")}
        </p>
      </div>
    );
  return (
    <>
      {searchResult.map((result, index) => (
        <Result result={result} index={index} />
      ))}
    </>
  );
};

export default SearchResult;
