import { getDateInFormat } from "../../helper/date.time";
import {
  addCommaToNumber,
  seperateNumberWithComma,
} from "../../helper/format.number";
import {
  IContractNoteDataTable,
  IDerivSTTBrokerOutstanding,
  IDerivSTTBrokerPLClose,
  IDerivSTTBrokerPOSClose,
  IDerivSTTBrokerTrade,
  IDerivSTTLastTrade,
  IDerivSTTStatement,
  IOutstandingForeignCurrencyCollaterals,
} from "../../interfaces/contract.note";

export const getTradingTransactionSummaryData = (
  trade: IDerivSTTBrokerTrade[]
): { data: IContractNoteDataTable[]; summary: string[] } => {
  let sumPremiumAmount = 0,
    sumVat = 0,
    sumWh = 0,
    sumChargeAmount = 0,
    sumBrokerFee = 0;
  let data: IContractNoteDataTable[] = [];

  for (const item of trade) {
    sumPremiumAmount += Number(item.premiumAmount ?? 0);
    sumBrokerFee += Number(item.brokerageFee ?? 0);
    sumChargeAmount += Number(item.chargeAmount ?? 0);
    sumWh += Number(item.wh ?? 0);
    sumVat += Number(item.vat ?? 0);
    data.push({
      value: [
        item.instrumentCode ?? "",
        item.contractNo ?? "",
        item.bs ?? "",
        item.noOfContract ?? "",
        seperateNumberWithComma(item.dealPrice ?? "0"),
        seperateNumberWithComma(item.premiumAmount ?? "0"),
        seperateNumberWithComma(item.brokerageFee ?? "0"),
        seperateNumberWithComma(item.vat ?? "0"),
        seperateNumberWithComma(item.wh ?? "0"),
        seperateNumberWithComma(item.chargeAmount ?? "0"),
      ],
    });
  }
  return {
    data: data,
    summary: [
      seperateNumberWithComma(sumPremiumAmount.toString()),
      seperateNumberWithComma(sumBrokerFee.toString()),
      seperateNumberWithComma(sumVat.toString()),
      seperateNumberWithComma(sumWh.toString()),
      seperateNumberWithComma(sumChargeAmount.toString()),
    ],
  };
};

export const getPositionClosingData = (
  posClose: IDerivSTTBrokerPOSClose[],
  plClose: IDerivSTTBrokerPLClose[]
): { data: IContractNoteDataTable[]; summary: string[] } => {
  let sumFuture = 0,
    sumOptions = 0;

  let groupByBatchNo: { [key: string]: IDerivSTTBrokerPOSClose[] } = {};
  for (let item of posClose) {
    groupByBatchNo[item.batchNo] = [
      ...(groupByBatchNo[item.batchNo] ?? ""),
      item,
    ];
  }

  let data: IContractNoteDataTable[] = [];
  for (const [key, item] of Object.entries(groupByBatchNo)) {
    const pl = plClose.find((item) => item.batchNo === key);
    sumFuture += Number(pl?.realizePlFutures) ?? 0;
    sumOptions += Number(pl?.realizePlOptions) ?? 0;
    data.push(
      ...item.map((el, index) => {
        return {
          value: [
            index === 0 ? el.instrumentCode : "",
            getDateInFormat(el.tradeDate ?? "0"),
            addCommaToNumber(el.noOfLongContract) ?? "0",
            addCommaToNumber(el.noOfShortContract) ?? "0",
            seperateNumberWithComma(el.dealPrice) ?? "0",
            "",
            "",
          ],
        };
      })
    );
    data.push({
      sumRow: true,
      value: [
        "",
        "",
        "",
        "",
        "",
        seperateNumberWithComma(pl?.realizePlFutures ?? "0"),
        seperateNumberWithComma(pl?.realizePlOptions ?? "0"),
      ],
    });
  }
  return {
    data: data,
    summary: [
      seperateNumberWithComma(sumFuture.toString()),
      seperateNumberWithComma(sumOptions.toString()),
    ],
  };
};

export const getPositionAtLastTradingDay = (
  lastTrade: IDerivSTTLastTrade[]
): { data: IContractNoteDataTable[]; summary: string[] } => {
  let sumSettlementAmount = 0,
    sumVat = 0,
    sumWh = 0,
    sumChargeAmount = 0,
    sumBrokerFee = 0;
  let data: IContractNoteDataTable[] = [];

  for (const item of lastTrade) {
    sumSettlementAmount += Number(item.settlementAmount ?? 0);
    sumBrokerFee += Number(item.brokerageFee ?? 0);
    sumChargeAmount += Number(item.chargeAmount ?? 0);
    sumWh += Number(item.wh ?? 0);
    sumVat += Number(item.vat ?? 0);
    data.push({
      value: [
        item.action ?? "",
        item.longShort ?? "",
        addCommaToNumber(item.noOfContract ?? "0"),
        seperateNumberWithComma(item.finalSettlementPirce ?? "0"),
        seperateNumberWithComma(item.settlementAmount ?? "0"),
        seperateNumberWithComma(item.brokerageFee ?? "0"),
        seperateNumberWithComma(item.vat ?? "0"),
        seperateNumberWithComma(item.wh ?? "0"),
        seperateNumberWithComma(item.chargeAmount ?? "0"),
      ],
    });
  }
  return {
    data: data,
    summary: [
      seperateNumberWithComma(sumSettlementAmount.toString()),
      seperateNumberWithComma(sumBrokerFee.toString()),
      seperateNumberWithComma(sumVat.toString()),
      seperateNumberWithComma(sumWh.toString()),
      seperateNumberWithComma(sumChargeAmount.toString()),
    ],
  };
};

export const getOutstandingPosition = (
  outstanding: IDerivSTTBrokerOutstanding[]
): { data: IContractNoteDataTable[]; summary: string[] } => {
  let sumUnrealizedPL = 0,
    sumLong = 0,
    sumShort = 0;
  let data: IContractNoteDataTable[] = [];

  for (const item of outstanding) {
    sumUnrealizedPL += Number(item.unrealizePl ?? 0);
    sumLong += Number(item.longOptionValue ?? 0);
    sumShort += Number(item.shortOptionValue ?? 0);
    data.push({
      value: [
        item.instrumentCode ?? "",
        addCommaToNumber(item.noOfLongContract ?? "0"),
        addCommaToNumber(item.noOfShortContract ?? "0"),
        seperateNumberWithComma(item.costPrice ?? "0"),
        seperateNumberWithComma(item.settlementPrice ?? "0"),
        seperateNumberWithComma(item.unrealizePl ?? "0"),
        seperateNumberWithComma(item.longOptionValue ?? "0"),
        seperateNumberWithComma(item.shortOptionValue ?? "0"),
      ],
    });
  }
  return {
    data: data,
    summary: [
      seperateNumberWithComma(sumUnrealizedPL.toString()),
      seperateNumberWithComma(sumLong.toString()),
      seperateNumberWithComma(sumShort.toString()),
    ],
  };
};

export const getOutstandingFCCYCollateral = (
  fccyCollateral: IOutstandingForeignCurrencyCollaterals[]
): { data: IContractNoteDataTable[]; summary: string[] } => {
  let sumMaxOfSettlementRequire = 0;
  let data: IContractNoteDataTable[] = [];

  for (const item of fccyCollateral) {
    sumMaxOfSettlementRequire += Number(item.maxForSettlementRequire ?? 0);
    data.push({
      value: [
        item.currency ?? "",
        seperateNumberWithComma(item.amount ?? 0),
        seperateNumberWithComma(item.fxRate ?? 0),
        seperateNumberWithComma(item.valueInTHB ?? 0),
        seperateNumberWithComma(item.colForCurrencyAmount ?? 0),
        seperateNumberWithComma(item.maxForSettlementRequire ?? 0),
      ],
    });
  }
  return {
    data: data,
    summary: [seperateNumberWithComma(sumMaxOfSettlementRequire.toString())],
  };
};

export const getAccountStatement = (
  statement: IDerivSTTStatement[]
): {
  first: IContractNoteDataTable[];
  second: IContractNoteDataTable[];
  third: IContractNoteDataTable[];
} => {
  let first: IContractNoteDataTable[] = [];
  let second: IContractNoteDataTable[] = [];
  let third: IContractNoteDataTable[] = [];

  for (const item of statement) {
    first.push({
      value: [
        seperateNumberWithComma(item.beginingCashBl ?? "0"),
        seperateNumberWithComma(item.endingCashBl ?? "0"),
        seperateNumberWithComma(item.nonCashCol ?? "0"),
        seperateNumberWithComma(item.realizePlFuture ?? "0"),
        seperateNumberWithComma(item.unrealizePl ?? "0"),
        seperateNumberWithComma(item.advanceWithdrawal ?? "0"),
      ],
    });
    second.push({
      value: [
        seperateNumberWithComma(item.beginingEquityBalance ?? "0"),
        seperateNumberWithComma(item.endingEquityBalance ?? "0"),
        seperateNumberWithComma(item.initialMargin ?? "0"),
        seperateNumberWithComma(item.maintenanceMargin ?? "0"),
        seperateNumberWithComma(item.excess ?? "0"),
        seperateNumberWithComma(item.netCustomerPaid ?? "0"),
      ],
    });
    third.push({
      value: [
        seperateNumberWithComma(item.deferredCharge ?? "0"),
        "",
        "",
        "",
        "",
        "",
      ],
    });
  }
  return {
    first: first,
    second: second,
    third: third,
  };
};
