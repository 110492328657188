import i18n from "i18next";
import en from "./locales/en/translation.json";
import th from "./locales/th/translation.json";
import { initReactI18next } from "react-i18next";

export const resources = {
  en: {
    translation: en,
  },
  th: {
    translation: th,
  },
} as const;

i18n.use(initReactI18next).init({
  lng: "en",
  resources,
});
