import Tooltip from "../Tooltip";

export const Card = ({
  icon,
  action,
  title,
  subTitle,
  children,
  tooltip,
  childrenStyle = "py-5",
}: {
  icon: JSX.Element;
  action?: JSX.Element;
  title: string;
  subTitle?: JSX.Element;
  children: JSX.Element;
  tooltip?: {
    title: string;
    description: string;
  };
  childrenStyle?: string;
}) => {
  return (
    <div className="w-full px-4 py-3 bg-white rounded-xl">
      <div className="flex flex-row items-center justify-between h-10">
        <div className="flex flex-row items-center gap-x-2">
          <div className="w-6 h-6">{icon}</div>
          <div className="ml-2 text-xs font-bold md:text-base text-dbsv-global-black">
            {title} {subTitle}
          </div>
          {!!tooltip && <Tooltip tooltip={tooltip} />}
        </div>
        {action}
      </div>
      <div className={childrenStyle}>{children}</div>
    </div>
  );
};
