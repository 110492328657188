export const HeaderModalDetail = ({
  leftHeader,
  rightHeader,
}: {
  leftHeader: string;
  rightHeader: string;
}) => {
  return (
    <div className="flex justify-between mt-4">
      <div className="flex">
        <span>{leftHeader}</span>
      </div>
      <div className="flex text-right">
        <span>{rightHeader}</span>
      </div>
    </div>
  );
};
