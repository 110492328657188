const IconButton = ({
  Icon,
  label,
  onClick,
}: {
  Icon: JSX.Element;
  label: string;
  onClick: () => void;
}) => {
  return (
    <div className="flex flex-col items-center py-2 gap-y-2">
      <div
        onClick={onClick}
        className="flex flex-col items-center cursor-pointer gap-y-1"
      >
        <div className="border border-dbsv-neutral-slate-10 rounded-full p-1.5 w-10 h-10 flex items-center justify-center">
          {Icon}
        </div>
        <p className="text-xs font-normal text-center break-words">{label}</p>
      </div>
    </div>
  );
};

export default IconButton;
