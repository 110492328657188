import { AccountType } from "../../../enum/account";
import { IConfirmationReportDetail } from "../../../interfaces/confirmation.report.detail";
import { IConfirmationReportList } from "../../../interfaces/confirmation.report.lists";
import { IContractNoteDetail } from "../../../interfaces/contract.note";
import {
  IDailyCashMovement,
  IDailyStockMovement,
} from "../../../interfaces/daily-movment";
import { IEStatementResponse } from "../../../interfaces/e-statement";
import { isEquityAccount } from "../../MyInvestment/helper";
import { AccountInfo } from "../AccountInfo";
import { AccountMenuItem } from "../AccountMenuItem";

export interface AccountDefaultTypeProps {
  accountNo?: string;
  accountType?: AccountType;
  dailyCashMovement?: IDailyCashMovement;
  dailyStockMovement?: IDailyStockMovement;
  dailyConfirmation?: {
    confirmation: IConfirmationReportList;
    confirmationDetails: IConfirmationReportDetail[];
  };
  dailyContractNote?: IContractNoteDetail;
  eMonthlyStatement?: IEStatementResponse;
  onOpenStreaming: () => void;
  onOpenCashDeposit: () => void;
  onOpenCashWithdrawal: () => void;
  onOpenDailyConfirmation: () => void;
  onOpenDailyContractNote: () => void;
  onDownloadEStatement: () => void;
}

export const AccountDefaultType: React.FC<AccountDefaultTypeProps> = ({
  accountNo = "",
  accountType,
  dailyCashMovement,
  dailyStockMovement,
  dailyConfirmation,
  dailyContractNote,
  eMonthlyStatement,
  onOpenStreaming,
  onOpenCashDeposit,
  onOpenCashWithdrawal,
  onOpenDailyConfirmation,
  onOpenDailyContractNote,
  onDownloadEStatement,
}) => {
  return (
    <div
      data-testid="account-default-type"
      className="flex flex-row flex-wrap p-5 pb-0 gap-y-4"
    >
      <AccountInfo accountNo={accountNo} accountType={accountType} />
      <div className="flex flex-row flex-1 gap-4 pb-8 pl-6 overflow-hidden overflow-x-auto">
        <AccountMenuItem type="streaming" onClick={onOpenStreaming} />
        <AccountMenuItem type="deposit" onClick={onOpenCashDeposit} />
        <AccountMenuItem type="withdrawal" onClick={onOpenCashWithdrawal} />
        {isEquityAccount(accountType) ? (
          <>
            {(!!dailyCashMovement?.in || !!dailyCashMovement?.out) && (
              <AccountMenuItem
                type="cashmovement"
                inflow={dailyCashMovement?.in}
                outflow={dailyCashMovement?.out}
                asOf={dailyCashMovement.asOfDate}
                onClick={() => {}}
              />
            )}
            {(!!dailyStockMovement?.in || !!dailyStockMovement?.out) && (
              <AccountMenuItem
                type="stockmovement"
                inflow={dailyStockMovement?.in}
                outflow={dailyStockMovement?.out}
                asOf={dailyStockMovement.asOfDate}
                onClick={() => {}}
              />
            )}
            {dailyConfirmation?.confirmation &&
              dailyConfirmation.confirmationDetails && (
                <AccountMenuItem
                  type="dailyconfirmation"
                  asOf={dailyConfirmation.confirmation.tradeDate}
                  onClick={onOpenDailyConfirmation}
                />
              )}
          </>
        ) : (
          <>
            {!!dailyContractNote && (
              <AccountMenuItem
                type="contractnote"
                asOf={new Date(dailyContractNote.header.tradeDate)}
                onClick={onOpenDailyContractNote}
              />
            )}
          </>
        )}
        {!!eMonthlyStatement && (
          <AccountMenuItem
            type="e-monthly-statement"
            asOf={new Date()}
            onClick={onDownloadEStatement}
          />
        )}
      </div>
    </div>
  );
};
