import { TFunction } from "i18next";
import { IHandleModal } from "../interfaces/handle.modal";

export const getErrorMessageGetDataFail = (
  translate: TFunction
): IHandleModal => {
  return {
    isShow: true,
    title: translate("alert.default_title"),
    action: "",
    description: translate("alert.get_data_error"),
    submitText: "",
  };
};

export const initIHandleModal = {
  isShow: false,
  title: "",
  description: "",
  action: "",
  submitText: "",
};
