import React, { createContext, useContext, useState } from "react";
import AlertDialog from "../../components/AlertDialog";
import Loading from "../../components/Loading";
import { initIHandleModal } from "../../helper/modal.message";
import { IHandleModal } from "../../interfaces/handle.modal";

interface IHandleAPIStateContext {
  gettingData: boolean;
  getDataError: IHandleModal;
  setGettingData: React.Dispatch<React.SetStateAction<boolean>>;
  setGetDataEror: React.Dispatch<React.SetStateAction<IHandleModal>>;
}
const HandleAPIStateContext = createContext<IHandleAPIStateContext>({} as any);

export const HandleAPIStateContextWrapper = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const [gettingData, setGettingData] = useState<boolean>(false);
  const [getDataError, setGetDataEror] =
    useState<IHandleModal>(initIHandleModal);

  const shareState = {
    gettingData,
    getDataError,
    setGetDataEror,
    setGettingData,
  };

  return (
    <>
      {getDataError.isShow && (
        <AlertDialog
          title={getDataError.title}
          description={getDataError.description}
          onSubmit={() => {
            setGetDataEror(initIHandleModal);
            if (getDataError.onConfirm) {
              getDataError.onConfirm();
            }
          }}
        />
      )}
      {gettingData && <Loading />}
      <HandleAPIStateContext.Provider value={shareState}>
        {children}
      </HandleAPIStateContext.Provider>
    </>
  );
};

export const useHandleAPIStateContext = () => {
  return useContext(HandleAPIStateContext);
};
