/* eslint-disable react-hooks/exhaustive-deps */
import { ReactComponent as GiveHandHeartIcon } from "../../svgIcon/new-dashboard/research/give-hand-heart.svg";
import { Card } from "../Card";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { APP_ROUTE } from "../../const/path";
import { useResearchContext } from "../../context/research";
import { ResearchType } from "../../enum/research";
import { getDateNormalFormat, getDateTimeFormat } from "../../helper/date.time";
import { isThaiLanguage } from "../../i18n/helper";
import { IResearch } from "../../interfaces/research";
import { getPathResearchArchive } from "../../pages/Research/helper";
import ResearchCarousel from "./ResearchCarousel";
import { ResearchDescription } from "./ResearchDescription";

export const Research = () => {
  const { getResearchItems } = useResearchContext();
  const { t: translate } = useTranslation();
  const history = useHistory();

  const [thaiResearches, setThaiResearches] = useState<IResearch[]>([]);
  const [overseaResearches, setOverseaResearches] = useState<IResearch[]>([]);

  useEffect(() => {
    getResearchItems({ researchType: ResearchType.THAI, latest: true }).then(
      setThaiResearches
    );
    getResearchItems({ researchType: ResearchType.OVERSEA, latest: true }).then(
      setOverseaResearches
    );
  }, []);

  const goToResearch = (researchType: ResearchType) => {
    history.push(getPathResearchArchive({ isEService: false, researchType }));
  };

  const getLastUpdateDateOfThaiResearch = (researches: IResearch[]) => {
    if (!researches.length) return "-";
    const firstResearch = researches[0];
    return getDateNormalFormat(
      firstResearch.post_date
        ? firstResearch.post_date?.toString()
        : firstResearch.updated_at?.toString()
    );
  };

  function getMondayAndFridayDates(): string {
    const today = new Date();
    const dayOfWeek = today.getDay();

    const mondayOffset = (dayOfWeek === 0 ? -6 : 1) - dayOfWeek;
    const fridayOffset = (dayOfWeek === 0 ? -2 : 5) - dayOfWeek;

    const mondayDate = new Date(today);
    mondayDate.setDate(today.getDate() + mondayOffset);

    const fridayDate = new Date(today);
    fridayDate.setDate(today.getDate() + fridayOffset);

    if (isThaiLanguage()) {
      const startDateTimeFormated = getDateTimeFormat(mondayDate, {
        day: "numeric",
        month: "long",
      }).split(" ");
      const startDate = startDateTimeFormated[0];
      const startMonth = startDateTimeFormated[1];

      const endDateTimeFormated = getDateTimeFormat(fridayDate, {
        day: "numeric",
        month: "long",
        year: "numeric",
      }).split(" ");
      const endDate = endDateTimeFormated[0];
      const endMonth = endDateTimeFormated[1];
      const endYear = endDateTimeFormated[2];

      return `${startDate} ${
        startMonth !== endMonth ? startMonth : ""
      } - ${endDate} ${endMonth} ${endYear}`;
    } else {
      const startDateTimeFormated = getDateTimeFormat(mondayDate, {
        day: "numeric",
        month: "long",
      }).split(" ");
      const startDate = startDateTimeFormated[1];
      const startMonth = startDateTimeFormated[0];

      const endDateTimeFormated = getDateTimeFormat(fridayDate, {
        day: "numeric",
        month: "long",
        year: "numeric",
      }).split(" ");
      const endDate = endDateTimeFormated[1].replace(",", "");
      const endMonth = endDateTimeFormated[0];
      const endYear = endDateTimeFormated[2];

      return `${startMonth} ${startDate} - ${
        startMonth === endMonth ? "" : endMonth
      } ${endDate}, ${endYear}`;
    }
  }

  const onReadResearch = (researchId: number): void => {
    history.push(`${APP_ROUTE.DBSV_RESEARCH_PDF_VIEW.path}/${researchId}`);
  };

  return (
    <Card icon={<GiveHandHeartIcon />} title="Research">
      <div>
        <div className="flex flex-col gap-3 md:flex-row">
          <ResearchDescription
            title={translate("latestResearch.thaiResearch")}
            description={translate("latestResearch.thaiResearchDescription")}
            iconPath={"/icons/research/thai-research-icon.png"}
          />
          <ResearchCarousel
            onClickItem={onReadResearch}
            title={`Last Update : ${getLastUpdateDateOfThaiResearch(
              thaiResearches
            )}`}
            researches={thaiResearches}
            onClickViewAll={() => goToResearch(ResearchType.THAI)}
          />
        </div>
        <div
          className="w-full h-px my-5"
          style={{ backgroundColor: "#DDE3E7" }}
        />
        <div className="flex flex-col gap-3 md:flex-row">
          <ResearchDescription
            title={translate("latestResearch.overseaResearch")}
            description={translate("latestResearch.overseaResearchDescription")}
            iconPath={"/icons/research/oversea-research-icon.png"}
          />
          <ResearchCarousel
            onClickItem={onReadResearch}
            title={`Weekly Update : ${getMondayAndFridayDates()}`}
            researches={overseaResearches}
            onClickViewAll={() => goToResearch(ResearchType.OVERSEA)}
          />
        </div>
      </div>
    </Card>
  );
};
