import styles from "./style.module.scss";

const LoadingTab = () => {
  return (
    <div className="flex items-center justify-center w-48 h-10 py-4 pl-2 pr-4 space-y-4 border-b rounded-t bg-dbsv-neutral-slate-00 bg-opacity-30 border-dbsv-neutral-slate-20">
      <div
        className={`${styles.loader} ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6 my-3`}
      />
    </div>
  );
};

export default LoadingTab;
