import { useTranslation } from "react-i18next";
import { useUserContext } from "../../context/user/user.context";
import { BTFLevel } from "../../enum/rewards";
import { ReactComponent as CheckIcon } from "../../svgIcon/check-circle.svg";

export const UltimateLevel = () => {
  const { t: translate } = useTranslation();
  const { userRewards } = useUserContext();

  const isUltimateLevel = () => userRewards.btfLevel === BTFLevel.ULTIMATE;

  const UpgradingDesciption = () => {
    if (isUltimateLevel())
      return (
        <div
          className="flex flex-row items-start justify-center mt-3 text-xs whitespace-pre-line"
          data-testid="upgrading-description"
        >
          <p>
            {translate(
              "rewards.overseas_trading_mission.ultimate_level_upgrading"
            )}
          </p>
        </div>
      );
    return <></>;
  };

  return (
    <div className="flex flex-col xl:w-48 lg:w-40">
      <div
        className={`flex flex-col bg-dbsvRewards-bgContent ${
          isUltimateLevel() 
            ? "border-solid"
            : "border-dashed"
        } border border-dbsvRewards-ultimateLevel rounded-lg px-3 py-4 text-center items-center xl:h-40 lg:h-44`}
        data-testid="ultimate-level-section"
      >
        <p data-testid="title-section">Ultimate Level</p>
        {isUltimateLevel() ? (
          <CheckIcon
            className="w-12 h-12 my-3 text-dbsvRewards-ultimateLevel"
            data-testid="check-icon"
          />
        ) : (
          <img
            src="/icons/rewards/ultimate-badge.png"
            className="w-12 my-3"
            alt="ultimate-badge"
          />
        )}
        <p
          className="text-xs whitespace-pre-line"
          data-testid="ultimate-level-desc"
        >
          {translate("rewards.overseas_trading_mission.ultimate_level_desc")}
        </p>
      </div>
      <UpgradingDesciption />
    </div>
  );
};
