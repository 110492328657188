import { DateTimeFormatOptions } from "luxon";
import { IMyInvestmentTab } from ".";
import { AccountType } from "../../enum/account";
import { MyInvestmentShortCutType } from "./Shortcut";
import { IAccountInfo } from "../../interfaces/user";

export const isEquityAccount = (accountType?: AccountType) =>
  accountType === AccountType.ITP;

export const isActive = (
  tab: IMyInvestmentTab,
  currentTab?: IMyInvestmentTab
) => {
  return (
    tab.accountNo === currentTab?.accountNo && tab.type === currentTab?.type
  );
};

export const getDateFormatConfig = (
  isMonthly?: boolean
): DateTimeFormatOptions => {
  return isMonthly
    ? {
        month: "short",
        year: "numeric",
      }
    : {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      };
};

export const getShortCutDetail = (type: MyInvestmentShortCutType) => {
  switch (type) {
    case "streaming":
      return {
        color: "bg-streaming",
        label: "myInvestment.streaming.label",
        iconPath: "/icons/myInvestment/streaming-icon.png",
        button: "myInvestment.streaming.button",
      };
    case "streaming-mobile":
      return {
        color: "bg-streaming",
        label: "myInvestment.streaming-mobile.label",
        iconPath: "/icons/myInvestment/streaming-icon.png",
        button: "myInvestment.streaming-mobile.button",
      };
    case "deposit":
      return {
        color: "bg-deposit",
        label: "myInvestment.deposit.label",
        iconPath: "/icons/myInvestment/deposit-icon.png",
        button: "myInvestment.deposit.button",
      };
    case "cashmovement":
      return {
        color: "bg-cashMovement",
        label: "myInvestment.cashMovement.label",
        iconPath: "/icons/myInvestment/cash-movement-icon.png",
      };
    case "stockmovement":
      return {
        color: "bg-stockMovement",
        label: "myInvestment.stockMovement.label",
        iconPath: "/icons/myInvestment/stock-movement-icon.png",
      };
    case "dailyconfirmation":
      return {
        color: "bg-dailyConfirmation",
        label: "myInvestment.dailyConfirmation.label",
        iconPath: "/icons/myInvestment/daily-icon.png",
        button: "myInvestment.dailyConfirmation.button",
      };
    case "e-monthly-statement":
      return {
        color: "bg-red-500",
        label: "myInvestment.eMonthlyStatement.label",
        iconPath: "/icons/myInvestment/e-monthly-icon.png",
        button: "myInvestment.eMonthlyStatement.button",
      };
    case "contract-note":
      return {
        color: "bg-contractNote",
        label: "myInvestment.contractNote.label",
        iconPath: "/icons/myInvestment/contract-note-icon.png",
        button: "myInvestment.contractNote.button",
      };
    case "wealthbox":
      return {
        color: "bg-streaming",
        label: "myInvestment.wealthbox.label",
        iconPath: "/icons/myInvestment/wealthbox-icon.png",
        button: "myInvestment.wealthbox.button",
      };
  }
};

export const arrageAccountTaps = (
  accountInfo: IAccountInfo
): IMyInvestmentTab[] => {
  const equityAccounts: IMyInvestmentTab[] = [];
  const drxAccounts: IMyInvestmentTab[] = [];
  const derivAccount: IMyInvestmentTab[] = [];
  const fundAccounts: IMyInvestmentTab[] = [];

  const accountSorted = accountInfo.accounts.sort((a, b) =>
    a.accountNo.localeCompare(b.accountNo)
  );
  for (const acc of accountSorted) {
    const constructedAccount = { accountNo: acc.accountNo, type: acc.systemId };
    if (acc.systemId === AccountType.ITP)
      equityAccounts.push(constructedAccount);
    else if (acc.systemId === AccountType.FRAC)
      drxAccounts.push(constructedAccount);
    else if (acc.systemId === AccountType.DERIV)
      derivAccount.push(constructedAccount);
    else fundAccounts.push(constructedAccount);
  }

  return equityAccounts
    .concat(drxAccounts)
    .concat(derivAccount)
    .concat(fundAccounts);
};
