/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";
import { IResearch } from "../interfaces/research";
import CMSService from "../services/cms.service";

const ResearchContext = createContext({} as any);

export const ResearchContextWrapper = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const [researches, setResearches] = useState<IResearch[]>([]);

  const getResearchItems = new CMSService().getResearchItems;
  const getResearchItemCount = new CMSService().getResearchItemCount;
  const getResearchCategory = new CMSService().getResearchCategory;

  useEffect(() => {
    getResearchItems({ latest: false }).then((value) => setResearches(value));
  }, []);

  const shareState = {
    researches,
    getResearchItems,
    getResearchItemCount,
    getResearchCategory,
  };
  return (
    <ResearchContext.Provider value={shareState}>
      {children}
    </ResearchContext.Provider>
  );
};

export const useResearchContext = () => {
  return useContext(ResearchContext);
};
