import { getDateInFormat } from "../../helper/date.time";
import { seperateNumberWithComma } from "../../helper/format.number";
import { IConfirmationReportDetail } from "../../interfaces/confirmation.report.detail";
import { IConfirmationReportList } from "../../interfaces/confirmation.report.lists";
import { HeaderModal } from "../HeaderModal";
import { HeaderModalDetail } from "../HeaderModalDetail";
import { ModalDetail } from "../ModalDetail";
import { getTotal } from "./helper";

export const ComfirmationReportDetail = ({
  confirmation,
  confirmationDetails,
  closeModal,
}: {
  confirmation: IConfirmationReportList;
  confirmationDetails: IConfirmationReportDetail[];
  closeModal: () => void;
}) => {
  const {
    amountTotal,
    clearingTotal,
    commTotal,
    tradingTotal,
    vatTotal,
    totalBuyAmount,
    totalSellAmount,
  } = getTotal(confirmationDetails);

  const TableHeader = ({
    title,
    rowSpan,
    colSpan,
  }: {
    title: string;
    rowSpan?: number;
    colSpan?: number;
  }) => {
    return (
      <th
        rowSpan={rowSpan ?? 2}
        colSpan={colSpan ?? 1}
        className="px-3 py-4 text-center"
      >
        {title}
      </th>
    );
  };
  const SubHeader = ({ title }: { title: string }) => {
    return <th className="border-black border px-2 py-1">{title}</th>;
  };

  /** alignment default [center] */
  const TableField = ({
    value,
    alignment,
  }: {
    value: string;
    alignment: "center" | "right";
  }) => {
    return <td className={`px-3 py-4 text-${alignment}`}>{value ?? "-"}</td>;
  };

  const TotalRecord = ({
    title,
    value1,
    value2,
    value3,
    value4,
    value5,
    value6,
  }: {
    title: string;
    value1?: string;
    value2?: string;
    value3?: string;
    value4?: string;
    value5?: string;
    value6?: string;
  }) => {
    return (
      <tr
        className="text-white divide-x divide-white border-r border-l border-black border-b"
        style={{ borderBottomColor: "white" }}
      >
        <td colSpan={5} className="bg-dropdown-700 p-3">
          {title}
        </td>
        <td className="bg-dropdown-700 p-3 text-right">{value1 ?? ""}</td>
        <td className="bg-dropdown-700 p-3 text-right">{value2 ?? ""}</td>
        <td className="bg-dropdown-700 p-3 text-right">{value3 ?? ""}</td>
        <td className="bg-dropdown-700 p-3 text-right">{value4 ?? ""}</td>
        <td className="bg-dropdown-700 p-3 text-right">{value5 ?? ""}</td>
        <td colSpan={2} className="bg-dropdown-700 p-3 text-right">
          {value6 ?? ""}
        </td>
      </tr>
    );
  };

  return (
    <ModalDetail
      closeModal={() => closeModal()}
      chlidren={
        <div>
          <HeaderModal title="Daily Confirmation" />
          <HeaderModalDetail
            leftHeader={"Account No: " + confirmation.accountNo}
            rightHeader={
              "Trade Date: " +
              getDateInFormat(confirmation.tradeDate.toString())
            }
          />
          <HeaderModalDetail
            leftHeader={"Document No: " + confirmation.docNo}
            rightHeader={
              "Settlement Date: " +
              getDateInFormat(confirmation.dueDate.toString())
            }
          />
          <div className="w-full overflow-auto mt-4">
            <table className="w-full">
              <thead>
                <tr className="table-header-cell-default">
                  <TableHeader title="No" />
                  <TableHeader title="Symbol" />
                  <TableHeader title="Buy/Sell" />
                  <TableHeader title="Unit" />
                  <TableHeader title="Unit Price" />
                  <TableHeader title="Amount" />
                  <TableHeader title="FEE" colSpan={3} rowSpan={1} />
                  <TableHeader title="Vat" />
                  <TableHeader title="Buy Amount" />
                  <TableHeader title="Sell Amount" />
                </tr>
                <tr className="table-header-cell-default">
                  <SubHeader title="Comm." />
                  <SubHeader title="Tradeing" />
                  <SubHeader title="Clearing" />
                </tr>
              </thead>
              <tbody>
                {confirmationDetails.map((confirmationDetail, index) => {
                  return (
                    <tr
                      key={`confirmation-detail-record-${index}`}
                      className="table-cell-default"
                    >
                      <TableField
                        value={(index + 1).toString()}
                        alignment="center"
                      />

                      <TableField
                        value={confirmationDetail.stock}
                        alignment="center"
                      />
                      <TableField
                        value={confirmationDetail.refType}
                        alignment="center"
                      />
                      <TableField
                        value={seperateNumberWithComma(
                          confirmationDetail.quantity
                        )}
                        alignment="right"
                      />
                      <TableField
                        value={seperateNumberWithComma(
                          confirmationDetail.dealPrice
                        )}
                        alignment="right"
                      />
                      <TableField
                        value={seperateNumberWithComma(
                          confirmationDetail.grossAmount
                        )}
                        alignment="right"
                      />
                      <TableField
                        value={seperateNumberWithComma(
                          confirmationDetail.commAmount
                        )}
                        alignment="right"
                      />
                      <TableField
                        value={seperateNumberWithComma(
                          confirmationDetail.tradingFee
                        )}
                        alignment="right"
                      />
                      <TableField
                        value={seperateNumberWithComma(
                          confirmationDetail.clearingFee
                        )}
                        alignment="right"
                      />
                      <TableField
                        value={seperateNumberWithComma(
                          confirmationDetail.vatAmount
                        )}
                        alignment="right"
                      />
                      <TableField
                        value={
                          confirmationDetail.refType === "B"
                            ? seperateNumberWithComma(
                                confirmationDetail.netAmount
                              )
                            : ""
                        }
                        alignment="right"
                      />
                      <TableField
                        value={
                          confirmationDetail.refType === "S"
                            ? seperateNumberWithComma(
                                confirmationDetail.netAmount
                              )
                            : ""
                        }
                        alignment="right"
                      />
                    </tr>
                  );
                })}
                <TotalRecord
                  title="Grand Total"
                  value1={seperateNumberWithComma(amountTotal.toString())}
                  value2={seperateNumberWithComma(commTotal.toString())}
                  value3={seperateNumberWithComma(tradingTotal.toString())}
                  value4={seperateNumberWithComma(clearingTotal.toString())}
                  value5={seperateNumberWithComma(vatTotal.toString())}
                />
                <TotalRecord
                  title="ATS FEE"
                  value6={seperateNumberWithComma(
                    Number(confirmation.atsFee).toFixed(2)
                  )}
                />
                <TotalRecord
                  title="Net Customer Pay"
                  value6={
                    totalBuyAmount - totalSellAmount > 0
                      ? seperateNumberWithComma(
                          (totalBuyAmount - totalSellAmount).toFixed(2)
                        )
                      : ""
                  }
                />
                <TotalRecord
                  title="Net Customer Receive"
                  value6={
                    totalBuyAmount - totalSellAmount < 0
                      ? seperateNumberWithComma(
                          (totalSellAmount - totalBuyAmount).toFixed(2)
                        )
                      : ""
                  }
                />
              </tbody>
            </table>
          </div>
        </div>
      }
    />
  );
};
