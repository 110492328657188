import { useTranslation } from "react-i18next";
import { getDateInFormat } from "../../../helper/date.time";
import { MovementCard } from "./../MovementCard";
import { AccountItemAction } from "./AccountItemAction";
import { AccountItemIcon } from "./AccountItemIcon";
import { AccountItemLabel } from "./AccountItemLabel";
import {
  accountMenuItems,
  AccountMovementMenus,
  AccountPortfolioMenus,
} from "./helper";

type AccountMenuMovementItemProps = {
  type: AccountMovementMenus;
  inflow: number;
  outflow: number;
  asOf: Date;
};

type AccountMenuPortfolioItemProps = {
  type: AccountPortfolioMenus;
  asOf?: Date;
};

type TAccountMenuItem =
  | AccountMenuMovementItemProps
  | AccountMenuPortfolioItemProps;

type AccountMenuItemProps = { onClick: () => void } & TAccountMenuItem;

export const AccountMenuItem = (props: AccountMenuItemProps) => {
  const { t: translate } = useTranslation();

  const getDescription = (props: AccountMenuItemProps) => {
    if (
      props.type === "cashmovement" ||
      props.type === "stockmovement" ||
      props.type === "dailyconfirmation" ||
      props.type === "contractnote" ||
      props.type === "e-monthly-statement"
    ) {
      return props?.asOf
        ? `${translate("myInvestment.asOf")} ${getDateInFormat(
            new Date(props.asOf).toISOString(),
            props.type !== "e-monthly-statement" ? "dd/MM/yyyy" : "MMM yyyy"
          )}`
        : "";
    }
    return "By account type or account no.";
  };

  const renderAction = (item: TAccountMenuItem) => {
    if (item.type === "cashmovement" || item.type === "stockmovement") {
      return (
        <div
          className="flex justify-center cursor-pointer md:justify-start"
          onClick={props.onClick}
        >
          <MovementCard
            inflow={item.inflow}
            outflow={item.outflow}
            type={item.type}
          />
        </div>
      );
    }
    return (
      <div
        className="flex justify-center cursor-pointer md:pl-12 md:justify-start"
        onClick={props.onClick}
      >
        <AccountItemAction type={item.type} />
      </div>
    );
  };

  return (
    <div
      data-testid={props.type + "-account-menu-item"}
      className="flex flex-col p-5 bg-cover w-72 md:h-40 h-36 md:w-96 md:min-w-96 gap-y-2 md:gap-y-3"
      style={{
        backgroundImage: "url('/images/accounts/account-menu-bg.png')",
        backgroundColor: accountMenuItems.getBackgroundColor(props.type),
        borderRadius: "20px",
        boxShadow: "0px 15px 25px 0px rgba(105, 115, 123, 0.15)",
      }}
    >
      <div className="flex flex-row gap-x-3">
        <AccountItemIcon type={props.type} />
        <div className="flex flex-col gap-y-1">
          <AccountItemLabel type={props.type} />
          <div className="text-xs text-white">{getDescription(props)}</div>
        </div>
      </div>
      {renderAction(props)}
    </div>
  );
};
