import { getDateInFormat } from "../../helper/date.time";
import { seperateNumberWithComma } from "../../helper/format.number";
import { IMonthlyReport } from "../../interfaces/monthly.report.list";
import { IStockStatement } from "../../interfaces/stock.statement";
import { HeaderModal } from "../HeaderModal";
import { HeaderModalDetail } from "../HeaderModalDetail";
import { ModalDetail } from "../ModalDetail";

export const StockStatementDetail = ({
  closeModal,
  monthlyReport,
  stockStatements,
}: {
  closeModal: () => void;
  monthlyReport: IMonthlyReport;
  stockStatements: IStockStatement[];
}) => {
  return (
    <ModalDetail
      closeModal={() => closeModal()}
      chlidren={
        <div>
          <HeaderModal title="Monthly Stock Statement" />
          <HeaderModalDetail
            leftHeader={"Account No: " + monthlyReport.accountNo}
            rightHeader={
              "As of Date: " +
              getDateInFormat(monthlyReport.asOfDate.toString())
            }
          />
          <div className="grid grid-cols-11 mt-4 table-header-cell-default">
            <div className="col-span-1 px-3 py-4 my-text-center">Symbol</div>
            <div className="col-span-2 px-3 py-4 my-text-center">
              Outstanding Unit
            </div>
            <div className="col-span-2 px-3 py-4 my-text-center">
              Average Cost
            </div>
            <div className="col-span-2 px-3 py-4 my-text-center">
              Market Price
            </div>
            <div className="col-span-2 px-3 py-4 my-text-center">
              Market Value
            </div>
            <div className="col-span-2 px-3 py-4 my-text-center">
              Realized Gain/Loss
            </div>
          </div>
          <div className="border-l border-r border-black">
            {stockStatements.map((stockStatement, index) => {
              return (
                <div
                  key={`cash-movement-${index}`}
                  className="grid grid-cols-11 border-b border-black divide-x divide-black"
                >
                  <div className="col-span-1 px-3 py-4">
                    {stockStatement.stock}
                  </div>
                  <div className="col-span-2 px-3 py-4 my-text-right-center">
                    {seperateNumberWithComma(stockStatement.outUnit)}
                  </div>
                  <div className="col-span-2 px-3 py-4 my-text-right-center">
                    {seperateNumberWithComma(stockStatement.avgCost)}
                  </div>
                  <div className="col-span-2 px-3 py-4 my-text-right-center">
                    {seperateNumberWithComma(stockStatement.margetPrice)}
                  </div>
                  <div className="col-span-2 px-3 py-4 my-text-right-center">
                    {seperateNumberWithComma(stockStatement.margetValue)}
                  </div>
                  <div className="col-span-2 px-3 py-4 my-text-right-center">
                    {seperateNumberWithComma(stockStatement.gainLoss)}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      }
    />
  );
};
