import { ReactComponent as Gear } from "../../svgIcon/new-dashboard/gear.svg";

export const SettingButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <div
      className="flex flex-row items-center justify-between p-2 rounded cursor-pointer md:py-1 md:px-4 gap-x-3"
      style={{ backgroundColor: "#FF3E3E" }}
      onClick={onClick}
    >
      <Gear className="w-4 h-4" />
      <div className="hidden text-xs text-white md:flex">Setting</div>
    </div>
  );
};
