import httpClient from "./http";

class ERewardService {
  private http = httpClient();

  async getERewardUrl() {
    const response = await this.http.get<{ url: string }>("/e-reward/url");
    return response.data;
  }
}

export default ERewardService;
