import { getDateInFormat } from "../../helper/date.time";
import { seperateNumberWithComma } from "../../helper/format.number";
import { IMonthlyReport } from "../../interfaces/monthly.report.list";
import { IStockMovement } from "../../interfaces/stock.movement";
import { HeaderModal } from "../HeaderModal";
import { HeaderModalDetail } from "../HeaderModalDetail";
import { ModalDetail } from "../ModalDetail";

export const StockMovementDetail = ({
  closeModal,
  monthlyReport,
  stockMovements,
}: {
  closeModal: () => void;
  monthlyReport: IMonthlyReport;
  stockMovements: IStockMovement[];
}) => {
  return (
    <ModalDetail
      closeModal={() => closeModal()}
      chlidren={
        <div>
          <HeaderModal title="Monthly Stock Movement" />
          <HeaderModalDetail
            leftHeader={"Account No: " + monthlyReport.accountNo}
            rightHeader={
              "As of Date: " +
              getDateInFormat(monthlyReport.asOfDate.toString())
            }
          />
          <div className="grid grid-cols-12 mt-4 table-header-cell-default">
            <div className="col-span-2 px-3 py-4 my-text-center">Symbol</div>
            <div className="col-span-1 px-3 py-4 my-text-center">Type</div>
            <div className="col-span-1 px-3 py-4 my-text-center">Date</div>
            <div className="col-span-2 px-3 py-4 my-text-center">
              Average Price
            </div>
            <div className="col-span-2 px-3 py-4 my-text-center">In</div>
            <div className="col-span-2 px-3 py-4 my-text-center">Out</div>
            <div className="col-span-2 px-3 py-4 my-text-center">Balance</div>
          </div>
          <div className="border-l border-r border-black">
            {stockMovements.map((stockMovement, index) => {
              return (
                <div
                  key={`stock-movement-${index}`}
                  className="grid grid-cols-12 border-b border-black divide-x divide-black"
                >
                  <div className="col-span-2 px-3 py-4">
                    {stockMovement.stock}
                  </div>
                  <div className="col-span-1 px-3 py-4">
                    {stockMovement.trnType}
                  </div>
                  <div className="col-span-1 px-3 py-4 my-text-center">
                    {getDateInFormat(stockMovement.trnDate)}
                  </div>
                  <div className="col-span-2 px-3 py-4 my-text-right-center">
                    {seperateNumberWithComma(stockMovement.price)}
                  </div>
                  <div className="col-span-2 px-3 py-4 my-text-right-center">
                    {Number(stockMovement.cfUnit) > 0
                      ? seperateNumberWithComma(stockMovement.cfUnit)
                      : "-"}
                  </div>
                  <div className="col-span-2 px-3 py-4 my-text-right-center">
                    {Number(stockMovement.unit) > 0
                      ? seperateNumberWithComma(stockMovement.unit)
                      : "-"}
                  </div>
                  <div className="col-span-2 px-3 py-4 my-text-right-center">
                    {seperateNumberWithComma(stockMovement.bfUnit)}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      }
    />
  );
};
