import { useHistory } from "react-router-dom";
import { APP_ROUTE } from "../../const/path";

export const ItemResearch = ({
  id,
  title,
  date,
  imageUrl,
  researchId,
  reserachCategory,
}: {
  id: number;
  title: string;
  date: string;
  imageUrl: string;
  researchId: number;
  reserachCategory: string;
}) => {
  const history = useHistory();
  const goToResearctPDFView = () => {
    history.push(`${APP_ROUTE.DBSV_RESEARCH_PDF_VIEW.path}/${researchId}`);
  };
  return (
    <>
      <div
        className="flex flex-col lg:flex-row bg-white w-full h-auto  gap-3 select-none cursor-pointer group"
        onClick={goToResearctPDFView}
      >
        <div
          style={{
            backgroundImage: `url(${process.env.REACT_APP_BASE_URL}/proxy/cms${imageUrl}), url('images/default_banner_research.png')`,
          }}
          className="h-24 lg:h-24 lg:w-24 rounded-none bg-gray-100 bg-center bg-cover"
        />
        <div className="flex sm:flex-1 flex-col gap-2 py-2 h-24 transition ease-out duration-300 transform group-hover:-translate-y-1 group-hover:translate-x-1">
          <p className="text-xs text-gray-400 ">
            {reserachCategory} | {date}
          </p>
          <p className="text-gray-600 text-sm overflow-ellipsis overflow-hidden">
            {title}
          </p>
        </div>
      </div>
    </>
  );
};
