import { MENU_KEYWORDS } from "../const/search.menu";
import { IKeywordsSearch } from "../interfaces/keywords.search";
import { APP_ROUTE } from "../const/path";
import { isThaiLanguage } from "../i18n/helper";
import CMSService from "../services/cms.service";

export const searching = async (
  keyword: string
): Promise<IKeywordsSearch[]> => {
  var result: IKeywordsSearch[] = [];
  MENU_KEYWORDS.forEach((menu) =>
    menu.keywords.forEach((kw) => {
      if (kw.includes(keyword.toLowerCase())) return result.push(menu);
    })
  );

  if (process.env.REACT_APP_ENV !== "test")
    await new CMSService()
      .getResearchItems({ text: keyword, latest: false })
      .then((researches) =>
        researches.forEach((research) => {
          result.push({
            title: isThaiLanguage() ? research.title_th : research.title_en,
            keywords: [research.title_en, research.title_th],
            route: `${APP_ROUTE.DBSV_RESEARCH_PDF_VIEW.path}/${research.id}`,
          });
        })
      )
      .catch(() => []);

  return result.filter((e, i) => result.indexOf(e) === i);
};
