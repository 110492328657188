import { ThumbnailImage } from "./research";

export interface IBannerSet {
  id: number;
  active: boolean;
  frequency: Frequency;
  effectiveDate: Date;
  expiredDate: Date;
  bannerWidth: string;
  bannerHeight: string;
  created_at: Date;
  updated_at: Date;
  name: string;
  banners: IBanner[];
}

export interface IBanner {
  id: number;
  url: string;
  banner_set: number;
  created_at: Date;
  updated_at: Date;
  image: ThumbnailImage;
}

export enum Frequency {
  Always = "always",
  Once = "once",
}
