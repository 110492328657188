const EmptyData = () => {
  return (
    <div className="flex flex-col items-center p-8 text-center text-gray-200 gap-y-2">
      <img src="/images/doc_not_found.svg" className="w-40 mb-2" alt="" />
      <h1>Document not found</h1>
    </div>
  );
};

export default EmptyData;
