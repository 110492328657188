export const getPublicCMSUrl = (path: string): string => {
  try {
    const host =
      process.env.REACT_APP_PUBLIC_CMS_URL?.slice(-1) === "/"
        ? process.env.REACT_APP_PUBLIC_CMS_URL
        : process.env.REACT_APP_PUBLIC_CMS_URL + "/";
    path = path[0] === "/" ? path.slice(1) : path;

    return `${host}${path}`;
  } catch {
    return "images/default_banner_research.png";
  }
};
