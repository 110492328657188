import { useTranslation } from "react-i18next";

const AlertDialog = ({
  title,
  description,
  onSubmit,
  submitText,
}: {
  title?: string;
  description?: string;
  submitText?: string;
  onSubmit: () => void;
}) => {
  const { t: translate } = useTranslation();
  return (
    <div className="fixed inset-0 z-30 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen p-4 text-center opacity-100 sm:block sm:p-0">
        <div
          className="fixed inset-0 transition-opacity"
          aria-hidden="true"
          onClick={() => {}}
        >
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div
          style={{ minWidth: "20rem", maxWidth: "40rem" }}
          className="inline-block overflow-hidden text-left transition-all transform bg-white rounded-lg sm:my-8 sm:align-middle"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="flex flex-col items-center justify-center h-full py-4">
            <AlertIcon></AlertIcon>
            <h1 className="mt-2 font-bold text-primary-500">
              {title ?? translate("alert.default_title")}
            </h1>
            <h1 className="px-6 pt-2 text-center text-gray-500 whitespace-pre font-md">
              {description ?? translate("alert.get_data_error")}
            </h1>
            <button
              onClick={onSubmit}
              className="w-5/6 h-10 mt-3 text-white rounded-md bg-primary-500"
            >
              {submitText ?? translate("alert.submit")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const AlertIcon = () => {
  return <img className="w-16" src="/icons/ico-Alert.png" alt={""} />;
};

export default AlertDialog;
