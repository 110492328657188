export const SVGGraphIcon = ({
  className,
  active,
}: {
  className?: string;
  active?: boolean;
}) => {
  return (
    <svg
      viewBox="0 0 73 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.1725 3.13636H61.2399L36.4021 30.6205L17.6731 13.0048C16.9798 12.3527 15.8409 12.4013 15.2133 13.1097L6.87689 22.5188C6.2874 23.1841 6.38297 24.173 7.09036 24.7274C7.79775 25.2819 8.84908 25.192 9.43857 24.5266L16.6062 16.4368L35.3226 34.0407C36.0113 34.6884 37.1411 34.6454 37.7719 33.9474L63.178 5.83445V17.25C63.178 18.1161 63.9245 18.8182 64.8453 18.8182C65.7661 18.8182 66.5126 18.1161 66.5126 17.25V1.57891C66.5156 1.13141 66.316 0.685825 65.9251 0.373298C65.6342 0.140489 65.2572 0 64.8453 0H48.1725C47.2517 0 46.5052 0.702098 46.5052 1.56818C46.5052 2.43426 47.2517 3.13636 48.1725 3.13636ZM29.8636 65.8689H23.2273V39.2592C23.2273 38.3947 22.4845 37.6939 21.5682 37.6939H11.6136C10.6973 37.6939 9.95455 38.3947 9.95455 39.2592V65.8689H1.65909C0.7428 65.8689 0 66.5697 0 67.4342C0 68.2987 0.7428 68.9995 1.65909 68.9995H71.3409C72.2572 68.9995 73 68.2987 73 67.4342C73 66.5697 72.2572 65.8689 71.3409 65.8689H63.0455V32.9981C63.0455 32.1336 62.3027 31.4328 61.3864 31.4328H51.4318C50.5155 31.4328 49.7727 32.1336 49.7727 32.9981V65.8689H43.1364V48.6509C43.1364 47.7864 42.3936 47.0856 41.4773 47.0856H31.5227C30.6064 47.0856 29.8636 47.7864 29.8636 48.6509V65.8689Z"
        fill={`${active ? "white" : "gray"}`}
      />
    </svg>
  );
};
