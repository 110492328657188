export type AccountMovementMenus = "cashmovement" | "stockmovement";
export type AccountPortfolioMenus =
  | "streaming"
  | "deposit"
  | "withdrawal"
  | "wealthBOX"
  | "dailyconfirmation"
  | "contractnote"
  | "e-monthly-statement"
  | "consolidated-portfolio";
export type AccountMenus = AccountPortfolioMenus | AccountMovementMenus;

const getBackgroundColor = (type: AccountMenus) => {
  if (type === "streaming") {
    return "#6B8BFF";
  } else if (type === "wealthBOX") {
    return "#00926B";
  } else if (type === "cashmovement") {
    return "#458FFF";
  } else if (type === "stockmovement") {
    return "#FF8080";
  } else if (type === "dailyconfirmation") {
    return "#1937A6";
  } else if (type === "contractnote") {
    return "#0066A6";
  } else if (type === "e-monthly-statement") {
    return "#4A0DEB";
  } else if (type === "consolidated-portfolio") {
    return "#EC9A00";
  } else if (type === "withdrawal") {
    return "#CC47AE";
  }
  return "#12CA98";
};

const getKeyAccountItemLabel = (type: AccountMenus): string => {
  if (type === "streaming" || type === "wealthBOX") {
    return "myInvestment.streaming.label";
  } else if (type === "cashmovement") {
    return "myInvestment.cashMovement.label";
  } else if (type === "dailyconfirmation") {
    return "myInvestment.dailyConfirmation.label";
  } else if (type === "contractnote") {
    return "myInvestment.contractNote.label";
  } else if (type === "e-monthly-statement") {
    return "myInvestment.eMonthlyStatement.label";
  } else if (type === "consolidated-portfolio") {
    return "myInvestment.consolidated-portfolio.label";
  } else if (type === "stockmovement") {
    return "myInvestment.stockMovement.label";
  } else if (type === "withdrawal") {
    return "myInvestment.withdrawal.label";
  }
  return "myInvestment.deposit.label";
};

const getKeyAccountItemAction = (type: AccountMenus): string => {
  if (type === "streaming") {
    return "myInvestment.streaming.button";
  } else if (type === "wealthBOX") {
    return "myInvestment.wealthbox.button";
  } else if (type === "dailyconfirmation") {
    return "myInvestment.dailyConfirmation.button";
  } else if (type === "contractnote") {
    return "myInvestment.contractNote.button";
  } else if (type === "e-monthly-statement") {
    return "myInvestment.eMonthlyStatement.button";
  } else if (type === "consolidated-portfolio") {
    return "myInvestment.consolidated-portfolio.button";
  } else if (type === "withdrawal") {
    return "myInvestment.withdrawal.button";
  }
  return "myInvestment.deposit.button";
};

export const accountMenuItems = {
  getBackgroundColor,
  getKeyAccountItemLabel,
  getKeyAccountItemAction,
};
