import queryString from "query-string";
import { useEffect } from "react";
import { useHistory } from "react-router";
import Cookies from "universal-cookie";
import { AUTH_SOURCE, DEFAULT_LANGUAGE, DEFAULT_PAGE } from "../const/cookie";
import { APP_ROUTE } from "../const/path";
import { AuthenticationRequestBody } from "../interfaces/authentication";
import AuthenService from "../services/authen.service";

const SSOCallback = () => {
  const cookie = new Cookies();
  const history = useHistory();

  useEffect(() => {
    CheckAuthentication();
  });

  const CheckAuthentication = async () => {
    try {
      const authenticationBody = queryString.parse(
        window.location.search
      ) as unknown as AuthenticationRequestBody;

      const checkAuthenticationData =
        authenticationBody.params &&
        authenticationBody.iv &&
        authenticationBody.brokerId;

      if (authenticationBody.source) {
        cookie.set(AUTH_SOURCE, authenticationBody.source);
      }

      if (checkAuthenticationData) {
        const result = await new AuthenService().Authentication(
          authenticationBody
        );
        handleOtherParams(authenticationBody);
        window.location.replace(result.callback_uri);
      } else {
        throw Error();
      }
    } catch (error) {
      history.replace(APP_ROUTE.UNAUTHORIZED.path);
    }
  };

  const handleOtherParams = (params: AuthenticationRequestBody) => {
    if (params.defaultPage)
      cookie.set(DEFAULT_PAGE, extractPage(params.defaultPage));
    if (params.language)
      cookie.set(DEFAULT_LANGUAGE, mapQueryLang(params.language));
  };

  const extractPage = (pageUrl: string) => {
    return pageUrl.split(process.env.REACT_APP_BASE_WEB_URL!)[1];
  };

  const mapQueryLang = (lang: string) => {
    return lang.toLowerCase() ?? "th";
  };

  return <></>;
};

export default SSOCallback;
