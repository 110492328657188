import { DateTime } from "luxon";
import { isThaiLanguage } from "../../i18n/helper";

export const DatePickerHeader = ({
  dateSelected,
  decreaseMonth,
  increaseMonth,
}: {
  dateSelected: Date;
  decreaseMonth: () => void;
  increaseMonth: () => void;
}) => {
  const getDateTitle = (date: Date) => {
    return DateTime.fromJSDate(date)
      .setLocale(isThaiLanguage() ? "th" : "us")
      .toFormat("LLLL yyyy");
  };
  return (
    <div className="flex justify-between px-2">
      <button
      id="decreaseMonth"
        className="h-7 w-7 bg-primary-500 text-white rounded-md  focus:outline-none"
        onClick={decreaseMonth}
      >
        {"<"}
      </button>
      <div className="col-span-2 flex items-center">
        <h1 className="font-bold text-lg">{getDateTitle(dateSelected)}</h1>
      </div>
      <button
        id="increaseMonth"
        className="h-7 w-7 bg-primary-500 text-white rounded-md focus:outline-none"
        onClick={increaseMonth}
      >
        {">"}
      </button>
    </div>
  );
};
