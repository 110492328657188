import { SVG_PLUS } from "../../../../const/svg.icon";

const EmptyShortcut = () => {
  return (
    <div className="flex justify-center px-2 py-4">
      <div className="flex w-24 h-24 text-gray-400 bg-gray-100 border-2 border-gray-200 border-dashed stroke-current">
        <div className="m-auto">{SVG_PLUS}</div>
      </div>
    </div>
  );
};

export default EmptyShortcut;
