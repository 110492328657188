const MissionCardPopup = ({
  index,
  title,
  icon,
  onClick,
}: {
  index: number;
  title: string;
  icon: string;
  onClick: () => void;
}) => {
  return (
    <div
      onClick={onClick}
      data-testid={`mission-card-${index}`}
      className={`flex px-5 py-3 gap-x-2 bg-write rounded-lg border cursor-pointer truncate`}
    >
      <img
        src={icon}
        className="w-8 h-8 p-1 border rounded-full border-slate-30"
        alt="mission-icon"
      />
      <div className="flex flex-col justify-start gap-y-1">
        <h1
          className="text-2xs text-missionTitle md:text-sm"
          data-testid={`mission-card-index-${index}`}
        >
          Mission {index + 1}
        </h1>
        <p
          data-testid={`mission-card-title-${index}`}
          className="text-xs font-semibold truncate whitespace-pre-line md:text-base"
        >
          {title}
        </p>
      </div>
    </div>
  );
};

export default MissionCardPopup;
