import {
  AuthenticationRequestBody,
  AuthorizationResponse,
  GenerateTokenRequestBody,
} from "../interfaces/authentication";
import httpClient from "./http";

class AuthenService {
  private http = httpClient();

  async Authentication(
    body: AuthenticationRequestBody
  ): Promise<AuthorizationResponse> {
    return await this.http
      .post("/auth/authorize", body)
      .then((res) => res.data);
  }

  async generateToken(body: GenerateTokenRequestBody): Promise<void> {
    await this.http.post("/auth/token", body);
  }

  async checkAuthentication(): Promise<void> {
    try {
      await this.http.get("/auth");
    } catch (error) {
      throw error;
    }
  }

  logout(): Promise<void>  {
    return this.http.delete("/auth/logout");
  }
}

export default AuthenService;
