import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IDailyCashMovement } from "../../../interfaces/daily-movment";
import { getDateFormatConfig, getShortCutDetail } from "../helper";

export type MyInvestmentShortCutType =
  | "streaming"
  | "streaming-mobile"
  | "deposit"
  | "cashmovement"
  | "stockmovement"
  | "dailyconfirmation"
  | "e-monthly-statement"
  | "contract-note"
  | "wealthbox";

export type MovementUnit = "baht" | "stock";

export const MyInvestmentShortCut = ({
  type,
  asOfDate,
  daily,
  monthly,
  onClick,
  data,
}: {
  type: MyInvestmentShortCutType;
  asOfDate?: Date;
  daily?: boolean;
  monthly?: boolean;
  onClick?: () => void;
  data?: IDailyCashMovement;
}) => {
  const { t: translate } = useTranslation();
  const dateFormat = new Intl.DateTimeFormat(
    "en",
    getDateFormatConfig(monthly)
  );
  const [shortcutDetail, setShortcutDetail] = useState<{
    label: string;
    color: string;
    iconPath: string;
    button?: string;
  }>({ label: "", color: "", iconPath: "" });

  useEffect(() => {
    setShortcutDetail(getShortCutDetail(type));
  }, [type]);

  return (
    <div
      className="flex flex-col items-end justify-between h-40 p-4 border border-gray-300"
      data-testid={`${type}-shortcut-card`}
    >
      <div className="flex w-full gap-x-4">
        <img
          className="w-14 h-14"
          src={shortcutDetail.iconPath}
          alt="shory-cut-detail"
        />
        <div className="flex flex-col w-full gap-y-1">
          <h1 className="text-md" data-testid={`${type}-shortcut-label`}>
            {translate(shortcutDetail.label)}
          </h1>
          <div className={`w-full h-0.5 ${shortcutDetail.color}`}></div>
          <p
            className={`${
              monthly || daily ? "text-sm" : "text-xs"
            } text-gray-400`}
          >
            {asOfDate ? "As of " + dateFormat.format(new Date(asOfDate)) : ""}
          </p>
        </div>
      </div>
      {shortcutDetail.button ? (
        <button
          onClick={onClick}
          className="h-8 px-4 text-red-500 border border-red-500 rounded focus:outline-none"
          data-testid={`${type}-shortcut-button`}
        >
          {translate(shortcutDetail.button)}
        </button>
      ) : (
        <SummaryMovement
          type={type}
          valueIn={data?.in ?? 0}
          valueOut={data?.out ?? 0}
        />
      )}
    </div>
  );
};

const SummaryMovement = ({
  type,
  valueIn,
  valueOut,
}: {
  type: MyInvestmentShortCutType;
  valueIn: number;
  valueOut: number;
}) => {
  const [movementDetail, setMovementDetail] = useState<{
    label: string;
    unit: MovementUnit;
  }>({
    label: "",
    unit: "baht",
  });

  useEffect(() => {
    switch (type) {
      case "cashmovement":
        setMovementDetail({
          label: "myInvestment.cashMovement.movement",
          unit: "baht",
        });
        break;
      case "stockmovement":
        setMovementDetail({
          label: "myInvestment.stockMovement.movement",
          unit: "stock",
        });
        break;
    }
  }, [type]);

  return (
    <div className="flex w-full gap-x-2">
      <MovementCard
        value={valueIn}
        label={movementDetail.label}
        unit={movementDetail.unit}
        transactionIn
      />
      <div className="w-0.5 h-full bg-gray-300"></div>
      <MovementCard
        value={valueOut}
        label={movementDetail.label}
        unit={movementDetail.unit}
      />
    </div>
  );
};

const MovementCard = ({
  label,
  transactionIn,
  value,
  unit,
}: {
  label: string;
  transactionIn?: boolean;
  value: number;
  unit: MovementUnit;
}) => {
  const numberFormat = new Intl.NumberFormat("en", {
    minimumFractionDigits: unit === "stock" ? 0 : 2,
  });
  const { t: translate } = useTranslation();
  return (
    <div className="flex flex-col w-full">
      <div className="flex gap-x-2">
        <img
          alt="movement-card"
          className="w-3 h-4"
          src={`${
            transactionIn
              ? "/icons/myInvestment/arrow-green-down.png"
              : "/icons/myInvestment/arrow-red-up.png"
          }`}
        />
        <p
          className={`text-sm ${
            transactionIn ? "text-green-600" : "text-red-500"
          }`}
        >
          {translate(`${label}${transactionIn ? ".in" : ".out"}`)}
        </p>
      </div>
      <h1
        className={`text-right text-xl ${
          value
            ? transactionIn
              ? "text-green-600"
              : "text-red-500"
            : "text-gray-400"
        }`}
      >{`${value ? (transactionIn ? "+" : "-") : ""}${numberFormat.format(
        value
      )}`}</h1>
      <p className="text-xs text-right text-gray-400">
        {" "}
        {translate(
          `${label}.unit${
            unit === "stock" ? (value < 2 ? ".singular" : ".plural") : ""
          }`
        )}
      </p>
    </div>
  );
};
