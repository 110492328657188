/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { SVG_CHEVRON_DOWN } from "../../const/svg.icon";

export interface IDynamicDropdownOption {
  label: string;
  value: any;
  divideTop?: boolean;
  type?: string;
}

export const DynamicDropdown = ({
  theme,
  options,
  selectedOption,
  onSelected,
  icon,
  noBorder,
  label,
  showAll,
  disable = false,
}: {
  theme: "dark" | "white";
  options: IDynamicDropdownOption[];
  selectedOption: IDynamicDropdownOption;
  icon?: JSX.Element;
  onSelected: (option: IDynamicDropdownOption) => void;
  noBorder?: boolean;
  label?: string;
  showAll?: boolean;
  disable?: boolean;
}) => {
  const [isDropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [option, setOption] = useState<IDynamicDropdownOption>(selectedOption);

  useEffect(() => {
    setOption(selectedOption);
    document.addEventListener("click", (e: MouseEvent) => {
      const { id } = e.target as HTMLElement;
      if (!id.includes("dynamic-dropdown")) {
        setDropdownOpen(false);
      }
    });
    return document.removeEventListener("click", () => {});
  }, [selectedOption]);

  const isDarkTheme = () => theme === "dark";

  return (
    <div id="dynamic-dropdown" className="relative select-none min-w-max">
      <div
        id="dynamic-dropdown"
        className={`flex w-full justify-between items-center ${
          disable ? "" : "cursor-pointer"
        } gap-x-2 ${noBorder ? "" : "px-2 py-2 border border-gray-500"}`}
        onClick={() => {
          if (!disable) setDropdownOpen(!isDropdownOpen);
        }}
      >
        <div
          id="dynamic-dropdown-label"
          className={`font-bold pl-2 ${noBorder ? "" : "w-max pr-8 "}`}
        >
          {label ?? option.label}
        </div>
        <div className="flex items-center justify-center w-4 h-4 sm:w-6 sm:h-6 text-dbsv-neutral-slate-70">
          {icon ? icon : SVG_CHEVRON_DOWN}
        </div>
      </div>
      <div
        id="dynamic-dropdown-list"
        className={`${
          isDropdownOpen ? "" : "hidden"
        } -left-8 sm:right-auto sm:left-0 top-8 absolute z-10 w-max ${
          showAll ? "" : "max-h-60"
        } min-h-full shadow-md overflow-auto ${
          isDarkTheme()
            ? "bg-dropdown-700 text-white"
            : "text-black bg-white border border-gray-200"
        }`}
      >
        {options?.map((option, index) => (
          <div
            key={`dynamic-dropdown-${index}`}
            id={`dynamic-dropdown-item-${index}`}
            className={`py-2 pl-4 pr-8 cursor-pointer 2xl:pr-4 w-48 xl:w-60 2xl:w-80 ${
              isDarkTheme() ? "hover:bg-dropdown-600" : "hover:bg-gray-100"
            } ${
              option.divideTop ? "border-t border-gray-200" : ""
            }  whitespace-pre-line`}
            onClick={() => {
              setDropdownOpen(false);
              setOption(option);
              onSelected(option);
            }}
          >
            {option.label}
          </div>
        ))}
      </div>
    </div>
  );
};
