import { Chart } from "chart.js";
import { useEffect } from "react";
import { currency } from "../../helper/format.number";
import { IBarChartData } from "../../interfaces/chart";
import { getChartColor, isValueMoreThanOrEqualZero } from "./bar.chart";

const BarChart = ({
  id = 1,
  chartData,
  width,
  height,
}: {
  id?: number;
  chartData: IBarChartData;
  width?: string;
  height?: string;
}) => {
  const chartID = `bar-chart-${id}`;
  const labels = chartData.data.map((el) => el.label);
  const values = chartData.data.map((el) => el.value);
  const title = chartData.title || "Unrealized P/L";
  const color = getChartColor(chartData);

  useEffect(() => {
    const chart = drawChart();
    return () => chart.destroy();
  });

  const drawChart = () => {
    const docs = document.getElementById(chartID) as HTMLCanvasElement;
    const ctx = docs.getContext("2d");
    return new Chart(ctx!, {
      type: "bar",
      data: {
        labels,
        datasets: [
          {
            data: values,
            backgroundColor: color,
            maxBarThickness: 50,
          },
        ],
      },
      options: {
        layout: {
          padding: {
            top: 16,
            bottom: 40,
            right: 16,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: {
            color: "#000000",
            font: {
              weight: "normal",
              size: 18,
            },
            display: true,
            text: "",
          },
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            callbacks: {
              label: (context) => {
                if (chartData.isPercent) {
                  return context.formattedValue + "%";
                }
                return context.formattedValue;
              },
            },
          },
          datalabels: {
            display: true,
            color: "#000000",
            font: {
              size: 10,
              weight: "normal",
            },
            anchor: (ctx) => {
              const value = ctx.chart.data.datasets[0].data[ctx.dataIndex];
              if (isValueMoreThanOrEqualZero(Number(value))) {
                return "end";
              }
              return "start";
            },
            align: (ctx) => {
              const value = ctx.chart.data.datasets[0].data[ctx.dataIndex];
              if (isValueMoreThanOrEqualZero(Number(value))) {
                return "end";
              }
              return "start";
            },
            textAlign: "center",
            offset: -1,
            formatter: (value, context) => {
              const chartValue = chartData.isPercent
                ? `${
                    context.chart.data.labels![context.dataIndex]
                  }\n${value.toFixed(2)}%`
                : currency(Number(value));
              return chartValue;
            },
          },
        },
        scales: {
          x: {
            display: false,
            offset: true,
          },
          y: {
            ticks: {
              callback: (value) => {
                if (chartData.isPercent) {
                  return Number(value).toFixed(2) + "%";
                }
                return currency(Number(value), {
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                });
              },
            },
            beginAtZero: true,
          },
        },
      },
    });
  };

  return (
    <div className="flex flex-col" style={{ height, width }}>
      <div
        className="flex justify-center text-xl align-middle text-bold"
        style={{ color: "#595959" }}
      >
        {title}
      </div>
      <canvas id={chartID}></canvas>
    </div>
  );
};

export default BarChart;
