export const ResearchDescription = ({
  title,
  description,
  iconPath,
}: {
  title: string;
  description: string;
  iconPath: string;
}) => {
  return (
    <div
      className="flex flex-col w-full gap-3 px-6 py-3 md:w-1/3"
      style={{ color: "#59656D" }}
    >
      <div className="text-base font-bold md:text-xl">{title}</div>
      <div className="text-xs md:text-sm">{description}</div>
      <div className="flex items-center justify-center">
        <img className="w-32 md:w-52" src={iconPath} alt={title} />
      </div>
    </div>
  );
};
