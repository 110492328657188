import { useTranslation } from "react-i18next";
import { AccountType } from "../../enum/account";
import { currency } from "../../helper/format.number";
import { IUserPoint } from "../../interfaces/user";
import { PointOption } from "../../pages/Rewards/PointDetails";
import { IDynamicDropdownOption } from "../DynamicDropdown";

export const AccountRewardDetail = ({
  accounts,
  option,
  setAccountsDetail,
  setShowPointDetailModal,
}: {
  accounts: IUserPoint[];
  option: IDynamicDropdownOption;
  setAccountsDetail: (accountsDetail: IUserPoint[]) => void;
  setShowPointDetailModal: (isShow: boolean) => void;
}) => {
  const { t: translate } = useTranslation();

  const filterByAccountType = accounts.reduce((accumulator, account) => {
    const { systemId, points } = account as IUserPoint;
    if (!accumulator[systemId]) accumulator[systemId] = 0;
    accumulator[systemId] += points ?? 0;
    return accumulator;
  }, {} as any);

  const pointDetail = ({
    accountNo,
    accountType,
  }: {
    accountNo?: string;
    accountType?: AccountType;
  }) => {
    if (option.value === PointOption.AccountNo && accountNo) {
      return accounts.filter((account) => account.accountNo === accountNo);
    }
    if (option.value === PointOption.AccountType && accountType) {
      return accounts.filter((account) => account.systemId === accountType);
    }
  };

  const AccountDetailCard = ({
    index,
    account,
    accountLabel,
  }: {
    index: number;
    account: IUserPoint;
    accountLabel: string;
  }) => {
    return (
      <div
        key={`account-point-detail-${index}`}
        className="grid grid-cols-12 p-2 text-white rounded-lg bg-dbsvRewards-bgContent"
      >
        <div className="col-span-8" data-testid={`account-detail-${index}`}>
          {accountLabel}
        </div>
        <div
          className={`${
            account.points ? "cursor-pointer underline" : ""
          } col-span-4 text-right`}
          data-testid={`account-point-${index}`}
          onClick={() => {
            if (account.points) {
              let accounts: IUserPoint[] | undefined = [];
              if (option.value === PointOption.AccountNo)
                accounts = pointDetail({ accountNo: account.accountNo });
              else accounts = pointDetail({ accountType: account.systemId });
              setAccountsDetail(accounts!);
              setShowPointDetailModal(true);
            }
          }}
        >{`${currency(account.points)} ${translate(
          "rewards.point_details.unit"
        )}`}</div>
      </div>
    );
  };

  return (
    <div className="flex flex-col h-64 px-6 pb-6 overflow-y-auto gap-y-2">
      <>
        {option.value === PointOption.AccountNo
          ? accounts.map((account, index) => (
              <AccountDetailCard
                account={account}
                index={index}
                accountLabel={`${translate(
                  `rewards.accountType.${account.systemId}`
                )} ${translate("rewards.point_details.no")} ${
                  account.accountNo
                }`}
              />
            ))
          : Object.entries(filterByAccountType).map(([key, point], index) => (
              <AccountDetailCard
                accountLabel={`${translate(`rewards.accountType.${key}`)}`}
                index={index}
                account={{ systemId: key, points: point } as IUserPoint}
              />
            ))}
      </>
    </div>
  );
};
