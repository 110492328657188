import { ISSODBSVLink } from "../interfaces/sso-dbsv";
import httpClient from "./http";

class SSODBSVService {
  private http = httpClient();

  getBrainBoxTILink = async (): Promise<string> => {
    try {
      const res = await this.http.get<ISSODBSVLink>(`/sso-dbsv/brain-box-ti`);
      return res.data.url;
    } catch (error) {
      throw error;
    }
  };

  getBrainBoxVALink = async (): Promise<string> => {
    try {
      const res = await this.http.get<ISSODBSVLink>(`/sso-dbsv/brain-box-va`);
      return res.data.url;
    } catch (error) {
      throw error;
    }
  };

  getBTFEFormLink = async (lang: string): Promise<string> => {
    try {
      const res = await this.http.get<ISSODBSVLink>(
        `/sso-dbsv/btf-e-form?lang=${lang}`
      );
      return res.data.url;
    } catch (error) {
      throw error;
    }
  };

  getRequestFormLink = async (): Promise<string> => {
    try {
      const res = await this.http.get<ISSODBSVLink>(`/sso-dbsv/request-form`);
      return res.data.url;
    } catch (error) {
      throw error;
    }
  };

  getBlockTradeLink = () => {
    return Promise.resolve(
      "https://www.dbsvitrade.com/brokerpage/004/web/BlocktradeCalculator.html"
    );
  };

  getWealthBoxLink = () => {
    return Promise.resolve(" https://onelink.to/dbswealthbox");
  };

  getDBSVOnline = () => {
    return Promise.resolve("https://www1.dbsvonline.com/OTS/English/Login");
  };

  getOnlineTgradingWebsiteLink = () =>
    Promise.resolve("https://www.dbsvickers.com/");

  getMTradingWebsiteLink = () => Promise.resolve("https://onelink.to/6pskja");
}

export default SSODBSVService;
