import { IBarChartData } from "../../interfaces/chart";

export const positiveColor = "#4CAD5A";
export const negativeColor = "#EC3323";

export const getChartColor = (chartData: IBarChartData) => {
  return chartData.data.map((el) => {
    if (el.hexColor) {
      return el.hexColor;
    }
    return isValueMoreThanOrEqualZero(el.value) ? positiveColor : negativeColor;
  });
};

export const isValueMoreThanOrEqualZero = (value: number) => {
  return value >= 0;
};
