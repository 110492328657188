/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import Cookies from "universal-cookie";
import { CustomTab } from "../../components/CustomTab";
import { Disclaimer } from "../../components/ResearchDisclaimer";
import { AUTH_SOURCE } from "../../const/cookie";
import { APP_ROUTE } from "../../const/path";
import { useHandleAPIStateContext } from "../../context/handle-api-state";
import { useResearchContext } from "../../context/research";
import { ResearchType } from "../../enum/research";
import { getPublicCMSUrl } from "../../helper/cms";
import { getDateNormalFormat } from "../../helper/date.time";
import { getErrorMessageGetDataFail } from "../../helper/modal.message";
import { isThaiLanguage } from "../../i18n/helper";
import { IResearchDisclaimer } from "../../interfaces/disclaimer";
import { IHandleModal } from "../../interfaces/handle.modal";
import { IResearch, IResearchGroupByCategory } from "../../interfaces/research";
import { ITab } from "../../interfaces/tab";
import DisclaimerService from "../../services/disclaimer.service";
import UserService from "../../services/user.service";
import {
  getPathResearchArchive,
  getPathResearchView,
  groupResearchByCategory,
} from "./helper";

const Research = ({ isEService = false }: { isEService?: boolean }) => {
  const {
    getResearchItems,
  }: {
    getResearchItems: ({
      researchType,
    }: {
      researchType: ResearchType;
    }) => Promise<IResearch[]>;
  } = useResearchContext();
  const {
    setGetDataEror,
    setGettingData,
  }: {
    setGettingData: (gettingData: boolean) => void;
    setGetDataEror: (getDataError: IHandleModal) => void;
  } = useHandleAPIStateContext();

  const history = useHistory();
  const { t: translate } = useTranslation();
  const [disclaimer, setDiscliamer] = useState<IResearchDisclaimer>({
    _id: "",
    th: "",
    en: "",
  });
  const [researchType, setResearchType] = useState<ResearchType>(
    ResearchType.THAI
  );
  const [showDisclaimer, setShowDisclaimer] = useState<boolean>(false);
  const [researchesGroupByCategory, setResearchesGroupByCategory] = useState<
    IResearchGroupByCategory[]
  >([]);

  useEffect(() => {
    getResearches();
  }, [researchType]);

  useEffect(() => {
    checkUserAcceptedDisclaimer();
  }, []);
  const cookie = new Cookies();
  const source = cookie.get(AUTH_SOURCE);

  const checkUserAcceptedDisclaimer = async () => {
    try {
      const result = await new UserService().checkUserShouldAcceptDisclaimer();
      if (result.shouldAcceptDisclaimer) {
        const disclaimerData =
          await new DisclaimerService().getResearchRecentDisclaimer();
        setDiscliamer(disclaimerData);
        setShowDisclaimer(result.shouldAcceptDisclaimer);
      }
    } catch {
      setGetDataEror({
        isShow: true,
        title: translate("alert.default_title"),
        description: translate("alert.disclaimer.checking_fail"),
        submitText: translate("alert.submit"),
        onConfirm: () => history.push(APP_ROUTE.DASHBOARD.path),
      });
    }
  };

  const getResearches = () => {
    setGettingData(true);
    getResearchItems({ researchType: researchType })
      .then((researchList) => {
        const group = groupResearchByCategory(researchList);
        setResearchesGroupByCategory(group);
      })
      .catch(() => {
        setGetDataEror(getErrorMessageGetDataFail(translate));
      })
      .finally(() => setGettingData(false));
  };

  const onShowMore = (categoryId: number) =>
    history.push(
      getPathResearchArchive({ isEService, researchType, categoryId })
    );

  const onReadResearch = (researchId: number) => {
    history.push(getPathResearchView(researchId, isEService));
  };

  const onAceeptDiscliamer = async () => {
    try {
      setShowDisclaimer(false);
      setGettingData(true);
      await new UserService().acceptDisclaimer(disclaimer._id);
      setShowDisclaimer(false);
    } catch (error) {
      setGetDataEror({
        isShow: true,
        title: translate("alert.default_title"),
        description: translate("alert.disclaimer.accept_fail"),
        submitText: translate("alert.submit"),
        onConfirm: () => history.push(APP_ROUTE.DASHBOARD.path),
      });
    } finally {
      setGettingData(false);
    }
  };

  const onDenyDisclaimer = async () => {
    try {
      setGettingData(true);
      await new UserService().denyDisclaimer(disclaimer._id);
    } catch {
    } finally {
      setGettingData(false);
    }
    history.goBack();
  };

  const BuildResearchTypeTab = () => {
    const tabs: ITab[] = [
      {
        value: ResearchType.THAI,
        label: translate("research.research_type.thai"),
      },
      {
        value: ResearchType.OVERSEA,
        label: translate("research.research_type.oversea"),
      },
    ];
    return (
      <div className="w-full mx-auto lg:w-1/2 md:w-1/2">
        <CustomTab
          currentTab={researchType}
          className="mt-8 border-b border-gray-200"
          tabs={tabs}
          onClick={(currentTab) => setResearchType(currentTab as ResearchType)}
        />
      </div>
    );
  };

  const getBGImageUrl = (research: IResearch) =>
    getPublicCMSUrl(
      research.thumbnail_image?.formats?.small?.url ??
        research?.thumbnail_image?.url
    );

  const buildResearch = (researches: IResearch[]) => {
    return (
      <div className="grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5 md:grid-cols-2 gap-x-8 gap-y-4">
        {researches.slice(0, 6).map((research) => (
          <div
            className="w-full h-auto gap-3 bg-white border-b cursor-pointer select-none group animate-up-2"
            onClick={() => onReadResearch(research.id)}
          >
            <div
              style={{
                backgroundImage: `url(${getBGImageUrl(
                  research
                )}), url('images/default_banner_research.png')`,
              }}
              className="w-full bg-gray-100 bg-center bg-cover rounded-lg h-36 lg:h-48"
            />
            <div className="flex flex-col h-24 gap-2 py-2 sm:flex-1">
              <p className="text-xs text-gray-400 ">
                {getDateNormalFormat(
                  research?.post_date ?? research?.updated_at
                )}
              </p>
              <p className="overflow-hidden text-gray-800 overflow-ellipsis">
                {isThaiLanguage() ? research.title_th : research.title_en}
              </p>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const buildCategoryAndResearches = (category: IResearchGroupByCategory) => {
    return (
      <div>
        <h1 className="py-4 text-2xl text-gray-800">
          {category.research_category[isThaiLanguage() ? "name_th" : "name_en"]}
        </h1>
        {buildResearch(category.researches)}
        <p
          className="flex items-center mt-2 text-sm cursor-pointer text-error-500 hover:underline"
          onClick={() => onShowMore(category.research_category.id)}
        >
          {translate("research.view_all")}
          <ChevronRightIcon />
        </p>
      </div>
    );
  };

  const EmptyResearch = () => {
    return (
      <div className="flex flex-col items-center justify-center mt-8 gap-y-8">
        <img
          className="w-1/4"
          src="/images/find_illustration.png"
          alt="find-illustration"
        />
        <h1 className="text-gray-400">
          {translate("research.empty_research")}
        </h1>
      </div>
    );
  };

  return (
    <>
      {showDisclaimer && (
        <Disclaimer
          title={translate("research.disclaimer.title")}
          th={disclaimer.th}
          en={disclaimer.en}
          onAccept={onAceeptDiscliamer}
          onCancel={onDenyDisclaimer}
          isFullScreen={source === "streaming"}
        />
      )}
      <div className="p-4">
        <div className="px-6 py-4 card-bg">
          <div className="xl:grid xl:grid-cols-1 gap-x-16">
            <div className="flex flex-col xl:col-span-3 gap-y-4">
              <BuildResearchTypeTab />
              {researchesGroupByCategory.length ? (
                researchesGroupByCategory.map(buildCategoryAndResearches)
              ) : (
                <EmptyResearch />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const ChevronRightIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="w-4 feather feather-chevron-right"
    >
      <polyline points="9 18 15 12 9 6"></polyline>
    </svg>
  );
};

export default Research;
