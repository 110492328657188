import AuthenService from "../services/authen.service";

export const isAuthorized = async (): Promise<boolean> => {
  try {
    await new AuthenService().checkAuthentication();
    return true;
  } catch (error) {
    return false;
  }
};
