import { PageName } from "../../enum/page.name";
import { openWindow, Target } from "../../helper/open.window";
import GenerateLinkService from "../../services/generate-sso-ekyc-link-service";
import SSODBSVService from "../../services/sso-dbsv.service";

export const onOpenEKYC = async (target?: Target) => {
  const generateSSOKYCLinkService = new GenerateLinkService();
  const SSOKYCLink = await generateSSOKYCLinkService.generateSSOKYCLink();
  openWindow({ link: SSOKYCLink, target });
};

export const onOpenRequestForm = async (target?: Target) => {
  const ssoDBSVService = new SSODBSVService();
  const SSODBSVLink = await ssoDBSVService.getRequestFormLink();
  openWindow({ link: SSODBSVLink, target });
};

export const isOpenEKYC = (pageName: PageName) =>
  pageName === PageName.ekycSuitTest;

export const onOpenDDR = (target?: Target) => {
  return openWindow({
    link: process.env.REACT_APP_DIRECT_DEBIT_REGISTRATION!,
    target,
  });
};
