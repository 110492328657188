import { ChangeEvent } from "react";
import { SVG_SEARCH } from "../../../../const/svg.icon";

const SearchBar = ({
  keyword,
  onChange,
}: {
  keyword: string;
  onChange: (e: ChangeEvent) => void;
}) => {
  return (
    <div className="flex justify-center items-center">
      <div>
        <input
          type="text"
          placeholder="Search"
          className="focus:outline-none h-12 w-48 lg:w-96"
          value={keyword}
          onChange={onChange}
        />
      </div>
      <div>
        <button>
          <div className="flex stroke-current text-white bg-red-500 w-12 h-12">
            <div className="m-auto">{SVG_SEARCH}</div>
          </div>
        </button>
      </div>
    </div>
  );
};

export default SearchBar;
