import { ChangeEvent, useState } from "react";
import { IShortcut } from "../../../../interfaces/user";
import Divider from "../Divider";
import PageBar from "../PageBar";
import SearchBar from "../SearchBar";
import { Shortcut } from "../Shortcut";

const AllShortcuts = ({
  allShortcuts,
  keyword,
  onClick,
  onSearchChange,
  selectedShortcut,
}: {
  allShortcuts: IShortcut[];
  selectedShortcut: IShortcut[];
  keyword: string;
  onClick: (shortcut: IShortcut) => void;
  onSearchChange: (e: ChangeEvent) => void;
}) => {
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const paginate = () => {
    if (!!keyword) {
      return getCurrentPageShortcut(searchItem());
    }
    return getCurrentPageShortcut(allShortcuts);
  };

  const getCurrentPageShortcut = (shortcuts: IShortcut[]) =>
    shortcuts.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  const getPageCount = () => {
    if (!!keyword) return Math.ceil(searchItem().length / pageSize);
    return Math.ceil(allShortcuts.length / pageSize);
  };

  const searchItem = () => {
    return allShortcuts.filter(
      (each) =>
        formatSearch(each.nameEN).includes(formatSearch(keyword)) ||
        formatSearch(each.nameTH).includes(formatSearch(keyword))
    );
  };

  const formatSearch = (text: string) => {
    return text.toLowerCase().trim();
  };

  const setPage = (page: number) => {
    if (validatePage(page)) {
      setCurrentPage(page);
    }
  };

  const validatePage = (page: number) => {
    return page >= 1 && page !== currentPage && page <= getPageCount();
  };

  const isSelectedShortcut = (shortcut: IShortcut) => {
    const selectedIndex = selectedShortcut.findIndex(
      (el) => el.nameEN === shortcut.nameEN
    );
    return selectedIndex >= 0;
  };

  return (
    <div className="w-full">
      <div className="pb-8">
        <Divider />
      </div>
      <SearchBar keyword={keyword} onChange={onSearchChange} />
      <div className="grid grid-cols-2 gap-4 mt-6 md:grid-cols-5 sm:grid-cols-4 xs:grid-cols-3">
        {paginate().map((shortcut, i) => {
          return (
            <Shortcut
              isSelected={isSelectedShortcut(shortcut)}
              key={`shortcut-${i}`}
              shortcut={shortcut}
              onClick={() => onClick(shortcut)}
            />
          );
        })}
      </div>
      <PageBar
        pageCount={getPageCount()}
        currentPage={currentPage}
        setCurrentPage={setPage}
      />
      <div className="pb-8">
        <Divider />
      </div>
    </div>
  );
};

export default AllShortcuts;
