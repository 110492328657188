const ListTilePDF = ({
  label,
  onClick,
}: {
  label: string;
  onClick: () => void;
}) => {
  return (
    <div className="flex w-full justify-between items-center">
      <div
        id="name-pdf"
        className="cursor-pointer whitespace-pre-line py-2"
        onClick={onClick}
      >
        {label}
      </div>
      <img
        id="download-pdf-icon"
        className="h-4 cursor-pointer right-0"
        src="/icons/download-icon.png"
        alt=""
        onClick={onClick}
      />
    </div>
  );
};

export default ListTilePDF;
