import OverseasTradingMission from "./OverseasTradingMission";
import { PointsSummary } from "./PointsSummary";
import { useState } from "react";
import { IUserPoint } from "../../interfaces/user";
import { PointDetailModal } from "../../components/CustomerPointDetail/PointDetailModal";
import { PointDetails } from "./PointDetails";
import { useFeatureToggle } from "../../hooks/features";

export const RewardsPage = () => {
  const { openOverseaTradingAccount } = useFeatureToggle();
  const [isShowModal, setIsShowModal] = useState(false);
  const [selectAccount, setSelectAccount] = useState<IUserPoint[]>();
  return (
    <div className="p-10">
      {isShowModal && (
        <PointDetailModal
          accounts={selectAccount}
          isShowModel={(isShow) => setIsShowModal(isShow)}
        />
      )}
      <div>
        <div className="flex flex-wrap w-full gap-8 lg:flex-nowrap">
          <PointsSummary />
          <PointDetails
            setAccountsDetail={(accountsDetail) => {
              setSelectAccount(accountsDetail);
            }}
            setShowPointDetailModal={(showModal) => setIsShowModal(showModal)}
          />
        </div>
        {openOverseaTradingAccount && <OverseasTradingMission />}
      </div>
    </div>
  );
};
