import { AccountType } from "../../../enum/account";
import { openWindowWithBlankPage } from "../../../helper/open.window";
import { IEStatementResponse } from "../../../interfaces/e-statement";
import SSODBSVService from "../../../services/sso-dbsv.service";
import { handleDownloadEStatement } from "../../EStatement";
import { AccountInfo } from "../AccountInfo";
import { AccountMenuItem } from "../AccountMenuItem";

export const AccountFundType = ({
  accountNo = "",
  accountType,
  eMonthlyStatement,
}: {
  accountNo?: string;
  accountType?: AccountType;
  eMonthlyStatement?: IEStatementResponse;
}) => {
  const onOpenWealthbox = async () => {
    openWindowWithBlankPage({
      link: await new SSODBSVService().getWealthBoxLink(),
      isPopup: false,
    });
  };

  const renderFundAccount = (eMonthlyStatement?: IEStatementResponse) => {
    return (
      <>
        <AccountMenuItem type="wealthBOX" onClick={onOpenWealthbox} />
        {eMonthlyStatement && (
          <AccountMenuItem
            type="e-monthly-statement"
            asOf={new Date()}
            onClick={() => handleDownloadEStatement(eMonthlyStatement)}
          />
        )}
      </>
    );
  };

  return (
    <div
      data-testid="account-fund-type"
      className="flex flex-row flex-wrap p-5 pb-0 gap-y-4"
    >
      <AccountInfo accountNo={accountNo} accountType={accountType} />
      <div className="flex flex-row flex-1 gap-4 pb-8 pl-6 overflow-hidden overflow-x-auto">
        {renderFundAccount(eMonthlyStatement)}
      </div>
    </div>
  );
};
