/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHandleAPIStateContext } from "../../context/handle-api-state";
import { filterAccount } from "../../helper/accounts";
import { getDateInFormat } from "../../helper/date.time";
import { seperateNumberWithComma } from "../../helper/format.number";
import { getErrorMessageGetDataFail } from "../../helper/modal.message";
import { IConfirmationReportDetail } from "../../interfaces/confirmation.report.detail";
import { IConfirmationReportList } from "../../interfaces/confirmation.report.lists";
import { IHandleModal } from "../../interfaces/handle.modal";
import ReportService from "../../services/report.service";
import { ComfirmationReportDetail } from "../ComfirmationReportDetail";
import { FilterAccount } from "../FilterAccount";
import { FilterTradeDate } from "../FilterTradeDate";
import LoadingCard from "../LoadingCard";
import ReportTable, { IReportTableBody } from "../ReportTable";

interface IDailyReportState {
  accountsConfirmation: string[];
  confirmationLists: IConfirmationReportList[];
  isLoading: boolean;
}

interface IDailyReportAction {
  type: "pending" | "fulfilled" | "rejected";
  payload?: IConfirmationReportList[];
}

const initialState: IDailyReportState = {
  accountsConfirmation: [],
  confirmationLists: [],
  isLoading: false,
};

const dailyReportReducer = (
  state: IDailyReportState,
  action: IDailyReportAction
): IDailyReportState => {
  switch (action.type) {
    case "pending":
      return { ...state, isLoading: true };
    case "fulfilled":
      return {
        ...state,
        isLoading: false,
        confirmationLists: action.payload ?? [],
        accountsConfirmation: [
          ...filterAccount(action.payload ?? [], state.accountsConfirmation),
        ],
      };
    case "rejected":
      return { ...state, isLoading: false };
  }
};

export const DailyReport = () => {
  const { t: translate } = useTranslation();
  const {
    setGetDataEror,
  }: {
    setGetDataEror: (getDataError: IHandleModal) => void;
  } = useHandleAPIStateContext();
  const [{ accountsConfirmation, confirmationLists, isLoading }, dispatch] =
    useReducer(dailyReportReducer, initialState);
  const [accountSelected, setAccountSelected] = useState<string>("");
  const [confirmationSelected, setConfirmationSelected] =
    useState<IConfirmationReportList>({
      accountNo: "",
      docNo: "",
      tradeDate: new Date(),
      dueDate: new Date(),
      netAmount: "",
      drCr: "",
      description: "",
      settlementFee: "",
      atsFee: "",
      importedDate: new Date(),
    });
  const [confirmationDetail, setConfirmationDetail] = useState<
    IConfirmationReportDetail[]
  >([
    {
      id: "",
      accountNo: "",
      docNo: "",
      stock: "",
      refType: "",
      quantity: "",
      dealPrice: "",
      grossAmount: "",
      commAmount: "",
      vatAmount: "",
      netAmount: "",
      trusteeId: "",
      tradingFee: "",
      clearingFee: "",
      totalFee: "",
      matchedFee: "",
      serviceType: "",
      importedDate: new Date(),
    },
  ]);
  const [openDetail, setOpenDetail] = useState<boolean>(false);
  const [tradeDate, setTradeDate] = useState<Date | undefined>();
  const [tableBody, setTableBody] = useState<IReportTableBody[]>([]);

  const getConfirmationList = async ({
    accounts,
    date,
  }: {
    accounts?: string[];
    date?: string;
  }) => {
    try {
      dispatch({ type: "pending" });
      const confirmationList = await new ReportService().getConfirmationList({
        accounts: accounts ?? [],
        tradeDate: date ?? "",
      });
      dispatch({ type: "fulfilled", payload: confirmationList });
    } catch {
      dispatch({ type: "rejected" });
      setGetDataEror(getErrorMessageGetDataFail(translate));
    }
  };

  const getConfirmationDetail = async (accountNo: string, docNo: string) => {
    try {
      const confirmationDetail =
        await new ReportService().getConfirmationDetail({
          accountNo,
          docNo,
        });
      setConfirmationDetail(confirmationDetail);
      setOpenDetail(true);
    } catch {
      setGetDataEror(getErrorMessageGetDataFail(translate));
    }
  };

  useEffect(() => {
    getConfirmationList({});
  }, []);

  useEffect(() => {
    setTableBody([
      ...confirmationLists.reduce((prev, curr) => {
        prev.push({
          datas: [
            { text: curr?.accountNo ?? "-", align: "left" },
            { text: curr?.docNo ?? "-", align: "left" },
            {
              text: curr?.tradeDate
                ? getDateInFormat(curr.tradeDate.toString())
                : "-",
              align: "center",
            },
            { text: curr?.description ?? "-", align: "right" },
            {
              text:
                seperateNumberWithComma(Number(curr?.netAmount).toFixed(2)) ??
                "-",
              align: "right",
            },
          ],
          action: () => {
            setConfirmationSelected(curr);
            getConfirmationDetail(curr.accountNo, curr.docNo);
          },
        });
        return prev;
      }, [] as IReportTableBody[]),
    ]);
  }, [confirmationLists, translate]);

  return (
    <div className="w-full">
      <div className="flex flex-wrap justify-center mt-6 md:justify-start gap-x-6 gap-y-2">
        <FilterAccount
          accounts={accountsConfirmation}
          onSelectAccount={(account) => {
            setAccountSelected(account);
            getConfirmationList({
              accounts: account ? [account] : [],
              date: tradeDate?.toISOString(),
            });
          }}
        />
        <FilterTradeDate
          date={tradeDate}
          onSelectDate={(date) => {
            setTradeDate(date);
            getConfirmationList({
              accounts: accountSelected ? [accountSelected] : [],
              date: date?.toISOString(),
            });
          }}
        />
      </div>
      {isLoading ? (
        <LoadingCard />
      ) : (
        <ReportTable
          labels={[
            { text: "Account No.", align: "left" },
            { text: "Doc.No.", align: "left" },
            { text: "Trade Date", align: "center" },
            { text: "Description", align: "right" },
            { text: "Net Amount", align: "right" },
            { text: "Detail", align: "center" },
          ]}
          bodys={tableBody}
          key="daily-report"
        />
      )}
      {openDetail && (
        <ComfirmationReportDetail
          confirmation={confirmationSelected}
          confirmationDetails={confirmationDetail}
          closeModal={() => setOpenDetail(false)}
        />
      )}
    </div>
  );
};
