import { useTranslation } from "react-i18next";
import { AccountType } from "../../enum/account";

export const AccountInfo = ({
  accountNo,
  accountType,
}: {
  accountNo: string;
  accountType: AccountType | undefined;
}) => {
  const { t: translate } = useTranslation();

  return (
    <div className="flex flex-row justify-start w-full p-0 md:py-3 md:w-max gap-x-2.5 items-center md:items-start">
      <div className="w-16 md:w-32">
        <img
          src="/icons/my-account/DBSV-TH-Products.png"
          alt="DBSV-TH-Products"
        />
      </div>
      <div className="flex flex-col w-40 py-1 md:py-5 gap-y-1 text-dbsv-neutral-slate-80">
        <div>
          <div className="text-xs md:text-sm whitespace-nowrap w-max">
            {accountType &&
              translate(`myInvestment.accountType.${accountType}`)}
          </div>
          <div className="text-sm font-bold md:text-xl w-max">{accountNo}</div>
        </div>
      </div>
    </div>
  );
};
