/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Route, useHistory } from "react-router-dom";
import { APP_ROUTE } from "../../const/path";
import { isAuthorized } from "../../helper/authentication";

export default function PrivateRoute({
  component,
  path,
}: {
  component: () => JSX.Element;
  path: string;
}) {
  const history = useHistory();
  useEffect(() => {
    isAuthorized().then((isAuth) => {
      if (!isAuth) history.replace(APP_ROUTE.UNAUTHORIZED.path);
    });
  }, []);

  return <Route exact path={path} component={component}></Route>;
}
