import { PageName } from "../enum/page.name";
import { ISubMenu } from "../interfaces/menu";

export const INVESTMENT_TOOLS: { [key: string]: ISubMenu } = {
  TECHNICAL_INSIGHT: {
    name: "BrainBOX\nTechnical Insight",
    imagePath: "/images/BrainBOX_TI_Logo.png",
  },
  FUNDAMENTAL_INSIGHT: {
    name: "BrainBOX\nFundamental Insight",
    imagePath: "/images/BrainBOX_VA_Logo.png",
  },
  BLOCK_TRADE: {
    name: "Block Trade Calculator",
    imagePath: "/images/BlockTradeCalculator_Logo.png",
  },
  WEALTHBOX: {
    name: "DBSV WealthBOX\n(Mobile App)",
    imagePath: "/images/New_WealthBOX_Logo.png",
  },
  EFIN_STOCK_PICKUP: {
    name: "eFin StockPickup",
    imagePath: "/images/eFinStockPickup_Logo.png",
    pageName: PageName.EFinance,
  },
  ASPEN: {
    name: "Aspen For Browser",
    imagePath: "/images/Aspen4Browser_Logo.png",
    pageName: PageName.Aspen,
  },
  PLACE_ORDER: {
    name: "Place Order",
    imagePath: "/images/settradePlaceOrder_Logo.png",
    pageName: PageName.PlaceOrderPopup,
    isPopUp: true,
  },
  ONLINE_TRADING_WEBSITE: {
    name: "DBS Vickers\n(Online Trading Website)",
    imagePath: "/images/mTradingThailand-White.png",
    pageName: PageName.ONLINE_TRADING_WEBSITE,
  },
  M_TRADING_TH: {
    name: "DBSV mTrading TH\n(Mobile App)",
    imagePath: "/images/mTradingTH-Black-1024.png",
    pageName: PageName.M_TRADING_TH,
  },
};
