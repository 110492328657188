import { useTranslation } from "react-i18next";
import { AccountType } from "../../enum/account";
import { ReactComponent as ChartUpIcon } from "../../svgIcon/new-dashboard/accounts/chart-up.svg";

export const AccountTab = ({
  active,
  accountType,
  onClick,
  accountNo,
}: {
  active: boolean;
  accountType: AccountType;
  onClick: () => void;
  accountNo: string;
}) => {
  const { t: translate } = useTranslation();

  return (
    <div
      className={`flex flex-row items-center h-10 px-2 cursor-pointer gap-x-2 w-full border-b ${
        active
          ? "bg-dbsv-product-dbs-crimson-00 border-dbsv-brand-dbs-red "
          : "bg-dbsv-neutral-slate-10 border-dbsv-neutral-slate-50"
      }`}
      onClick={onClick}
    >
      <ChartUpIcon
        className={`${
          active ? "text-dbsv-brand-dbs-red" : "text-dbsv-neutral-slate-40"
        }`}
      />
      <div
        className={`flex flex-col text-sm ${
          active
            ? "font-bold text-dbsv-brand-dbs-red"
            : "font-normal text-dbsv-neutral-slate-50"
        }`}
      >
        <div className="text-sm truncate w-fit">
          {translate(`myInvestment.accountType.${accountType}`)}
        </div>
        <div className="text-xs">{accountNo}</div>
      </div>
    </div>
  );
};
