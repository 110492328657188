/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { APP_ROUTE } from "../../const/path";
import { useUserContext } from "../../context/user/user.context";
import { Frequency, IBannerSet } from "../../interfaces/banner";
import BannerService from "../../services/banner.service";
import ArrowLeft from "../Carousel/ArrowLeft";
import ArrowRight from "../Carousel/ArrowRight";
import CarouselImage from "../Carousel/BannerImage";
import { BANNER_CLOSE_ICON } from "../Carousel/CloseButton";
import Indicators from "../Carousel/Indicators";

const Banners = () => {
  const localtion = useLocation();
  const [currentBanner, setCurrentBanner] = useState<number>(0);
  const [showBanner, setShowBanner] = useState<boolean>(false);
  const [bannerSet, setBanner] = useState<IBannerSet>();
  const [invervalId, setInvervalId] = useState<NodeJS.Timeout>();
  const [isPauseSlide, pauseSlide] = useState<boolean>(false);
  const { userInfo } = useUserContext();
  const [selectedBannerId, setSelectedBannerId] = useState<number[]>([]);

  useEffect(() => {
    if (
      localtion.pathname !== APP_ROUTE.DASHBOARD.path &&
      localtion.pathname !== "/"
    )
      return;
    const bannerService = new BannerService();
    if (userInfo.username.length)
      bannerService.getBanners().then((bannerSet) => {
        setBanner(bannerSet);
        if (bannerSet?.banners?.length > 0) {
          setShowBanner(true);
          handlePauseBannerSlide();
        }
      });
    return document.removeEventListener("mouseover", () => { });
  }, [userInfo]);

  useEffect(() => {
    if (!showBanner || isPauseSlide) return clearInterval(invervalId!);
    if (!isPauseSlide && isBannerMoreOne(bannerSet!))
      autoSlideBanner(bannerSet!);
    return () => clearInterval(invervalId!);
  }, [showBanner, isPauseSlide]);

  const isBannerMoreOne = (banner: IBannerSet) => banner.banners.length > 1;

  const handlePauseBannerSlide = () => {
    document.addEventListener("mouseover", (e) => {
      const { id } = e.target as HTMLElement;
      if (id.includes("banner")) {
        pauseSlide(true);
      } else {
        pauseSlide(false);
      }
    });
  };

  const autoSlideBanner = (banner: IBannerSet) => {
    // second * 1000
    const DELAY_TIME_CHANGE_BANNER = 6 * 1000;
    const interval = setInterval(() => {
      setCurrentBanner((prevCurrentBanner) =>
        prevCurrentBanner === banner.banners.length - 1
          ? 0
          : prevCurrentBanner + 1
      );
    }, DELAY_TIME_CHANGE_BANNER);
    setInvervalId(interval);
  };

  const lastBannerIndex = () => bannerSet!.banners.length - 1;

  const onPreviodBanner = () => {
    if (currentBanner === 0) setCurrentBanner(lastBannerIndex());
    else setCurrentBanner(currentBanner - 1);
  };

  const onNextBanner = () => {
    if (currentBanner === lastBannerIndex()) setCurrentBanner(0);
    else setCurrentBanner(currentBanner + 1);
  };

  const closeBanner = () => {
    setShowBanner(false);
    if (bannerSet!.frequency === Frequency.Once) {
      const bannerService = new BannerService();
      bannerService.seenBanner(bannerSet!.id);
    }
    if (selectedBannerId.length) {
      const bannerService = new BannerService();
      bannerService.updateUserSeenBanner(selectedBannerId);
    }
  };

  const onSelectBanner = () => {
    const bannerId = bannerSet!.banners[currentBanner].id;
    if (selectedBannerId.includes(bannerId)) {
      setSelectedBannerId((prevSelectedBannerId) =>
        prevSelectedBannerId.filter((id) => id !== bannerId)
      );
    } else {
      setSelectedBannerId((prevSelectedBannerId) => [
        ...prevSelectedBannerId,
        bannerId,
      ]);
    }
  };

  return (
    <div>
      {showBanner && (
        <div className="absolute z-20 flex items-center justify-center w-screen h-screen bg-black bg-opacity-60">
          <div
            className="fixed inset-0 transition-opacity"
            aria-hidden="true"
            onClick={() => closeBanner()}
          ></div>
          <div className="relative w-full lg:w-auto">
            <div className="relative flex items-center justify-center w-full">
              <CarouselImage
                bannerSet={bannerSet!}
                currentBanner={currentBanner}
              />
              {isBannerMoreOne(bannerSet!) && (
                <>
                  <ArrowLeft onPreviodBanner={onPreviodBanner} />
                  <ArrowRight onNextBanner={onNextBanner} />
                  <Indicators
                    bannerSet={bannerSet!}
                    currentBanner={currentBanner}
                    onClick={setCurrentBanner}
                  />
                </>
              )}
            </div>
            <div
              id="close-banner"
              className="absolute text-xs text-white cursor-pointer right-1 top-1 flex items-center gap-1 bg-black py-1.5 px-2 bg-opacity-40"
              onClick={() => closeBanner()}
            >
              <span className="w-4 h-4">{BANNER_CLOSE_ICON}</span>
              ปิดหน้าต่างนี้
            </div>
            <div className="bg-black bg-opacity-40">
              <p
                className="flex items-center gap-2 p-2 text-xs text-white cursor-pointer"
                style={{ width: "fit-content" }}
                onClick={() => onSelectBanner()}
              >
                <input
                  checked={selectedBannerId.includes(
                    bannerSet!.banners[currentBanner].id
                  )}
                  id=""
                  type="checkbox"
                  className="relative w-4 h-4 bg-transparent border border-white rounded-sm cursor-pointer focus:ring-0 focus:ring-offset-0"
                />
                ไม่ต้องการแสดงข้อความนี้อีก
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Banners;
