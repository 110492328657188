import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { IKeywordsSearch } from "../../interfaces/keywords.search";
import SearchResult from "./SearchResult";

const SearchModal = ({
  searchResult,
  onClose,
  onSearch,
  onChange,
  searchText,
}: {
  searchResult: IKeywordsSearch[] | null;
  onClose: () => void;
  onSearch: () => void;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  searchText: string;
}) => {
  const { t: translate } = useTranslation();
  const history = useHistory();

  return (
    <div className="fixed inset-0 z-20 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen p-4 text-center opacity-100 sm:block sm:p-0">
        <div
          className="fixed inset-0 transition-opacity"
          aria-hidden="true"
          onClick={() => {}}
        >
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div
          className="inline-block w-full px-8 overflow-hidden text-left transition-all transform bg-white rounded-lg md:w-4/6 lg:w-3/5 sm:my-8 sm:align-middle"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="flex flex-col justify-start h-full py-4">
            <div className="flex justify-between text-xl font-bold cursor-pointer">
              {translate("search.title")}
              <div onClick={onClose}>X</div>
            </div>
            <div className="flex flex-col w-full py-4 overflow-x-auto md:space-x-2">
              <div className="flex gap-x-2">
                <form
                  className="flex items-center w-full gap-x-3"
                  onSubmit={(e) => {
                    e.preventDefault();
                    onSearch();
                  }}
                >
                  <input
                    value={searchText}
                    type="text"
                    autoFocus={true}
                    className="w-full h-10 px-4 border border-gray-300 caret-red focus:outline-none focus:ring-transparent focus:border-gray-500"
                    onChange={onChange}
                  />
                  <button
                    type="submit"
                    className="w-32 h-10 text-white bg-red-500 rounded focus:outline-none"
                  >
                    {translate("search.button")}
                  </button>
                </form>
              </div>
              <div
                id="search-result"
                className="flex flex-col py-3 divide-y divide-gray-100"
              >
                <SearchResult
                  searchResult={searchResult}
                  onClick={(route: string) => {
                    onClose();
                    history.push(route);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchModal;
