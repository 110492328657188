export const LabelWithChevronRight = ({
  label,
  onClick,
}: {
  label: string;
  onClick?: (state: boolean) => void;
}) => {
  return (
    <div className="flex items-center text-primary-500 hover:underline">
      <span
        className="cursor-pointer mr-1"
        onClick={() => {
          if (onClick) {
            onClick(true);
          }
        }}
      >
        {label}
      </span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-4 w-4"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 5l7 7-7 7"
        />
      </svg>
    </div>
  );
};
