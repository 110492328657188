import { useTranslation } from "react-i18next";

const NoFavorite = ({ onClick }: { onClick: (state: boolean) => void }) => {
  const { t: translate } = useTranslation();
  return (
    <div className="grid px-2 py-4 text-gray-500 justify-items-center">
      <div className="">{translate("myFavorite.empty_title")}</div>
      <div className="">
        {translate("myFavorite.empty_description")}
        <button className="text-primary-500" onClick={() => onClick(true)}>
          &nbsp;{translate("myFavorite.setting")}
        </button>
      </div>
    </div>
  );
};

export default NoFavorite;
