import RCTooltip from "rc-tooltip";
import { useEffect, useState } from "react";
import { ReactComponent as InfoSVG } from "../svgIcon/info.svg";

const Tooltip = ({
  tooltip,
}: {
  tooltip: {
    title: string;
    description?: string;
  };
}) => {
  const [width, setWidth] = useState(window.innerWidth);

  const handleResize = () => setWidth(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isSmallDevice = (width: number) => width <= 540;

  return (
    <RCTooltip
      placement={isSmallDevice(width) ? "bottom" : "right"}
      overlay={
        <div className="flex flex-col p-1 bg-white text-dbsv-global-black gap-y-2.5">
          <h1 className="text-xs font-bold" data-testid="tooltip-title">
            {tooltip.title}
          </h1>
          {!!tooltip?.description && (
            <p className="text-xs whitespace-pre" data-testid="description">
              {tooltip.description}
            </p>
          )}
        </div>
      }
    >
      <InfoSVG
        data-testid="info-tooltip-icon"
        className="w-4 h-4 text-dbsv-brand-dbs-red"
      />
    </RCTooltip>
  );
};

export default Tooltip;
