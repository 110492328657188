export const SVGWorldIcon = ({
  className,
  active,
}: {
  className?: string;
  active?: boolean;
}) => {
  return (
    <svg
      viewBox="0 0 81 91"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
    >
      <path
        d="M26.37 9.09375C25.5859 9.09375 24.8007 9.39519 24.2016 9.99427L17.3885 16.8074L2.41732 14.4436L0.385221 16.4757L11.4166 22.7793L6.15566 28.0402L0.663672 26.6658L-0.900391 28.2298L10.3028 39.433L11.8669 37.8689L10.4924 32.377L15.7474 27.116L22.051 38.1474L24.0831 36.1153L21.7252 21.1441L28.5383 14.331C29.7365 13.1328 29.7365 11.1924 28.5383 9.99427C27.9393 9.39519 27.1541 9.09375 26.37 9.09375ZM35.4996 9.09967C35.3115 9.09967 35.1337 9.12626 34.9486 9.1293C35.6402 11.0919 35.6372 13.2334 34.9486 15.196C35.1306 15.1929 35.3146 15.1663 35.4996 15.1663C41.8848 15.1663 47.8056 17.1577 52.6984 20.5398L50.6663 21.233V24.2663H47.6329L44.5996 27.2997V33.3663L50.6663 30.333L59.7663 33.3663L56.7329 39.433L50.6663 36.3997H44.5996L38.5329 42.4663V48.533L44.5996 54.5997H50.6663L53.6996 60.6663L47.313 73.4395C43.6791 74.9804 39.6886 75.833 35.4996 75.833C34.3833 75.833 33.2826 75.7617 32.1997 75.6434L26.293 69.873L26.3996 57.633L17.2996 48.533H8.19961L5.90091 52.0581C5.43378 49.9408 5.16628 47.7534 5.16628 45.4997C5.16628 44.6564 5.22685 43.8305 5.29662 43.0055L-0.0294921 37.6794C-0.587626 40.2001 -0.900391 42.8121 -0.900391 45.4997C-0.900391 65.5712 15.428 81.8997 35.4996 81.8997C55.5712 81.8997 71.8996 65.5712 71.8996 45.4997C71.8996 25.4281 55.5712 9.09967 35.4996 9.09967Z"
        fill={`${active ? "white" : "gray"}`}
      />
    </svg>
  );
};
