import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ListTilePDF from "../../components/CustomerHandBookMenu";
import { Title } from "../../components/Title";
import { openWindow } from "../../helper/open.window";
import { isThaiLanguage } from "../../i18n/helper";
import {
  ICustomerHandbook,
  ICustomerHandbookPDF,
} from "../../interfaces/customer-handbook-pdf";
import { CustomerHandbookPDFService } from "../../services/customer-handbook-pdf.service";
import ErrorComponent from "../../components/Error";
import Loading from "../../components/Loading";

const CustomerHandbook = () => {
  const { t: translate } = useTranslation();
  const [customerHandbook, setCustomerHandbook] =
    useState<ICustomerHandbookPDF>();
  const [showGetDataError, setShowGetDataError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    customerHandbookPDF().then(setCustomerHandbook);
  }, []);

  const customerHandbookPDF = async () => {
    try {
      const customerHandbookPDFService = new CustomerHandbookPDFService();
      const resultCustomerHandbook =
        await customerHandbookPDFService.dataCustomerHandBookPDF();
      return resultCustomerHandbook;
    } catch (error) {
      setShowGetDataError(true);
    } finally {
      setLoading(false);
    }
  };

  const date = (date: string) =>
    DateTime.fromFormat(date, "dd-MM-yyyy").toFormat("dd MMM yyyy");

  const buildListPDF = ({
    pdfList,
  }: {
    pdfList: ICustomerHandbook | undefined;
  }) => {
    return pdfList?.files.map((names) => (
      <ListTilePDF
        key={names.en.name}
        label={isThaiLanguage() ? names.th.name : names.en.name}
        onClick={() =>
          openWindow({ link: isThaiLanguage() ? names.th.url : names.en.url })
        }
      />
    ));
  };

  return loading ? (
    <div className="p-4">
      <Loading />
    </div>
  ) : (
    <>
      {showGetDataError ? (
        <div className="flex flex-col items-center justify-center mt-20">
          <ErrorComponent
            header={translate("error.head")}
            errorCode={"404"}
            title={translate("error.title")}
            description={translate("error.description")}
          />
        </div>
      ) : (
        <div className="flex flex-col p-4 gap-y-8">
          <div className="p-6 card-bg" data-testid="customer-handbook-handbook">
            <Title title={translate("customer_handbook.handbook")} />
            <div className="text-sm text-gray-400 md:my-2">
              {translate("customer_handbook.lastUpdate")}: //{" "}
              {date(customerHandbook?.customerHandbook.latestUpdate || "")}
            </div>
            <div className="flex flex-col mt-3 divide-y">
              {buildListPDF({ pdfList: customerHandbook?.customerHandbook })}
            </div>
          </div>
          <div className="p-6 card-bg" data-testid="customer-handbook-contract">
            <Title title={translate("customer_handbook.contract")} />
            <div className="text-sm text-gray-400 md:my-2">
              {translate("customer_handbook.lastUpdate")}: //{" "}
              {date(customerHandbook?.agreements.latestUpdate || "")}
            </div>
            <div className="flex flex-col mt-3 divide-y">
              {buildListPDF({ pdfList: customerHandbook?.agreements })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default CustomerHandbook;
