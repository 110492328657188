import { ReactComponent as LineSVG } from "../svgIcon/footer/Line.svg";
import { ReactComponent as FacebookSVG } from "../svgIcon/footer/facebook.svg";
import { ReactComponent as InstagramSVG } from "../svgIcon/footer/instagram.svg";
import { ReactComponent as XSVG } from "../svgIcon/footer/X.svg";
import { ReactComponent as YoutubeSVG } from "../svgIcon/footer/youtube.svg";

export const Footer = () => {
  return (
    <div className="flex flex-col items-center p-3 bg-white ml-14 xl:ml-64 xl:p-5 xl:flex-row xl:justify-between gap-y-2 text-dbsv-global-black">
      <div className="flex flex-col items-center text-xs xl:flex-row gap-y-1 md:text-base xl:gap-x-3">
        <span>{`©DBS Vickers Securities (Thailand) Co.,Ltd.`}</span>
        <span className="hidden xl:block">|</span>
        <span>{`E-Commerce Registered Number 7100403000300`}</span>
        <span className="hidden xl:block">|</span>
      </div>
      <div className="flex gap-x-3">
        <LineSVG
          className="w-5 h-5 cursor-pointer"
          onClick={() =>
            window.open("https://line.me/R/ti/p/%40lhv8351p", "_blank")
          }
        />
        <FacebookSVG
          className="w-5 h-5 cursor-pointer"
          onClick={() =>
            window.open("https://www.facebook.com/dbsthailand", "_blank")
          }
        />
        <InstagramSVG
          className="w-5 h-5 cursor-pointer"
          onClick={() =>
            window.open("https://www.instagram.com/dbsthailand", "_blank")
          }
        />
        <XSVG
          className="w-5 h-5 cursor-pointer"
          onClick={() => window.open("https://x.com/dbsthailand", "_blank")}
        />
        <YoutubeSVG
          className="w-5 h-5 cursor-pointer"
          onClick={() =>
            window.open("https://www.youtube.com/dbsthailand", "_blank")
          }
        />
      </div>
    </div>
  );
};
