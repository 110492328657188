import httpClient from "./http";

class MissionService {
  private http = httpClient();

  async getMissions(): Promise<IMissions[]> {
    return this.http
      .post<IMissions[]>("/missions")
      .then((res) => res.data)
      .catch((error) => {
        console.log("🚀 ~ ATSService ~ popup ~ error:", error);
        return [];
      });
  }
}

export default MissionService;

export interface IMissions {
  titleEN: string;
  titleTH: string;
  callback: string;
  image: string;
}
