import { useTranslation } from "react-i18next";
import OverseasTradingPortfolioModel from "../../../../models/overseas-trading-portfolio.model";
import { IOverseasPortfolio } from "../../../../services/overseas-trading";
import BarChart from "../../../BarChart";

const UnrealizedGainChart = ({
  portfolio,
  viewMode,
}: {
  portfolio: IOverseasPortfolio;
  viewMode: "Stock" | "Market";
}) => {
  const { t: translate } = useTranslation();

  return (
    <BarChart
      id={100}
      chartData={{
        data: new OverseasTradingPortfolioModel(
          portfolio
        ).getChartDataUnrealizedByViewMode(viewMode),
        isPercent: true,
        title: translate("overseasPortfolio.percentUnrealizedGainLoss"),
      }}
      height="20rem"
    />
  );
};

export default UnrealizedGainChart;
