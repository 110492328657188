import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { useUserContext } from "../../context/user/user.context";
import { BTFLevel } from "../../enum/rewards";
import { addCommaToNumber } from "../../helper/format.number";
import { ReactComponent as CheckIcon } from "../../svgIcon/check-circle.svg";
import { ReactComponent as InformationIcon } from "../../svgIcon/information.svg";

export const ActiveLevel = () => {
  const { t: translate } = useTranslation();
  const { userRewards } = useUserContext();

  const isActiveLevel = () => userRewards.btfLevel === BTFLevel.ACTIVE;
  const getLanguage = () => i18next.language;
  const tradingVolumeLeft = addCommaToNumber(
    (10000000 - userRewards.btfTradingVolume).toString()
  );

  const upgradingDesc = () => {
    if (getLanguage() === "en")
      return `${tradingVolumeLeft} baht left to\nupgrade to Elite Level`;
    if (getLanguage() === "th")
      return `เทรดอีก ${tradingVolumeLeft} บาท\nเพื่ออัพเกรดสู่ Elite Level`;
  };

  return (
    <div className="flex flex-col xl:w-48 lg:w-40">
      <div
        className={`flex flex-col bg-dbsvRewards-bgContent ${
          isActiveLevel() ? "border-solid" : "border-dashed"
        } border border-error-500 rounded-lg px-3 py-4 text-center items-center xl:h-40 lg:h-44`}
        data-testid="active-level-section"
      >
        <p data-testid="title-section">Active Level</p>
        {isActiveLevel() ? (
          <img
            src="/icons/rewards/active-badge.png"
            className="w-12 my-3"
            alt="active-badge"
          />
        ) : (
          <CheckIcon
            className="w-12 h-12 my-3 text-error-500"
            data-testid="check-icon"
          />
        )}
        <p
          className="text-xs whitespace-pre-line"
          data-testid="active-level-desc"
        >
          {translate("rewards.overseas_trading_mission.active_level_desc")}
        </p>
      </div>
      {isActiveLevel() ? (
        <div
          className="flex flex-row items-start justify-center mt-3 text-xs whitespace-pre-line"
          data-testid="upgrading-description"
        >
          <InformationIcon className="w-6 h-6 mr-2 text-error-500" />
          {upgradingDesc()}
        </div>
      ) : (
        <div
          className="mt-3 text-xs text-center whitespace-pre-line"
          data-testid="mission-completed-description"
        >
          {translate("rewards.overseas_trading_mission.mission_complete")}
        </div>
      )}
    </div>
  );
};
