import { IBannerSet } from "../interfaces/banner";
import httpClient from "./http";

class BannerService {
  private client = httpClient();
  getBanners = () =>
    this.client.get<IBannerSet>("/banners").then((res) => res.data);

  seenBanner = (id: number) =>
    this.client.patch<string[]>(`/banners/${id}/seen`);

  updateUserSeenBanner = (bannerIds: number[]) =>
    this.client.put<string[]>(`/banners/user-seen-banners`, {
      banner_ids: bannerIds,
    });
}

export default BannerService;
