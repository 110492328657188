import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";

const Loading = () => {
  const { t: translate } = useTranslation();
  return (
    <div className="fixed inset-0 z-30 overflow-y-auto" data-testid="loading">
      <div className="flex items-center justify-center h-full min-h-screen p-4 text-center opacity-100 sm:block sm:p-0">
        <div
          className="fixed inset-0 transition-opacity"
          aria-hidden="true"
          onClick={() => {}}
        >
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div
          style={{ width: "20rem", maxWidth: "40rem" }}
          className="inline-block h-40 overflow-hidden text-left transition-all transform bg-white rounded-lg sm:my-8 sm:align-middle"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="flex flex-col items-center justify-center h-full">
            <div
              className={`${styles.loader} ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 my-3`}
            ></div>
            <h1>{translate("loading.message")}</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
