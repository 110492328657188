import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PageName } from "../../enum/page.name";
import { openWindow } from "../../helper/open.window";
import SSOSTTLink from "../../helper/sso.stt.link";
import { currentLanguage } from "../../i18n/helper";
import { IUserInfo } from "../../interfaces/user";
import SSODBSVService from "../../services/sso-dbsv.service";
import UserService from "../../services/user.service";
import IconButton from "./IconButton";
import Title from "./Title";

const Bank = () => {
  const { t } = useTranslation();

  const [userInfo, setUserInfo] = useState<IUserInfo>();

  useEffect(() => {
    new UserService().getUserInfo().then(setUserInfo);
  }, []);

  return (
    <div className="flex flex-col gap-y-4">
      <Title title={t("eService.accountManagement.title")} />
      <div className="grid grid-cols-4 items-start gap-3">
        <IconButton
          label={t("eService.accountManagement.cashDeposit")}
          Icon={DepositCash}
          onClick={() =>
            new SSOSTTLink({ userref: userInfo!.userref }).openLink({
              pageName: PageName.CashDepositITP,
              isAdditionalLang: true,
              isPopup: false,
              replaceUrl: true,
            })
          }
        />
        <IconButton
          label={t("eService.accountManagement.cashWithdrawal")}
          Icon={WithdrawCash}
          onClick={() =>
            new SSOSTTLink({ userref: userInfo!.userref }).openLink({
              pageName: PageName.CashWithdrawITP,
              isAdditionalLang: true,
              isPopup: false,
              replaceUrl: true,
            })
          }
        />
        <IconButton
          label={t("eService.accountManagement.securitiesDeposit")}
          Icon={DepositStock}
          onClick={() =>
            new SSOSTTLink({ userref: userInfo!.userref }).openLink({
              pageName: PageName.SecuritiesDepositITP,
              isAdditionalLang: true,
              isPopup: false,
              replaceUrl: true,
            })
          }
        />
        <IconButton
          label={t("eService.accountManagement.securitiesWithdrawal")}
          Icon={WithdrawStock}
          onClick={() =>
            new SSOSTTLink({ userref: userInfo!.userref }).openLink({
              pageName: PageName.SecuritiesWithdrawITP,
              isAdditionalLang: true,
              isPopup: false,
              replaceUrl: true,
            })
          }
        />
        <IconButton
          label={t("eService.accountManagement.forOffshoreTrading")}
          Icon={Oversea}
          onClick={() => {
            new SSODBSVService()
              .getBTFEFormLink(currentLanguage())
              .then((link) =>
                openWindow({ link, isPopup: false, target: "_self" })
              );
          }}
        />
      </div>
    </div>
  );
};

export default Bank;

const DepositCash = (
  <img src="/icons/e-service/bank/deposit_cash.png" alt="deposit-cash" />
);
const WithdrawCash = (
  <img src="/icons/e-service/bank/withdraw_cash.png" alt="withdraw-cash" />
);
const DepositStock = (
  <img src="/icons/e-service/bank/deposit_stock.png" alt="deposit-stock" />
);
const WithdrawStock = (
  <img src="/icons/e-service/bank/withdraw_stock.png" alt="withdraw-stock" />
);
const Oversea = <img src="/icons/e-service/bank/oversea.png" alt="oversea" />;
