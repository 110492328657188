import { useTranslation } from "react-i18next";
import { SpecialMenuIcon } from "../../components/SubMenuIcon/special-menu-icon";
import { SubMenuIcon } from "../../components/SubMenuIcon/sub-menu-icon";
import { Title } from "../../components/Title";
import { useUserContext } from "../../context/user/user.context";
import { PageName } from "../../enum/page.name";
import { openWindowWithBlankPage } from "../../helper/open.window";
import { currentLanguage } from "../../i18n/helper";
import { IMenu, ISpecialMenu, ISubMenu } from "../../interfaces/menu";
import SSODBSVService from "../../services/sso-dbsv.service";
import { onOpenRequestForm } from "../MyAccount/helper";

const EForms = () => {
  const { t: translate } = useTranslation();
  const { eFormSubMenu, ssoSTTLink } = useUserContext();

  const ChangePersonalDataMenu = () => (
    <EFormsBuildMenu
      menu={eFormSubMenu.changePersonalData}
      title={translate("e_forms.change_personal_data")}
    />
  );

  const RequestReportsMenu = () => (
    <EFormsBuildMenu
      menu={eFormSubMenu.requestReports}
      title={translate("e_forms.request_reports")}
    />
  );

  const CashStockTransactionMenu = () => (
    <EFormsBuildMenu
      menu={eFormSubMenu.cashStockTransactions}
      title={translate("e_forms.cash_stock_transactions")}
    />
  );

  const EFormsBuildMenu = ({
    menu,
    title,
  }: {
    menu: IMenu[];
    title: string;
  }) => {
    if (!menu.length) return <></>;
    return (
      <>
        <Title title={title} />
        <div className="grid items-start justify-center grid-cols-2 m-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 md:m-6 lg:m-12 gap-y-8">
          {buildMenu(menu)}
        </div>
      </>
    );
  };

  const buildMenu = (menu: IMenu[]) => {
    return menu.map((el, index) =>
      el.isSpecialMenu
        ? buildSpecialMenuButton(el.menuDetail as ISpecialMenu, index)
        : buildMenuButton(el.menuDetail as ISubMenu, index)
    );
  };

  const buildMenuButton = (subMenu: ISubMenu, index: number) => {
    return (
      <SubMenuIcon
        imgPath={subMenu.imagePath}
        label={translate(subMenu.name)}
        key={index}
        onClick={async () => {
          switch (subMenu.pageName) {
            case PageName.ChangePersonalData:
              onOpenRequestForm();
              break;
            case PageName.BTFEForm:
              const ssoLink = await new SSODBSVService().getBTFEFormLink(
                currentLanguage()
              );
              openWindowWithBlankPage({ link: ssoLink, isPopup: false });
              break;
            default:
              ssoSTTLink.openLink({
                pageName: subMenu.pageName!,
                isAdditionalLang: subMenu.isAdditionalLang!,
                isPopup: true,
              });
              break;
          }
        }}
      />
    );
  };

  const buildSpecialMenuButton = (menu: ISpecialMenu, index: number) => {
    return <SpecialMenuIcon specialMenu={menu} key={index} />;
  };

  return (
    <div className="flex flex-col p-4 gap-y-8">
      <div
        id="e-froms-submenu"
        className="px-6 py-4 card-bg md:col-span-5 xl:col-span-4"
      >
        <CashStockTransactionMenu />
        <ChangePersonalDataMenu />
        <RequestReportsMenu />
      </div>
    </div>
  );
};
export default EForms;
