/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { PATH_ROUTE } from "../../const/path";
import UserService from "../../services/user.service";
import PrivateRoute from "../PrivateRoute";

const ShouldAcceptDisclaimerRoute = ({
  component,
  path,
}: {
  component: () => JSX.Element;
  path: string;
}) => {
  const history = useHistory();
  useEffect(() => {
    checkUserShouldAcceptDisclaimer();
  }, []);

  const checkUserShouldAcceptDisclaimer = async () => {
    const result = await new UserService().checkUserShouldAcceptDisclaimer();
    if (result.shouldAcceptDisclaimer)
      history.replace(PATH_ROUTE.DBSV_RESEARCH.path);
  };
  return <PrivateRoute path={path} component={component}></PrivateRoute>;
};

export default ShouldAcceptDisclaimerRoute;
