import { isThaiLanguage } from "../../../../i18n/helper";
import { IShortcut } from "../../../../interfaces/user";

export const ShortcutDashboard = ({
  shortcut,
  onClick,
}: {
  shortcut: IShortcut;
  onClick?: () => void;
}) => {
  return (
    <ShortcutItem
      isSelected={false}
      onClick={onClick}
      shortcut={shortcut}
      type="shortcut-dashboard"
    />
  );
};

export const Shortcut = ({
  shortcut,
  onClick,
  isSelected,
}: {
  shortcut: IShortcut;
  onClick?: () => void;
  isSelected?: boolean;
}) => {
  isSelected = isSelected ?? false;
  return (
    <ShortcutItem
      onClick={onClick}
      type="shortcut"
      shortcut={shortcut}
      isSelected={isSelected}
    />
  );
};

export const ShortcutSelected = ({
  shortcut,
  onClick,
}: {
  shortcut: IShortcut;
  onClick?: () => void;
}) => {
  return (
    <ShortcutItem
      onClick={onClick}
      type="shortcut-selected"
      shortcut={shortcut}
      isSelected={true}
    />
  );
};

const ShortcutItem = ({
  shortcut,
  onClick,
  isSelected,
  type,
}: {
  type: "shortcut" | "shortcut-dashboard" | "shortcut-selected";
  shortcut: IShortcut;
  onClick?: () => void;
  isSelected: boolean;
}) => {
  const shortcutName = (): string => {
    if (isThaiLanguage()) return shortcut.nameTH;
    return shortcut.nameEN;
  };
  const getShortcutStyle = () => {
    if (type === "shortcut-selected") return "";
    if (type === "shortcut" && isSelected)
      return "bg-black bg-opacity-50 opacity-80";
    return "hover:bg-gray-200 cursor-pointer";
  };

  return (
    <div className="relative h-36 w-44">
      <div className={`flex md:p-2 h-full  justify-center`} onClick={onClick}>
        <div
          className={`flex flex-col items-center pt-4 pb-2 px-4 h-full w-32 md:w-40 gap-y-4 ${
            type === "shortcut-dashboard" ? "" : "justify-between"
          } ${getShortcutStyle()}
          `}
        >
          <img src={shortcut.imagePath} alt="" className="h-6 mb-2 md:h-8" />
          <p className="flex text-sm text-center whitespace-pre-wrap md:text-md">
            {shortcutName()}
          </p>
        </div>
        {type === "shortcut" && isSelected && (
          <h1 className="absolute text-xl text-white top-1/2">SELECTED</h1>
        )}
      </div>
    </div>
  );
};
