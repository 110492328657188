import { IDynamicDropdownOption } from "../../components/DynamicDropdown";
import { ResearchType } from "../../enum/research";
import { isThaiLanguage } from "../../i18n/helper";
import { IResearchCategory } from "../../interfaces/research";

export const filterResearchCategoryOption = (
  option: IDynamicDropdownOption,
  researchTypeSelected: IDynamicDropdownOption,
  initialResearchCategory: IDynamicDropdownOption
): boolean => {
  if (researchTypeSelected.value === ResearchType.ALL) return true;
  return (
    option.value === initialResearchCategory.value ||
    option?.type === researchTypeSelected.value
  );
};

const findResearchType = (
  listOfResearchType: IDynamicDropdownOption[],
  researchTypeSelected: IDynamicDropdownOption
) =>
  listOfResearchType.find((f) => f.value === researchTypeSelected.value) ||
  researchTypeSelected;

const findResearchCategory = (
  options: IDynamicDropdownOption[],
  categorySelected: IDynamicDropdownOption
) =>
  options.find((f) => f.value === categorySelected.value) || categorySelected;

function mapReseachCategory(
  researchCategoies: IResearchCategory[],
  initialResearchCategory: IDynamicDropdownOption
) {
  const mapCategory = (category: IResearchCategory) => {
    return {
      value: category.id,
      label: isThaiLanguage() ? category.name_th : category.name_en,
      type: category.research_type,
    };
  };
  return [initialResearchCategory, ...researchCategoies.map(mapCategory)];
}

function calculateTotalPage(count: number, perPage: number) {
  if (count === 0) return 1;
  const hasRemainder = count % perPage !== 0;
  const totalPage = Math.floor(count / perPage);
  if (hasRemainder) return totalPage + 1;
  return totalPage;
}

const helper = {
  findResearchType,
  findResearchCategory,
  mapReseachCategory,
  calculateTotalPage,
};
export default helper;
