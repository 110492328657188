import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowRight } from "../../svgIcon/new-dashboard/accounts/arrow-right.svg";

export const ViewAllButton = ({ onClick }: { onClick: VoidFunction }) => {
  const { t: translate } = useTranslation();
  return (
    <button
      className="flex flex-row items-center justify-center h-6 text-xs text-white rounded bg-dbsv-brand-dbs-red w-28 gap-x-3"
      onClick={onClick}
    >
      <div>{translate("research.view_all")}</div> <ArrowRight />
    </button>
  );
};
