/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { DateTime } from "luxon";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHandleAPIStateContext } from "../../context/handle-api-state";
import { isThaiLanguage } from "../../i18n/helper";
import {
  IEStatementGroupAccountType,
  IEStatementResponse,
} from "../../interfaces/e-statement";
import { IHandleModal } from "../../interfaces/handle.modal";
import { ReportType } from "../../interfaces/reporttype";
import { ITab } from "../../interfaces/tab";
import { EStatementService } from "../../services/e-statement.service";
import { CustomTab } from "../CustomTab";
import { DynamicDropdown, IDynamicDropdownOption } from "../DynamicDropdown";
import { DynamicTable, ITableCell, ITableRow } from "../DynamicTable";
import { FilterAccount } from "../FilterAccount";
import { FilterTradeDate } from "../FilterTradeDate";
import LoadingCard from "../LoadingCard";
import { Title } from "../Title";
import {
  filterDailyStatement,
  filterMonthlyStatement,
  generateEStatementDateOptions,
  groupAccounts,
  groupEStatementByAccountType,
} from "./helper";

export const EStatement = () => {
  const { t: translate } = useTranslation();
  const {
    setGetDataEror,
  }: {
    setGetDataEror: (getDataError: IHandleModal) => void;
  } = useHandleAPIStateContext();

  const [loading, setLoading] = useState(true);
  const [type, setType] = useState<ReportType>(ReportType.DAILY);
  const [tradeDateFilter, setTradeDateFailter] = useState<Date | undefined>(
    DateTime.local().minus({ days: 1 }).toJSDate()
  );
  const { t: translation } = useTranslation();
  const [eStatements, setEStatements] = useState<IEStatementResponse[]>([]);
  const [eStatementTableBody, setEStatementTableBody] = useState<ITableRow[]>(
    []
  );
  const [accountOptions, setAccountOptions] = useState<string[]>([]);
  const [account, setAccount] = useState<string>("");
  const [monthOptions, setMonthOptions] = useState<IDynamicDropdownOption[]>(
    []
  );
  const [selectedMonthOptions, setSelectedMonthOptions] =
    useState<IDynamicDropdownOption>({ label: "", value: "" });

  const eStatementService = new EStatementService();
  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) return;
    let currentDate;
    if (type === ReportType.MONTHLY) {
      currentDate = DateTime.local().set({ day: 0 });
    } else {
      currentDate = DateTime.fromJSDate(tradeDateFilter!);
    }
    const day = currentDate.day.toString();
    const month = currentDate.month.toString();
    const year = currentDate.year.toString();
    getEStatement({
      day,
      month,
      year,
    });
  }, [type]);

  useEffect(() => {
    changeLanguageSelectMonth();
    mapEStatementToTableData(eStatements);
  }, [translation]);

  useEffect(() => {
    if (firstRender.current) return;
    handleFilter(selectedMonthOptions.value);
  }, [selectedMonthOptions]);

  useEffect(() => {
    if (firstRender.current) return;
    handleAccountFilter();
  }, [account]);

  useEffect(() => {
    if (firstRender.current) firstRender.current = false;
    handleFilter(tradeDateFilter?.toISOString()!);
  }, [tradeDateFilter]);

  const getEStatement = async ({
    day,
    month,
    year,
  }: {
    day: string;
    month: string;
    year: string;
  }) => {
    try {
      setLoading(true);
      const { data: eStatementsResponse } =
        await eStatementService.getEStatement({
          date: day,
          month,
          year,
        });
      if (!eStatementsResponse) {
        setEStatements([]);
        mapEStatementToTableData([]);
        return;
      }

      const eStatementType = {
        [ReportType.MONTHLY]: "monthly",
        [ReportType.DAILY]: "daily",
      };
      const eStatementsFiltered = eStatementsResponse.filter((each) => {
        const filterByType = each.documentType.startsWith(eStatementType[type]);

        if (type === ReportType.MONTHLY) {
          return (
            filterByType &&
            filterMonthlyStatement(
              new Date(each.documentDate),
              new Date(selectedMonthOptions.value)
            )
          );
        } else {
          return (
            filterByType &&
            tradeDateFilter &&
            filterDailyStatement(new Date(each.documentDate), tradeDateFilter)
          );
        }
      });
      setEStatements([...eStatementsFiltered]);
      mapEStatementToTableData([...eStatementsFiltered]);
    } finally {
      setLoading(false);
    }
  };

  const onDownloadFileError = (error: any) => {
    if (axios.isAxiosError(error) && error.response?.status === 403) {
      setGetDataEror({
        isShow: true,
        title: translate("alert.e_statement.title"),
        action: "",
        description: translate("alert.e_statement.description"),
        submitText: "",
      });
    } else {
      setGetDataEror({
        isShow: true,
        title: translate("alert.default_title"),
        action: "",
        description: translate("alert.get_data_error"),
        submitText: "",
      });
    }
  };

  const mapEStatementToTableData = (
    eStatementsResponse: IEStatementResponse[]
  ) => {
    const accountsGrouped = groupAccounts(eStatementsResponse);
    setAccountOptions(accountsGrouped);
    if (account)
      eStatementsResponse = eStatementsResponse.filter(
        (el) => el.account === account
      );
    const grouped = groupEStatementByAccountType(eStatementsResponse);
    setEStatementTableBody(transformToTableBody(grouped, onDownloadFileError));
  };

  const handleFilter = (dateISO: string) => {
    let date = DateTime.fromISO(dateISO);
    if (type === ReportType.MONTHLY) date = date.endOf("month");
    getEStatement({
      day: date.day.toString(),
      month: date.month.toString(),
      year: date.year.toString(),
    });
  };

  const changeLanguageSelectMonth = () => {
    const months = generateEStatementDateOptions(
      DateTime.now(),
      isThaiLanguage()
    );
    setSelectedMonthOptions(months[0]);
    setMonthOptions(months);
  };

  const handleAccountFilter = (): void => {
    if (account) {
      const eStatementFilter = eStatements.filter(
        (eStatement) => eStatement.account === account
      );
      const grouped = groupEStatementByAccountType(eStatementFilter);
      setEStatementTableBody(
        transformToTableBody(grouped, onDownloadFileError)
      );
      return;
    }
    const grouped = groupEStatementByAccountType(eStatements);
    setEStatementTableBody(transformToTableBody(grouped, onDownloadFileError));
  };

  const tabs: ITab[] = [
    { value: ReportType.DAILY, label: translation("statement_report.daily") },
    {
      value: ReportType.MONTHLY,
      label: translation("statement_report.monthly"),
    },
  ];

  return (
    <div className="px-6 py-4 card-bg md:col-span-5 xl:col-span-4">
      <Title title={translation("statement_report.statement_title")} />
      <div className="justify-center block w-1/2 mx-auto mb-6 md:w-4/12">
        <CustomTab
          className="pt-8 border-b border-gray-200"
          tabs={tabs}
          currentTab={type}
          onClick={(currentTab) => {
            if (currentTab === ReportType.MONTHLY) {
              setSelectedMonthOptions(monthOptions[0]);
            }
            setType(currentTab as ReportType);
          }}
        />
      </div>
      <div className="flex flex-wrap justify-center mt-6 md:justify-start gap-x-6 gap-y-2">
        <FilterAccount accounts={accountOptions} onSelectAccount={setAccount} />
        {type === ReportType.MONTHLY ? (
          <DynamicDropdown
            theme="dark"
            options={monthOptions}
            selectedOption={selectedMonthOptions}
            onSelected={(option) => setSelectedMonthOptions(option)}
          />
        ) : (
          <FilterTradeDate
            date={tradeDateFilter}
            onSelectDate={(date) => {
              setTradeDateFailter(date);
            }}
          />
        )}
      </div>
      {loading ? (
        <LoadingCard />
      ) : (
        <DynamicTable
          header={[
            { element: "Account No.", align: "left", className: "w-2/12" },
            { element: "Report", align: "left" },
            { element: "Download", align: "center", className: "w-1/12" },
          ]}
          body={eStatementTableBody}
          key="e-statement-table"
        />
      )}
    </div>
  );
};

export const IconDownload = () => {
  return (
    <div className="flex items-center justify-center">
      <svg
        className="w-6 h-6"
        viewBox="0 0 45 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V56H45V13.1744L31.8727 0H31.5H0ZM1.79951 1.80649H30.5995V14.4517H43.1995V54.1936H1.79951V1.80649ZM41.9272 12.6453L32.3998 3.08376V12.6453H41.9272ZM23.3996 18.0647C16.4515 18.0647 10.7996 23.7368 10.7996 30.7098C10.7996 37.6829 16.4515 43.355 23.3996 43.355C30.3477 43.355 35.9996 37.6829 35.9996 30.7098C35.9996 23.7368 30.3477 18.0647 23.3996 18.0647ZM34.1543 29.8066C33.7219 24.5336 29.5535 20.3488 24.3 19.9152V24.3872C24.7971 24.3872 25.2 24.7916 25.2 25.2905C25.2 25.7893 24.7971 26.1937 24.3 26.1937V29.8066H34.1543ZM22.5001 19.9168V31.0837L30.3663 38.9798C28.4877 40.5774 26.0631 41.5484 23.4001 41.5484C17.4248 41.5484 12.6001 36.7064 12.6001 30.7097C12.6001 25.018 16.9489 20.3754 22.5001 19.9168ZM26.1 22.5806C26.1 22.0818 26.503 21.6774 27 21.6774C27.4971 21.6774 27.9 22.0818 27.9 22.5806C27.9 23.0795 27.4971 23.4839 27 23.4839C26.503 23.4839 26.1 23.0795 26.1 22.5806ZM29.6997 24.3873C29.2027 24.3873 28.7997 24.7917 28.7997 25.2905C28.7997 25.7893 29.2027 26.1937 29.6997 26.1937C30.1968 26.1937 30.5997 25.7893 30.5997 25.2905C30.5997 24.7917 30.1968 24.3873 29.6997 24.3873ZM26.1 27.9999C26.1 27.501 26.503 27.0967 27 27.0967C27.4971 27.0967 27.9 27.501 27.9 27.9999C27.9 28.4987 27.4971 28.9031 27 28.9031C26.503 28.9031 26.1 28.4987 26.1 27.9999ZM32.4001 27.0967C31.9031 27.0967 31.5001 27.501 31.5001 27.9999C31.5001 28.4987 31.9031 28.9031 32.4001 28.9031C32.8972 28.9031 33.3001 28.4987 33.3001 27.9999C33.3001 27.501 32.8972 27.0967 32.4001 27.0967ZM34.1546 31.6128H25.573L31.6409 37.7007C33.0472 36.0288 33.9647 33.9284 34.1546 31.6128Z"
          fill="black"
        />
      </svg>
    </div>
  );
};

export function transformToTableBody(
  eStatementAccountTypeGrouped: IEStatementGroupAccountType[],
  onDownloadFileError: (error: unknown) => void
): ITableRow[] {
  let result: ITableRow[] = [];

  for (const group of eStatementAccountTypeGrouped) {
    result.push({
      cell: {
        seperatorLabel: group.accountType,
      },
    });
    for (const eStatement of group.eStatements.sort((a, b) =>
      a.account.localeCompare(b.account)
    )) {
      const handleClickToDownloadFile = () =>
        handleDownloadEStatement(eStatement).catch(onDownloadFileError);

      let row: ITableCell[] = [
        {
          element: eStatement.account,
          align: "left",
          className: "h-14",
          onClick: handleClickToDownloadFile,
        },
        {
          element: getReportNameToDisplay(eStatement),
          align: "left",
          onClick: handleClickToDownloadFile,
        },
        {
          element: <IconDownload />,
          align: "center",
          onClick: handleClickToDownloadFile,
        },
      ];

      result.push({
        cell: row,
      });
    }
  }
  return result;
}
const getReportNameToDisplay = (eStatement: IEStatementResponse) => {
  const date = DateTime.fromISO(eStatement.documentDate)
    .setLocale(isThaiLanguage() ? "th" : "us")
    .toFormat("LLLL yyyy");
  return `${eStatement.reportName} - ${date}`;
};

export const handleDownloadEStatement = async (
  eStatement: IEStatementResponse
) => {
  return new EStatementService().downloadEstatement(eStatement);
};
