import {
  IContractNoteDetail,
  IDerivSTTHeaderNonBsb,
} from "../interfaces/contract.note";
import { IConfirmationReportList } from "../interfaces/confirmation.report.lists";
import httpClient from "./http";
import { IConfirmationReportDetail } from "../interfaces/confirmation.report.detail";
import { IMonthlyReport } from "../interfaces/monthly.report.list";
import { ICashMovement } from "../interfaces/cash.movement";
import { IStockMovement } from "../interfaces/stock.movement";
import { IStockStatement } from "../interfaces/stock.statement";
import {
  IDailyCashMovement,
  IDailyStockMovement,
} from "../interfaces/daily-movment";

class ReportService {
  private http = httpClient();

  async getContractNoteSTTHeader(
    tradeDate: string,
    accountNo: string[]
  ): Promise<IDerivSTTHeaderNonBsb[]> {
    try {
      const res = await this.http.post("/report/contract-note/stt-header", {
        tradeDate,
        accountNoList: accountNo,
      });
      return res.data;
    } catch (error) {
      throw error;
    }
  }

  async getConfirmationList({
    accounts,
    tradeDate,
  }: {
    accounts: string[];
    tradeDate: string;
  }): Promise<IConfirmationReportList[]> {
    try {
      const result = await this.http.post("/report/confirmation/lists", {
        accountNoList: accounts,
        tradeDate,
      });
      return result.data;
    } catch (error) {
      throw error;
    }
  }

  async getConfirmationDetail({
    accountNo,
    docNo,
  }: {
    accountNo: string;
    docNo: string;
  }): Promise<IConfirmationReportDetail[]> {
    try {
      const result = await this.http.post("/report/confirmation/details", {
        accountNoList: [accountNo],
        docNo,
      });
      return result.data;
    } catch (error) {
      throw error;
    }
  }

  async getMonthlyReport({
    accounts,
  }: {
    accounts: string[];
  }): Promise<IMonthlyReport[]> {
    try {
      const result = await this.http.post("/report/monthly-report", {
        accountNoList: accounts,
      });
      return result.data;
    } catch (error) {
      throw error;
    }
  }

  async getMonthlyCashMovementDetail(
    monthlyReport: IMonthlyReport
  ): Promise<ICashMovement[]> {
    try {
      const result = await this.http.post(
        "/report/monthly-report/cash-movement",
        {
          accountNoList: [monthlyReport.accountNo],
          asOfDate: monthlyReport.asOfDate,
        }
      );
      return result.data;
    } catch (error) {
      throw error;
    }
  }

  async getMonthlyStockMovementDetail(
    monthlyReport: IMonthlyReport
  ): Promise<IStockMovement[]> {
    try {
      const result = await this.http.post(
        "/report/monthly-report/stock-movement",
        {
          accountNoList: [monthlyReport.accountNo],
          asOfDate: monthlyReport.asOfDate,
        }
      );
      return result.data;
    } catch (error) {
      throw error;
    }
  }

  async getMonthlyStockStatementDetail(
    monthlyReport: IMonthlyReport
  ): Promise<IStockStatement[]> {
    try {
      const result = await this.http.post(
        "/report/monthly-report/stock-statement",
        {
          accountNoList: [monthlyReport.accountNo],
          asOfDate: monthlyReport.asOfDate,
        }
      );
      return result.data;
    } catch (error) {
      throw error;
    }
  }

  async getContractNoteDetail(
    settlementNo: string,
    accountNo: string
  ): Promise<IContractNoteDetail> {
    try {
      const result = await this.http.post("/report/contract-note/detail", {
        settlementNo,
        accountNoList: [accountNo],
      });
      return result.data;
    } catch (error) {
      throw error;
    }
  }

  async getDailayCashMovement(accountNo: string): Promise<IDailyCashMovement> {
    try {
      const result = await this.http.post("/report/daily/cash-movement", {
        accountNoList: [accountNo],
      });
      return result.data;
    } catch (error) {
      throw error;
    }
  }

  async getDailayStockMovement(
    accountNo: string
  ): Promise<IDailyStockMovement> {
    try {
      const result = await this.http.post("/report/daily/stock-movement", {
        accountNoList: [accountNo],
      });
      return result.data;
    } catch (error) {
      throw error;
    }
  }

  async getDailayConfirmation(accountNo: string): Promise<{
    confirmationDetails: IConfirmationReportDetail[];
    confirmation: IConfirmationReportList;
  }> {
    try {
      const result = await this.http.post("/report/daily/confirmation", {
        accountNoList: [accountNo],
      });
      return result.data;
    } catch (error) {
      throw error;
    }
  }

  async getDailayContractNote(accountNo: string): Promise<IContractNoteDetail> {
    try {
      const result = await this.http.post("/report/daily/contract-note", {
        accountNoList: [accountNo],
      });
      return result.data;
    } catch (error) {
      throw error;
    }
  }
}

export default ReportService;
