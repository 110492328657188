/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHandleAPIStateContext } from "../../context/handle-api-state";
import { useUserContext } from "../../context/user/user.context";
import { AccountType } from "../../enum/account";
import { PageName } from "../../enum/page.name";
import { getErrorMessageGetDataFail } from "../../helper/modal.message";
import { openWindowWithBlankPage } from "../../helper/open.window";
import { useFeatureToggle } from "../../hooks/features";
import { IDisclaimer } from "../../interfaces/cms";
import { IConfirmationReportDetail } from "../../interfaces/confirmation.report.detail";
import { IConfirmationReportList } from "../../interfaces/confirmation.report.lists";
import { IContractNoteDetail } from "../../interfaces/contract.note";
import {
  IDailyCashMovement,
  IDailyStockMovement,
} from "../../interfaces/daily-movment";
import { IEStatementResponse } from "../../interfaces/e-statement";
import { IHandleModal } from "../../interfaces/handle.modal";
import CMSService from "../../services/cms.service";
import EStatementService from "../../services/e-statement.service";
import OverseasTrading, {
  IOverseasPortfolio,
} from "../../services/overseas-trading";
import ReportService from "../../services/report.service";
import { ReactComponent as BankAccountIcon } from "../../svgIcon/new-dashboard/accounts/bank-account.svg";
import { Card } from "../Card";
import { ComfirmationReportDetail } from "../ComfirmationReportDetail";
import { ConsolidatedPortfolioDialog } from "../ConsolidatedPortfolioDialog";
import ContractNoteModal from "../ContractNoteModal";
import { handleDownloadEStatement } from "../EStatement";
import LoadingCard from "../LoadingCard";
import { IMyInvestmentTab } from "../MyInvestment";
import LoadingTab from "../MyInvestment/TabMenu/LoadingTab";
import OpenOverseasAccountTab from "../MyInvestment/TabMenu/OpenOverseasAccountTab";
import {
  arrageAccountTaps,
  isActive,
  isEquityAccount,
} from "../MyInvestment/helper";
import { AccountTab } from "./AccountTab";
import { InvestmentShortcutByAccountType } from "./InvestmentShorcut";

export const Accounts = () => {
  const { t: translate } = useTranslation();
  const { ssoSTTLink, accountInfo, userInfo } = useUserContext();
  const {
    setGetDataEror,
    setGettingData,
  }: {
    setGettingData: (gettingData: boolean) => void;
    setGetDataEror: (getDataError: IHandleModal) => void;
  } = useHandleAPIStateContext();
  const { consolidatePortFeat } = useFeatureToggle();

  const [accountsTab, setAccountsTab] = useState<IMyInvestmentTab[]>([]);
  const [currentTab, setCurrentTab] = useState<IMyInvestmentTab>();
  const [loading, setLoading] = useState<boolean>(false);
  const [overseasPortfolio, setOverseasPortfolio] =
    useState<IOverseasPortfolio | null>(null);

  const [isConsolidateUnconnectable, setIsConsolidateUnconnectable] =
    useState(false);

  const [loadingOverseasPortfolio, setLoadingOverseasPortfolio] =
    useState<boolean>(false);

  const [dailyCashMovement, setDailyCashMovement] =
    useState<IDailyCashMovement>();
  const [dailyStockMovement, setDailyStockMovement] =
    useState<IDailyStockMovement>();

  const [dailyConfirmation, setDailyConfirmation] = useState<{
    confirmation: IConfirmationReportList;
    confirmationDetails: IConfirmationReportDetail[];
  }>();
  const [openDailyConfirmation, setOpenDailyConfirmation] =
    useState<boolean>(false);

  const [dailyContractNote, setDailyContractNote] =
    useState<IContractNoteDetail>();
  const [openDailyContractNote, setOpenDailyContractNote] =
    useState<boolean>(false);

  const [eMonthlyStatement, setEMonthlyStatement] =
    useState<IEStatementResponse>();

  const [disclaimer, setDisclaimer] = useState<IDisclaimer>();

  const [openConsolidatedPortfolio, setOpenConsolidatedPortfolio] =
    useState<boolean>(false);

  useEffect(() => {
    if (currentTab?.accountNo) getDialyAccountInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab?.accountNo]);

  useEffect(() => {
    setLoadingOverseasPortfolio(true);
    Promise.allSettled([
      new OverseasTrading().getPortfolio(),
      new CMSService().getDisclaimerLatest(),
    ])
      .then((result) => {
        if (result[0].status === "fulfilled") {
          setOverseasPortfolio(result[0].value);
        } else if (
          result[0].status === "rejected" &&
          !String(result[0].reason).includes("404")
        ) {
          setIsConsolidateUnconnectable(true);
        }

        if (result[1].status === "fulfilled") {
          setDisclaimer(result[1].value);
        }
      })
      .finally(() => {
        setLoadingOverseasPortfolio(false);
      })
      .catch();
  }, []);

  const arrangeAccountTaps = useCallback(() => {
    const arragedAccountTaps = arrageAccountTaps(accountInfo);
    if (
      consolidatePortFeat &&
      (overseasPortfolio || isConsolidateUnconnectable)
    ) {
      setAccountsTab([
        ...arragedAccountTaps,
        {
          type: AccountType.BTF,
          accountNo: "",
        },
      ]);
    } else {
      setAccountsTab([...arragedAccountTaps]);
    }
    setCurrentTab(arragedAccountTaps[0]);
  }, [accountInfo.accounts, overseasPortfolio, isConsolidateUnconnectable]);

  useEffect(() => {
    arrangeAccountTaps();
  }, [arrangeAccountTaps]);

  const getDialyAccountInformation = () => {
    setLoading(true);
    const reportService = new ReportService();
    const eStatementService = new EStatementService();
    try {
      if (isEquityAccount(currentTab!.type)) {
        Promise.all([
          reportService.getDailayCashMovement(currentTab!.accountNo),
          reportService.getDailayStockMovement(currentTab!.accountNo),
          reportService.getDailayConfirmation(currentTab!.accountNo),
        ]).then((result) => {
          setDailyCashMovement(result[0]);
          setDailyStockMovement(result[1]);
          setDailyConfirmation(result[2]);
        });
      } else {
        reportService
          .getDailayContractNote(currentTab!.accountNo)
          .then(setDailyContractNote);
      }
      eStatementService
        .getEMonthlyStatement(currentTab!.accountNo)
        .then(setEMonthlyStatement);
    } catch (error) {
      setGetDataEror(getErrorMessageGetDataFail(translate));
    } finally {
      setGettingData(false);
      setLoading(false);
    }
  };

  const onSelectTab = (tab: IMyInvestmentTab) => {
    setDailyConfirmation(undefined);
    setDailyContractNote(undefined);
    setDailyStockMovement(undefined);
    setDailyCashMovement(undefined);
    setEMonthlyStatement(undefined);
    setCurrentTab(tab);
  };

  return (
    <Card icon={<BankAccountIcon />} title="Accounts" childrenStyle="pt-5">
      <div className="flex flex-row w-full">
        {accountInfo.accounts.length > 0 ? (
          <>
            <div className="w-full" data-testid="my-investment">
              <div className="flex justify-between w-full mt-2 overflow-x-auto md:mt-0">
                {accountsTab.map((el, index) => (
                  <AccountTab
                    key={index}
                    accountType={el.type}
                    accountNo={el.accountNo}
                    active={isActive(el, currentTab)}
                    onClick={() => onSelectTab(el)}
                  />
                ))}
                {loadingOverseasPortfolio && <LoadingTab />}
                {consolidatePortFeat &&
                  !hasOversesaAccount(overseasPortfolio) &&
                  !loadingOverseasPortfolio &&
                  !isConsolidateUnconnectable && (
                    <OpenOverseasAccountTab
                      onClick={() => {
                        openWindowWithBlankPage({
                          link: process.env.REACT_APP_OPEN_ACCOUNT_LINK!,
                          isPopup: false,
                        });
                      }}
                    />
                  )}
              </div>
              {loading ? (
                <LoadingCard />
              ) : (
                <div>
                  <InvestmentShortcutByAccountType
                    accountNo={currentTab?.accountNo}
                    accountType={currentTab?.type}
                    dailyCashMovement={dailyCashMovement}
                    dailyStockMovement={dailyStockMovement}
                    dailyConfirmation={dailyConfirmation}
                    dailyContractNote={dailyContractNote}
                    eMonthlyStatement={eMonthlyStatement}
                    onOpenStreaming={() => ssoSTTLink.openStreamingLink()}
                    onOpenConsolidatePort={() =>
                      setOpenConsolidatedPortfolio(true)
                    }
                    onOpenCashDeposit={() =>
                      ssoSTTLink.openLink({
                        pageName: PageName.CashDepositITP,
                        isAdditionalLang: true,
                        isPopup: true,
                      })
                    }
                    onOpenCashWithdrawal={() =>
                      ssoSTTLink.openLink({
                        pageName: PageName.CashWithdrawITP,
                        isAdditionalLang: true,
                        isPopup: true,
                      })
                    }
                    onOpenDailyConfirmation={() =>
                      setOpenDailyConfirmation(true)
                    }
                    onOpenDailyContractNote={() =>
                      setOpenDailyContractNote(true)
                    }
                    onDownloadEStatement={() => {
                      if (!!eMonthlyStatement)
                        handleDownloadEStatement(eMonthlyStatement);
                    }}
                  />
                </div>
              )}
            </div>
            {openDailyConfirmation && (
              <ComfirmationReportDetail
                confirmation={dailyConfirmation!.confirmation}
                confirmationDetails={dailyConfirmation!.confirmationDetails}
                closeModal={() => setOpenDailyConfirmation(false)}
              />
            )}
            {openDailyContractNote && (
              <ContractNoteModal
                onClose={() => setOpenDailyContractNote(false)}
                contractNote={dailyContractNote!}
              />
            )}
          </>
        ) : (
          <LoadingCard />
        )}
        {openConsolidatedPortfolio && (
          <ConsolidatedPortfolioDialog
            disclaimer={disclaimer!}
            portfolio={overseasPortfolio!}
            username={userInfo.username}
            isConsolidateUnconnectable={isConsolidateUnconnectable}
            onClick={() => setOpenConsolidatedPortfolio(false)}
          />
        )}
      </div>
    </Card>
  );
};
const hasOversesaAccount = (overseasPortfolio: IOverseasPortfolio | null) => {
  return overseasPortfolio !== null;
};
