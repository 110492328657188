import { Chart, DoughnutController } from "chart.js";
import { Context } from "chartjs-plugin-datalabels";
import { useEffect } from "react";
import { currency } from "../../helper/format.number";
import { IDoughnutChartData } from "../../interfaces/chart";
import { getScaleColor } from "./doughnut.chart";

const DoughnutChart = ({
  id = 1,
  chartData,
  width = "100%",
  height = "40vh",
  newLinePercent = false,
  title = "",
}: {
  id?: number;
  chartData: IDoughnutChartData;
  width?: string;
  height?: string;
  newLinePercent?: boolean;
  title?: string;
}) => {
  const chartID = `doughtnut-chart-${id}`;
  const chartTitle = chartData.title;
  const labels = chartData.data.map((el) => el.label);
  const values = chartData.data.map((el) => el.value);
  const totalPortValue = chartData.totalPortValue;
  const color = chartData.hexColorScale
    ? getScaleColor({
        colors: chartData.hexColorScale!,
        dataLength: chartData.data.length,
      })
    : chartData.data.map((el) => el.hexColor);

  useEffect(() => {
    const chart = drawChart();
    return () => chart.destroy();
  });

  const drawChart = () => {
    const docs = document.getElementById(chartID) as HTMLCanvasElement;
    const ctx = docs.getContext("2d");
    return new Chart(ctx!, {
      type: "doughnut",
      data: {
        labels,
        datasets: [
          {
            data: values,
            backgroundColor: color,
            borderWidth: 0,
            borderRadius: 100,
            spacing: 6,
          },
        ],
      },
      plugins: [
        {
          id: chartID,
          beforeDraw: (chart) => {
            const titles = `THB ${currency(totalPortValue)}`.split("\n");
            titles.push(chartTitle);
            drawCenterText(chart, titles);
            if (!chartData.data.length) {
              fillDefaultDoughnutChartColor(chart);
            }
          },
        },
      ],
      options: {
        layout: {
          padding: (_ctx: Context) => {
            return 44;
          },
        },
        cutout: "90%",
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: (context) => {
                return (
                  Number(context.formattedValue).toFixed(2) +
                  (chartData.isPercent ? "%" : "")
                );
              },
              title: (context) => {
                return context[0].label;
              },
            },
          },
          datalabels: {
            color: "#000000",
            font: {
              size: 10,
              weight: "normal",
            },
            anchor: "end",
            align: "end",
            textAlign: "center",
            formatter: (value: number, ctx: Context) => {
              const label = ctx.chart.data?.labels![ctx.dataIndex];
              if (chartData.isPercent) {
                return `${label}${newLinePercent ? "\n" : " "}${currency(
                  value
                )}%`;
              }
              return `${label}${newLinePercent ? "\n" : " "}${value}`;
            },
          },
        },
      },
    });
  };

  return (
    <div className="flex flex-col" style={{ height, width }}>
      <div
        className="flex justify-center text-xl align-middle text-bold"
        style={{ color: "#595959" }}
      >
        {title}
      </div>
      <canvas id={chartID}></canvas>
    </div>
  );
};

const fillDefaultDoughnutChartColor = (chart: Chart) => {
  const { top, left, width, height } = chart.chartArea;
  const controller = chart.getDatasetMeta(0).controller as DoughnutController;
  const { ctx } = chart;
  const x = left + width / 2;
  const y = top + height / 2;

  const outerRadius = controller.outerRadius;
  const innerRadius = controller.innerRadius;

  const greyColor = "#D3D3D3";
  ctx.beginPath();
  ctx.arc(x, y, innerRadius, 0, Math.PI * 2, false);
  ctx.arc(x, y, outerRadius, 0, Math.PI * 2, true);
  ctx.fillStyle = greyColor;
  ctx.fill();
};

const drawCenterText = (chart: Chart, titles: string[]) => {
  const { width, height, ctx } = chart;
  let fontSizeTile = 8;
  let fontSizeAmount = 8;
  if (width > 315) {
    fontSizeTile = 10;
    fontSizeAmount = 16;
  }
  if (width > 550) {
    fontSizeTile = 14;
    fontSizeAmount = 20;
  }
  const fontStyle = "800";
  ctx.restore();
  ctx.textBaseline = "middle";
  titles.forEach((title, index) => {
    ctx.font = title.includes("THB")
      ? `${fontStyle} ${fontSizeAmount}px sukhumvittadmai`
      : `${fontStyle} ${fontSizeTile}px sukhumvittadmai`;
    const centerTextOffset = 3 * titles.length;
    const spaceBetweenLines = 1.5;
    const centerX = Math.round((width - ctx.measureText(title).width) / 2);
    const centerY = height / 2;
    const centerYforMuliLines =
      index *
      (title.includes("THB") ? fontSizeAmount : fontSizeTile) *
      spaceBetweenLines;
    let text = title;
    let textPostionX = centerX;
    let textPostionY = centerY + centerYforMuliLines - centerTextOffset;
    ctx.fillText(text, textPostionX, textPostionY);
  });
  ctx.save();
};
export default DoughnutChart;
