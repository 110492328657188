import { currentLanguage } from "../i18n/helper";
import httpClient from "./http";

class GenerateLinkService {
  private http = httpClient();

  async generateSSOKYCLink(): Promise<string> {
    return this.http
      .get("/e-kyc/sso-ekyc/generate-link")
      .then<string>((response) => response.data);
  }

  async generateOpenForeignAccountLink(): Promise<string> {
    return this.http
      .get(`/open-foreign-account/${currentLanguage()}/generate-link`)
      .then<string>((response) => response.data);
  }

  async generateBenefitsLink(): Promise<string> {
    return this.http
      .get(`/overseas-trading/benefits/${currentLanguage()}/generate-link`)
      .then<string>((response) => response.data);
  }

  async getRedemptionLink() {
    return this.http
      .get<string>("/rewards/generate-link")
      .then((res) => res.data);
  }
}

export default GenerateLinkService;
