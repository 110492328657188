import { openWindow, openWindowWithBlankPage } from "../../helper/open.window";
import { isThaiLanguage } from "../../i18n/helper";
import { ISpecialMenu } from "../../interfaces/menu";
import { SubMenuIcon } from "./sub-menu-icon";

export const SpecialMenuIcon = ({
  specialMenu,
}: {
  specialMenu: ISpecialMenu;
}) => {
  const { isPopup, imageIcon, titleEN, titleTH, url } = specialMenu;

  const onClick = () => {
    isPopup
      ? openWindowWithBlankPage({ link: url })
      : openWindow({ link: url });
  };

  return (
    <SubMenuIcon
      onClick={onClick}
      label={isThaiLanguage() ? titleTH : titleEN}
      imgPath={imageIcon}
    />
  );
};
