/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { getPublicCMSUrl } from "../../../helper/cms";
import { openWindowWithBlankPage } from "../../../helper/open.window";
import { IBannerSet } from "../../../interfaces/banner";

const CarouselImage = ({
  bannerSet,
  currentBanner,
}: {
  bannerSet: IBannerSet;
  currentBanner: number;
}) => {
  const [height, setHeigh] = useState<number>(0);
  const imageBox = useRef<any>();

  useEffect(() => {
    window.addEventListener("resize", calculateBannerHeight);
    calculateBannerHeight();
    return () => window.removeEventListener("resize", calculateBannerHeight);
  }, [currentBanner]);

  const calculateBannerHeight = () => {
    const ratio: number =
      Number(bannerSet.bannerHeight) / Number(bannerSet.bannerWidth);
    setHeigh(ratio * imageBox.current.width);
  };

  return (
    <div
      id="banner-image"
      className="relative overflow-hidden"
      style={{
        width: `${bannerSet.bannerWidth}px`,
        height: `calc(${height}px - 2px)`,
      }}
    >
      {bannerSet.banners.map((banner, index) => {
        const animate = currentBanner === index ? "opacity-100" : "opacity-0";
        return (
          <img
            ref={imageBox}
            id="banner-image-item-"
            className={`absolute w-full ${
              banner.url ? "cursor-pointer" : ""
            } transition-all duration-700 transform ${animate}`}
            src={getPublicCMSUrl(banner.image.url)}
            alt=""
            onClick={() => {
              if (banner.url)
                openWindowWithBlankPage({
                  link: bannerSet.banners[currentBanner].url,
                  isPopup: false,
                });
            }}
          />
        );
      })}
    </div>
  );
};

export default CarouselImage;
