export const seperateNumberWithComma = (numString: string) => {
  const parts = Number(numString).toFixed(2).split(".");
  parts[0] = addCommaToNumber(removeComma(parts[0]));
  return parts.join(".");
};

export const addCommaToNumber = (numString: string) => {
  return numString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const removeComma = (numString: string) => {
  return numString.replace(/,/g, "");
};

export const currency = (
  value: number,
  options: {
    minimumFractionDigits: number;
    maximumFractionDigits: number;
  } = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }
): string => {
  return new Intl.NumberFormat("th-TH", {
    minimumFractionDigits: options.minimumFractionDigits,
    maximumFractionDigits: options.maximumFractionDigits,
  }).format(value);
};
