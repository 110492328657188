import { DateTime } from "luxon";
import { isThaiLanguage } from "../../i18n/helper";
import {
  IEStatementGroupAccountType,
  IEStatementResponse,
} from "../../interfaces/e-statement";
import { IDynamicDropdownOption } from "../DynamicDropdown";
const ACCOUNT_TYPE_DISPLAY: any = {
  CSP: { en: "Consolidated", th: "Consolidated" },
  Selling: { en: "Funds", th: "บัญชีกองทุน" },
  DBPR: { en: "Derivatives", th: "บัญชีอนุพันธ์" },
  BCR: { en: "Equity", th: "บัญชีซื้อขายหลักทรัพย์" },
  BHR009NV_TFEX: { en: "Derivatives", th: "บัญชีอนุพันธ์" },
  BHR009NV_1: { en: "Equity", th: "บัญชีซื้อขายหลักทรัพย์" },
  BPR: { en: "Equity", th: "บัญชีซื้อขายหลักทรัพย์" },
  Other: { en: "Other", th: "อื่นๆ" },
};

export function generateEStatementDateOptions(
  currentDate: DateTime,
  isThaiLanguage: boolean = true
): IDynamicDropdownOption[] {
  let options: IDynamicDropdownOption[] = [];
  let locale = isThaiLanguage
    ? currentDate.setLocale("th")
    : currentDate.setLocale("us");

  for (let month = 1; month <= 6; month++) {
    locale = currentDate
      .minus({ month })
      .setLocale(isThaiLanguage ? "th" : "us");
    options.push(getMonthSelectOnDropdown(isThaiLanguage, locale));
  }
  return options;
}

const getMonthSelectOnDropdown = (
  isThaiLanguage: boolean,
  locale: DateTime
): IDynamicDropdownOption => {
  const year = isThaiLanguage ? locale.year + 543 : locale.year;
  return {
    label: `${locale.monthLong} ${year}`,
    value: locale.toJSDate().toISOString(),
  };
};

export function groupEStatementByAccountType(
  eStatements: IEStatementResponse[]
): IEStatementGroupAccountType[] {
  let result: IEStatementGroupAccountType[] = [];
  for (const eStatement of eStatements) {
    const accountType = getAccountTypeToDisplay(eStatement.accountType);
    let idx = result.findIndex(
      (element) => element.accountType === accountType
    );
    if (idx < 0) {
      result.push({
        accountType: accountType,
        eStatements: [eStatement],
      });
      continue;
    }
    result[idx].eStatements.push(eStatement);
  }
  return result;
}

function getAccountTypeToDisplay(type: string = "Other"): string {
  const { th, en } = ACCOUNT_TYPE_DISPLAY[type];
  return isThaiLanguage() ? th : en;
}

export function groupAccounts(eStatements: IEStatementResponse[]): string[] {
  let result: string[] = [];
  for (const eStatement of eStatements) {
    if (!result.some((el) => el === eStatement.account)) {
      result.push(eStatement.account);
    }
  }
  return result;
}

export const filterMonthlyStatement = (
  documentDate: Date,
  tradeDateFilter: Date
) => {
  return (
    documentDate.getFullYear() === tradeDateFilter.getFullYear() &&
    documentDate.getMonth() === tradeDateFilter.getMonth()
  );
};

export const filterDailyStatement = (
  documentDate: Date,
  tradeDateFilter: Date
) => {
  return (
    documentDate.toLocaleDateString() === tradeDateFilter.toLocaleDateString()
  );
};
