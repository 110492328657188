import { PageName } from "../enum/page.name";

export interface IBaseMenu {
  imagePath: string;
  name: string;
}

export interface IMenu {
  isSpecialMenu?: boolean;
  menuDetail: ISubMenu | ISpecialMenu;
}

export interface ISubMenu extends IBaseMenu {
  isAdditionalLang?: boolean;
  pageName?: PageName;
  isPopUp?: boolean;
}

export enum MainMenu {
  INVESTMENT_TOOLS = "investment_tools",
  MY_ACCOUNT = "my_account",
  E_FORMS = "e_forms",
}

export enum SubMenu {
  DBS_DIGITAL_SERVICES = "dbs_digital_services",
  DBS_THAILAND_MOBILE_APPLICATION = "dbs_thailand_mobile_application",
  PARTERS_TOOLS = "partners_tools",
  MY_CORNER = "my_corner",
  ACCOUNT_MANAGEMENT = "account_management",
  CASH_STOCK_TRANSACTIONS = "cash_stock_transactions",
  CHANGE_PERSONAL_DATA = "change_personal_data",
  REQUEST_REPORTS = "request_reports",
}

export interface ISpecialMenu {
  isPopup: boolean;
  imageIcon: string;
  titleEN: string;
  titleTH: string;
  url: string;
  mainMenu: MainMenu;
  subMenu: SubMenu;
}

export interface ISideBarMenu {
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  name: string;
  path: string;
  component: () => JSX.Element;
  activePaths?: string[];
}
