/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getDateInFormat } from "../../../helper/date.time";
import { IDisclaimer } from "../../../interfaces/cms";
import { IOverseasPortfolio } from "../../../services/overseas-trading";
import { DynamicDropdown, IDynamicDropdownOption } from "../../DynamicDropdown";
import ErrorComponent from "../../Error";
import AssetAllocateChart from "./AssetAllocateChart";
import OverseasPortfolioTable from "./OverseasPortfolioTable";
import UnrealizedGainChart from "./UnrealizedGainChart";

export const OverseasPortfolio = ({
  portfolio,
  disclaimer,
  username,
  isConsolidateUnconnectable,
}: {
  portfolio: IOverseasPortfolio;
  disclaimer: IDisclaimer;
  username: string;
  isConsolidateUnconnectable: boolean;
}) => {
  const { t: translate, i18n } = useTranslation();
  const [viewMode, setViewMode] = useState<IDynamicDropdownOption>({
    label: translate("overseasPortfolio.marketExposureView"),
    value: "Market",
  });

  useEffect(() => {
    setViewMode({
      label: translate("overseasPortfolio.marketExposureView"),
      value: "Market",
    });
  }, [i18n.language]);

  return (
    <div className="grid px-4 bg-white gap-y-4">
      {isConsolidateUnconnectable ? (
        <ErrorComponent
          header={translate("error.head")}
          errorCode={"404"}
          title={translate("error.title")}
          description={translate("error.description")}
        />
      ) : (
        <>
          <div className="flex justify-end">
            <DynamicDropdown
              theme="dark"
              options={[
                {
                  label: translate("overseasPortfolio.marketExposureView"),
                  value: "Market",
                },
                {
                  label: translate("overseasPortfolio.stockExposureView"),
                  value: "Stock",
                },
              ]}
              selectedOption={viewMode}
              onSelected={(option) => setViewMode(option)}
            />
          </div>
          <div className="grid w-full grid-cols-1 pb-8 gap-x-4 gap-y-12 lg:grid-cols-2">
            <div className="h-80">
              <AssetAllocateChart
                portfolio={portfolio}
                viewMode={viewMode.value}
              />
            </div>
            <div>
              <UnrealizedGainChart
                portfolio={portfolio}
                viewMode={viewMode.value}
              />
            </div>
          </div>
          <div className="relative right-0 w-full pr-2 text-xs font-semibold text-right">
            {`${translate("overseasPortfolio.asOfDate")} ${getDateInFormat(
              new Date().toISOString(),
              "dd MMM yyyy"
            )}`}
          </div>
          <OverseasPortfolioTable
            portfolios={portfolio}
            disclaimer={disclaimer}
            username={username}
          />
        </>
      )}
    </div>
  );
};
