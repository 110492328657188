import { useEffect, useState } from "react";
import SSOSTTLink from "../../helper/sso.stt.link";
import IconButton from "./IconButton";
import Title from "./Title";
import { IUserInfo } from "../../interfaces/user";
import UserService from "../../services/user.service";
import {
  onOpenDDR,
  onOpenEKYC,
  onOpenRequestForm,
} from "../../pages/MyAccount/helper";
import { PageName } from "../../enum/page.name";
import { useTranslation } from "react-i18next";

const ManageAccount = () => {
  const { t } = useTranslation();

  const [userInfo, setUserInfo] = useState<IUserInfo>();

  useEffect(() => {
    new UserService().getUserInfo().then(setUserInfo);
  }, []);

  return (
    <div className="flex flex-col gap-y-4">
      <Title title={t("eService.myCorner.title")} />
      <div className="grid grid-cols-4 items-start gap-3">
        <IconButton
          label={t("eService.myCorner.suit")}
          Icon={ManageRisk}
          onClick={() => {
            onOpenEKYC("_self");
          }}
        />
        <IconButton
          label={t("eService.myCorner.changePersonalData")}
          Icon={EditPersonalData}
          onClick={() => {
            onOpenRequestForm("_self");
          }}
        />
        <IconButton
          label={t("eService.myCorner.ats")}
          Icon={RegisterATS}
          onClick={() => {
            onOpenDDR("_self");
          }}
        />
        <IconButton
          label={t("eService.myCorner.changeDefaultAccount")}
          Icon={ChangeDefaultAccount}
          onClick={() =>
            new SSOSTTLink({ userref: userInfo!.userref }).openLink({
              pageName: PageName.ChangeDefaultAccount,
              isAdditionalLang: false,
              isPopup: false,
              replaceUrl: true,
            })
          }
        />
      </div>
    </div>
  );
};

export default ManageAccount;

const ManageRisk = (
  <img src="/icons/e-service/manage-account/document-pen.png" alt="MA-risk" />
);

const EditPersonalData = (
  <img
    src="/icons/e-service/manage-account/document-user.png"
    alt="MA-edit-personal-data"
  />
);

const RegisterATS = (
  <img src="/icons/e-service/manage-account/atm.png" alt="MA-register-ats" />
);

const ChangeDefaultAccount = (
  <img
    src="/icons/e-service/manage-account/bank-account.png"
    alt="MA-change-default-account"
  />
);
