import { useEffect } from "react";
import { createContext, useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { PATH_ROUTE } from "../const/path";

const RouteContext = createContext({} as any);

export const RouteContextWrapper = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const location = useLocation();
  const [path, setPath] = useState(location.pathname);
  const [currentPathName, setCurentPathName] = useState("");

  useEffect(() => {
    updatePath(location.pathname);
  }, [location]);

  const updatePath = (pathString: string) => {
    setPath(pathString);
    updatePathName(pathString, setCurentPathName);
  };

  const shareState = { path, currentPathName };
  return (
    <RouteContext.Provider value={shareState}>{children}</RouteContext.Provider>
  );
};

export const useRouteContext = () => {
  return useContext(RouteContext);
};

export function updatePathName(
  pathString: string,
  setCurentPathName: (pathName: string) => void
) {
  Object.entries(PATH_ROUTE).forEach((route) => {
    const pathWithoutParam = "/" + pathString.split("/", 2)[1];
    if (
      pathString === route[1].path ||
      route[1].activePaths?.includes(pathWithoutParam)
    ) {
      setCurentPathName(route[1].name);
    }
  });
}
