import { ICustomerHandbookPDF } from "../interfaces/customer-handbook-pdf";
import httpClient from "./http";

export class CustomerHandbookPDFService {
  private http = httpClient();

  async dataCustomerHandBookPDF() {
    const response = await this.http.get<ICustomerHandbookPDF>(
      `/customer-handbook/fetch-data`
    );
    return response.data;
  }
}
