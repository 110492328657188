import { ReactComponent as ChevronRight } from "../../svgIcon/new-dashboard/chevron-right.svg";

export const FavoriteItem = ({
  name,
  iconPath,
  onClick,
}: {
  name: string;
  iconPath: string;
  onClick: () => void;
}) => {
  return (
    <div
      className="flex w-20 cursor-pointer h-14 md:justify-between md:h-28 md:w-56 rounded-xl bg-slate-00"
      onClick={onClick}
    >
      <div className="flex flex-col items-center w-full h-16 py-1.5 md:items-start md:px-5 md:py-3 md:gap-y-3 gap-y-1">
        <img className="w-6 h-6 md:w-10 md:h-10" src={iconPath} alt={name} />
        <div className="w-16 text-center truncate text-2xs md:text-left md:w-36 md:overflow-visible md:whitespace-normal md:text-wrap md:text-xs md:font-bold">
          {name}
        </div>
      </div>
      <div
        className="hidden w-6 md:items-center md:justify-center md:flex rounded-tr-xl rounded-br-xl"
        style={{ backgroundColor: "rgba(221, 227, 231, 0.3)" }}
      >
        <ChevronRight className="w-6 text-dbsv-neutral-slate-40" />
      </div>
    </div>
  );
};
