import { useTranslation } from "react-i18next";
import { ReactComponent as InflowIcon } from "../../svgIcon/new-dashboard/accounts/arrow-down.svg";
import { ReactComponent as OutflowIcon } from "../../svgIcon/new-dashboard/accounts/arrow-up.svg";
import { AccountMenus } from "./AccountMenuItem/helper";

export const MovementCard = ({
  inflow,
  outflow,
  type,
}: {
  inflow: number;
  outflow: number;
  type: AccountMenus;
}) => {
  const { t: translate } = useTranslation();

  const numberFormat = new Intl.NumberFormat("en", {
    minimumFractionDigits: type === "stockmovement" ? 0 : 2,
    maximumFractionDigits: type === "stockmovement" ? 0 : 2,
  });

  const getUnit = (value: number, type: AccountMenus) => {
    if (type === "cashmovement") {
      return translate("myInvestment.cashMovement.movement.unit");
    }
    if (value < 2) {
      return translate("myInvestment.stockMovement.movement.unit.singular");
    }
    return translate("myInvestment.stockMovement.movement.unit.plural");
  };

  const getLabel = (type: AccountMenus, flow: "inflow" | "outflow") => {
    if (type === "cashmovement") {
      if (flow === "inflow") {
        return translate("myInvestment.cashMovement.movement.in");
      } else {
        return translate("myInvestment.cashMovement.movement.out");
      }
    } else {
      if (flow === "inflow") {
        return translate("myInvestment.stockMovement.movement.in");
      } else {
        return translate("myInvestment.stockMovement.movement.out");
      }
    }
  };

  return (
    <div className="flex flex-row justify-between w-full px-2 py-px rounded-xl md:py-2 h-14 md:h-16 bg-dbsv-neutral-slate-00 gap-x-10">
      <div className="flex flex-col w-1/2">
        <div className="flex flex-row items-center text-xs text-dbsv-status-success-green gap-x-3">
          <InflowIcon />
          <div>{getLabel(type, "inflow")}</div>
        </div>
        <div className="w-full font-bold text-right text-dbsv-status-success-green">
          + {numberFormat.format(inflow)}
        </div>
        <div className="text-xs text-right text-dbsv-neutral-slate-50">
          {getUnit(inflow, type)}
        </div>
      </div>
      <div className="flex flex-col w-1/2">
        <div className="flex flex-row items-center text-xs text-dbsv-status-danger-danger gap-x-3">
          <OutflowIcon />
          <div>{getLabel(type, "outflow")}</div>
        </div>
        <div className="w-full font-bold text-right text-dbsv-status-danger-danger">
          - {numberFormat.format(outflow)}
        </div>
        <div className="text-xs text-right text-dbsv-neutral-slate-50">
          {getUnit(outflow, type)}
        </div>
      </div>
    </div>
  );
};
