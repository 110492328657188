export const Indicator = ({
  active,
  onClick,
}: {
  active: boolean;
  onClick: () => void;
}) => {
  if (active) {
    return (
      <div
        className="w-5 h-1.5 rounded-full cursor-pointer"
        style={{ backgroundColor: "#FF3E3E" }}
      ></div>
    );
  }
  return (
    <div
      className="w-1.5 h-1.5 rounded-full cursor-pointer"
      style={{ backgroundColor: "#C7CFD5" }}
      onClick={onClick}
    ></div>
  );
};
