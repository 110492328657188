import { useTranslation } from "react-i18next";
import { isThaiLanguage } from "../../i18n/helper";
import ReactMarkdown from "react-markdown";

export const Disclaimer = ({
  onAccept,
  onCancel,
  th,
  en,
  title,
  isFullScreen = false,
}: {
  onAccept: () => void;
  onCancel?: () => void;
  th: string;
  en: string;
  title: string;
  isFullScreen?: boolean;
}) => {
  const { t: translate } = useTranslation();

  return (
    <div
      className={`fixed inset-0 z-50 overflow-y-auto ${
        isFullScreen ? "h-screen w-screen" : ""
      }`}
    >
      <div
        className={`flex ${
          isFullScreen
            ? "h-screen w-screen"
            : "items-center justify-center min-h-screen p-4 text-center sm:block sm:p-0"
        }`}
      >
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div
          className={`inline-block overflow-hidden text-left transition-all transform bg-white lg:w-2/5 md:w-3/5 sm:my-8 sm:align-middle ${
            isFullScreen ? "w-full h-full" : "w-4/5"
          }`}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="flex flex-col h-full">
            <div className="py-4 text-center border-b">
              <h1 className="text-bold">{title}</h1>
            </div>
            <div
              className={`flex-1 overflow-y-auto px-6 pt-4 text-sm text-gray-500 whitespace-pre-line ${
                isFullScreen ? "" : "h-auto"
              }`}
            >
              <ReactMarkdown>{isThaiLanguage() ? th : en}</ReactMarkdown>
            </div>
            <div className="w-full border-t py-4 flex justify-center gap-x-4">
              {onCancel && (
                <button
                  onClick={onCancel}
                  data-testid="deny-button"
                  className="h-10 mt-3 border rounded-md w-28 text-primary-500 border-primary-500"
                >
                  {translate("research.disclaimer.cancel")}
                </button>
              )}
              <button
                data-testid="accept-button"
                onClick={onAccept}
                className="h-10 mt-3 text-white rounded-md w-28 bg-primary-500"
              >
                {translate("research.disclaimer.accept")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
