import React, { Fragment } from "react";
const ContractNoteHelp = () => {
  return (
    <>
      <Fragment key={"help"}>
        <div
          dangerouslySetInnerHTML={{
            __html: `<html>
  <head>
    <meta http-equiv="Content-Type" content="text/html; charset=windows-874" />

    <meta name="Generator" content="Microsoft Word 12 (filtered)" />
    <style>
      @font-face {
        font-family: "Cordia New";
        panose-1: 2 11 3 4 2 2 2 2 2 4;
      }
      @font-face {
        font-family: "Cambria Math";
        panose-1: 2 4 5 3 5 4 6 3 2 4;
      }
      @font-face {
        font-family: Calibri;
        panose-1: 2 15 5 2 2 2 4 3 2 4;
      }
      @font-face {
        font-family: Tahoma;
        panose-1: 2 11 6 4 3 5 4 4 2 4;
      }
      p.MsoNormal,
      li.MsoNormal,
      div.MsoNormal {
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 10pt;
        margin-left: 0cm;
        line-height: 115%;
        font-size: 11pt;
        font-family: "Calibri", "sans-serif";
      }
      a:link,
      span.MsoHyperlink {
        color: blue;
        text-decoration: underline;
      }
      a:visited,
      span.MsoHyperlinkFollowed {
        color: purple;
        text-decoration: underline;
      }
      p.MsoNoSpacing,
      li.MsoNoSpacing,
      div.MsoNoSpacing {
        margin: 0cm;
        margin-bottom: 0.0001pt;
        font-size: 11pt;
        font-family: "Calibri", "sans-serif";
      }
      .MsoPapDefault {
        margin-bottom: 10pt;
        line-height: 115%;
      }
      @page Section1 {
        size: 612pt 792pt;
        margin: 72pt 72pt 72pt 72pt;
      }
      div.Section1 {
        page: Section1;
      }
      .customTable td {
        border: 1px solid;
      }
    </style>
  </head>

  <body lang="EN-US" link="blue" vlink="purple">
    <center>
      <table border="0" cellpadding="0" cellspacing="0" width="100%">
        <tbody>
          <tr>
            <td>
              <div class="Section1">
                <div align="center">
                  <table
                    class="MsoNormalTable"
                    border="0"
                    cellpadding="0"
                    width="90%"
                    style="width: 90%"
                  >
                    <tbody>
                      <tr>
                        <td
                          width="65%"
                          style="
                            width: 65%;
                            padding: 3.75pt 3.75pt 3.75pt 3.75pt;
                          "
                        ></td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 3.75pt 3.75pt 3.75pt 3.75pt;
                          "
                        ></td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <p class="MsoNoSpacing">
                  <span
                    style="
                      font-size: 10pt;
                      font-family: 'Tahoma', 'sans-serif';
                      display: none;
                    "
                    >&nbsp;</span
                  >
                </p>

                <div align="center">
                  <table
                    class="MsoNormalTable customTable"
                    border="1"
                    cellspacing="0"
                    cellpadding="0"
                    width="90%"
                    style="width: 90%; border-collapse: collapse"
                  >
                    <tbody>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <b
                              ><span
                                style="
                                  font-size: 10pt;
                                  font-family: 'Tahoma', 'sans-serif';
                                "
                                >Field</span
                              ></b
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <b
                              ><span
                                lang="TH"
                                style="
                                  font-size: 10pt;
                                  font-family: 'Tahoma', 'sans-serif';
                                "
                                >ความหมาย</span
                              ></b
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <b
                              ><span
                                lang="TH"
                                style="
                                  font-size: 10pt;
                                  font-family: 'Tahoma', 'sans-serif';
                                "
                                >คำอธิบาย / สูตรคำนวณ</span
                              ></b
                            >
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          width="100%"
                          colspan="3"
                          valign="top"
                          style="
                            width: 100%;
                            background: lightgrey;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Account Information</span
                            >
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Name</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >ชื่อลูกค้า</span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        ></td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Account No.</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >หมายเลขบัญชี</span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        ></td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Account Type</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >ประเภทบัญชี</span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        ></td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Account Officer</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >รหัสผู้ดูแลบัญชีลูกค้า</span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        ></td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Settlement No.</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >เลขที่ใบยืนยันการซื้อขาย
                            </span>
                          </p>
                        </td>
                        <td
                          width="35%"
                          valign="top"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        ></td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Trading Date.</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >วันที่ ที่เกิดรายการซื้อขาย</span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          valign="top"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        ></td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Settlement Date</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >วันที่ ที่จะต้องชำระราคา</span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          valign="top"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        ></td>
                      </tr>
                      <tr>
                        <td
                          width="100%"
                          colspan="3"
                          valign="top"
                          style="
                            width: 100%;
                            background: lightgrey;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Trading Transaction Summary</span
                            >
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Series</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Contract</span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        ></td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Contract No.</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >เลขที่</span
                            ><span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Contract</span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        ></td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Buy/Sell</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Side <span lang="TH">ของ </span>Contract</span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >B = Buy</span
                            >
                          </p>
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >S = Sell</span
                            >
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >No. of Contract</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >จำนวน</span
                            ><span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Contract</span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        ></td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Deal Price</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >ราคาที่ </span
                            ><span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >match</span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        ></td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Premium Amout (+/-)</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >ค่า </span
                            ><span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Premium</span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        ></td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Brokerage Fee</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >ค่าธรรมเนียมนายหน้าสำหรับการซื้อขายหลักทรัพย์</span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        ></td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >VAT</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >ภาษีมูลค่าเพิ่ม
                            </span>
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        ></td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >W/H</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Withholding Tax</span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        ></td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Charge Amount
                            </span>
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Net Charge Amount;
                            </span>
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Charge Amount = Comm.+VAT-WT</span
                            >
                          </p>
                        </td>
                      </tr>
                      <tr style="height: 19.5pt">
                        <td
                          width="100%"
                          colspan="3"
                          valign="top"
                          style="
                            width: 100%;
                            background: lightgrey;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                            height: 19.5pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Position Closing</span
                            >
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Series</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >ชื่อสัญญา</span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        ></td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Trade Date</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >วันที่เกิดรายการซื้อขาย</span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        ></td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Long</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >จำนวนของ </span
                            ><span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Long Contract <span lang="TH">ณ </span>Trade
                              date</span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        ></td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Short</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >จำนวนของ </span
                            ><span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Short Contract <span lang="TH">ณ </span>Trade
                              date</span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        ></td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Deal Price
                            </span>
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >ราคา </span
                            ><span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >match</span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        ></td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Realized P&amp;L (Futures)</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >กำไร/ขาดทุน ของ</span
                            ><span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                            >
                              Futures</span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        ></td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Realized P&amp;L (Options)</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >กำไร/ขาดทุน ของ</span
                            ><span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                            >
                              Options</span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >ไม่นำมาคำนวณ </span
                            ><span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Cash Balance</span
                            >
                          </p>
                        </td>
                      </tr>
                      <tr style="height: 19.5pt">
                        <td
                          width="100%"
                          colspan="3"
                          valign="top"
                          style="
                            width: 100%;
                            background: lightgrey;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                            height: 19.5pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >POSITION AT LAST TRADING DAY</span
                            >
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Action / Series</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >ชื่อสัญญา</span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        ></td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Long/Short</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Side<span lang="TH"> ของ </span>Contract</span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >L = Long</span
                            >
                          </p>
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >S = Short</span
                            >
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >No. Of Contract</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >จำนวน </span
                            ><span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Contract</span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        ></td>
                      </tr>
                      <tr style="height: 11.4pt">
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                            height: 11.4pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Final Settlement Price</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                            height: 11.4pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >ราคาที่ใช้ชำระราคา ณ วันส่งมอบ</span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                            height: 11.4pt;
                          "
                        ></td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Settlement Amount</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        ></td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Settlement Amount&nbsp; = No.of Contract *( Final
                              settlement price - Avg. Cost price )</span
                            >
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Brokerage Fee</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >ค่าธรรมเนียมนายหน้าสำหรับการซื้อขายสัญญา</span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        ></td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >VAT</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >ภาษีมูลค่าเพิ่ม</span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        ></td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >W/H</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Withholding Tax</span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        ></td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Charge Amount</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        ></td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Charge Amount<span lang="TH"> </span>= Brokerage
                              Fee + VAT – W/H</span
                            >
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          width="100%"
                          colspan="3"
                          valign="top"
                          style="
                            width: 100%;
                            background: lightgrey;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >OUTSTANDING POSITION</span
                            >
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Series</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >ชื่อสัญญา</span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        ></td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Long</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >จำนวน </span
                            ><span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Long Contract</span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        ></td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Short</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >จำนวน </span
                            ><span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Short Contract</span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        ></td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Avg. Cost Price</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >ราคาต้นทุนเฉลี่ย</span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >ลูกค้าสถาบัน </span
                            ><span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >:
                              <span lang="TH">ค่าเฉลี่ยรวมทุกสัญญา แยกตาม </span
                              >Series</span
                            >
                          </p>
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >ลูกค้าทั่วไป </span
                            ><span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >: matched price<span lang="TH"
                                >แยกตามรายการ </span
                              >Deal</span
                            >
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Settlement Price</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >ราคาที่ใช้ชำระราคา</span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        ></td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Unrealized P&amp;L</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >กำไร / ขาดทุน
                              ที่เกิดจากการถือครองสัญญาซื้อขายล่วงหน้า(</span
                            ><span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Mark to Market<span lang="TH">)</span></span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        ></td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Long Options Value</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >มูลค่าของ </span
                            ><span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Long Options</span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Long Options Value&nbsp; = Position(L)*Settlement
                              Price*Multiplier</span
                            >
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Short Options Value</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >มูลค่าของ </span
                            ><span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Short Options
                            </span>
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Short Options Value&nbsp; = (-1)
                              *Position(S)*Settlement Price*Multiplier</span
                            >
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          width="100%"
                          colspan="3"
                          valign="top"
                          style="
                            width: 100%;
                            background: lightgrey;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >OUTSTANDING FOREIGN CURRENCY COLLATERAL</span
                            >
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >CCY</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >สกุลเงิน</span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        ></td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Amount</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >จำนวน</span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        ></td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Fx Rate</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >อัตราการแลกเปลี่ยน</span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        ></td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Value in THB</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >มูลค่า(บาท)</span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        ></td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Col%</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >อัตราส่วนร้อยละในการคำนวณมูลค่าของหลักประกัน</span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        ></td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Max. for Settlement Require</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >มูลค่าที่สามารถใช้เป็นหลักประกัน</span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Max. for Settlement Require&nbsp; = Min(Value in
                              THB * Col% , Maximum Foreign Currency Collateral
                              Value)</span
                            >
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          width="100%"
                          colspan="3"
                          valign="top"
                          style="
                            width: 100%;
                            background: lightgrey;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >STATEMENT OF ACCOUNT</span
                            >
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Begin Cash Bal</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Cash Balance
                              <span lang="TH">ณ ต้นวัน</span></span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        ></td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >End Cash Bal</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Cash Balance
                              <span lang="TH">ณ สิ้นวัน</span></span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >End Cash Bal<span lang="TH"> </span>= Begin Cash
                              Bal + Cash Deposit – Cash &nbsp;Withdrawal<span
                                lang="TH"
                              >
                                +/- </span
                              >Realized P&amp;L +/- Premium - Charge Amout&nbsp;
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Non Cash Collateral</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >หลักประกันประเภทอื่นที่บริษัทอนุญาตให้นำฝากเป็นหลักประกันได้
                              เช่น หุ้น พันธบัตร </span
                            ><span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >,<span lang="TH">เงินสกุลต่างประเทศ</span></span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        ></td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Realized P&amp;L&nbsp; Futures</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >กำไร/ขาดทุน จากการปิดสถานะของ </span
                            ><span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Futures</span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        ></td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Unrealized P&amp;L</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >กำไร / ขาดทุน
                              ที่เกิดจากการถือครองสัญญาซื้อขายล่วงหน้า(</span
                            ><span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Mark to Market<span lang="TH">)</span></span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        ></td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Advance Withdraw</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >เงินเบิกถอนล่วงหน้า</span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        ></td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Begin-Equity Bal</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >มูลค่าทรัพย์สินของลูกค้า ณ ต้นวัน</span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        ></td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >End-Equity Bal</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >มูลค่าทรัพย์สินของลูกค้า ณ สิ้นวัน</span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >End-Equity Bal = Begin Cash +/- Unrealized
                              P&amp;L Futures</span
                            >
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Initial Margin</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >หลักประกันขั้นต้น</span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        ></td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Maintenance Margin</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >หลักประกันรักษาสภาพ</span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        ></td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Excess/Insufficient</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >ทรัพย์สินส่วนเกิน / ส่วนขาด</span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        ></td>
                      </tr>
                      <tr>
                        <td
                          width="25%"
                          valign="top"
                          style="
                            width: 25%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >Net Customer Paid (Call/Force Margin)</span
                            >
                          </p>
                        </td>
                        <td
                          width="40%"
                          valign="top"
                          style="
                            width: 40%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >จำนวนเงินที่ถูกเรียกหลักประกันเพิ่ม</span
                            >
                          </p>
                        </td>
                        <td
                          width="35%"
                          style="
                            width: 35%;
                            padding: 2.25pt 2.25pt 2.25pt 2.25pt;
                          "
                        >
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >ลูกค้าสถานบัน </span
                            ><span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >: <span lang="TH">เทียบ </span>IM</span
                            >
                          </p>
                          <p class="MsoNoSpacing">
                            <span
                              lang="TH"
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >ลูกค้าทั่วไป </span
                            ><span
                              style="
                                font-size: 10pt;
                                font-family: 'Tahoma', 'sans-serif';
                              "
                              >: <span lang="TH">เทียบ </span>MM</span
                            >
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <p class="MsoNoSpacing">
                  <span
                    style="font-size: 10pt; font-family: 'Tahoma', 'sans-serif'"
                    >&nbsp;</span
                  >
                </p>

                <p class="MsoNoSpacing">
                  <span
                    style="font-size: 10pt; font-family: 'Tahoma', 'sans-serif'"
                    >&nbsp;</span
                  >
                </p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </center>
  </body>
</html>`,
          }}
        ></div>
      </Fragment>
    </>
  );
};

export default ContractNoteHelp;
