import { PageName } from "../enum/page.name";
import { ISubMenu } from "../interfaces/menu";

export const E_FORMS: { [key: string]: ISubMenu } = {
  SECURITY_STATUS_REPORT: {
    imagePath: "/icons/e-report/securities-status-report-form.png",
    name: "e_forms.securities_request_form",
    pageName: PageName.SecuritiesStatusReportITP,
    isAdditionalLang: true,
  },
  CHANGE_CONTRACT_INFO: {
    imagePath: "/icons/my-account/change_email_telno_icon.png",
    name: "e_forms.change_email_telno",
    pageName: PageName.ChangePersonalData,
  },
  DEPOSIT_CASH: {
    imagePath: "/icons/my-account/deposit_cash_icon.png",
    name: "e_forms.deposit_cash",
    pageName: PageName.CashDepositITP,
    isAdditionalLang: true,
  },
  WITHDRAW_CASH: {
    imagePath: "/icons/my-account/withdraw_cash_icon.png",
    name: "e_forms.withdraw_cash",
    pageName: PageName.CashWithdrawITP,
    isAdditionalLang: true,
  },
  DEPOSIT_STOCK: {
    imagePath: "/icons/my-account/deposit_stock_icon.png",
    name: "e_forms.deposit_stock",
    pageName: PageName.SecuritiesDepositITP,
    isAdditionalLang: true,
  },
  WITHDRAW_STOCK: {
    imagePath: "/icons/my-account/withdraw_stock_icon.png",
    name: "e_forms.withdraw_stock",
    pageName: PageName.SecuritiesWithdrawITP,
    isAdditionalLang: true,
  },
  CONVERT_STOCK: {
    imagePath: "/icons/my-account/convert_stock_icon.png",
    name: "e_forms.convert_stock",
    pageName: PageName.SecuritiesConversionITP,
    isAdditionalLang: true,
  },
  BTF_E_FORM: {
    imagePath: "/icons/my-account/offshore_cash_deposit_witdrawal.png",
    name: "my_account.btf_e_form",
    pageName: PageName.BTFEForm,
  },
};
