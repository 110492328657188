import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { PATHS } from "../../const/path";
import { SVG_CHEVRON_LEFT_THIN } from "../../const/svg.icon";
import Language from "../TopBar/Language";

const Topbar = () => {
  const { t: translate } = useTranslation();
  const history = useHistory();

  const pageName = () => {
    switch (history.location.pathname.split("/").slice(0, 2).join("/")) {
      case PATHS.E_SERVICE_RESEARCH:
        return "sidebar.research";
      case PATHS.E_SERVICE_RESEARCH_VIEW:
        return "sidebar.research";
      case PATHS.E_SERVICE_RESEARCH_ARCHIVE:
        return "sidebar.research";
      case PATHS.E_SERVICE_E_STATEMENT:
        return "sidebar.statement";
      case PATHS.E_SERVICE_CUSTOMER_HANDBOOK:
        return "sidebar.customer-handbook";
      default:
        return "";
    }
  };

  return (
    <div
      className="flex justify-between items-center pl-2 pr-4 py-2 sticky top-0 w-full bg-white border-b border-dbsv-neutral-slate-10"
      style={{ zIndex: 1 }}
    >
      <div
        className="p-2 cursor-pointer text-dbsv-neutral-slate-70"
        onClick={history.goBack}
      >
        {SVG_CHEVRON_LEFT_THIN}
      </div>
      <span className="font-bold">{translate(pageName())}</span>
      <Language from="e-service" />
    </div>
  );
};

export default Topbar;
