import qs from "qs";
import {
  LIMIT_RELATED_RESEARCH_ITEM,
  LIMIT_RESEARCH_ARCHIVE_PER_PAGE,
} from "../const/cms";
import { TIMEOUT_RESEARCH_ITEMS } from "../const/timeout";
import { ResearchType } from "../enum/research";
import { ICMSResearchItem, IDisclaimer } from "../interfaces/cms";
import { IResearch, IResearchCategory } from "../interfaces/research";
import httpClient from "./http";

class CMSService {
  private http = httpClient();

  getResearchItems = async ({
    researchType = ResearchType.ALL,
    latest,
    categoryId,
    text,
    currentPage = 1,
  }: ICMSResearchItem) => {
    const _researchType = researchType
      ? `&research_category.research_type=${researchType}`
      : "";
    const _latest = latest ? "&_limit=7" : "";
    const _category = categoryId ? `&research_category.id=${categoryId}` : "";
    const skip = `&_start=${
      (currentPage - 1) * LIMIT_RESEARCH_ARCHIVE_PER_PAGE
    }`;
    const amountContent = latest
      ? ""
      : `&_limit=${LIMIT_RESEARCH_ARCHIVE_PER_PAGE}`;

    const filters = qs.stringify({
      _where: {
        _or: [
          { title_en_contains: text || "" },
          { title_th_contains: text || "" },
        ],
      },
    });
    return this.http
      .get<IResearch[]>(
        `/proxy/cms/research-items?&_sort=updated_at:DESC&${filters}${_researchType}${_category}${_latest}${skip}${amountContent}`,
        { timeout: TIMEOUT_RESEARCH_ITEMS }
      )
      .then((res) => this.sortWithPostDate(res.data));
  };

  getRelatedResearchItem = async ({
    researchType,
    categoryId,
  }: {
    researchType: ResearchType;
    categoryId: number;
  }) => {
    const paramLimit = `&_limit=${LIMIT_RELATED_RESEARCH_ITEM}`;
    const paramsQueryResearchType = `&research_category.research_type=${researchType}`;
    const paramQueryCategory = `&research_category.id=${categoryId}`;

    const res = await this.http.get<IResearch[]>(
      `/proxy/cms/research-items?&_sort=updated_at:DESC&${paramsQueryResearchType}${paramQueryCategory}${paramLimit}`
    );
    return this.sortWithPostDate(res.data);
  };

  getResearchItemById = async (researchId: number): Promise<IResearch> => {
    const res = await this.http(`/proxy/cms/research-items/${researchId}`);
    return res.data;
  };

  getResearchCategory = async (): Promise<IResearchCategory[]> => {
    return this.http
      .get<IResearchCategory[]>(
        `/proxy/cms/research-categories?_sort=priority:ASC`
      )
      .then((res) => res.data);
  };

  getResearchPDF = async (pdfUrl: string) => {
    return this.http
      .get(`/proxy/cms${pdfUrl}`, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/pdf",
        },
      })
      .then((res) => res);
  };

  getResearchItemCount = async ({
    researchType = ResearchType.ALL,
    categoryId,
    text,
  }: {
    researchType: ResearchType;
    categoryId: number;
    text: string;
  }): Promise<number> => {
    const _researchType = researchType
      ? `&research_category.research_type=${researchType}`
      : "";
    const _category = categoryId ? `&research_category.id=${categoryId}` : "";
    const filters = qs.stringify({
      _where: {
        _or: [
          { title_en_contains: text || "" },
          { title_th_contains: text || "" },
        ],
      },
    });
    return this.http
      .get<number>(
        `/proxy/cms/research-items/count?_sort=updated_at:DESC&${filters}${_researchType}${_category}`
      )
      .then((res) => res.data);
  };

  sortWithPostDate = (researches: IResearch[]): IResearch[] => {
    return researches.sort((r1, r2) => {
      const r1Date = r1.post_date ?? r1.updated_at;
      const r2Date = r2.post_date ?? r2.updated_at;
      return new Date(r2Date).getTime() - new Date(r1Date).getTime();
    });
  };

  async getDisclaimerLatest(): Promise<IDisclaimer | undefined> {
    const filters = qs.stringify({
      _where: [{ active: true }],
    });
    const res = await this.http.get<IDisclaimer[]>(
      `/proxy/cms/disclaimers?${filters}&_limit=1&_sort=created_at:DESC`
    );
    return res.data.length ? res.data[0] : undefined;
  }

  async userAcceptDisclaimer(
    disclaimer: IDisclaimer,
    username: string,
    ip: string
  ) {
    const body = {
      username,
      disclaimer_version: disclaimer.version,
      disclaimer,
      ip_address: ip,
    };
    return this.http.post("/proxy/cms/user-accept-disclaimers", body);
  }
}

export default CMSService;
