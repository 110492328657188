import { Accounts } from "../components/Accounts";
import { NewShortcuts } from "../components/NewFavorite";
import { Points } from "../components/Points";
import { Research } from "../components/Research";
import YourMissionPanel from "../components/YourMission/YourMissionPanel";
import { useUserContext } from "../context/user/user.context";

export const NewDashboard = () => {
  const { missions } = useUserContext();
  return (
    <div className="flex flex-col w-full p-3 xl:p-5 gap-y-5">
      <NewShortcuts />
      <YourMissionPanel missions={missions} />
      <Points />
      <Accounts />
      <Research />
    </div>
  );
};
