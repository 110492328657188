export enum PageName {
  Streaming = "streamingMM",
  PlaceOrderPopup = "placeOrderPopUpMM",
  Logout = "",
  Aspen = "aspenITP",
  EFinance = "efinanceITP",
  // My Cornor
  ChangePassword = "changePasswordMM",
  ChangePin = "changePinMM",
  ForgotPin = "forgotPinMM",
  ChangePersonalData = "changeContactInfoMM",
  ekycSuitTest = "ekycSuitTest",
  ChangeDefaultAccount = "changeDefaultAccountITP",
  // ITP Account Management
  ePaymentITP = "epaymentITP",
  CashDepositITP = "cashDepositITP",
  CashWithdrawITP = "cashWithdrawalITP",
  SecuritiesDepositITP = "securityDepositITP",
  SecuritiesWithdrawITP = "securityWithdrawalITP",
  SecuritiesConversionITP = "securityConversionITP",
  SecuritiesStatusReportITP = "securityStatusReportITP",
  // View order & Portfolio
  MarginSimulator = "marginSimulationDERIV",
  // Market View
  TradeReportInformation = "tradeReportDERIV",
  // DERIV Account Management
  ePaymentDERIV = "epaymentDERIV",
  // E-Statement Page
  TotalDealSummary = "totoalDealSummary",
  TradingPortfolio = "portFolio",

  BTFEForm = "BTF_E_FORM",

  DirectDebitRegistration = "DIRECT_DEBIT_REGISTRATION",
  ONLINE_TRADING_WEBSITE = "ONLINE_TRADING_WEBSITE",
  M_TRADING_TH = "M_TRADING_TH",
}
