export interface IErrorComponent {
  header: string;
  errorCode: string;
  title: string;
  description: string;
  ctaButton?: JSX.Element;
}

const ErrorComponent = (props: IErrorComponent) => {
  return (
    <div className="flex flex-col my-8" data-testid="get-top-chart-error-card">
      <div className="flex justify-center text-center">
        <img className="w-20" src="/icons/ico-Alert.png" alt={""} />
      </div>
      <h1
        className="mt-4 font-bold text-center lg:text-xl md:text-lg text-primary-500"
        data-testid="get-top-chart-error-head"
      >
        {props.header}
      </h1>
      <h1
        className="text-xs text-center text-primary-500"
        data-testid="get-top-chart-error-code"
      >
        {`(Error Code ${props.errorCode})`}
      </h1>
      <h1
        className="mt-4 text-sm font-bold text-center"
        data-testid="get-top-chart-error-title"
      >
        {props.title}
      </h1>
      <h1
        className="text-sm text-center whitespace-pre-line"
        data-testid="get-top-chart-error-description"
      >
        {props.description}
      </h1>
      {props.ctaButton}
    </div>
  );
};

export default ErrorComponent;
