export const ModalDetail = ({
  chlidren,
  closeModal,
  ref,
  contentStyle = "p-8",
}: {
  contentStyle?: string;
  ref?: React.MutableRefObject<null>;
  chlidren: JSX.Element;
  closeModal: () => void;
}) => {
  return (
    <div className="fixed z-20 inset-0">
      <div
        id="modal-detail-outside"
        className="bg-black bg-opacity-50 h-screen lg:px-32 md:px-16 px-4 py-24 cursor-pointer overflow-x-scroll"
        onClick={(e) => {
          const { id } = e.target as HTMLElement;
          if (id.includes("modal-detail-outside")) {
            closeModal();
          }
        }}
      >
        <div ref={ref} className={`bg-white cursor-auto rounded-lg ${contentStyle}`}>
          {chlidren}
        </div>
      </div>
    </div>
  );
};
