import { PageName } from "../enum/page.name";
import { ISubMenu } from "../interfaces/menu";

export const MY_ACCOUNT: { [key: string]: ISubMenu } = {
  CHANGE_PASSWORD: {
    name: "my_account.change_password",
    imagePath: "/icons/my-account/change_password_icon.png",
    pageName: PageName.ChangePassword,
  },
  CHANGE_PIN: {
    imagePath: "/icons/my-account/change_pin_icon.png",
    name: "my_account.change_pin",
    pageName: PageName.ChangePin,
  },
  FORGOT_PIN: {
    imagePath: "/icons/my-account/forgot_pin_icon.png",
    name: "my_account.forgot_pin",
    pageName: PageName.ForgotPin,
  },
  SUIT_TEST: {
    imagePath: "/icons/my-account/suit_test_icon.png",
    name: "my_account.suit_test",
    pageName: PageName.ekycSuitTest,
  },
  CHANGE_CONTRACT_INFO: {
    imagePath: "/icons/my-account/change_email_telno_icon.png",
    name: "my_account.change_email_telno",
    pageName: PageName.ChangePersonalData,
  },
  NEW_EPAYMENT: {
    imagePath: "/icons/my-account/new_epayment.png",
    name: "my_account.new_epayment",
  },
  DEPOSIT_CASH: {
    imagePath: "/icons/my-account/deposit_cash_icon.png",
    name: "my_account.deposit_cash",
    pageName: PageName.CashDepositITP,
    isAdditionalLang: true,
  },
  WITHDRAW_CASH: {
    imagePath: "/icons/my-account/withdraw_cash_icon.png",
    name: "my_account.withdraw_cash",
    pageName: PageName.CashWithdrawITP,
    isAdditionalLang: true,
  },
  DEPOSIT_STOCK: {
    imagePath: "/icons/my-account/deposit_stock_icon.png",
    name: "my_account.deposit_stock",
    pageName: PageName.SecuritiesDepositITP,
    isAdditionalLang: true,
  },
  WITHDRAW_STOCK: {
    imagePath: "/icons/my-account/withdraw_stock_icon.png",
    name: "my_account.withdraw_stock",
    pageName: PageName.SecuritiesWithdrawITP,
    isAdditionalLang: true,
  },
  CONVERT_STOCK: {
    imagePath: "/icons/my-account/convert_stock_icon.png",
    name: "my_account.convert_stock",
    pageName: PageName.SecuritiesConversionITP,
    isAdditionalLang: true,
  },
  DIRECT_DEBIT_REGISTRATION: {
    imagePath: "/icons/my-account/direct_debit_registration.png",
    name: "my_account.direct_debit_registration",
    pageName: PageName.DirectDebitRegistration,
  },
  CHANGE_DEFAULT_ACCOUNT: {
    imagePath: "/icons/my-account/change_default_account_icon.png",
    name: "my_account.change_default_account",
    pageName: PageName.ChangeDefaultAccount,
  },
};
