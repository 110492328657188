import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { PATH_ROUTE } from "../../const/path";
import { useRouteContext } from "../../context/route.context";
import { useUserContext } from "../../context/user/user.context";
import { ISideBarMenu } from "../../interfaces/menu";

export const SideBar = () => {
  const { t: translate } = useTranslation();
  const history = useHistory();
  const { path }: { path: string } = useRouteContext();
  const { sideBarMenu, ssoSTTLink, userInfo } = useUserContext();

  const isActive = (currentRoute: ISideBarMenu) => {
    if (currentRoute.activePaths) {
      const pathNameWithoutParam = "/" + path.split("/", 2)[1];
      return currentRoute.activePaths.includes(pathNameWithoutParam);
    }
    return currentRoute.path === path;
  };

  const handleClickSidebar = async (
    e: React.MouseEvent,
    menu: ISideBarMenu
  ) => {
    e.preventDefault();
    if (menu.path === PATH_ROUTE.STREAMING.path) {
      ReactGA.send({ hitType: "pageview", page: "/streaming" });
      ssoSTTLink.openStreamingLink();
    } else {
      history.push(menu.path);
    }
    const label = menu.name.split(".")[1];
    ReactGA.event({ category: "side_bar", action: "user_access", label });
  };

  const onClickLogo = () => {
    if (history.location.pathname !== PATH_ROUTE.DASHBOARD.path) {
      history.push(PATH_ROUTE.DASHBOARD.path);
    }
  };
  const isLogin = () => !!userInfo.username;

  return (
    <>
      <div className="flex flex-col h-screen font-light bg-white ring-1 gap-y-3 text-dbsv-neutral-slate-70 w-14 xl:w-64 ring-dbsv-neutral-slate-10">
        <div
          onClick={onClickLogo}
          className="flex items-center justify-center cursor-pointer ring-1 ring-dbsv-neutral-slate-10 h-14 min-h-14"
        >
          <img
            id="logo"
            alt="logo-desktop-mode"
            src="/images/dbsv-logo-black.png"
            className="hidden xl:block xl:px-5"
          />
          <img
            id="logo"
            alt="logo-mobile-mode"
            src="/favicon.ico"
            className="block p-3 xl:hidden"
          />
        </div>
        <div className="flex flex-col overflow-y-auto gap-y-3">
          {sideBarMenu().map((menu) => (
            <div
              key={`menu-${menu.name}`}
              className={`flex mx-2.5 xl:mx-3 select-none
                transition ease-out duration-300 ${
                  isActive(menu)
                    ? "bg-dbsv-data-red-10 text-dbsv-global-black font-bold"
                    : isLogin()
                    ? "hover:text-dbsv-global-black hover:font-medium"
                    : ""
                } ${
                isLogin() ? "cursor-pointer " : ""
              } rounded-sm text-xs items-center justify-center xl:justify-start`}
              onClick={(e) => {
                if (isLogin()) handleClickSidebar(e, menu);
              }}
            >
              <div
                className={`${
                  isActive(menu) ? "bg-dbsv-brand-dbs-red" : " bg-transparent"
                } w-0.5 h-full rounded-l-inherit`}
              />
              <div className="flex items-center justify-center w-full py-2 xl:justify-start xl:px-3 xl:py-3">
                {!!menu.Icon && (
                  <menu.Icon
                    className={isActive(menu) ? "text-dbsv-brand-dbs-red" : ""}
                  />
                )}
                <div
                  className="invisible hidden xl:flex xl:visible xl:ml-2"
                  style={{ fontSize: "0.9rem" }}
                >
                  {translate(menu.name)}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
