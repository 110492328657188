/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { DATE_FULL_FORMAT } from "../../const/date.format";
import { useResearchContext } from "../../context/research";
import { ResearchType } from "../../enum/research";
import { getDateInFormat } from "../../helper/date.time";
import { isThaiLanguage } from "../../i18n/helper";
import { IResearch } from "../../interfaces/research";
import { getPathResearchArchive } from "../../pages/Research/helper";
import { LabelWithChevronRight } from "../LabelWithChevronRight";
import { Title } from "../Title";
import { ItemResearch } from "./ItemResearch";

export const OverseaResearch = () => {
  const history = useHistory();
  const { t: translate } = useTranslation();
  const {
    getResearchItems,
  }: {
    getResearchItems: ({
      researchType,
      latest,
    }: {
      latest: boolean;
      researchType?: ResearchType | undefined;
    }) => Promise<IResearch[]>;
  } = useResearchContext();
  const [overseaResearches, setOverseaResearches] = useState<IResearch[]>([]);

  useEffect(() => {
    getResearchItems({
      researchType: ResearchType.OVERSEA,
      latest: true,
    }).then(setOverseaResearches);
  }, []);

  const goToOverseaResearch = () => {
    history.push(
      getPathResearchArchive({
        isEService: false,
        researchType: ResearchType.OVERSEA,
      })
    );
  };

  return (
    <>
      <Title title={translate("latestResearch.latestOverseasResearch")} />
      <div className="pt-6 space-y-4">
        {overseaResearches.map((research, index) => (
          <ItemResearch
            reserachCategory={
              isThaiLanguage()
                ? research.research_category.name_th
                : research.research_category.name_en
            }
            researchId={research.id}
            key={`overseas-reserach-${index}`}
            id={index}
            title={isThaiLanguage() ? research.title_th : research.title_en}
            date={getDateInFormat(
              research?.post_date
                ? research?.post_date.toString()
                : research?.updated_at.toString(),
              DATE_FULL_FORMAT
            )}
            imageUrl={research?.thumbnail_image?.url}
          />
        ))}
      </div>
      <div
        className="flex justify-start mt-4 text-primary-500"
        onClick={goToOverseaResearch}
      >
        <LabelWithChevronRight label={translate("latestResearch.viewAll")} />
      </div>
    </>
  );
};
