import { PageName } from "../enum/page.name";
import { currentLanguage } from "../i18n/helper";
import { openWindow, openWindowWithBlankPage } from "./open.window";

class SSOSTTLink {
  private userref = "";
  private currentLangUpperCase = "TH";

  constructor({
    userref,
    currentLangUpperCase,
  }: {
    userref: string;
    currentLangUpperCase?: string;
  }) {
    this.userref = userref;
    this.currentLangUpperCase =
      currentLangUpperCase ?? currentLanguage().toUpperCase();
  }

  openStreamingLink = () => {
    this.openLink({
      pageName: PageName.Streaming,
      isAdditionalLang: false,
      isPopup: true,
    });
  };

  openLink({
    pageName,
    isAdditionalLang,
    isPopup,
    replaceUrl = false,
  }: {
    pageName: PageName;
    isAdditionalLang: boolean;
    isPopup: boolean;
    replaceUrl?: boolean;
  }) {
    const link = this.getLink({ pageName, isAdditionalLang });
    if (replaceUrl) {
      return openWindow({ link, isPopup: false, target: "_self" });
    }
    openWindowWithBlankPage({ link, isPopup });
  }

  getLink({
    pageName,
    isAdditionalLang,
  }: {
    pageName: PageName;
    isAdditionalLang: boolean;
  }) {
    const additionalLang = isAdditionalLang
      ? `&additionalParams=lang=${this.currentLangUpperCase}`
      : "";
    if (pageName === PageName.TotalDealSummary)
      return `${process.env.REACT_APP_STT_URL}/C15_TotalDealSummary.jsp`;
    if (pageName === PageName.TradingPortfolio)
      return `${process.env.REACT_APP_STT_URL}/C15_PortFolio.jsp`;
    if (pageName === PageName.PlaceOrderPopup)
      return `${process.env.REACT_APP_STT_URL}/realtime/fastorder/fastorder.jsp?platform=mm`;
    return `${process.env.REACT_APP_SSO_STT_URL}?pageName=${pageName}&brokerId=${process.env.REACT_APP_BROKER_ID}&userref=${this.userref}${additionalLang}`;
  }
}

export default SSOSTTLink;
