import { TFunction } from "react-i18next";
import { PageName } from "../../enum/page.name";
import { IDynamicDropdownOption } from "../DynamicDropdown";

export const getMyAccountMenu = (
  translate: TFunction
): IDynamicDropdownOption[] => {
  return [
    {
      value: `${PageName.ChangePassword}-false`,
      label: translate("topbar.myAccountMenu.change-password"),
    },
    {
      value: `${PageName.ChangePin}-false`,
      label: translate("topbar.myAccountMenu.change-pin"),
    },
    {
      value: `${PageName.ForgotPin}-false`,
      label: translate("topbar.myAccountMenu.forgot-pin"),
    },
    {
      value: `${PageName.ChangePersonalData}-false`,
      label: translate("topbar.myAccountMenu.change-contract-info"),
    },
    {
      value: `${PageName.ekycSuitTest}-false`,
      label: translate("topbar.myAccountMenu.suit-test"),
    },
    {
      value: "logout",
      label: translate("topbar.myAccountMenu.logout"),
      divideTop: true,
    },
  ];
};
