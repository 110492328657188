import { IShortcut } from "./../interfaces/user";
import httpClient from "./http";

class ShortcutService {
  private http = httpClient();
  getShortcuts = async (): Promise<IShortcut[]> => {
    return this.http.get<IShortcut[]>(`shortcut`).then((res) => res.data);
  };
}

export default ShortcutService;
