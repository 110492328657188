import axios from "axios";

const httpClient = () => {
  return axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: true,
  });
};

export default httpClient;
