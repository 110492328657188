import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowDownCircle } from "../../../svgIcon/new-dashboard/accounts/arrow-down-circle.svg";
import { ReactComponent as ArrowRight } from "../../../svgIcon/new-dashboard/accounts/arrow-right.svg";
import { accountMenuItems, AccountMenus } from "./helper";

export const AccountItemAction = ({ type }: { type: AccountMenus }) => {
  const { t: translate } = useTranslation();

  return (
    <div className="flex flex-row items-center justify-center w-48 gap-2 px-4 py-2 text-xs text-white border rounded md:text-sm md:w-auto">
      <div>{translate(accountMenuItems.getKeyAccountItemAction(type))}</div>
      {type === "e-monthly-statement" ? <ArrowDownCircle /> : <ArrowRight />}
    </div>
  );
};
