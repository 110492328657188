import axios from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { isThaiLanguage } from "../../../../i18n/helper";
import { IDisclaimer } from "../../../../interfaces/cms";
import OverseasTradingPortfolioModel, {
  ICurrencyPairRate,
  ISummaryOverseasPortfolioWithExchangeMarket,
} from "../../../../models/overseas-trading-portfolio.model";
import CMSService from "../../../../services/cms.service";
import { IOverseasPortfolio } from "../../../../services/overseas-trading";
import { Disclaimer } from "../../../ResearchDisclaimer";

const thStyle: React.CSSProperties = { borderColor: "#D8D8D8" };

const twoDigit = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  useGrouping: false,
});

const currency = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const OverseasPortfolioTable = ({
  portfolios,
  disclaimer,
  username,
}: {
  portfolios: IOverseasPortfolio;
  disclaimer: IDisclaimer;
  username: string;
}) => {
  const { t: translate } = useTranslation();
  const model = new OverseasTradingPortfolioModel(portfolios);
  const [showDisclaim, setShowDisclaim] = useState<boolean>(false);
  return (
    <div className="overflow-x-auto">
      <table className="w-full border-collapse table-auto">
        <thead className="h-16" style={{ backgroundColor: "#F2F2F2" }}>
          <tr>
            <th
              data-testid="symbol-header-table"
              className="px-4 text-sm font-bold border"
              style={thStyle}
            >
              {translate("overseasPortfolio.symbol")}
            </th>
            <th
              data-testid="stock-header-table"
              className="px-4 text-sm font-bold border w-60"
              style={thStyle}
            >
              {translate("overseasPortfolio.stockName")}
            </th>
            <th
              data-testid="unit-header-table"
              className="px-4 text-sm font-bold border"
              style={thStyle}
            >
              {translate("overseasPortfolio.unit")}
            </th>
            <th
              data-testid="const-price-header-table"
              className="px-4 text-sm font-bold border"
              style={thStyle}
            >
              {translate("overseasPortfolio.costPrice")}
            </th>
            <th
              data-testid="market-price-header-table"
              className="px-4 text-sm font-bold border"
              style={thStyle}
            >
              {translate("overseasPortfolio.marketPrice")}
            </th>
            <th
              data-testid="total-cost-value-header-table"
              className="px-4 text-sm font-bold border"
              style={thStyle}
            >
              {translate("overseasPortfolio.totalCostValue")}
            </th>
            <th
              data-testid="total-market-value-header-table"
              className="px-4 text-sm font-bold border"
              style={thStyle}
            >
              {translate("overseasPortfolio.totalMarketValue")}
            </th>
            <th
              data-testid="unrealized-header-table"
              className="px-4 text-sm font-bold border"
              style={thStyle}
            >
              {translate("overseasPortfolio.unrealizedGainLoss")}
            </th>
            <th
              data-testid="unrealized-percent-header-table"
              className="px-4 text-sm font-bold border"
              style={thStyle}
            >
              {translate("overseasPortfolio.percentUnrealizedGainLoss")}
            </th>
            <th
              data-testid="amount-header-table"
              className="px-4 text-sm font-bold border"
              style={thStyle}
            >
              {translate("overseasPortfolio.amount")}
            </th>
          </tr>
        </thead>
        <tbody>
          <PortfolioData
            portfolioWithMarkets={model.getSummaryPortfolioGroupByMarket()}
            totalAmount={model.getTotalAmount()}
          />
        </tbody>
      </table>
      <div className="flex flex-col items-start justify-between w-full mt-4 mb-2 lg:flex-row">
        <CurrencyExchange
          currencyPairRate={model.getCurrencyExchangeRatePairWithTHB()}
        />
        <div
          className="flex flex-row items-center justify-between cursor-pointer gap-x-2"
          onClick={() => {
            setShowDisclaim(true);
          }}
        >
          <img src="/icons/info.png" alt="" className="w-8" />
          <div className="font-medium underline ">
            {translate("overseasPortfolio.generalInformationAndDisclaimer")}
          </div>
        </div>
      </div>

      {showDisclaim && (
        <Disclaimer
          title={isThaiLanguage() ? disclaimer.title_th : disclaimer.title_en}
          th={disclaimer.content_th}
          en={disclaimer.content_en}
          onAccept={() => {
            setShowDisclaim(false);
            acceptDisclaimer(disclaimer, username);
          }}
        />
      )}
    </div>
  );
};

const MarketHeader = ({ market }: { market: string }) => {
  const style: React.CSSProperties = {
    color: "#386d68",
  };
  return (
    <>
      <tr className="h-4" />
      <tr className="pt-16 text-sm font-bold" style={style}>
        <td>Market :</td>
        <td>{market}</td>
      </tr>
    </>
  );
};

const SummaryPortfolio = ({
  totalCostValue,
  totalMarketValue,
  totalUnrealized,
  totalUnrealizePercentage,
  totalAmount,
  unit,
}: {
  totalCostValue: number;
  totalMarketValue: number;
  totalUnrealized: number;
  totalUnrealizePercentage: number;
  totalAmount: number;
  unit: string;
}) => {
  return (
    <tr className="text-sm font-bold">
      <td colSpan={4} />
      <td className="text-right">Total</td>
      <td className="px-1 text-right underline">
        {unit} {currency.format(totalCostValue)}
      </td>
      <td className="px-1 text-right underline">
        {unit} {currency.format(totalMarketValue)}
      </td>
      <td
        className="px-1 text-right underline"
        style={{ ...textStyle(totalUnrealized) }}
      >
        {unit} {currency.format(totalUnrealized)}
      </td>
      <td
        className="px-1 text-right underline"
        style={{ ...textStyle(totalUnrealizePercentage) }}
      >
        {currency.format(totalUnrealizePercentage)}%
      </td>
      <td
        className="px-1 text-right underline"
        style={{
          backgroundColor: "#fee8df",
        }}
      >
        THB {currency.format(totalAmount)}
      </td>
    </tr>
  );
};

const PortfolioData = ({
  portfolioWithMarkets,
  totalAmount,
}: {
  portfolioWithMarkets: ISummaryOverseasPortfolioWithExchangeMarket[];
  totalAmount: number;
}) => {
  return (
    <>
      {portfolioWithMarkets.map((portfolioWithMarket, marketIndex) => {
        return (
          <>
            <MarketHeader market={portfolioWithMarket.exchangeMarket} />
            <>
              {portfolioWithMarket.portfolios.map((portfolio, index) => {
                const {
                  stockCode,
                  stockFullEngName,
                  volume,
                  averageCost,
                  marketPrice,
                  averageValue,
                  marketValue,
                  unrealize,
                  unrealizePercentage,
                  amountEquivalent,
                  unit,
                } = portfolio;
                const style: React.CSSProperties = {};
                if (index === 0) {
                  style.borderTop = "1.5px #e73323 solid";
                }
                if (index === portfolioWithMarket.portfolios.length - 1) {
                  style.borderBottom = "2.5px #e73323 solid";
                }
                return (
                  <tr className="text-sm h-7">
                    <td
                      data-testid={`symbol-matket-${marketIndex}-row-${index}`}
                      className="px-3 font-semibold border"
                      style={{
                        color: "#386d68",
                        ...style,
                      }}
                    >
                      {stockCode}
                    </td>
                    <td
                      data-testid={`stock-name-matket-${marketIndex}-row-${index}`}
                      className="px-3 font-semibold border"
                      style={{
                        color: "#386d68",
                        ...style,
                      }}
                    >
                      {stockFullEngName}
                    </td>
                    <td
                      data-testid={`unit-matket-${marketIndex}-row-${index}`}
                      className="px-1 font-semibold text-right text-black border"
                      style={style}
                    >
                      {twoDigit.format(volume)}
                    </td>
                    <td
                      data-testid={`cost-price-matket-${marketIndex}-row-${index}`}
                      className="px-1 font-semibold text-right text-black border"
                      style={style}
                    >
                      {unit} {currency.format(averageCost)}
                    </td>
                    <td
                      data-testid={`market-price-matket-${marketIndex}-row-${index}`}
                      className="px-1 font-semibold text-right text-black border"
                      style={style}
                    >
                      {unit} {currency.format(marketPrice)}
                    </td>
                    <td
                      data-testid={`total-cost-value-matket-${marketIndex}-row-${index}`}
                      className="px-1 font-semibold text-right text-black border "
                      style={style}
                    >
                      {unit} {currency.format(averageValue)}
                    </td>
                    <td
                      data-testid={`total-market-value-matket-${marketIndex}-row-${index}`}
                      className="px-1 font-semibold text-right text-black border"
                      style={style}
                    >
                      {unit} {currency.format(marketValue)}
                    </td>
                    <td
                      data-testid={`unrealized-matket-${marketIndex}-row-${index}`}
                      className="px-1 font-semibold text-right border"
                      style={{
                        ...style,
                        ...textStyle(unrealize),
                      }}
                    >
                      {unit} {currency.format(unrealize)}
                    </td>
                    <td
                      data-testid={`unrealized-percent-matket-${marketIndex}-row-${index}`}
                      className="px-1 font-semibold text-right border"
                      style={{
                        ...style,
                        ...textStyle(unrealizePercentage),
                      }}
                    >
                      {currency.format(unrealizePercentage)}%
                    </td>
                    <td
                      data-testid={`amount-matket-${marketIndex}-row-${index}`}
                      className="px-1 font-semibold text-right text-black border "
                      style={{ ...style, backgroundColor: "#fee8df" }}
                    >
                      THB&nbsp;
                      {currency.format(amountEquivalent)}
                    </td>
                  </tr>
                );
              })}
            </>
            <SummaryPortfolio
              {...portfolioWithMarket.summary}
              unit={portfolioWithMarket.portfolios[0].unit}
            />
          </>
        );
      })}
      <TotalSummaryPortfolio totalAmount={totalAmount} />
    </>
  );
};

const TotalSummaryPortfolio = ({ totalAmount }: { totalAmount: number }) => {
  return (
    <>
      <tr className="h-4" />
      <tr className="h-8 font-bold" style={{ backgroundColor: "#f2f2f2" }}>
        <td colSpan={7}></td>
        <td>Total Amount</td>
        <td className="px-1 text-right underline" colSpan={2}>
          THB {currency.format(totalAmount)}
        </td>
      </tr>
    </>
  );
};

const CurrencyExchange = ({
  currencyPairRate,
}: {
  currencyPairRate: ICurrencyPairRate[];
}) => {
  const { t: translate } = useTranslation();

  return (
    <table className="font-semibold">
      {currencyPairRate.map((rate, index) => {
        if (index === 0) {
          return (
            <tr>
              <td>{translate("overseasPortfolio.exchangeRate")} : </td>
              <td>
                &nbsp;{rate.currencyPair} = {currency.format(rate.rate)}
              </td>
            </tr>
          );
        }
        return (
          <tr>
            <td />
            <td>
              &nbsp;{rate.currencyPair} = {currency.format(rate.rate)}
            </td>
          </tr>
        );
      })}
    </table>
  );
};

const textStyle = (value: number): React.CSSProperties => {
  if (value >= 0) {
    return {
      color: "#3f6e28",
    };
  }
  return {
    color: "#de3125",
  };
};

export const getClientIP = async (): Promise<string> => {
  return axios
    .get<{ ip: string }>("https://api.ipify.org?format=json")
    .then((res) => res.data.ip);
};

const acceptDisclaimer = async (disclaimer: IDisclaimer, username: string) => {
  const ip = await getClientIP();
  new CMSService().userAcceptDisclaimer(disclaimer, username, ip);
};

export default OverseasPortfolioTable;
