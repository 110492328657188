const ArrowLeft = ({ onPreviodBanner }: { onPreviodBanner: () => void }) => {
  return (
    <div
      id="banner-arrow-left"
      className="absolute flex items-center justify-center w-8 h-44 text-white left-0 cursor-pointer hover:bg-gray-600 hover:bg-opacity-20 transition ease-out duration-300"
      onClick={() => onPreviodBanner()}
    >
      {CHEVRON_LEFT}
    </div>
  );
};

const CHEVRON_LEFT = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="w-6 h-10"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M15 26l-7-14 7-14"
    />
  </svg>
);

export default ArrowLeft;
