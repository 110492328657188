import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ContractNote } from "../components/ContractNote";
import { CustomTab } from "../components/CustomTab";
import { DailyReport } from "../components/DailyReport";
import { EStatement } from "../components/EStatement";
import { MonthlyReport } from "../components/MonthlyReport";
import { Title } from "../components/Title";
import { TotalDealSummary } from "../components/TotalDealSummary";
import { useUserContext } from "../context/user/user.context";
import { ReportType } from "../interfaces/reporttype";
import { ITab } from "../interfaces/tab";

export const StatementReport = () => {
  const { eReportSubMenu } = useUserContext();
  const { t: translate } = useTranslation();
  const [type, setType] = useState<ReportType>(ReportType.DAILY);

  const tabs: ITab[] = [
    { value: ReportType.DAILY, label: translate("statement_report.daily") },
    { value: ReportType.MONTHLY, label: translate("statement_report.monthly") },
  ];

  return (
    <div className="flex flex-col p-4 gap-y-4">
      <TotalDealSummary />
      <EStatement />
      {eReportSubMenu().eReport && (
        <div className="px-6 py-4 card-bg">
          <Title title={translate("statement_report.report_title")} />
          <div className="justify-center block w-1/2 mx-auto mb-6 md:w-4/12">
            <CustomTab
              className="pt-8 border-b border-gray-200"
              tabs={tabs}
              currentTab={type}
              onClick={(currentTab) => setType(currentTab as ReportType)}
            />
          </div>

          {type === ReportType.DAILY ? <DailyReport /> : <MonthlyReport />}
        </div>
      )}
      {eReportSubMenu().contractNote && <ContractNote />}
    </div>
  );
};
