import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { PATH_ROUTE } from "../../const/path";
import { SVG_CHEVRON_RIGHT } from "../../const/svg.icon";
import { useUserContext } from "../../context/user/user.context";
import { BTFLevel } from "../../enum/rewards";
import { getDateInFormat } from "../../helper/date.time";
import { currency } from "../../helper/format.number";
import { isThaiLanguage } from "../../i18n/helper";
import { IUserPoint, IUserRewards } from "../../interfaces/user";
import { ReactComponent as GiftBoxIcon } from "../../svgIcon/new-dashboard/points/giftbox.svg";
import Tooltip from "../Tooltip";

export const Points = () => {
  const { t: translate } = useTranslation();
  const history = useHistory();
  const { userRewards } = useUserContext();

  const expirationDate: string = getDateInFormat(
    new Date(userRewards.pointExpirationDate).toISOString(),
    "dd/MM/yyyy"
  );
  const asOfDate: string = getDateInFormat(
    new Date(userRewards.asOfDate).toISOString(),
    "dd/MM/yyyy"
  );

  const filterByAccountType = userRewards.accounts.reduce((p, v) => {
    const { systemId, points } = v as IUserPoint;
    if (!p[systemId]) p[systemId] = 0;
    p[systemId] += points ?? 0;
    return p;
  }, {} as any);

  const hasReward = !!userRewards.accounts.length;

  return (
    <div className="w-full px-4 pt-4 pb-3 bg-white rounded-xl text-dbsv-global-black">
      <div className="flex items-center justify-between pb-8 gap-x-5 md:justify-start">
        <div className="flex items-center gap-x-3">
          <GiftBoxIcon />
          <div className="flex items-center gap-x-1 md:gap-x-3">
            <div className="font-bold text-dbsv-global-black">
              <span className="hidden md:block">Points :</span>
              <span className="block text-xs md:hidden">Your Status</span>
            </div>
            <span className="hidden font-normal md:contents text-dbsv-global-black">
              Your Current Point Status is
            </span>
            <Tooltip tooltip={{ title: btfTooltip(userRewards) }} />
          </div>
        </div>
        <div className="flex items-center gap-x-3">
          {!!btfIconPath(userRewards) && (
            <img
              src={btfIconPath(userRewards)}
              alt="btf-icon"
              className="w-10 h-10"
            />
          )}
          <span className="font-bold">{btfName(userRewards)}</span>
        </div>
      </div>
      <div className="flex flex-col gap-5 mb-4 md:flex-row">
        <div className="relative w-full md:w-1/2">
          <div
            id="point-summary-card"
            className="relative w-full h-48 overflow-hidden border rounded-lg bg-dbsv-neutral-slate-10 md:h-60 border-dbsv-neutral-slate-20"
          >
            <img
              src="/images/Investment_Young_Point.png"
              alt="investment-point"
              className="absolute bottom-0 h-32 -right-4 md:right-0 md:h-48"
            />
            <div className="absolute flex flex-col p-5 pt-16 md:pt-24 gap-y-5">
              <div className="flex items-baseline font-bold gap-x-2 text-dbsv-neutral-slate-100">
                <p className="text-2xl md:text-4xl">
                  {currency(userRewards.totalPoints)}
                </p>
                <p>pts.</p>
              </div>
              <div className="text-xs text-dbsv-neutral-slate-60 md:text-base">
                <div>
                  <Bullet />
                  <span>
                    {isThaiLanguage()
                      ? "คะแนนอัพเดทล่าสุด ณ วันที่ "
                      : "As of "}
                  </span>
                  <span className="font-bold">{asOfDate}</span>
                </div>
                <PointExpire
                  expirationDate={expirationDate}
                  userRewards={userRewards}
                />
              </div>
            </div>
          </div>
          <BadgePointSummary />
        </div>
        <div
          id="point-detail-by-account"
          className={`relative w-full overflow-hidden border rounded-lg md:h-60 md:w-1/2 bg-dbsv-neutral-slate-10 border-dbsv-neutral-slate-20 ${
            hasReward ? "h-point-detail-mobile" : "h-40"
          }`}
        >
          <img
            src="/images/investment-crypto-1.png"
            alt="point-detail"
            className="absolute bottom-0 h-32 -right-6 md:right-0 md:h-48"
          />
          <div className="absolute w-full p-5 top-1">
            <div className="flex flex-col items-center justify-between p-3 rounded-lg gap-y-4 md:flex-row bg-dbsv-neutral-slate-20 text-dbsv-neutral-slate-80">
              <div className="w-full md:w-max">
                <p className="font-bold md:text-2xl">
                  {translate("rewards.point_details.title")}
                </p>
                <p className="text-xs">
                  {translate("rewards.point_details.account_type")}
                </p>
              </div>
              <div
                className="flex items-center justify-center w-full px-4 py-1 border rounded cursor-pointer select-none md:w-auto border-dbsv-brand-dbs-red text-dbsv-brand-dbs-red gap-x-4"
                onClick={() => history.push(PATH_ROUTE.DBSV_REWARDS.path)}
              >
                <div>{translate("rewards.point_details.view_detail")}</div>
                <div className="flex items-center w-4 h-4">
                  {SVG_CHEVRON_RIGHT}
                </div>
              </div>
            </div>
          </div>
          <div className="absolute left-0 w-full md:px-5 top-36 md:top-24">
            {hasReward && (
              <div className="w-full overflow-scroll h-point-list-mobile md:h-auto">
                <div className="flex flex-col w-full gap-3 px-5 md:px-0 md:py-5 md:flex-row">
                  {Object.entries(filterByAccountType).map(
                    ([systemId, point], index) => (
                      <div
                        key={`account-point-detail-${index}`}
                        className="flex flex-col w-full rounded-lg shadow-lg bg-dbsv-neutral-slate-00"
                      >
                        <div className="py-2 font-bold text-center rounded-t-lg md:w-48 bg-dbsv-point-detail-header text-dbsv-point-header">
                          {translate(`rewards.accountType.${systemId}`)}
                        </div>
                        <div className="flex flex-col px-3 py-2 text-right text-dbsv-neutral-slate-100">
                          <div className="font-bold">
                            {currency(Number(point))}
                          </div>
                          <div>{translate("rewards.point_details.unit")}</div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const BadgePointSummary = () => {
  const { t: translate } = useTranslation();

  return (
    <div className="absolute flex flex-col gap-y-1.5 py-3 px-4 pr-8 text-white shadow-md md:pr-16 -top-2.5 -left-2.5 bg-dbsv-brand-dbs-red rounded-tl-xl rounded-tr-md rounded-bl-md rounded-br-point">
      <p className="font-bold md:text-2xl">
        {translate("rewards.points_summary.title")}
      </p>
      <p className="text-xs">
        {translate("rewards.points_summary.description")}
      </p>
    </div>
  );
};

export const Bullet = () => <span className="pr-1">•</span>;

export const PointExpire = ({
  userRewards,
  expirationDate,
}: {
  userRewards: IUserRewards;
  expirationDate: string;
}) => {
  const { t: translate } = useTranslation();

  if (isThaiLanguage()) {
    return (
      <div>
        <Bullet />
        <span>มี </span>
        <span className="px-1 font-bold">
          {currency(userRewards.expiryPoints)}
        </span>
        <span>{translate("rewards.points_summary.unit")}</span>
        <span className="px-1">
          {translate("rewards.points_summary.expiry_point")}
        </span>
        <span className="font-bold">
          {expirationDate + isThaiLanguage() ? "" : "."}
        </span>
      </div>
    );
  } else {
    return (
      <>
        <div className="block md:hidden">
          <p>
            <Bullet />
            <span>Point Expiration :</span>
            <span className="px-1 font-bold">
              {currency(userRewards.expiryPoints)}
            </span>
          </p>
          <p className="ml-2">
            <span>{translate("rewards.points_summary.unit")}</span>
            <span className="px-1">on</span>
            <span className="font-bold">{expirationDate}</span>
          </p>
        </div>
        <div className="hidden md:block">
          <Bullet />
          <span>Point Expiration :</span>
          <span className="px-1 font-bold">
            {currency(userRewards.expiryPoints)}
          </span>
          <span>{translate("rewards.points_summary.unit")}</span>
          <span className="px-1">on</span>
          <span className="font-bold">{expirationDate}</span>
        </div>
      </>
    );
  }
};

export const btfTooltip = (userRewards: IUserRewards) => {
  switch (userRewards.btfLevel) {
    case BTFLevel.ACTIVE: {
      const volumeLeft = currency(10000000 - userRewards.btfTradingVolume, {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      });
      return isThaiLanguage()
        ? `เทรดอีก ${volumeLeft} บาท เพื่ออัพเกรดสู่ Elite Level`
        : `${volumeLeft} baht left to upgrade to Elite Level.`;
    }
    case BTFLevel.ELITE: {
      const volumeLeft = currency(20000000 - userRewards.btfTradingVolume, {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      });
      return isThaiLanguage()
        ? `เทรดอีก ${volumeLeft} บาท เพื่ออัพเกรดสู่ Ultimate Level`
        : `${volumeLeft} baht left to upgrade to Ultimate Level`;
    }
    case BTFLevel.ULTIMATE: {
      return isThaiLanguage()
        ? "คุณได้ถึงระดับสูงสุดแล้ว"
        : "You have reached the Ultimate Level.";
    }
    default:
      return isThaiLanguage()
        ? "คุณยังไม่มีบัญชีหุ้นต่างประเทศ"
        : "You do not have overseas trading account.";
  }
};

export const btfIconPath = (userRewards: IUserRewards): string => {
  if (userRewards.btfLevel === BTFLevel.ACTIVE)
    return "/icons/trade-mission/Active.png";
  if (userRewards.btfLevel === BTFLevel.ELITE)
    return "/icons/trade-mission/Elite.png";
  if (userRewards.btfLevel === BTFLevel.ULTIMATE)
    return "/icons/trade-mission/Ultimate.png";
  return "/icons/trade-mission/Active.png";
};

export const btfName = (userRewards: IUserRewards): string => {
  if (userRewards.btfLevel === BTFLevel.ACTIVE) return "ACTIVE";
  if (userRewards.btfLevel === BTFLevel.ELITE) return "ELITE";
  if (userRewards.btfLevel === BTFLevel.ULTIMATE) return "ULTIMATE";
  return "ENTRY";
};
