import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Cookies from "universal-cookie/es6";
import { COOKIE_AUTH } from "../../const/cookie";
import { PATH_ROUTE } from "../../const/path";
import { useUserContext } from "../../context/user/user.context";
import { PageName } from "../../enum/page.name";
import { login } from "../../helper/login";
import { ISideBarMenu } from "../../interfaces/menu";
import { onOpenEKYC, onOpenRequestForm } from "../../pages/MyAccount/helper";
import AuthenService from "../../services/authen.service";
import { DynamicDropdown, IDynamicDropdownOption } from "../DynamicDropdown";
import IconSearch from "../IconSearch";
import Language from "./Language";
import { getMyAccountMenu } from "./helper";

export const TopBar = () => {
  const cookie = new Cookies();
  const { t: translate } = useTranslation();
  const { ssoSTTLink } = useUserContext();

  const history = useHistory();
  const { sideBarMenu, userRewards, userInfo } = useUserContext();
  const [toggleMenu, setToggleMenu] = useState(false);

  function handleClickOutOfMenuToggle(
    setToggleMenu: (toggle: boolean) => void
  ) {
    window.addEventListener("click", (event) => {
      const { id } = event.target as HTMLElement;
      if (!id.includes("menu-toggle")) {
        setToggleMenu(false);
      }
    });
    return window.removeEventListener("click", () => {});
  }

  const isLogoutMenu = (value: string) => value === "logout";

  const onSelectMenu = (option: IDynamicDropdownOption) => {
    const [pageName, isAdditionalLang] = option.value.split("-");
    ReactGA.event({
      category: "action_menu",
      action: "user_access",
      label: option.label,
    });
    if (isLogoutMenu(pageName)) {
      onLogout();
    } else {
      switch (pageName) {
        case PageName.ekycSuitTest:
          onOpenEKYC();
          break;
        case PageName.ChangePersonalData:
          onOpenRequestForm();
          break;
        default:
          ssoSTTLink.openLink({
            pageName: pageName!,
            isAdditionalLang: isAdditionalLang!,
            isPopup: true,
          });
          break;
      }
    }
  };

  const handleClickSidebar = async (
    e: React.MouseEvent,
    menu: ISideBarMenu
  ) => {
    e.preventDefault();
    if (menu.path === PATH_ROUTE.STREAMING.path) {
      ssoSTTLink.openStreamingLink();
    } else {
      history.push(menu.path);
    }
    setToggleMenu(false);
  };

  useEffect(() => {
    return handleClickOutOfMenuToggle(setToggleMenu);
  }, []);

  const onLogout = () => {
    try {
      cookie.remove(COOKIE_AUTH);
      new AuthenService().logout();
      login();
    } catch {}
  };

  const ListMenu = ({
    title,
    readOnly = false,
    onClick = () => {},
  }: {
    title: string;
    readOnly?: boolean;
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  }) => {
    return (
      <div
        id="menu-toggle-item"
        className={`flex h-14 p-4 ${
          !readOnly && "hover:bg-sidebar-bg cursor-pointer"
        }`}
        onClick={onClick}
      >
        {title}
      </div>
    );
  };

  const Greeting = () => {
    return (
      <div
        id="greeting"
        className="flex items-center text-xs text-black sm:text-base"
      >
        <span>Welcome,</span>
        <DynamicDropdown
          showAll
          theme="white"
          label={userRewards.customerNameEN}
          noBorder={true}
          options={getMyAccountMenu(translate)}
          selectedOption={{ label: "", value: "" }}
          onSelected={onSelectMenu}
          disable={!userInfo.username}
        />
      </div>
    );
  };

  return (
    <>
      <div
        id="desktop-topbar"
        className="items-center justify-between hidden px-6 ml-64 text-gray-700 bg-white border-l shadow-sm h-14 xl:flex border-dbsv-neutral-slate-10"
      >
        <div className="flex justify-between w-full">
          <Greeting />
          <div id="rightMenu" className="flex items-center gap-x-8">
            <IconSearch />
            <Language />
          </div>
        </div>
      </div>
      <div
        id="mobile-topbar"
        className="flex items-center justify-between text-gray-700 bg-white shadow-sm xl:hidden h-14 ml-14"
      >
        <div className="flex items-center justify-between w-full h-full pr-4">
          <div className="flex items-center h-full">
            <div className="w-px h-full mr-3 bg-dbsv-neutral-slate-10" />
            <Greeting />
          </div>
          <div className="flex items-center gap-x-4">
            <IconSearch />
            <Language />
          </div>
        </div>
        <div
          className={`sm:hidden absolute block w-full overflow-y-scroll bg-sidebar-bg bg-opacity-90
          ${
            toggleMenu ? "opacity-100" : "opacity-0 hidden"
          } text-white font-light`}
        >
          {sideBarMenu().map((route, index) => (
            <ListMenu
              title={translate(route.name)}
              key={index}
              onClick={(e) => handleClickSidebar(e, route)}
            />
          ))}
        </div>
      </div>
    </>
  );
};
