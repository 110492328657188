import { AccountType } from "../../../enum/account";
import { IConfirmationReportDetail } from "../../../interfaces/confirmation.report.detail";
import { IConfirmationReportList } from "../../../interfaces/confirmation.report.lists";
import { IContractNoteDetail } from "../../../interfaces/contract.note";
import {
  IDailyCashMovement,
  IDailyStockMovement,
} from "../../../interfaces/daily-movment";
import { IEStatementResponse } from "../../../interfaces/e-statement";
import { AccountBTFType } from "./AccountBTFType";
import { AccountDefaultType } from "./AccountDefaultType";
import { AccountFRACType } from "./AccountFRACType";
import { AccountFundType } from "./AccountFundType";

export interface InvestmentShorcutProps {
  accountNo?: string;
  accountType?: AccountType;
  dailyCashMovement?: IDailyCashMovement;
  dailyStockMovement?: IDailyStockMovement;
  dailyConfirmation?: {
    confirmation: IConfirmationReportList;
    confirmationDetails: IConfirmationReportDetail[];
  };
  dailyContractNote?: IContractNoteDetail;
  eMonthlyStatement?: IEStatementResponse;
  onOpenStreaming: () => void;
  onOpenCashDeposit: () => void;
  onOpenCashWithdrawal: () => void;
  onOpenDailyConfirmation: () => void;
  onOpenDailyContractNote: () => void;
  onDownloadEStatement: () => void;
  onOpenConsolidatePort: () => void;
}

export const InvestmentShortcutByAccountType: React.FC<
  InvestmentShorcutProps
> = ({
  accountNo = "",
  accountType,
  dailyCashMovement,
  dailyStockMovement,
  dailyConfirmation,
  dailyContractNote,
  eMonthlyStatement,
  onOpenStreaming,
  onOpenCashDeposit,
  onOpenCashWithdrawal,
  onOpenDailyConfirmation,
  onOpenDailyContractNote,
  onDownloadEStatement,
  onOpenConsolidatePort,
}) => {
  if (accountType === AccountType.FUND) {
    return (
      <AccountFundType
        eMonthlyStatement={eMonthlyStatement}
        accountNo={accountNo}
        accountType={accountType}
      />
    );
  } else if (accountType === AccountType.FRAC) {
    return (
      <AccountFRACType
        accountNo={accountNo}
        accountType={accountType}
        onOpenStreaming={onOpenStreaming}
      />
    );
  } else if (accountType === AccountType.BTF) {
    return (
      <AccountBTFType
        accountNo={accountNo}
        accountType={accountType}
        onOpenConsolidatePort={onOpenConsolidatePort}
      />
    );
  } else {
    return (
      <AccountDefaultType
        accountNo={accountNo}
        accountType={accountType}
        dailyCashMovement={dailyCashMovement}
        dailyStockMovement={dailyStockMovement}
        dailyConfirmation={dailyConfirmation}
        dailyContractNote={dailyContractNote}
        eMonthlyStatement={eMonthlyStatement}
        onOpenStreaming={onOpenStreaming}
        onOpenCashDeposit={onOpenCashDeposit}
        onOpenCashWithdrawal={onOpenCashWithdrawal}
        onOpenDailyConfirmation={onOpenDailyConfirmation}
        onOpenDailyContractNote={onOpenDailyContractNote}
        onDownloadEStatement={onDownloadEStatement}
      />
    );
  }
};
