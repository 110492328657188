import { useTranslation } from "react-i18next";
import { SpecialMenuIcon } from "../../components/SubMenuIcon/special-menu-icon";
import { SubMenuIcon } from "../../components/SubMenuIcon/sub-menu-icon";
import { Title } from "../../components/Title";
import { useUserContext } from "../../context/user/user.context";
import { PageName } from "../../enum/page.name";
import { IMenu, ISpecialMenu, ISubMenu } from "../../interfaces/menu";
import { onOpenDDR, onOpenEKYC, onOpenRequestForm } from "./helper";

const MyAccount = () => {
  const { t: translate } = useTranslation();
  const { myAccountSubMenu, ssoSTTLink } = useUserContext();

  const myAccountMenu: {
    myCorner: IMenu[];
    accountManagement: IMenu[];
  } = myAccountSubMenu();

  const onClick = async (subMenu: ISubMenu) => {
    switch (subMenu.pageName) {
      case PageName.ekycSuitTest:
        onOpenEKYC();
        break;
      case PageName.ChangePersonalData:
        onOpenRequestForm();
        break;
      case PageName.DirectDebitRegistration:
        onOpenDDR()
        break;
      default:
        ssoSTTLink.openLink({
          pageName: subMenu.pageName!,
          isAdditionalLang: subMenu.isAdditionalLang!,
          isPopup: true,
        });
        break;
    }
  };

  function buildmyCornerMenuIcon() {
    return myAccountMenu.myCorner.map((subMenu) => {
      return subMenu.isSpecialMenu ? (
        <SpecialMenuIcon specialMenu={subMenu.menuDetail as ISpecialMenu} />
      ) : (
        <SubMenuIcon
          imgPath={(subMenu.menuDetail as ISubMenu).imagePath}
          label={translate((subMenu.menuDetail as ISubMenu).name)}
          onClick={() => onClick(subMenu.menuDetail as ISubMenu)}
        />
      );
    });
  }

  function buildAccountManagementMenuIcon() {
    return myAccountMenu.accountManagement.map((subMenu) => {
      return subMenu.isSpecialMenu ? (
        <SpecialMenuIcon specialMenu={subMenu.menuDetail as ISpecialMenu} />
      ) : (
        <SubMenuIcon
          imgPath={(subMenu.menuDetail as ISubMenu).imagePath}
          label={translate((subMenu.menuDetail as ISubMenu).name)}
          onClick={() => onClick(subMenu.menuDetail as ISubMenu)}
        />
      );
    });
  }

  return (
    <div className="flex flex-col p-4 gap-y-8">
      {myAccountMenu.myCorner.length ? (
        <div
          id="my-cornor"
          className="px-6 py-4 card-bg md:col-span-5 xl:col-span-4"
        >
          <Title title="My Corner" />
          <div className="grid items-start justify-center grid-cols-2 m-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 md:m-6 lg:m-12 gap-y-8">
            {buildmyCornerMenuIcon()}
          </div>
        </div>
      ) : null}
      {myAccountMenu.accountManagement.length ? (
        <div
          id="my-account-menagement"
          className="px-6 py-4 card-bg md:col-span-5 xl:col-span-4"
        >
          <Title title="Account Management" />
          <div className="grid items-start justify-center grid-cols-2 m-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 md:m-6 lg:m-12 gap-y-8">
            {buildAccountManagementMenuIcon()}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default MyAccount;
