export type Target = "_self" | "_blank" | "_parent" | "_top";

export const openWindowWithBlankPage = ({
  link,
  isPopup = true,
}: {
  link: string;
  isPopup?: boolean;
}) => openWindow({ link, isPopup, target: "_blank" });

export const openWindow = ({
  link,
  isPopup,
  target,
}: {
  link: string;
  isPopup?: boolean;
  target?: Target;
}) => {
  const eightyPercentOfWidth = window.innerWidth * 0.8;
  const eightyPercentOfHeight = window.innerHeight * 0.8;
  const tenPercentOfWidth = window.innerWidth * 0.1;
  const tenPercentOfHeight = window.innerHeight * 0.1;

  const newWindow = window.open(
    link,
    target,
    isPopup
      ? `width=${eightyPercentOfWidth},height=${eightyPercentOfHeight},left=${tenPercentOfWidth},top=${tenPercentOfHeight}`
      : undefined
  );

  if (
    !newWindow ||
    newWindow.closed ||
    typeof newWindow.closed == "undefined"
  ) {
    alert(
      "This browser was blocking popups. To continue opening the page  please allow popups and try again"
    );
  }
  return newWindow;
};
