import { useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { AccountRewardDetail } from "../../components/CustomerPointDetail/AccountPointDetail";
import { PointOptionDropdown } from "../../components/CustomerPointDetail/PointOptionDropdown";
import { IDynamicDropdownOption } from "../../components/DynamicDropdown";
import { useUserContext } from "../../context/user/user.context";
import { IUserPoint } from "../../interfaces/user";

export enum PointOption {
  AccountType = "accountType",
  AccountNo = "accountNo",
}

export const PointOptionLabel = (
  translate: TFunction
): IDynamicDropdownOption[] => {
  return [
    {
      label: translate("rewards.point_details.options_account_type"),
      value: PointOption.AccountType,
    },
    {
      label: translate("rewards.point_details.options_account_no"),
      value: PointOption.AccountNo,
    },
  ];
};

export const PointDetails = ({
  setAccountsDetail,
  setShowPointDetailModal,
}: {
  setAccountsDetail: (accountsDetail: IUserPoint[]) => void;
  setShowPointDetailModal: (showModal: boolean) => void;
}) => {
  const { t: translate } = useTranslation();
  const { userRewards, accountInfo } = useUserContext();
  const [selectOption, setSelectOption] = useState(
    PointOptionLabel(translate)[0]
  );
  return (
    <div className="w-full pt-6 rounded-lg bg-dbsvRewards-bgCard">
      <div className="flex flex-col px-6 border-b border-grey-500">
        <div className="text-xl font-light text-white">
          {translate("rewards.point_details.title")}
        </div>
        <div className="my-2 text-sm text-gray-400">
          {translate("rewards.point_details.description")}
        </div>
      </div>
      <div className="flex justify-end px-6 py-4 text-white">
        <PointOptionDropdown
          selectedOption={PointOptionLabel(translate)[0]}
          options={PointOptionLabel(translate)}
          onSelect={(option) => setSelectOption(option)}
        />
      </div>
      {accountInfo.accounts.length && (
        <AccountRewardDetail
          accounts={userRewards.accounts}
          option={selectOption}
          setAccountsDetail={(accountsDetail) =>
            setAccountsDetail(accountsDetail)
          }
          setShowPointDetailModal={(isShow) => setShowPointDetailModal(isShow)}
        />
      )}
    </div>
  );
};
