const MissionCard = ({
  index,
  title,
  icon,
  onClick,
}: {
  index: number;
  title: string;
  icon: string;
  onClick: () => void;
}) => {
  return (
    <div
      onClick={onClick}
      data-testid={`mission-card-${index}`}
      className={`flex px-5 py-2 gap-x-5 bg-white rounded-lg cursor-pointer shadow-xl`}
    >
      <img
        src={icon}
        className="w-8 h-8 p-1 border rounded-full border-slate-30"
        alt="mission-icon"
      />
      <div className="flex flex-col min-w-0 text-sm font-bold gap-y-1">
        <h1
          className="text-missionTitle"
          data-testid={`mission-card-index-${index}`}
        >
          Mission {index + 1}
        </h1>
        <h2
          data-testid={`mission-card-title-${index}`}
          className="font-bold truncate whitespace-pre-line"
        >
          {title}
        </h2>
      </div>
    </div>
  );
};

export default MissionCard;
