import { OverseaResearch } from "../components/LatestResearch/OverseaResearch";
import { ThaiResearch } from "../components/LatestResearch/ThaiResearch";
import { MyInvestment } from "../components/MyInvestment";
import { Shortcuts } from "../components/Shortcuts";

export const Dashboard = () => {
  return (
    <div className="p-4">
      <div className="py-2 border-b border-gray-300 sm:mb-4">
        <Shortcuts />
      </div>
      <MyInvestment />
      <div className="grid gap-6 mt-6 grid-rows-auto md:grid-cols-10 xl:grid-cols-12">
        <div className="px-6 py-4 card-bg md:col-span-5 xl:col-span-6">
          <ThaiResearch />
        </div>
        <div className="px-6 py-4 card-bg md:col-span-5 xl:col-span-6">
          <OverseaResearch />
        </div>
      </div>
    </div>
  );
};
