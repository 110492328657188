import { useTranslation } from "react-i18next";
import { IDisclaimer } from "../../interfaces/cms";
import { IOverseasPortfolio } from "../../services/overseas-trading";
import { OverseasPortfolio } from "../MyInvestment/OverseasPortfolio";

export const ConsolidatedPortfolioDialog = ({
  portfolio,
  disclaimer,
  username,
  isConsolidateUnconnectable,
  onClick,
}: {
  portfolio: IOverseasPortfolio;
  disclaimer: IDisclaimer;
  username: string;
  isConsolidateUnconnectable: boolean;
  onClick: () => void;
}) => {
  const { t: translate } = useTranslation();
  return (
    <div className="fixed inset-0 z-30 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen p-4 text-center opacity-100 sm:block sm:p-0">
        <div
          className="fixed inset-0 transition-opacity"
          aria-hidden="true"
          onClick={() => {}}
        >
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div
          className="inline-block py-5 overflow-hidden text-left transition-all transform bg-white rounded-lg sm:align-middle"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="flex justify-between px-5">
            <div className="flex flex-col text-base font-bold md:text-2xl">
              {translate("myInvestment.accountType.BTF")}
            </div>
            <div
              className="w-4 h-4 cursor-pointer md:w-5 md:h-5"
              onClick={onClick}
            >
              <img src="/icons/my-account/close.png" alt="close" />
            </div>
          </div>
          <div className="px-5 mt-2">
            <OverseasPortfolio
              disclaimer={disclaimer!}
              portfolio={portfolio!}
              username={username}
              isConsolidateUnconnectable={isConsolidateUnconnectable}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
