import { AccountType } from "../../../enum/account";
import { SVGGlobalIcon } from "../../SVGIcon/global";
import { SVGGraphIcon } from "../../SVGIcon/graph";
import { SVGMoneyBoxIcon } from "../../SVGIcon/pig";
import { SVGProfitIcon } from "../../SVGIcon/profit";
import { SVGWorldIcon } from "../../SVGIcon/world";

export const MyInvestmentTabMenu = ({
  label,
  accountNo,
  active,
  onSelect,
  type,
  testId,
}: {
  label: string;
  accountNo: string;
  active: boolean;
  type: AccountType;
  testId: string;
  onSelect: () => void;
}) => {
  const AccountTabProps = {
    className: "w-8 hidden sm:block",
    active,
  };
  const getAccountTabIcon = () => {
    switch (type) {
      case AccountType.ITP:
        return <SVGGraphIcon {...AccountTabProps} />;
      case AccountType.DERIV:
        return <SVGProfitIcon {...AccountTabProps} />;
      case AccountType.FUND:
        return <SVGMoneyBoxIcon {...AccountTabProps} />;
      case AccountType.FRAC:
        return <SVGWorldIcon {...AccountTabProps} />;
      case AccountType.BTF:
        return <SVGGlobalIcon {...AccountTabProps} />;
    }
  };

  return (
    <div
      onClick={onSelect}
      data-testid={testId}
      className={`rounded-t flex ${
        active
          ? "bg-primary-500 text-white border-b-4 border-primary-700"
          : "bg-gray-200 text-gray-700"
      } items-center gap-x-4 px-4 py-1 cursor-pointer h-22`}
    >
      {getAccountTabIcon()}
      <div className="flex flex-col w-28">
        <h1>{label}</h1>
        <p data-testid={`${testId}-account-no`}>{accountNo}</p>
      </div>
    </div>
  );
};
