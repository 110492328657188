import { useEffect } from "react";
import { useState } from "react";

export const FilterAccount = ({
  accounts,
  onSelectAccount,
}: {
  accounts: string[];
  onSelectAccount: (account: string) => void;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [account, setAccount] = useState<string>("");

  useEffect(() => {
    if (account === "") setAccount("All Account");
  }, [account]);

  useEffect(() => {
    document.addEventListener("click", (e: MouseEvent) => {
      const { id } = e.target as HTMLElement;
      if (!id.includes("filter-account")) {
        setOpen(false);
      }
    });
    return document.removeEventListener("click", () => {});
  });

  return (
    <div id="filter-account" className="relative w-40 select-none">
      <div
        id="filter-account-dropdown"
        className="flex w-full px-2 py-2 border border-gray-500 justify-between cursor-pointer"
        onClick={() => setOpen(!open)}
      >
        <div id="filter-account-label" className="w-max font-light">
          {account}
        </div>
        <svg
          id="filter-account-icon"
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1}
            d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
          />
        </svg>
      </div>
      <div
        id="filter-account-list"
        className={`${
          open ? "" : "hidden"
        } absolute z-10 w-full max-h-44 min-h-full bg-dropdown-500 text-white shadow-md overflow-auto`}
      >
        <div
          id="filter-account-item-all"
          className="py-2 px-4 cursor-pointer hover:bg-dropdown-600"
          onClick={() => {
            setOpen(false);
            setAccount("");
            onSelectAccount("");
          }}
        >
          All Account
        </div>
        {accounts?.map((account, index) => {
          return (
            <div
              key={`filter-account-${index}`}
              id={`filter-account-item-${index}`}
              className="py-2 px-4 cursor-pointer hover:bg-dropdown-600"
              onClick={() => {
                setOpen(false);
                setAccount(account);
                onSelectAccount(account);
              }}
            >
              {account}
            </div>
          );
        })}
      </div>
    </div>
  );
};
