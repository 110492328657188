import { useTranslation } from "react-i18next";
import { PageName } from "../../enum/page.name";
import SSOSTTLink from "../../helper/sso.stt.link";
import { Title } from "../Title";
import { SubMenuIcon } from "../SubMenuIcon/sub-menu-icon";

export const TotalDealSummary = () => {
  const { t: translation } = useTranslation();

  const viewTotalDealSummray = () => {
    return new SSOSTTLink({ userref: "" }).openLink({
      pageName: PageName.TotalDealSummary,
      isPopup: true,
      isAdditionalLang: false,
    });
  };
  const viewPortfolio = () => {
    return new SSOSTTLink({ userref: "" }).openLink({
      pageName: PageName.TradingPortfolio,
      isPopup: true,
      isAdditionalLang: false,
    });
  };

  const totalDealSummaryMenu = [
    {
      label: translation("statement_report.total_deal_summary.deal_summary"),
      imgPath: "/icons/statement-report/total_deal_sum.svg",
      onClick: viewTotalDealSummray,
    },
    {
      label: translation("statement_report.total_deal_summary.portfolio"),
      imgPath: "/icons/statement-report/portfoilo_data.svg",
      onClick: viewPortfolio,
    },
  ];

  return (
    <div className="px-6 py-4 card-bg md:col-span-5 xl:col-span-4">
      <Title title={translation("statement_report.total_deal_summary.title")} />
      <div className="grid items-start justify-center grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 my-6 mx-4 gap-10">
        {totalDealSummaryMenu.map((menu, index) => (
          <SubMenuIcon {...menu} key={index} />
        ))}
      </div>
    </div>
  );
};
