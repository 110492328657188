import { useTranslation } from "react-i18next";
import { getDateInFormat } from "../../helper/date.time";
import { currency } from "../../helper/format.number";
import { IUserPoint } from "../../interfaces/user";

export const PointDetailModal = ({
  accounts,
  isShowModel,
}: {
  accounts?: IUserPoint[];
  isShowModel: (isShow: boolean) => void;
}) => {
  const { t: translate } = useTranslation();

  const points = accounts
    ?.map((el) => {
      const pointData = el.pointsDetail.map((point) => ({
        ...point,
        accountNo: el.accountNo,
      }));
      return pointData;
    })
    .flat()
    .sort(
      (a, b) =>
        new Date(b.tradeDate).getTime() - new Date(a.tradeDate).getTime()
    );

  const totalPoint =
    points?.reduce(
      (accumulator, account) => accumulator + account.standardPoint,
      0
    ) ?? 0;
  return (
    <div className="fixed inset-0 z-20 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen p-4 text-center opacity-100 sm:block sm:p-0">
        <div
          className="fixed inset-0 transition-opacity"
          aria-hidden="true"
          onClick={() => {}}
        >
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div
          className="inline-block w-full overflow-hidden text-left transition-all transform bg-white md:w-4/6 lg:w-3/5 sm:my-8 sm:align-middle"
          key="point-detail-modal"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="flex flex-col items-center justify-center h-full pt-4">
            <div className="flex justify-end w-full">
              <div
                className="flex items-center justify-center w-40 h-8 text-white bg-black cursor-pointer"
                onClick={() => isShowModel(false)}
              >
                <div className="flex items-center justify-between w-6 h-6">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={`flex h-5 w-5 text-white`}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
                <div className="flex text-sm font-light">
                  {translate("rewards.point_details.close_modal")}
                </div>
              </div>
            </div>
            <div className="w-full px-8 pb-8 text-xl font-light text-left">
              <div className="text-lg font-semibold">
                {translate("rewards.point_details.title")}
              </div>
            </div>
            <div className="w-full h-96">
              <div className="grid w-full grid-cols-9 py-2 bg-dbsvRewards-tableHeader">
                <div className="col-span-3 col-start-1 text-center text-md">
                  {"Trade Date"}
                </div>
                <div className="col-span-3 col-start-4 text-center text-md">
                  {translate("rewards.point_details.modal_table_account_no")}
                </div>
                <div className="col-span-3 col-start-7 text-center text-md">
                  {"Point"}
                </div>
              </div>
              <div className="overflow-y-scroll h-80">
                {points?.map((point) => (
                  <div
                    className="grid w-full grid-cols-9 py-2 border-b border-gray-300"
                    key="point-detail-list"
                  >
                    <div className="col-span-3 col-start-1 text-center text-md">
                      {getDateInFormat(new Date(point.tradeDate).toISOString())}
                    </div>
                    <div className="col-span-3 col-start-4 text-center text-md">
                      {point.accountNo}
                    </div>
                    <div className="col-span-3 col-start-7 text-center text-md">
                      {currency(point.standardPoint)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex justify-around w-full py-2 bg-dbsvRewards-tableFooter">
              <div className="font-bold">
                {translate("rewards.point_details.total")}
              </div>
              <div className="font-bold underline">{currency(totalPoint)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
