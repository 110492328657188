import { useTranslation } from "react-i18next";
import { ReactComponent as EmptyIcon } from "../../svgIcon/new-dashboard/research/empty.svg";

export const ResearchEmpty = () => {
  const { t: translate } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center">
      <EmptyIcon />
      <div className="text-sm text-dbsv-neutral-slate-60">
        {translate("research.content_not_found")}
      </div>
    </div>
  );
};
