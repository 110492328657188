import { AccountType } from "../../../enum/account";
import { AccountInfo } from "../AccountInfo";
import { AccountMenuItem } from "../AccountMenuItem";

export const AccountBTFType = ({
  accountNo = "",
  accountType,
  onOpenConsolidatePort,
}: {
  accountNo?: string;
  accountType?: AccountType;
  onOpenConsolidatePort: () => void;
}) => {
  const renderBTFAccount = () => {
    return (
      <AccountMenuItem
        type="consolidated-portfolio"
        onClick={onOpenConsolidatePort}
      />
    );
  };

  return (
    <div
      data-testid="account-btf-type"
      className="flex flex-row flex-wrap p-5 pb-0 gap-y-4"
    >
      <AccountInfo accountNo={accountNo} accountType={accountType} />
      <div className="flex flex-row flex-1 gap-4 pb-8 pl-6 overflow-hidden overflow-x-auto">
        {renderBTFAccount()}
      </div>
    </div>
  );
};
