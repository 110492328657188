import { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { APP_ROUTE } from "../../const/path";
import { getDateENInFormat } from "../../helper/date.time";
import { openWindowWithBlankPage } from "../../helper/open.window";
import {
  IContractNoteDataTable,
  IContractNoteDetail,
} from "../../interfaces/contract.note";
import { HeaderModalWithAction } from "../HeaderModal";
import { ModalDetail } from "../ModalDetail";
import {
  getAccountStatement,
  getOutstandingFCCYCollateral,
  getOutstandingPosition,
  getPositionAtLastTradingDay,
  getPositionClosingData,
  getTradingTransactionSummaryData,
} from "./helper";

const ContractNoteModal = ({
  contractNote,
  onClose,
}: {
  contractNote: IContractNoteDetail;
  onClose: () => void;
}) => {
  let contentArea = useRef(null);
  const handlePrint = useReactToPrint({
    onAfterPrint: () => setPrinting(false),
    content: () => contentArea.current,
  });

  const [printing, setPrinting] = useState<boolean>(false);

  const BuildHeader = () => {
    const GetLabel = ({ label }: { label: string }) => (
      <p className="font-medium">{label}</p>
    );

    const GetValue = ({ value }: { value: string }) => (
      <p className="font-light">{value}</p>
    );
    const headerData = [
      [{ label: "Name", value: "" }],
      [
        { label: "Account No.", value: contractNote.header.accountNo },

        { label: "Accunt Type.", value: contractNote.header.accountType },

        { label: "Account Office", value: contractNote.header.aeCode },
      ],
      [
        { label: "Settlement No.", value: contractNote.header.settlementNo },
        {
          label: "Trading Date.",
          value: getDateENInFormat(contractNote.header.tradeDate),
        },
        {
          label: "Settlement Date.",
          value: getDateENInFormat(contractNote.header.settlementDate),
        },
      ],
    ];

    return (
      <div className="flex flex-col justify-between lg:flex-row">
        {headerData.map((header) => {
          return (
            <div>
              {header.map((item) => {
                return (
                  <div className="grid grid-cols-2">
                    <GetLabel label={item.label} />
                    <GetValue value={item.value} />
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  };

  const BuildTradingTransactionSummary = () => {
    const { data, summary } = getTradingTransactionSummaryData(
      contractNote.trade
    );
    return (
      <div className="flex flex-col mt-4">
        <GetHeaderSection label="TRADING TRANSACTION SUMMARY" />
        <ContractNoteTable
          className="mt-4"
          totalLength={5}
          header={[
            { label: "Series" },
            { label: "Contract No" },
            { label: "Buy/Sell" },
            { label: "No. of Contract" },
            { label: "Deal Price" },
            { label: "Premium Amount" },
            { label: "Brokerage Fee" },
            { label: "VAT" },
            { label: "WH" },
            { label: "Charge Amount\n(+/-)" },
          ]}
          data={data}
          totalRowData={summary}
        />
      </div>
    );
  };

  const BuildPositionClosing = () => {
    const { data, summary } = getPositionClosingData(
      contractNote.posClose,
      contractNote.plClose
    );
    return (
      <div className="flex flex-col mt-4">
        <GetHeaderSection label="POSITION CLOSING" />
        <ContractNoteTable
          className="mt-4"
          mergeHeaderLabel="Realized P/L"
          totalLength={5}
          header={[
            { label: "Series" },
            { label: "Trade Date" },
            { label: "Long" },
            { label: "Short" },
            { label: "Deal Price" },
            { label: "Future" },
            { label: "Option" },
          ]}
          data={data}
          totalRowData={summary}
        />
      </div>
    );
  };

  const BuildPositionAtLastTradingDay = () => {
    const { data, summary } = getPositionAtLastTradingDay(
      contractNote.lastTrade
    );
    return (
      <div className="flex flex-col mt-4">
        <GetHeaderSection label="POSITION AT LAST TRADING DAY" />
        <ContractNoteTable
          className="mt-4"
          totalLength={4}
          header={[
            { label: "Action/Series" },
            { label: "Long/Short" },
            { label: "No. of Contract" },
            { label: "Final Settlement Price" },
            { label: "Settlement Amount" },
            { label: "Brokerage Fee" },
            { label: "VAT" },
            { label: "WH" },
            { label: "Charge Amount" },
          ]}
          data={data}
          totalRowData={summary}
        />
      </div>
    );
  };

  const BuildOutstandingPosition = () => {
    const { data, summary } = getOutstandingPosition(contractNote.outstanding);
    return (
      <div className="flex flex-col mt-4">
        <GetHeaderSection
          label={`OUTSTANDING POSITION AS OF ${getDateENInFormat(
            contractNote.header.tradeDate
          )}`}
        />
        <ContractNoteTable
          className="mt-4"
          totalLength={5}
          header={[
            { label: "Series" },
            { label: "Long" },
            { label: "Short" },
            { label: "Avg. Cost Price" },
            { label: "Settlement Price" },
            { label: "Unrealized P&L" },
            { label: "Long" },
            { label: "Short" },
          ]}
          mergeHeaderLabel="Options Value"
          data={data}
          totalRowData={summary}
        />
      </div>
    );
  };

  const BuildFCCYCollateral = () => {
    const { data, summary } = getOutstandingFCCYCollateral(
      contractNote.fccyCollaterals
    );
    return (
      <div className="flex flex-col mt-4">
        <GetHeaderSection label="OUTSTANDING FOREIGN CURRENCY COLLATERAL" />
        <ContractNoteTable
          className="mt-4"
          totalLength={5}
          header={[
            { label: "CCY" },
            { label: "Amount" },
            { label: "Fx Rate" },
            { label: "Value in THB" },
            { label: "Col%" },
            { label: "Max. For Settlement Require", length: 3 },
          ]}
          data={data}
          totalRowData={summary}
        />
      </div>
    );
  };

  const BuilStatementOfAccount = () => {
    const { first, second, third } = getAccountStatement(
      contractNote.statement
    );
    return (
      <div className="flex flex-col mt-4">
        <GetHeaderSection
          label={`STATEMENT OF ACCOUNT AS OF ${getDateENInFormat(
            contractNote.header.tradeDate
          )}`}
        />
        <ContractNoteTable
          className="mt-4"
          header={[
            { label: "Begin-Cash Bal" },
            { label: "End-Cash Bal" },
            { label: "Non Cash Collateral" },
            { label: "Realized P&L" },
            { label: "Unrealized P&L" },
            { label: "Advance" },
          ]}
          data={first}
        />
        <ContractNoteTable
          header={[
            { label: "Begin-Equity Bal" },
            { label: "End-Equity Bal" },
            { label: "Initial Margin" },
            { label: "Maintenance Margin" },
            { label: "Excess/Insufficient" },
            { label: "Net Customer Paid \n(Call/Force Margin)" },
          ]}
          data={second}
        />
        <ContractNoteTable
          header={[
            { label: "Deferred Charge" },
            { label: "" },
            { label: "" },
            { label: "" },
            { label: "" },
            { label: "" },
          ]}
          data={third}
        />
      </div>
    );
  };

  const onOpenDescription = () => {
    const currentPath = window.location.pathname;
    openWindowWithBlankPage({
      link: window.location.href.replace(
        currentPath,
        APP_ROUTE.CONTRACT_NOTE_HELP.path
      ),
      isPopup: false,
    });
  };

  const onPrintContractNoteDetail = () => {
    setPrinting(true);
    setTimeout(() => {
      if (handlePrint) handlePrint();
    }, 1);
  };

  return (
    <ModalDetail
      contentStyle=""
      closeModal={() => onClose()}
      chlidren={
        <>
          <div ref={contentArea} className="flex flex-col justify-center p-8">
            <HeaderModalWithAction
              onPrinting={printing}
              title="Contract Note"
              action={
                <>
                  <ActionButton
                    onClick={onOpenDescription}
                    actionName="Description"
                    iconPath="/icons/book.svg"
                  />
                  <ActionButton
                    onClick={onPrintContractNoteDetail}
                    actionName="Print"
                    iconPath="/icons/printer.svg"
                  />
                </>
              }
            />
            <BuildHeader />
            <BuildTradingTransactionSummary />
            <BuildPositionClosing />
            <BuildPositionAtLastTradingDay />
            <BuildOutstandingPosition />
            <BuildFCCYCollateral />
            <BuilStatementOfAccount />
          </div>
        </>
      }
    />
  );
};

export const ActionButton = ({
  actionName,
  iconPath,
  onClick,
}: {
  onClick: () => void;
  actionName: string;
  iconPath: string;
}) => {
  return (
    <div
      className="flex px-5 py-2 border border-gray-500 rounded cursor-pointer w-max gap-x-3"
      onClick={onClick}
    >
      <img src={iconPath} className="w-4" alt="action-button-icon" />
      <span className="font-light text-center">{actionName}</span>
    </div>
  );
};

export const GetHeaderSection = ({ label }: { label: string }) => {
  return <div className="w-full px-2 text-sm bg-gray-300">{label}</div>;
};

export const ContractNoteTable = ({
  className,
  header,
  data,
  totalLength,
  totalRowData,
  mergeHeaderLabel,
}: {
  className?: string;
  header: { length?: number; label: string }[];
  data: IContractNoteDataTable[];
  totalRowData?: any[];
  totalLength?: number;
  mergeHeaderLabel?: string;
}) => {
  const newHeader = mergeHeaderLabel
    ? header.slice(0, header.length - 2)
    : header;
  const mergeHeader = header.slice(newHeader.length, header.length);

  const BuildTotalRow = () => {
    return (
      <tr className="border-b-2 border-l-2 border-r-2 border-gray-300">
        <td colSpan={totalLength} className="pr-2 text-right bg-gray-200">
          Total
        </td>
        {totalRowData!.map((item, index) => (
          <td
            className={`text-center font-light col-span-${
              header[totalLength! + index]?.length ?? 1
            }`}
            key={`total-row-value-${index}`}
          >
            {item}
          </td>
        ))}
      </tr>
    );
  };

  const buildHeader = (
    item: { length?: number; label: string },
    key: string | number,
    isMergeHeader: boolean = false
  ) => {
    return (
      <th
        rowSpan={isMergeHeader ? 1 : 2}
        colSpan={item.length}
        className="text-center whitespace-pre-line"
        key={`header-${key}`}
      >
        {item.label}
      </th>
    );
  };

  return (
    <div className="overflow-auto text-xs">
      <table className={`w-full ${className}`}>
        <thead className="py-1 bg-gray-200 border-t-2 border-l-2 border-r-2 border-gray-300">
          <tr>
            {newHeader.map((item, index) => buildHeader(item, index))}
            {mergeHeaderLabel &&
              buildHeader(
                { length: mergeHeader.length, label: mergeHeaderLabel },
                mergeHeaderLabel,
                true
              )}
          </tr>
          {mergeHeaderLabel && (
            <tr>
              {mergeHeader.map((item, index) =>
                buildHeader(item, index + newHeader.length + 1)
              )}
            </tr>
          )}
        </thead>
        <tbody className="border-l-2 border-r-2 border-gray-300">
          {data.map((row, rowIndex) => (
            <tr>
              {row.value.map((value, index) => (
                <td
                  style={{ width: `${100 / row.value.length}%` }}
                  colSpan={newHeader[index]?.length ?? 1}
                  className={`${
                    row.sumRow && totalLength && index < totalLength
                      ? "bg-gray-200"
                      : ""
                  } text-center font-light py-1`}
                  key={`value--${rowIndex}-${index}`}
                >
                  {value}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        {totalRowData && totalLength ? (
          <BuildTotalRow />
        ) : (
          <tr className="border-b-2 border-gray-300 "></tr>
        )}
      </table>
    </div>
  );
};

export default ContractNoteModal;
