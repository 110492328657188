import { DateTime } from "luxon";
import { currentLanguage, isThaiLanguage } from "../i18n/helper";

export const getDateInFormat = (date: string, format?: string) => {
  format = format ?? "dd/MM/yyyy";
  return DateTime.fromISO(date).toFormat(format, {
    locale: isThaiLanguage() ? "th" : "us",
  });
};

export const getDateENInFormat = (date: string, format?: string) => {
  format = format ?? "dd/MM/yyyy";
  return DateTime.fromISO(date).toFormat(format, {
    locale: "us",
  });
};

export const getDateNormalFormat = (date: Date | string) => {
  return new Intl.DateTimeFormat(currentLanguage(), {
    day: "numeric",
    month: "long",
    year: "numeric",
  }).format(new Date(date));
};

export const getDateTimeFormat = (
  date: Date | string,
  option: Intl.DateTimeFormatOptions
) => {
  return new Intl.DateTimeFormat(currentLanguage(), option).format(
    new Date(date)
  );
};

export const getDateWithFormat = ({
  date,
  format = "dd MMM yyyy",
}: {
  date: Date | string;
  format?: string;
}) => {
  if (typeof date === "string") date = new Date(date);
  return DateTime.fromJSDate(date).toFormat(format);
};
