import httpClient from "./http";

export interface IPortfolio {
  stockCode: string;
  volume: number;
  exchangeMarket: string;
  stockFullEngName: string;
  averageCost: number;
  averageValue: number;
  marketPrice: number;
  marketValue: number;
  unrealize: number;
  unrealizePercentage: number;
  asOfDate: string;
  unit: string;
  amountEquivalent: number;
}

export interface ICurrencyRate {
  currencyRate: number;
  currencyName: string;
}

export interface IOverseasPortfolio {
  portfolio: IPortfolio[];
  currencyRate: ICurrencyRate[];
}

export class OverseasTrading {
  private http = httpClient();
  async getPortfolio(): Promise<IOverseasPortfolio> {
    return this.http
      .get<IOverseasPortfolio>(`/overseas-trading/equity-portfolio`)
      .then((res) => res.data)
      .catch();
  }
}

export default OverseasTrading;
