import {
  colorChartOfDoughnutMarket,
  colorChartOfDoughnutStock,
} from "../components/DoughnutChart/doughnut.chart";
import { IChart } from "../interfaces/chart";
import { IOverseasPortfolio, IPortfolio } from "../services/overseas-trading";
export interface IOverseasPortfolioWithExchangeMarket {
  exchangeMarket: string;
  portfolios: IPortfolio[];
}
export interface ISummaryPortfolio {
  totalCostValue: number;
  totalMarketValue: number;
  totalUnrealized: number;
  totalUnrealizePercentage: number;
  totalAmount: number;
}
export interface ISummaryOverseasPortfolioWithExchangeMarket
  extends IOverseasPortfolioWithExchangeMarket {
  summary: ISummaryPortfolio;
}

export interface ICurrencyPairRate {
  currencyPair: string;
  rate: number;
}

class OverseasTradingPortfolioModel {
  constructor(private overseasPortfolio: IOverseasPortfolio) {}

  getPortfolioGroupByMarket(): IOverseasPortfolioWithExchangeMarket[] {
    const result: IOverseasPortfolioWithExchangeMarket[] = [];
    for (const portfolio of this.overseasPortfolio.portfolio) {
      const index = result.findIndex(
        (r) => r.exchangeMarket === portfolio.exchangeMarket
      );
      if (index < 0) {
        result.push({
          exchangeMarket: portfolio.exchangeMarket,
          portfolios: [portfolio],
        });
        continue;
      }
      result[index].portfolios.push(portfolio);
    }
    return result;
  }

  getSummaryPortfolioGroupByMarket(): ISummaryOverseasPortfolioWithExchangeMarket[] {
    const portGroupped = this.getPortfolioGroupByMarket();
    return portGroupped.map((port) => {
      return {
        ...port,
        summary: {
          totalCostValue: port.portfolios.reduce(
            (prev, curr) => prev + curr.averageValue,
            0
          ),
          totalMarketValue: port.portfolios.reduce(
            (prev, curr) => prev + curr.marketValue,
            0
          ),
          totalUnrealized: port.portfolios.reduce(
            (prev, curr) => prev + curr.unrealize,
            0
          ),
          totalUnrealizePercentage: port.portfolios.reduce(
            (prev, curr) => prev + curr.unrealizePercentage,
            0
          ),
          totalAmount: port.portfolios.reduce(
            (prev, curr) => prev + curr.amountEquivalent,
            0
          ),
        },
      };
    });
  }

  getCurrencyExchangeRatePairWithTHB(): ICurrencyPairRate[] {
    return this.overseasPortfolio.currencyRate.map<ICurrencyPairRate>(
      (rate) => {
        return {
          currencyPair: `${rate.currencyName}/THB`,
          rate: rate.currencyRate,
        };
      }
    );
  }

  getTotalAmount(): number {
    return this.getSummaryPortfolioGroupByMarket().reduce((prev, curr) => {
      return prev + curr.summary.totalAmount;
    }, 0);
  }

  rankingAssetAllocationAndGroupingOthers(
    chartData: IChart[],
    viewMode: "Market" | "Stock"
  ): IChart[] {
    const numberOfGroup = 5;
    const sortChartData = chartData.sort(
      (perv, curr) => curr.value - perv.value
    );
    if (sortChartData.length > numberOfGroup) {
      const totalValueOther: number = sortChartData
        .slice(numberOfGroup - 1)
        .reduce((total, entry) => total + entry.value, 0);
      const otherEntry: IChart = { label: "Other", value: totalValueOther };
      const groupOtherData: IChart[] = [
        ...sortChartData.slice(0, numberOfGroup - 1),
        otherEntry,
      ];
      return groupOtherData.map((obj, index) => ({
        ...obj,
        hexColor:
          viewMode === "Market"
            ? colorChartOfDoughnutMarket[index]
            : colorChartOfDoughnutStock[index],
      }));
    }
    return sortChartData.map((obj, index) => ({
      ...obj,
      hexColor:
        viewMode === "Market"
          ? colorChartOfDoughnutMarket[index]
          : colorChartOfDoughnutStock[index],
    }));
  }

  getChartDataUnrealizedByViewMode(viewMode: "Market" | "Stock"): IChart[] {
    if (viewMode === "Market") {
      const groupedData: { [key: string]: number } = {};
      this.overseasPortfolio.portfolio.forEach((item) => {
        if (!groupedData[item.exchangeMarket]) {
          groupedData[item.exchangeMarket] = 0;
        }
        groupedData[item.exchangeMarket] += item.unrealizePercentage;
      });
      return Object.entries(groupedData).map(([label, value]) => ({
        label,
        value,
      }));
    }
    return this.overseasPortfolio.portfolio.map((o) => ({
      label: o.stockCode,
      value: o.unrealizePercentage,
    }));
  }

  getChartDataAssetAllocationByViewMode(
    viewMode: "Market" | "Stock"
  ): IChart[] {
    if (viewMode === "Market") {
      const groupedData: { [key: string]: number } = {};
      this.overseasPortfolio.portfolio.forEach((item) => {
        if (!groupedData[item.exchangeMarket]) {
          groupedData[item.exchangeMarket] = 0;
        }
        groupedData[item.exchangeMarket] += item.amountEquivalent;
      });
      const assetAllocation = Object.entries(groupedData).map(
        ([label, value]) => ({
          label,
          value: (value / this.getTotalAmount()) * 100,
        })
      );
      return this.rankingAssetAllocationAndGroupingOthers(
        assetAllocation,
        viewMode
      );
    }
    return this.rankingAssetAllocationAndGroupingOthers(
      this.overseasPortfolio.portfolio.map((obj) => ({
        label: obj.stockCode,
        value: (obj.amountEquivalent / this.getTotalAmount()) * 100,
      })),
      viewMode
    );
  }
}

export default OverseasTradingPortfolioModel;
