/* eslint-disable react-hooks/exhaustive-deps */
import i18n from "i18next";
import queryString from "query-string";
import { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  DynamicDropdown,
  IDynamicDropdownOption,
} from "../../components/DynamicDropdown";
import ResearchArchiveItems from "../../components/ResearchArchiveItems";
import SearchKeyword from "../../components/SearchKeyword";
import { Title } from "../../components/Title";
import { LIMIT_RESEARCH_ARCHIVE_PER_PAGE } from "../../const/cms";
import { SVG_CHEVRON_DOWN, SVG_CHEVRON_RIGHT } from "../../const/svg.icon";
import { useHandleAPIStateContext } from "../../context/handle-api-state";
import { useResearchContext } from "../../context/research";
import { ResearchType } from "../../enum/research";
import { ICMSResearchItem } from "../../interfaces/cms";
import { IResearch, IResearchCategory } from "../../interfaces/research";
import helper, { filterResearchCategoryOption } from "./helper";

const ResearchArchive = ({ isEService = false }: { isEService?: boolean }) => {
  const {
    getResearchItems,
    getResearchCategory,
    getResearchItemCount,
  }: {
    researches: IResearch[];
    getResearchCategory: () => Promise<IResearchCategory[]>;
    getResearchItemCount: ({
      researchType,
      categoryId,
      text,
    }: {
      researchType: ResearchType;
      categoryId: number;
      text: string;
    }) => Promise<number>;
    getResearchItems: ({
      researchType,
      latest,
      categoryId,
      text,
    }: ICMSResearchItem) => Promise<IResearch[]>;
  } = useResearchContext();

  const { setGettingData }: { setGettingData: (gettingData: boolean) => void } =
    useHandleAPIStateContext();
  const { t: translate } = useTranslation();
  const history = useHistory();

  const [researches, setResearchres] = useState<IResearch[]>([]);
  const listOfResearchType = [
    {
      label: translate("research_archive.all_researches"),
      value: ResearchType.ALL,
    },
    {
      label: translate("research_archive.thai_researches"),
      value: ResearchType.THAI,
    },
    {
      label: translate("research_archive.oversea_researches"),
      value: ResearchType.OVERSEA,
    },
  ];
  const [researchTypeOption, setResearchTypeOption] =
    useState<IDynamicDropdownOption[]>(listOfResearchType);
  const [researchTypeSelected, selectResearchType] =
    useState<IDynamicDropdownOption>(researchTypeOption[0]);

  const initialResearchCategory = {
    label: translate("research_archive.all_categories"),
    value: "0",
  };

  const [researchCategoryOption, setResearchCategoryOption] = useState<
    IDynamicDropdownOption[]
  >([initialResearchCategory]);

  const [categorySelected, selectCategory] = useState<IDynamicDropdownOption>(
    researchCategoryOption[0]
  );

  const [keyword, setKeyword] = useState<string>("");
  const refInput = useRef<HTMLInputElement>(null);

  const [pagination, setPagination] = useState({
    current: 1,
    total: 1,
  });

  useEffect(() => {
    setGettingData(true);
    setResearchCategoryOption([initialResearchCategory]);
    getResearchCategory()
      .then((researchCategoies) => {
        const researchMapped = helper.mapReseachCategory(
          researchCategoies,
          initialResearchCategory
        );
        selectResearchType(
          helper.findResearchType(listOfResearchType, researchTypeSelected)
        );
        selectCategory(
          helper.findResearchCategory(researchMapped, categorySelected)
        );
        setResearchTypeOption(listOfResearchType);
        setResearchCategoryOption(researchMapped);
      })
      .catch(() => setGettingData(false));
  }, [i18n.language]);

  useEffect(() => {
    const { type, category } = queryString.parse(history.location.search);
    const researchTypeSelect = researchTypeOption.find(
      (option) => String(option.value) === (type as string)
    );
    const categorySelect = researchCategoryOption.find(
      (option) => String(option.value) === (category as string)
    );
    if (researchTypeSelect) selectResearchType(researchTypeSelect);
    if (categorySelect) selectCategory(categorySelect);
    fetchResearches({
      category: category as string,
      type: type as string,
      text: keyword,
      currentPage: pagination.current,
    });
  }, [researchCategoryOption]);

  useEffect(() => {
    fetchCountResearches();
  }, [researchTypeSelected, categorySelected, researches]);

  function fetchCountResearches() {
    getResearchItemCount({
      researchType: researchTypeSelected.value as ResearchType,
      categoryId: Number(categorySelected.value),
      text: keyword,
    }).then((count) => {
      const total = helper.calculateTotalPage(
        count,
        LIMIT_RESEARCH_ARCHIVE_PER_PAGE
      );
      setPagination({ ...pagination, total });
    });
  }

  function fetchResearches({
    type,
    category,
    text,
    currentPage,
  }: {
    type: string;
    category: string;
    text: string;
    currentPage: number;
  }) {
    setGettingData(true);
    getResearchItems({
      researchType: type as ResearchType,
      latest: false,
      categoryId: Number(category as string),
      text,
      currentPage: currentPage,
    })
      .then(setResearchres)
      .finally(() => setGettingData(false));
  }

  function onSearch(e: FormEvent) {
    e.preventDefault();
    setPagination({ ...pagination, current: 1 });
    fetchResearches({
      text: keyword,
      type: researchTypeSelected.value as ResearchType,
      category: categorySelected.value,
      currentPage: 1,
    });
    refInput.current?.blur();
  }

  function onSelectResearchType(option: IDynamicDropdownOption) {
    selectResearchType(option);
    selectCategory(initialResearchCategory);
    setPagination({ ...pagination, current: 1 });
    fetchResearches({
      text: keyword,
      type: option.value,
      category: initialResearchCategory.value,
      currentPage: 1,
    });
  }

  function onSelectCategory(option: IDynamicDropdownOption) {
    selectCategory(option);
    setPagination({ ...pagination, current: 1 });
    fetchResearches({
      text: keyword,
      type: researchTypeSelected.value,
      category: option.value,
      currentPage: 1,
    });
  }

  function onClickPageIndex(pageIndex: number) {
    setPagination({ ...pagination, current: pageIndex });
    fetchResearches({
      category: categorySelected.value,
      type: researchTypeSelected.value,
      text: keyword,
      currentPage: pageIndex,
    });
  }

  function onNextPage() {
    const nextPage = pagination.current + 1;
    if (nextPage <= pagination.total) {
      setPagination({
        ...pagination,
        current: pagination.current + 1,
      });
      fetchResearches({
        category: categorySelected.value,
        type: researchTypeSelected.value,
        text: keyword,
        currentPage: nextPage,
      });
    }
  }

  function onKeywordInputChange(e: ChangeEvent) {
    const { value } = e.target as HTMLInputElement;
    setKeyword(value);
  }

  return (
    <div className="p-4">
      <div className="p-6 card-bg">
        <Title title={translate("research_archive.title")} />
        <div className="mt-3 text-sm text-gray-400">
          {translate("research_archive.description")}
        </div>
        <div className="flex flex-col md:flex-row my-8 gap-y-4 md:gap-x-8">
          <DynamicDropdown
            theme="dark"
            options={researchTypeOption}
            selectedOption={researchTypeSelected}
            key="research-archive-research-type"
            icon={<div className="text-red-500">{SVG_CHEVRON_DOWN}</div>}
            onSelected={onSelectResearchType}
          />
          <DynamicDropdown
            theme="dark"
            options={researchCategoryOption.filter((option) =>
              filterResearchCategoryOption(
                option,
                researchTypeSelected,
                initialResearchCategory
              )
            )}
            selectedOption={categorySelected}
            key="research-archive-research-category"
            icon={<div className="text-red-500">{SVG_CHEVRON_DOWN}</div>}
            onSelected={onSelectCategory}
          />
          <SearchKeyword
            keyword={keyword}
            onKeywordInputChange={onKeywordInputChange}
            onSearch={onSearch}
            refInput={refInput}
            setKeyword={setKeyword}
          />
        </div>
        <ResearchArchiveItems researches={researches} isEService={isEService} />
        {researches.length > 0 && (
          <div id="research-archive-pagination" className="flex gap-x-3">
            {buildPageIndex({ pagination, onClick: onClickPageIndex })}
            <NextPageButton onNextPage={onNextPage} />
          </div>
        )}
      </div>
    </div>
  );
};

const NextPageButton = ({ onNextPage }: { onNextPage: () => void }) => (
  <div
    className="flex items-center text-red-500 cursor-pointer"
    onClick={onNextPage}
  >
    {SVG_CHEVRON_RIGHT}
  </div>
);

const buildPageIndex = ({
  pagination,
  onClick,
}: {
  pagination: { current: number; total: number };
  onClick: (pageIndex: number) => void;
}) => {
  return Array.from({ length: pagination.total }, (_, i) => i + 1).map(
    (pageIndex) => (
      <div
        className={`cursor-pointer text-gray-400 hover:text-red-500 hover:underline ${
          pagination.current === pageIndex ? "text-red-500" : ""
        }`}
        onClick={() => onClick(pageIndex)}
      >
        {pageIndex}
      </div>
    )
  );
};

export default ResearchArchive;
