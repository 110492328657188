import { useTranslation } from "react-i18next";
import { accountMenuItems, AccountMenus } from "./helper";

export const AccountItemLabel = ({ type }: { type: AccountMenus }) => {
  const { t: translate } = useTranslation();
  return (
    <div
      data-testid="account-item-label"
      className="text-sm font-bold text-white truncate md:text-xl"
    >
      {translate(accountMenuItems.getKeyAccountItemLabel(type))}
    </div>
  );
};
