import { useEffect, useRef, useState } from "react";
import { getPublicCMSUrl } from "../../helper/cms";
import { ITEM_GAP_X } from "./ResearchCarousel";

export const ResearchItem = ({
  allItemCount,
  title,
  reserachCategory,
  date,
  imageUrl,
  onClick,
}: {
  allItemCount: number;
  title: string;
  reserachCategory: string;
  date: string;
  imageUrl: string;
  onClick: () => void;
}) => {
  const [width, setWidth] = useState<string>("100%");
  const observedDiv = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!observedDiv.current) return () => {};

    const resizeObserver = new ResizeObserver(() => {
      const wideWidth = `${100 / allItemCount}%`;
      setWidth(window.innerWidth < 640 ? "100%" : wideWidth);
    });

    resizeObserver.observe(observedDiv.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [allItemCount, observedDiv?.current?.offsetWidth]);

  return (
    <div
      ref={observedDiv}
      className={`flex flex-col items-center p-5 bg-white w-full rounded-lg cursor-pointer text-dbsv-neutral-slate-80`}
      style={{ width, gap: ITEM_GAP_X }}
      onClick={() => onClick()}
    >
      <img
        className="object-cover w-full h-32 rounded-xl"
        src={getPublicCMSUrl(imageUrl)}
        alt={title}
      />
      <div className="flex flex-col gap-1">
        <div className="w-full px-3 text-xs text-left">
          {reserachCategory} | {date}
        </div>
        <div className="w-full px-3 overflow-hidden text-base font-bold text-left overflow-ellipsis line-clamp-2">
          {title}
        </div>
      </div>
    </div>
  );
};
