/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import ResearchItem from "../../components/ResearchArchiveItem";
import { ScoreRating } from "../../components/ScoreRating";
import { useHandleAPIStateContext } from "../../context/handle-api-state";
import { ResearchType } from "../../enum/research";
import { getDateWithFormat } from "../../helper/date.time";
import { getErrorMessageGetDataFail } from "../../helper/modal.message";
import { openWindow, openWindowWithBlankPage } from "../../helper/open.window";
import { scrollToTopPage } from "../../helper/scrolling";
import { isThaiLanguage } from "../../i18n/helper";
import { IHandleModal } from "../../interfaces/handle.modal";
import { IResearch } from "../../interfaces/research";
import {
  IAverageScore,
  IRatingStar,
  IResearchPDFUrl,
} from "../../interfaces/research.pdf.view";
import CMSService from "../../services/cms.service";
import ResearchService from "../../services/research.service";
import { ReactComponent as DownloadPdfIcon } from "../../svgIcon/download-pdf.svg";
import { ReactComponent as ExpandArrowIcon } from "../../svgIcon/expand-arrow.svg";
import { ReactComponent as PrinterIcon } from "../../svgIcon/printer.svg";
import { ReactComponent as StarIcon } from "../../svgIcon/star.svg";
import { ReactComponent as ViewPdfIcon } from "../../svgIcon/view-pdf.svg";

import { getPathResearchArchive } from "../Research/helper";

const ResearchPDFView = ({ isEService = false }: { isEService?: boolean }) => {
  const {
    setGetDataEror,
    setGettingData,
  }: {
    setGettingData: (gettingData: boolean) => void;
    setGetDataEror: (getDataError: IHandleModal) => void;
  } = useHandleAPIStateContext();
  const researchId = Number(useParams<Record<string, string | undefined>>().id);
  const cmsService = new CMSService();
  const researchService = new ResearchService();
  const { t: translate } = useTranslation();
  const history = useHistory();
  const initialStarRating: IRatingStar[] = [
    { color: "#FFFFFF", stroke: "#999999" },
    { color: "#FFFFFF", stroke: "#999999" },
    { color: "#FFFFFF", stroke: "#999999" },
    { color: "#FFFFFF", stroke: "#999999" },
    { color: "#FFFFFF", stroke: "#999999" },
  ];
  const prefixStarRatingElementId = "star-";

  const [research, setResearch] = useState<IResearch>();
  const [relatedResearchs, setRelatedResearchs] = useState<IResearch[]>();
  const [ratingStars, setRatingStars] =
    useState<IRatingStar[]>(initialStarRating);
  const [researchPDFUrl, setResearchPDFUrl] = useState<IResearchPDFUrl>();
  const [isEnableRating, setEnableRating] = useState<boolean>(true);
  const [fadeProp, setFadeProp] = useState({ style: "" });
  const [isShowText, setIsShowText] = useState<boolean>(false);
  const [averageScore, setAverageScore] = useState<IAverageScore>();

  useEffect(() => {
    initializeData();
  }, [researchId]);

  useEffect(() => {
    const timeout = setInterval(() => {
      if (isShowText) {
        setFadeProp({
          style: "transition-opacity duration-1000 ease-out opacity-0",
        });
      }
    }, 5000);
    return () => clearInterval(timeout);
  }, [isShowText]);

  const getDataByLanguage = ({ th, en }: { th: string; en: string }) => {
    return isThaiLanguage() ? th : en;
  };

  const getAndSetResearchItem = async () => {
    const researchItems = await cmsService.getResearchItemById(researchId);
    setResearch(researchItems);
    return researchItems;
  };

  const getAverageScore = async () => {
    const averageScore = await researchService.getAverageScore(researchId);
    setAverageScore(averageScore);
  };

  const getAndSetRelatedResearch = async (research: IResearch) => {
    const relatedResearchs = await cmsService.getRelatedResearchItem({
      researchType: research.research_category.research_type,
      categoryId: research.research_category.id,
    });
    setRelatedResearchs(relatedResearchs);
  };

  const getAndSetResearchPDFUrl = async (research: IResearch) => {
    const pdfTH = await cmsService.getResearchPDF(research.pdf_file_th[0].url);
    const pdfEN = await cmsService.getResearchPDF(research.pdf_file_en[0].url);
    const urls: IResearchPDFUrl = {
      en: {
        urlPreview: pdfEN.config.baseURL! + pdfEN.config.url!,
        urlDownload:
          pdfEN.config.baseURL! + pdfEN.config.url! + "?action=download",
        urlPrint: window.URL.createObjectURL(
          new Blob([pdfEN.data], { type: "application/pdf" })
        ),
      },
      th: {
        urlPreview: pdfTH.config.baseURL! + pdfTH.config.url!,
        urlDownload:
          pdfTH.config.baseURL! + pdfTH.config.url! + "?action=download",
        urlPrint: window.URL.createObjectURL(
          new Blob([pdfTH.data], { type: "application/pdf" })
        ),
      },
    };
    setResearchPDFUrl(urls);
  };

  const mapStarRating = (ratingScore: number) => {
    const temp: IRatingStar[] = [];
    for (let index = 0; index < ratingStars.length; index++) {
      if (index < ratingScore) {
        const star: IRatingStar = {
          stroke: "#F8E71C",
          color: "#F8E71C",
        };
        temp.push(star);
      } else {
        const star: IRatingStar = {
          stroke: "#999999",
          color: "#FFFFFF",
        };
        temp.push(star);
      }
    }
    setRatingStars(temp);
  };

  const initializeData = async () => {
    setGettingData(true);
    try {
      const _research = await getAndSetResearchItem();
      await getAndSetRelatedResearch(_research);
      await getAndSetResearchPDFUrl(_research);
      await getAverageScore();
      const researchRating = await researchService.getResearchRating(
        researchId
      );
      if (!researchRating) {
        setRatingStars(initialStarRating);
      } else {
        mapStarRating(researchRating.score);
        setEnableRating(false);
      }
      scrollToTopPage();
    } catch (error) {
      setGetDataEror({
        ...getErrorMessageGetDataFail(translate),
        onConfirm: () => history.goBack(),
      });
    } finally {
      setGettingData(false);
    }
  };

  const rateResearch = (ratingScore: number) => {
    if (!isEnableRating) {
      return;
    }
    setIsShowText(true);
    setEnableRating(false);
    researchService
      .rateResearch({ score: ratingScore, researchId })
      .then(() => getAverageScore());
  };

  const printPDF = () => {
    const pdfWindow = openWindowWithBlankPage({
      link: getDataByLanguage({
        en: researchPDFUrl?.en.urlPrint!,
        th: researchPDFUrl?.th.urlPrint!,
      }),
      isPopup: false,
    });
    pdfWindow?.focus();
    pdfWindow?.print();
  };

  const downloadPDF = () => {
    const pdfUrl = getDataByLanguage({
      en: researchPDFUrl?.en.urlDownload!,
      th: researchPDFUrl?.th.urlDownload!,
    });

    fetch(pdfUrl, { credentials: "include" })
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(
          new Blob([blob], { type: "application/pdf" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          isThaiLanguage()
            ? research?.pdf_file_th[0].name ?? ""
            : research?.pdf_file_en[0].name ?? ""
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      })
      .catch((error) => console.error("Error fetching PDF:", error));
  };

  const viewPdf = () => {
    const pdfUrl = getDataByLanguage({
      en: researchPDFUrl?.en.urlPreview!,
      th: researchPDFUrl?.th.urlPreview!,
    });

    const userAgent = window.navigator.userAgent.toLowerCase();
    const safari = /safari/.test(userAgent);
    const ios = /iphone|ipad/.test(userAgent);
    if (ios || safari) {
      fetch(pdfUrl, { credentials: "include" })
        .then((response) => {
          return response.blob();
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(
            new Blob([blob], { type: "application/pdf" })
          );
          openWindow({ link: url, target: "_self" });
        });
    } else {
      openWindow({ link: pdfUrl, target: "_self" });
    }
  };

  const goToResearchArchive = ({
    researchType,
    categoryId,
  }: {
    researchType?: ResearchType;
    categoryId?: number;
  }) => {
    if (!researchType) return;
    history.push(
      getPathResearchArchive({ isEService, researchType, categoryId })
    );
  };

  const ResearchArchiveTypeText = () => {
    return (
      <span
        className="font-light cursor-pointer text-md hover:underline"
        onClick={() => {
          goToResearchArchive({
            researchType: research?.research_category.research_type,
          });
        }}
      >
        {research
          ? translate(
              `research.research_type.${research?.research_category.research_type}`
            )
          : ""}
      </span>
    );
  };

  const ResearchArchiveCategoryText = () => {
    return (
      <span
        className="font-light cursor-pointer text-md hover:underline"
        onClick={() => {
          goToResearchArchive({
            researchType: research?.research_category.research_type,
            categoryId: research?.research_category.id,
          });
        }}
      >
        {getDataByLanguage({
          th: research?.research_category.name_th!,
          en: research?.research_category.name_en!,
        })}
      </span>
    );
  };

  const startRate = () => {
    return ratingStars.map((star, index) => (
      <StarIcon
        key={index}
        stroke={star.stroke}
        color={star.color}
        width={25}
        height={25}
        className={`mx-1 ${
          isEnableRating ? "cursor-pointer animate-up-1" : ""
        }`}
        onMouseEnter={() => {
          if (!isEnableRating) {
            return;
          }
          const ratingScore = index + 1;
          mapStarRating(ratingScore);
        }}
        id={`${prefixStarRatingElementId}${index}`}
        onClick={() => {
          const ratingScore = index + 1;
          rateResearch(ratingScore);
        }}
      />
    ));
  };

  const buildRelatedResearch = () => {
    const filteredResearch = relatedResearchs?.filter(
      (research) => research.id !== researchId
    );
    if (!filteredResearch?.length) return;
    return (
      <div id="related-research-list" className="mt-16">
        <div className="text-center">
          {translate("research_review.related_researchs")}
        </div>
        <div className="pt-8 pb-4 pl-4 pr-16 -mt-1 border-l-4 border-gray-300">
          {filteredResearch.map((relatedResearch, index) => {
            return (
              <ResearchItem
                isEService={isEService}
                key={`relate-research-${index}`}
                research={relatedResearch}
              ></ResearchItem>
            );
          })}
        </div>
      </div>
    );
  };

  const DownLoadPDF = () => (
    <div
      onClick={downloadPDF}
      className="flex items-center p-4 border-b border-gray-300 cursor-pointer group gap-x-5"
    >
      <DownloadPdfIcon className="w-12 h-12" />
      <div className="animate-up-1">
        {translate("research_review.download")}
      </div>
    </div>
  );

  const ViewPDF = () => {
    return (
      <div
        onClick={viewPdf}
        className="flex items-center p-4 cursor-pointer group gap-x-5 justify-center h-full w-full"
      >
        <ViewPdfIcon className="w-12 h-12" />
        <div className="animate-up-1">{translate("research_review.view")}</div>
      </div>
    );
  };

  return (
    <div className="p-4 bg-white">
      <div id="main-card" className="p-6 card-bg">
        <div id="reseach-type-category" className="flex items-center mb-4">
          <ResearchArchiveTypeText />
          <ExpandArrowIcon className="w-3 h-3 mx-2" />
          <ResearchArchiveCategoryText />
        </div>
        <div id="research-title" className="mb-2 text-2xl font-semibold">
          {getDataByLanguage({
            th: research?.title_th!,
            en: research?.title_en!,
          })}
        </div>
        <div
          id="research-date"
          className="mb-8 font-light text-gray-400 text-md"
        >
          {research
            ? getDateWithFormat({
                date: research?.post_date ?? research?.updated_at,
                format: "d MMM yyyy",
              })
            : ""}
        </div>
        <div
          id="page-section"
          className="flex flex-wrap mt-4 sm:flex-col lg:flex-row"
        >
          <div id="pdf-section" className="flex-auto min-h-screen col-span-3">
            {isEService ? (
              <ViewPDF />
            ) : (
              <iframe
                className="w-full h-64-vh lg:h-full"
                title="researchPDF"
                src={getDataByLanguage({
                  th: researchPDFUrl?.th.urlPreview ?? "",
                  en: researchPDFUrl?.en.urlPreview ?? "",
                })}
              />
            )}
          </div>
          <div
            id="menu-section"
            className="flex flex-col justify-center flex-auto mt-8 lg:mt-0 md:ml-8 lg:flex-none lg:justify-start"
          >
            <div className="w-full lg:w-80">
              <div id="rating-box" className="p-8 border border-gray-200">
                <div className="mb-2 lg:text-lg md:font-medium">
                  {translate("research_review.rate_score")}
                </div>
                <div className="mb-4">
                  {averageScore?.score ? (
                    <ScoreRating score={averageScore.score} />
                  ) : (
                    <div className="text-center text-gray-400">
                      {translate("research_review.no_score")}
                    </div>
                  )}
                </div>
                <div className="mb-2">{translate("research_review.rate")}</div>
                <div
                  className="flex justify-center"
                  onMouseLeave={() => {
                    if (!isEnableRating) return;
                    setRatingStars(initialStarRating);
                  }}
                >
                  {startRate()}
                </div>
                <div className="flex justify-center mt-2 text-sm font-light text-gray-400">
                  <div className="w-full h-2 text-center">
                    {isShowText && (
                      <p className={fadeProp.style}>
                        {translate("research_review.feedback")}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              {!isEService && (
                <div id="menu-list" className="mt-8">
                  <DownLoadPDF />
                  <div
                    onClick={printPDF}
                    className="flex items-center p-4 border-b border-gray-300 cursor-pointer group gap-x-5"
                  >
                    <PrinterIcon className="w-12 h-12" />
                    <div className="animate-up-1">
                      {translate("research_review.print")}
                    </div>
                  </div>
                </div>
              )}
              {buildRelatedResearch()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResearchPDFView;
