import { useTranslation } from "react-i18next";

const ContactUs = () => {
  const { t } = useTranslation();

  return (
    <p className="w-full text-xs flex text-center py-3 border-t border-dbsv-neutral-slate-10 justify-center">
      {t("eService.contactUs")}
    </p>
  );
};

export default ContactUs;
