import { isThaiLanguage, setLanguage } from "../../i18n/helper";
import { ReactComponent as LanguageSVG } from "../../svgIcon/glob.svg";

const Language = ({ from }: { from?: undefined | "e-service" }) => {
  if (from === "e-service") {
    return (
      <div
        onClick={() => setLanguage(isThaiLanguage() ? "en" : "th")}
        className="w-10 justify-end -ml-2 flex text-sm items-center cursor-pointer text-dbsv-neutral-slate-40"
      >
        {isThaiLanguage() ? "ไทย" : "EN"}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4"
          id="dynamic-dropdown-icon"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            id="dynamic-dropdown-icon-path"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </div>
    );
  }
  return (
    <>
      <div className="items-center hidden md:flex gap-x-3">
        <LanguageSVG className="text-dbsv-neutral-slate-70" />
        <div className="flex items-center text-xs gap-x-1 text-dbsv-neutral-slate-30">
          <span
            onClick={() => setLanguage("en")}
            className={`cursor-pointer py-2 ${
              !isThaiLanguage() ? "font-bold text-dbsv-neutral-slate-70" : ""
            }`}
          >
            EN
          </span>
          <span>|</span>
          <span
            onClick={() => setLanguage("th")}
            className={`cursor-pointer py-2 ${
              isThaiLanguage() ? "font-bold text-dbsv-neutral-slate-70" : ""
            }`}
          >
            ไทย
          </span>
        </div>
      </div>
      <div
        onClick={() => {
          setLanguage(isThaiLanguage() ? "en" : "th");
        }}
        className="flex text-xs font-bold cursor-pointer md:hidden text-dbsv-neutral-slate-70"
      >
        {isThaiLanguage() ? "ไทย" : "EN"}
      </div>
    </>
  );
};

export default Language;
