import { IBannerSet } from "../../../interfaces/banner";

const Indicators = ({
  bannerSet,
  currentBanner,
  onClick,
}: {
  bannerSet: IBannerSet;
  currentBanner: number;
  onClick: (indnex: number) => void;
}) => {
  return (
    <div
      id="banner-indicator"
      className="absolute flex items-center justify-center w-max bottom-5 gap-x-2"
    >
      {bannerSet.banners.map((_, index) => {
        const active =
          currentBanner === index ? "bg-primary-500" : "bg-gray-400";
        return (
          <div
            className={`w-2 h-2 rounded-full cursor-pointer transition ease-in-out duration-1000 ${active}`}
            onClick={() => onClick(index)}
          ></div>
        );
      })}
    </div>
  );
};

export default Indicators;
