import { useEffect, useState } from "react";
import Bank from "../components/EService/Bank";
import ContactUs from "../components/EService/ContactUs";
import EStatement from "../components/EService/EStatement";
import InvestmentTool from "../components/EService/InvestmentTool";
import ManageAccount from "../components/EService/ManageAccount";
import OverseaInvestment from "../components/EService/OverseaInvestment";
import RewardPoint from "../components/EService/RewardPoint";
import Language from "../components/TopBar/Language";
import YourMissionPanel from "../components/YourMission/YourMissionPanel";
import MissionService, { IMissions } from "../services/ats.service";

const EService = () => {
  const [missions, setMissions] = useState<IMissions[]>([]);

  useEffect(() => {
    new MissionService().getMissions().then((data) => {
      setMissions(data);
    });
  }, []);

  return (
    <>
      <div className="bg-dbsv-neutral-slate-00">
        <nav className="flex items-center justify-between px-4 py-2 bg-white">
          <img
            id="logo"
            alt="logo-mobile-mode"
            src="/favicon.ico"
            className="w-8 h-8"
          />
          <span className="font-bold">DBSV e-Services</span>
          <Language from="e-service" />
        </nav>
        <div className="flex flex-col pt-4 gap-y-6">
          <OverseaInvestment />
          <YourMissionPanel missions={missions} replaceUrl={true} />
          <InvestmentTool />
          <div className="flex flex-col bg-white gap-y-2">
            <RewardPoint />
            <div className="flex flex-col px-4 py-3 gap-y-5">
              <ManageAccount />
              <Bank />
              <EStatement />
            </div>
            <ContactUs />
          </div>
        </div>
      </div>
    </>
  );
};

export default EService;
