import { useState } from "react";
import { useTranslation } from "react-i18next";
import { SVG_CHEVRON_DOWN } from "../../const/svg.icon";
import { isThaiLanguage } from "../../i18n/helper";
import { PointOptionLabel } from "../../pages/Rewards/PointDetails";
import { IDynamicDropdownOption } from "../DynamicDropdown";

export const PointOptionDropdown = ({
  selectedOption,
  options,
  onSelect,
}: {
  selectedOption: IDynamicDropdownOption;
  options: IDynamicDropdownOption[];
  onSelect: (option: IDynamicDropdownOption) => void;
}) => {
  const { t: translate } = useTranslation();
  const [isDropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [option, setOption] = useState<IDynamicDropdownOption>(selectedOption);
  const resultOption = PointOptionLabel(translate).find((each) => {
    return each.value === option.value;
  });
  return (
    <div
      id="point-option-dropdown"
      className={`relative flex py-1 px-3 border rounded-md select-none min-w-max ${
        isThaiLanguage() ? "w-72" : "w-40"
      }`}
    >
      <div
        id="point-option-dropdown"
        className="flex items-center justify-between w-full cursor-pointer"
        onClick={() => setDropdownOpen(!isDropdownOpen)}
      >
        <div className="mr-2 text-sm">
          {resultOption ? resultOption.label : option.label}
        </div>
        <div className="flex items-center w-4 h-4 text-dbsv-brand-dbs-red">
          {SVG_CHEVRON_DOWN}
        </div>
      </div>
      <div
        className={`${
          isDropdownOpen ? "" : "hidden"
        } w-full absolute top-12 right-0 z-10 bg-dbsvRewards-bgCard text-white min-h-full shadow-md overflow-auto rounded-md`}
      >
        {options.map((option, index) => (
          <div
            key={`point-option-dropdown-${index}`}
            className="py-2 pl-4 text-left cursor-pointer"
            onClick={() => {
              setDropdownOpen(false);
              setOption(option);
              onSelect(option);
            }}
          >
            {option.label}
          </div>
        ))}
      </div>
    </div>
  );
};
