import { useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { filterAccount } from "../../helper/accounts";
import { getDateInFormat } from "../../helper/date.time";
import {
  IContractNoteDetail,
  IDerivSTTHeaderNonBsb,
} from "../../interfaces/contract.note";
import ReportService from "../../services/report.service";
import ContractNoteModal from "../ContractNoteModal";
import { FilterAccount } from "../FilterAccount";
import { FilterTradeDate } from "../FilterTradeDate";
import LoadingCard from "../LoadingCard";
import ReportTable, { IReportTableBody } from "../ReportTable";
import { Title } from "../Title";

interface IContractNoteState {
  accountsContractNote: string[];
  contractNote: IDerivSTTHeaderNonBsb[];
  isLoading: boolean;
}

interface IContractNoteAction {
  type: "pending" | "fulfilled" | "rejected";
  payload?: IDerivSTTHeaderNonBsb[];
}

const initialState: IContractNoteState = {
  accountsContractNote: [],
  contractNote: [],
  isLoading: false,
};

const contractNoteReducer = (
  state: IContractNoteState,
  action: IContractNoteAction
): IContractNoteState => {
  switch (action.type) {
    case "pending":
      return { ...state, isLoading: true };
    case "fulfilled":
      return {
        ...state,
        isLoading: false,
        contractNote: action.payload ?? [],
        accountsContractNote: [
          ...filterAccount(action.payload ?? [], state.accountsContractNote),
        ],
      };
    case "rejected":
      return { ...state, isLoading: false };
  }
};

export const ContractNote = () => {
  const { t: translate } = useTranslation();
  const [{ contractNote, accountsContractNote, isLoading }, dispatch] =
    useReducer(contractNoteReducer, initialState);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [accountSelected, setAccountSelected] = useState<string>("");
  const [tradeDate, setTradeDate] = useState<Date | undefined>();
  const [contractNoteDetail, setContractNoteDetail] =
    useState<IContractNoteDetail>();
  const [tableBody, setTableBody] = useState<IReportTableBody[]>([]);

  useEffect(() => {
    getContractNote();
  }, []);

  useEffect(() => {
    setTableBody([
      ...contractNote.reduce((prev, curr) => {
        prev.push({
          datas: [
            { text: curr?.accountNo ?? "-", align: "left" },
            { text: "Contract Note", align: "left" },
            { text: curr.settlementNo, align: "center" },
            { text: getDateInFormat(curr.tradeDate), align: "center" },
            { text: getDateInFormat(curr.settlementDate), align: "center" },
          ],
          action: () => openContractNoteModal(curr),
        });
        return prev;
      }, [] as IReportTableBody[]),
    ]);
  }, [contractNote, translate]);

  const getContractNote = (accounts?: string[], tradeDate?: Date) => {
    dispatch({ type: "pending" });
    new ReportService()
      .getContractNoteSTTHeader(
        tradeDate ? tradeDate.toISOString() : "",
        accounts ?? []
      )
      .then((value) => dispatch({ type: "fulfilled", payload: value }))
      .catch(() => dispatch({ type: "rejected" }));
  };

  const openContractNoteModal = async (contractNote: IDerivSTTHeaderNonBsb) => {
    return new ReportService()
      .getContractNoteDetail(contractNote.settlementNo, contractNote.accountNo)
      .then((contractNOteDetail) => {
        setContractNoteDetail(contractNOteDetail);
        setShowModal(true);
      });
  };

  return (
    <>
      {showModal && (
        <ContractNoteModal
          onClose={() => setShowModal(false)}
          contractNote={contractNoteDetail!}
        />
      )}
      <div className="px-6 py-4 card-bg">
        <Title title="Derivatives Contract Note" />
        <div className="flex mt-6 gap-x-6">
          <FilterAccount
            accounts={accountsContractNote}
            onSelectAccount={(account) => {
              setAccountSelected(account);
              getContractNote(account ? [account] : [], tradeDate);
            }}
          />
          <FilterTradeDate
            date={tradeDate}
            onSelectDate={(date) => {
              setTradeDate(date);
              getContractNote(accountSelected ? [accountSelected] : [], date);
            }}
          />
        </div>
        {isLoading ? (
          <LoadingCard />
        ) : (
          <ReportTable
            labels={[
              { text: "Account No.", align: "left" },
              { text: "Report", align: "left" },
              { text: "Setttlement No.", align: "center" },
              { text: "Trade Date", align: "center" },
              { text: "Setttlement Date", align: "center" },
              { text: "Detail", align: "center" },
            ]}
            bodys={tableBody}
            key="contract-note-report"
          />
        )}
      </div>
    </>
  );
};
