import { useTranslation } from "react-i18next";

const NoShortcut = ({ onClick }: { onClick: (state: boolean) => void }) => {
  const { t: translate } = useTranslation();
  return (
    <div className="py-4 px-2 text-gray-500 grid justify-items-center">
      <div className="">{translate("shortcuts.empty_title")}</div>
      <div className="">
        {translate("shortcuts.empty_description")}
        <button className="text-primary-500" onClick={() => onClick(true)}>
          &nbsp;{translate("shortcuts.setting")}
        </button>
      </div>
    </div>
  );
};

export default NoShortcut;
