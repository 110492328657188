import { IChart } from "../../interfaces/chart";

const LegendChart = ({ data }: { data: IChart[] }) => {
  const gridCols = data.length > 3 ? "grid-cols-3" : `grid-cols-${data.length}`;
  return (
    <div className={`grid ${gridCols} gap-4`}>
      {data.map((o, index) => (
        <div className="flex items-center" key={index}>
          <div
            className="rounded-full"
            style={{
              width: 12,
              height: 12,
              backgroundColor: o.hexColor,
              marginRight: 4,
            }}
          />
          <div>{o.label}</div>
        </div>
      ))}
    </div>
  );
};

export default LegendChart;
