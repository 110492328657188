import i18n from "i18next";
import { Settings } from "luxon";

export const isThaiLanguage = () => i18n.language === "th";
export const currentLanguage = () => i18n.language;

export const setLanguage = (language: "th" | "en") => {
  i18n.changeLanguage(language);
  Settings.defaultLocale = language;
  language === "th"
    ? (Settings.defaultOutputCalendar = "buddhist")
    : (Settings.defaultOutputCalendar = "");
};
