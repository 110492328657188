import EmptyData from "../EmptyData";

export interface IReportTableBody {
  datas: IReportTableField[];
  action: () => void;
}

export interface IReportTableField {
  text: string;
  align: "left" | "center" | "right";
}

const ReportTable = ({
  labels,
  bodys,
  key,
}: {
  labels: IReportTableField[];
  bodys: IReportTableBody[];
  key: string;
}) => {
  return (
    <div className="w-full mt-4 overflow-auto h-80">
      <table className="w-full">
        <thead className="sticky top-0 h-12 whitespace-pre bg-white border-b shadow-sm">
          <tr className="">
            {labels.map((label) => {
              return (
                <td className={`px-4 text-${label.align}`}>{label.text}</td>
              );
            })}
          </tr>
        </thead>
        {bodys.length ? (
          <tbody className="whitespace-pre bg-white">
            {bodys.map((body, index) => {
              return <ReportRecord body={body} key={`${key}-${index}`} />;
            })}
          </tbody>
        ) : (
          <div></div>
        )}
      </table>
      {!bodys.length ? <EmptyData /> : <div></div>}
    </div>
  );
};

const ReportRecord = ({ body }: { body: IReportTableBody }) => {
  return (
    <tr
      className="items-center py-3 border-t cursor-pointer hover:bg-red-100"
      onClick={body.action}
    >
      {body.datas.map((data) => {
        return (
          <td className={`px-4 text-${data.align}`}>{data.text ?? "-"}</td>
        );
      })}
      <td className="flex items-center justify-center px-4 h-14">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-8 h-8"
          viewBox="0 0 20 20"
          fill="gray"
        >
          <path
            fillRule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z"
            clipRule="evenodd"
          />
        </svg>
      </td>
    </tr>
  );
};

export default ReportTable;
