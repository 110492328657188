import { useTranslation } from "react-i18next";
import { ReactComponent as SendIcon } from "../../../src/svgIcon/new-dashboard/your-mission/send.svg";
import { openWindow, openWindowWithBlankPage } from "../../helper/open.window";
import { isThaiLanguage } from "../../i18n/helper";
import { IMissions } from "../../services/ats.service";
import { Card } from "../Card";
import MissionCard from "./MissionCard";

const YourMissionPanel = ({
  missions,
  replaceUrl,
}: {
  missions: IMissions[];
  replaceUrl?: boolean;
}) => {
  const { t: translate } = useTranslation();

  if (!missions.length) return <></>;
  return (
    <Card
      icon={<SendIcon />}
      title="Your Mission"
      tooltip={{
        title: translate("yourMission.subTitle"),
        description: translate("yourMission.description"),
      }}
    >
      <div
        className="grid items-center grid-cols-12 gap-4"
        data-testid="your-mission-panel"
      >
        <div
          className="flex flex-col-reverse items-center justify-center col-span-12 md:flex-row xl:col-span-6 gap-x-10"
          data-testid="mission-banner"
        >
          <img
            style={{ marginBottom: "-40px" }}
            className="w-44"
            src="/images/your-mission/remittance-sent.png"
            alt="remittance-sent"
          />
          <div className="flex flex-col justify-center text-left md:w-3/5 gap-y-4">
            <h1
              className="text-xl font-bold whitespace-pre text-missionBannerTitle 2xl:whitespace-normal"
              data-testid="mission-banner-title"
            >
              {translate("yourMission.subTitle")}
            </h1>
            <p
              className="text-xs whitespace-pre text-missionBannerDescription lg:whitespace-normal"
              data-testid="mission-banner-description"
            >
              {translate("yourMission.description")}
            </p>
          </div>
        </div>
        <div
          data-testid="mission-list"
          className="grid grid-cols-1 col-span-12 gap-5 p-5 sm:grid-cols-2 xl:col-span-6 bg-slate-00 rounded-2xl"
          style={{ height: "fit-content" }}
        >
          {missions.map((m, index) => (
            <MissionCard
              key={index}
              title={isThaiLanguage() ? m.titleTH : m.titleEN}
              index={index}
              icon={m.image}
              onClick={() => {
                if (replaceUrl) {
                  return openWindow({
                    link: m.callback,
                    isPopup: false,
                    target: "_self",
                  });
                }
                openWindowWithBlankPage({ link: m.callback, isPopup: false });
              }}
            />
          ))}
        </div>
      </div>
    </Card>
  );
};

export default YourMissionPanel;
