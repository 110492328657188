import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { SVG_CANCEL_CIRCLE } from "../../const/svg.icon";
import { IShortcut } from "../../interfaces/user";
import AllShortcuts from "./components/AllShortcut";
import EmptyShortcut from "./components/EmptyShortcut";
import { ShortcutSelected } from "./components/Shortcut";

const AdvanceShortcut = ({
  userShortcuts,
  allShortcuts,
  onCancel,
  onConfirm,
}: {
  userShortcuts: IShortcut[];
  allShortcuts: IShortcut[];
  onCancel: () => void;
  onConfirm: (shortcuts: IShortcut[]) => void;
}) => {
  const { t: translate } = useTranslation();
  const [currentShortcut, setCurrentShortcut] = useState(userShortcuts);
  const [keyword, setKeyword] = useState<string>("");
  const onSearchChange = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setKeyword(value);
  };

  const onClickShortcut = (shortcut: IShortcut) => {
    if (currentShortcut.length === 10) return;
    if (currentShortcut.find((each) => each.nameEN === shortcut.nameEN)) return;
    setCurrentShortcut([...currentShortcut, shortcut]);
  };

  const removeShortcut = (name: string) => {
    setCurrentShortcut([
      ...currentShortcut.filter((each) => each.nameEN !== name),
    ]);
  };

  return (
    <div className="fixed z-20 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen p-4 text-center sm:block sm:p-0 opacity-100">
        <div
          className="fixed inset-0 transition-opacity"
          aria-hidden="true"
          onClick={() => {}}
        >
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div
          className="w-full md:w-5/6 lg:w-4/5 bg-white inline-block rounded-lg text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle p-8"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="flex flex-col items-center justify-center h-full py-4">
            <div className="font-bold text-xl">
              {translate("advance_shortcuts.title")}
            </div>
            <div className="font-light mt-1">
              {translate("advance_shortcuts.description")}
            </div>
            <div className="w-full flex md:space-x-2 overflow-x-auto py-4 items-center">
              <div className="mx-auto flex flex-wrap gap-x-2">
                {[...Array(10)].map((_, i) => {
                  if (i < currentShortcut.length) {
                    return (
                      <div key={`current-shortcut-${i}`} className="relative">
                        <ShortcutSelected shortcut={currentShortcut[i]} />
                        <div
                          onClick={() =>
                            removeShortcut(currentShortcut[i].nameEN)
                          }
                          className="absolute top-0 right-0 lg:top-2 lg:right-2 fill-current text-red-500 cursor-pointer hover:text-red-600"
                        >
                          {SVG_CANCEL_CIRCLE}
                        </div>
                      </div>
                    );
                  }
                  return <EmptyShortcut key={`empty-shortcut-${i}`} />;
                })}
              </div>
            </div>
            <AllShortcuts
              allShortcuts={allShortcuts}
              keyword={keyword}
              onSearchChange={onSearchChange}
              selectedShortcut={currentShortcut}
              onClick={onClickShortcut}
            />
            <div className="grid grid-cols-2 gap-4">
              <button
                onClick={() => onConfirm(currentShortcut)}
                className="focus:outline-none rounded text-white w-32 h-8 bg-red-500"
              >
                {translate("advance_shortcuts.confirm")}
              </button>
              <button
                onClick={onCancel}
                className="focus:outline-none text-red-500 border border-red-500 rounded w-32 h-8"
              >
                {translate("advance_shortcuts.cancel")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvanceShortcut;
