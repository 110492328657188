import { useHistory } from "react-router-dom";
import IconButton from "./IconButton";
import Title from "./Title";
import { PATHS } from "../../const/path";
import { useTranslation } from "react-i18next";

const EStatement = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-y-4">
      <Title title={t("eService.eStatement.title")} />
      <div className="grid grid-cols-4 items-start gap-3">
        <IconButton
          label={t("eService.eStatement.eStatement")}
          Icon={EStatementIcon}
          onClick={() => history.push(PATHS.E_SERVICE_E_STATEMENT)}
        />
        <IconButton
          label={t("eService.eStatement.handbook")}
          Icon={HandbookAndContract}
          onClick={() => history.push(PATHS.E_SERVICE_CUSTOMER_HANDBOOK)}
        />
      </div>
    </div>
  );
};

export default EStatement;

const EStatementIcon = (
  <img src="/icons/e-service/e-statement/Container.png" alt="e-statement" />
);
const HandbookAndContract = (
  <img src="/icons/e-service/e-statement/pen-note.png" alt="handbook" />
);
