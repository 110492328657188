import { ITab } from "../../interfaces/tab";

export const CustomTab = ({
  tabs,
  currentTab,
  className,
  onClick,
}: {
  tabs: ITab[];
  onClick: (currentTab: string) => void;
  currentTab: string;
  className?: string;
}) => {
  const buildTabMenu = (tab: ITab) => {
    const selectedStyle =
      tab.value === currentTab
        ? "text-red-500 border-red-500"
        : "border-transparent";
    return (
      <div
        id={`custom-tab-${tab.value}`}
        className={`w-full text-center text-gray-500 pb-3 border-b-4 cursor-pointer ${selectedStyle}`}
        onClick={() => onClick(tab.value)}
      >
        {tab.label}
      </div>
    );
  };

  return <div className={`flex ${className}`}>{tabs.map(buildTabMenu)}</div>;
};
