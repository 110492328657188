import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import "./i18n/config.ts";
import "./styles/bg.scss";
import "./styles/animation.scss";
import "react-circular-progressbar/dist/styles.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Settings } from "luxon";
import dotenv from "dotenv";
import { Chart, registerables } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import ReactGA from "react-ga4";

Settings.defaultOutputCalendar = "";
Settings.defaultLocale = "en";
Chart.register(...registerables, ChartDataLabels);
Chart.defaults.font.family = "sukhumvittadmai";
Chart.defaults.font.size = 14;
Chart.defaults.font.weight = "normal";

dotenv.config();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID ?? "");

const SendAnalytics = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
};

reportWebVitals(SendAnalytics);
