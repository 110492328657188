import { getDateInFormat } from "../../helper/date.time";
import { seperateNumberWithComma } from "../../helper/format.number";
import { ICashMovement } from "../../interfaces/cash.movement";
import { IMonthlyReport } from "../../interfaces/monthly.report.list";
import { HeaderModal } from "../HeaderModal";
import { HeaderModalDetail } from "../HeaderModalDetail";
import { ModalDetail } from "../ModalDetail";

export const CashMovementDetail = ({
  closeModal,
  monthlyReport,
  cashMovements,
}: {
  closeModal: () => void;
  monthlyReport: IMonthlyReport;
  cashMovements: ICashMovement[];
}) => {
  const showInFlag = (cashMovement: ICashMovement) =>
    cashMovement.inOutFlag === "0";
  return (
    <ModalDetail
      closeModal={() => closeModal()}
      chlidren={
        <div>
          <HeaderModal title="Monthly Cash Movement" />
          <HeaderModalDetail
            leftHeader={"Account No: " + monthlyReport.accountNo}
            rightHeader={
              "As of Date: " +
              getDateInFormat(monthlyReport.asOfDate.toString())
            }
          />
          <div className="overflow-auto mt-4">
            <table className="w-full  ">
              <thead className="py-1 bg-gray-200">
                <tr className="border border-black  divide-x divide-black">
                  <th className="px-3 py-4">Date</th>
                  <th className="px-3 py-4">Type</th>
                  <th className="px-3 py-4">Doc No</th>
                  <th className="px-3 py-4">In</th>
                  <th className="px-3 py-4">Out</th>
                  <th className="px-3 py-4">Balance</th>
                </tr>
              </thead>
              <tbody>
                {cashMovements.map((cashMovement, index) => (
                  <tr className="border border-black border-t-0 divide-x divide-black">
                    <td
                      key={`cashmovement-trade-date-index-${index}`}
                      className="px-3 py-4"
                    >
                      {getDateInFormat(cashMovement.tradeDate)}
                    </td>
                    <td
                      key={`cashmovement-type-index-${index}`}
                      className="text-center px-3 py-4"
                    >
                      {cashMovement.type}
                    </td>
                    <td
                      key={`cashmovement-docNo-index-${index}`}
                      className="px-3 py-4"
                    >
                      {cashMovement.docNo}
                    </td>
                    <td
                      key={`cashmovement-amount-index-${index}`}
                      className="text-right px-3 py-4"
                    >
                      {showInFlag(cashMovement)
                        ? seperateNumberWithComma(cashMovement.amount)
                        : "-"}
                    </td>
                    <td
                      key={`cashmovement-amount-index-${index}`}
                      className="text-right px-3 py-4"
                    >
                      {showInFlag(cashMovement)
                        ? seperateNumberWithComma(cashMovement.amount)
                        : "-"}
                    </td>
                    <td
                      key={`cashmovement-balance-index-${index}`}
                      className="text-right px-3 py-4"
                    >
                      {seperateNumberWithComma(cashMovement.balance)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      }
    />
  );
};
