export const OpenOverseasAccountTab = ({
  onClick,
}: {
  onClick: () => void;
}) => {
  return (
    <div
      className="flex items-center w-48 h-10 px-4 text-sm rounded-t cursor-pointer bg-dbsv-neutral-slate-00 bg-opacity-30 gap-x-4"
      onClick={onClick}
    >
      <img
        id="open-overseas-account"
        className="w-4 h-4"
        src="/icons/plus.png"
        alt=""
      />
      <h1 className="w-32 font-medium text-primary-500">เปิดบัญชีเพิ่ม</h1>
    </div>
  );
};

export default OpenOverseasAccountTab;
