import EmptyData from "../EmptyData";

export interface ITableRow {
  cell: ITableCell[] | ITableSeperator;
}

export interface ITableSeperator {
  seperatorLabel: string;
}

export interface ITableCell {
  element: string | JSX.Element;
  align: "left" | "center" | "right";
  className?: string;
  onClick?: () => void;
}

export const DynamicTable = ({
  header,
  body,
  key,
}: {
  header: ITableCell[];
  body: ITableRow[];
  key: string;
}) => {
  function isITableSeperator(instance: any): instance is ITableSeperator {
    return (instance as ITableSeperator).seperatorLabel !== undefined;
  }

  return (
    <div className="w-full overflow-auto mt-4">
      <table className="w-full">
        <thead className="sticky top-0 h-12 whitespace-pre bg-white border-b shadow-sm">
          <tr className="">
            {header.map((cell) => {
              return (
                <td className={`px-4 text-${cell.align} ${cell.className}`}>
                  {cell.element}
                </td>
              );
            })}
          </tr>
        </thead>
        {body.length ? (
          <tbody className="whitespace-pre bg-white">
            {body.map((row, idx) => {
              const { cell } = row;
              if (isITableSeperator(cell)) {
                return (
                  <TableSeperator
                    label={cell.seperatorLabel}
                    key={`${key}-${idx}`}
                  />
                );
              }
              return (
                <TableRecord
                  data={cell as ITableCell[]}
                  key={`${key}-${idx}`}
                />
              );
            })}
          </tbody>
        ) : (
          <div></div>
        )}
      </table>
      {!body.length ? <EmptyData /> : <div></div>}
    </div>
  );
};

const TableSeperator = ({ label }: { label: string }) => {
  return (
    <tr className=" border-t items-center cursor-pointer bg-dropdown-600">
      <td colSpan={99} className="text-white px-4 py-2">
        {label}
      </td>
    </tr>
  );
};

const TableRecord = ({ data }: { data: ITableCell[] }) => {
  return (
    <tr
      className="py-3 border-t items-center cursor-pointer hover:bg-red-100"
    >
      {data.map((cell, idx) => {
        return (
          <td className={`px-4 text-${cell.align} ${cell.className}`} onClick={cell.onClick}>
            {cell.element ?? "-"}
          </td>
        );
      })}
    </tr>
  );
};

export default DynamicTable;
