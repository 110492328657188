import { ReactComponent as CashMovementIcon } from "../../../svgIcon/new-dashboard/accounts/cash-movement.svg";
import { ReactComponent as ContractNoteIcon } from "../../../svgIcon/new-dashboard/accounts/contractnote.svg";
import { ReactComponent as DailyConfirmationIcon } from "../../../svgIcon/new-dashboard/accounts/daily-confirmation.svg";
import { ReactComponent as DepositIcon } from "../../../svgIcon/new-dashboard/accounts/deposit.svg";
import { ReactComponent as EMonthlyStatementIcon } from "../../../svgIcon/new-dashboard/accounts/e-monthly-statement.svg";
import { ReactComponent as ProductIcon } from "../../../svgIcon/new-dashboard/accounts/product.svg";
import { ReactComponent as StreamingIcon } from "../../../svgIcon/new-dashboard/accounts/streaming.svg";
import { ReactComponent as WealthBOXIcon } from "../../../svgIcon/new-dashboard/accounts/wealth-box.svg";
import { ReactComponent as WithdrawalIcon } from "../../../svgIcon/new-dashboard/accounts/withdrawal.svg";
import { AccountMenus } from "./helper";

export const AccountItemIcon = ({ type }: { type: AccountMenus }) => {
  if (type === "streaming") {
    return <StreamingIcon data-testid={type + "-icon"} />;
  } else if (type === "wealthBOX") {
    return <WealthBOXIcon data-testid={type + "-icon"} />;
  } else if (type === "cashmovement" || type === "stockmovement") {
    return <CashMovementIcon data-testid={type + "-icon"} />;
  } else if (type === "consolidated-portfolio") {
    return <ProductIcon data-testid={type + "-icon"} />;
  } else if (type === "dailyconfirmation") {
    return <DailyConfirmationIcon data-testid={type + "-icon"} />;
  } else if (type === "e-monthly-statement") {
    return <EMonthlyStatementIcon data-testid={type + "-icon"} />;
  } else if (type === "contractnote") {
    return <ContractNoteIcon data-testid={type + "-icon"} />;
  } else if (type === "withdrawal") {
    return <WithdrawalIcon data-testid={type + "-icon"} />;
  }
  return <DepositIcon data-testid={"deposit-icon"} />;
};
