import { IConfirmationReportDetail } from "../../interfaces/confirmation.report.detail";

export const getTotal = (confirmationDetails: IConfirmationReportDetail[]) => {
  const amountTotal = confirmationDetails.reduce(
    (acc, curr) => (acc = acc + Number(curr.grossAmount)),
    0
  );
  const commTotal = confirmationDetails.reduce(
    (acc, curr) => (acc = acc + Number(curr.commAmount)),
    0
  );
  const tradingTotal = confirmationDetails.reduce(
    (acc, curr) => (acc = acc + Number(curr.tradingFee)),
    0
  );
  const clearingTotal = confirmationDetails.reduce(
    (acc, curr) => (acc = acc + Number(curr.clearingFee)),
    0
  );
  const vatTotal = confirmationDetails.reduce(
    (acc, curr) => (acc = acc + Number(curr.vatAmount)),
    0
  );
  const totalBuyAmount = confirmationDetails.reduce(
    (acc, curr) =>
      (acc = acc + (curr.refType === "B" ? Number(curr.netAmount) : 0)),
    0
  );
  const totalSellAmount = confirmationDetails.reduce(
    (acc, curr) =>
      (acc = acc + (curr.refType === "S" ? Number(curr.netAmount) : 0)),
    0
  );
  return {
    amountTotal,
    commTotal,
    tradingTotal,
    clearingTotal,
    vatTotal,
    totalBuyAmount,
    totalSellAmount,
  };
};
