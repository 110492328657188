const ArrowRight = ({ onNextBanner }: { onNextBanner: () => void }) => {
  return (
    <div
      id="banner-arrow-right"
      className="absolute flex items-center justify-center w-8 h-44 text-white right-0 cursor-pointer hover:bg-gray-600 hover:bg-opacity-20 transition ease-out duration-300"
      onClick={() => onNextBanner()}
    >
      {CHEVRON_RIGHT}
    </div>
  );
};

const CHEVRON_RIGHT = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="w-6 h-10"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9 -2l7 14-7 14"
    />
  </svg>
);

export default ArrowRight;
