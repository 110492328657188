import { useTranslation } from "react-i18next";
import { PrimaryButton } from "../../components/Button";
import { ActiveLevel } from "../../components/TradingMission/ActiveLevel";
import { EliteLevel } from "../../components/TradingMission/EliteLevel";
import { UltimateLevel } from "../../components/TradingMission/UltimateLevel";
import { useUserContext } from "../../context/user/user.context";
import { openWindow } from "../../helper/open.window";
import GenerateLinkService from "../../services/generate-sso-ekyc-link-service";
import { BTFLevel } from "../../enum/rewards";

const OverseasTradingMission = () => {
  const generateLinkService = new GenerateLinkService();
  const { t: translate } = useTranslation();
  const {
    userRewards: { btfLevel },
  } = useUserContext();
  const isAccountOpened = btfLevel !== BTFLevel.NO_ACCOUNT;

  const onClickBenefitLink = async () => {
    const link = await generateLinkService.generateBenefitsLink();
    openWindow({
      link: link,
      isPopup: false,
    });
  };

  const onClickOpenAccountLink = async () => {
    const link = await generateLinkService.generateOpenForeignAccountLink();
    openWindow({
      link: link,
      isPopup: false,
    });
  };

  const OpenAccount = () => {
    return (
      <div className="flex items-end">
        <PrimaryButton
          label={translate("rewards.overseas_trading_mission.open_account")}
          onClick={onClickOpenAccountLink}
        />
      </div>
    );
  };

  return (
    <div className="relative my-8 rounded-lg bg-dbsvRewards-bgCard">
      <div
        style={{ minHeight: "270px" }}
        className="flex flex-col items-center justify-start p-8 text-white lg:items-start lg:justify-between lg:flex-row"
      >
        <div className="w-full lg:w-auto" style={{ minWidth: "300px" }}>
          <p className="text-2xl whitespace-pre-line">Overseas Trading</p>
          <p className="text-xl whitespace-pre-line">Mission</p>
          <p className="mt-2 text-xs">
            {translate("rewards.overseas_trading_mission.description")}
          </p>
        </div>
        {isAccountOpened ? (
          <div className="grid w-64 grid-cols-1 mt-4 mr-0 md:w-auto lg:mr-5 lg:mt-0 md:grid-cols-2 lg:grid-cols-3 gap-7">
            <ActiveLevel />
            <EliteLevel />
            <UltimateLevel />
          </div>
        ) : (
          <div className="flex flex-col items-end justify-end w-full h-24 lg:h-52">
            <OpenAccount />
          </div>
        )}
      </div>
      <img
        src="/icons/rewards/rewards-mission.png"
        className="absolute bottom-0 left-0 w-52"
        alt="rewards-mission"
      />
      <div className="flex justify-end lg:absolute lg:bottom-0">
        <PrimaryButton
          className="z-10 ml-20 mr-8 mb-14 md:mb-8 lg:mr-0 lg:ml-32"
          label={translate("rewards.overseas_trading_mission.button")}
          onClick={onClickBenefitLink}
        />
      </div>
    </div>
  );
};
export default OverseasTradingMission;
