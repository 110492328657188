import { AxiosResponse } from "axios";
import {
  IEStatementResponse
} from "../interfaces/e-statement";
import httpClient from "./http";

export class EStatementService {
  private http = httpClient();

  async getEStatement({
    date,
    month,
    year,
  }: {
    date: string;
    month: string;
    year: string;
  }): Promise<AxiosResponse<IEStatementResponse[]>> {
    return await this.http.post("/e-statement", {
      date,
      month,
      year,
    });
  }

  async getEMonthlyStatement(accountNo: string): Promise<IEStatementResponse> {
    return await this.http
      .post("/e-statement/monthly", {
        accountNoList: [accountNo],
      })
      .then((res) => res.data);
  }

  async downloadEstatement(request: IEStatementResponse) {
    const { account, documentId, reportName } = request;
    return this.http
      .post(
        `/e-statement/${documentId}`,
        {
          accountNoList: [account],
        },
        {
          responseType: "arraybuffer",
        }
      )
      .then((response) => {
        const filename = `${account} - ${reportName}`;
        var data = new Blob([response.data], { type: "application/pdf" });
        var pdf = window.URL.createObjectURL(data);
        var tempLink = document.createElement("a");
        tempLink.href = pdf;
        tempLink.setAttribute("download", `${filename}.pdf`);
        tempLink.click();
      });
  }
}

export default EStatementService;
