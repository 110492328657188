export const HeaderModalWithAction = ({
  title,
  action,
  onPrinting,
}: {
  title: string;
  action?: JSX.Element;
  onPrinting: boolean;
}) => {
  return (
    <div className="gap-y-8 items-center mb-6 flex flex-col lg:flex-row justify-between">
      <DBSVLogo />
      <div className="flex justify-between w-full items-center flex-col md:flex-row gap-y-4">
        <span className="hidden lg:block"></span>
        <h1 className="text-center text-2xl">{title}</h1>
        {onPrinting ? (
          <span className="w-1/4"></span>
        ) : (
          <div className="flex justify-self-end gap-x-4">{action}</div>
        )}
      </div>
    </div>
  );
};

export const HeaderModal = ({ title }: { title: string }) => {
  return (
    <div className="flex flex-col md:grid md:grid-cols-2 lg:grid-cols-3 gap-y-4 items-center mb-6">
      <DBSVLogo />
      <h1 className="lg:text-center text-center md:text-right text-2xl">
        {title}
      </h1>
      <span></span>
    </div>
  );
};

const DBSVLogo = () => {
  return (
    <img id="logo" alt={""} src="/images/dbsv-logo-black.png" className="h-6" />
  );
};
