import { useEffect, useRef, useState } from "react";
import { getDateNormalFormat } from "../../helper/date.time";
import { isThaiLanguage } from "../../i18n/helper";
import { IResearch } from "../../interfaces/research";
import { ReactComponent as OfficialLeaveIcon } from "../../svgIcon/new-dashboard/research/official-leave.svg";
import { Indicator } from "./Indicator";
import { ResearchItem } from "./ReseachItem";
import { ResearchEmpty } from "./ResearchEmpty";
import { ViewAllButton } from "./ViewAllButton";

const ITEM_WIDTH: number = 256;
const SLIDER_INTERVAL_TIMER = 5000;
export const ITEM_GAP_X: number = 12;

interface ResearchCarouselProps {
  title: string;
  researches: IResearch[];
  onClickViewAll: VoidFunction;
  onClickItem: (id: number) => void;
}

const ResearchCarousel: React.FC<ResearchCarouselProps> = ({
  title,
  researches,
  onClickViewAll,
  onClickItem,
}) => {
  const defaultItemPerPage = 3;
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(defaultItemPerPage);
  const [totalPage, setTotalPage] = useState(1);
  const [researchData, setResearchData] = useState<IResearch[]>(researches);
  const observedDiv = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!researches.length) return;
    const researchList = getResearchByPage(
      researches,
      currentPage,
      itemsPerPage
    );
    setResearchData([...researchList]);
    setTotalPage(Math.ceil(researches.length / itemsPerPage));
  }, [currentPage, itemsPerPage, researches]);

  useEffect(() => {
    if (!observedDiv.current) return;
    if (currentPage > totalPage) {
      setCurrentPage(totalPage);
    }
    const resizeObserver = new ResizeObserver(() => {
      if (window.innerWidth <= 640) {
        setItemsPerPage(3);
      } else {
        const currentWidth = observedDiv?.current?.offsetWidth ?? 0;
        const numberOfItem = Math.floor(
          (currentWidth + ITEM_GAP_X) / (ITEM_WIDTH + ITEM_GAP_X)
        );
        setItemsPerPage(numberOfItem);
      }
    });

    resizeObserver.observe(observedDiv.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [currentPage, totalPage]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPage((prevPage) => {
        const nextPage = prevPage + 1;
        return nextPage > totalPage ? 1 : nextPage;
      });
    }, SLIDER_INTERVAL_TIMER);

    return () => clearInterval(interval);
  }, [currentPage, totalPage]);

  const getResearchByPage = (
    researches: IResearch[],
    page: number,
    itemsPerPage: number
  ) => {
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return researches.slice(startIndex, endIndex);
  };

  const renderIndicators = () => {
    return Array.from({ length: totalPage }).map((_, index) => {
      const page = index + 1;
      return (
        <Indicator
          key={`indicator-${index}`}
          active={page === currentPage}
          onClick={() => setCurrentPage(page)}
        />
      );
    });
  };

  return (
    <div
      className="w-full h-full p-2 overflow-hidden overflow-x-auto rounded-xl"
      style={{ backgroundColor: "#F5F7F9" }}
    >
      <div className="flex flex-col items-center justify-between gap-4 p-2 md:flex-row">
        <div className="flex flex-row items-center gap-x-4">
          <OfficialLeaveIcon />
          <div
            className="text-sm font-bold md:text-base"
            style={{ color: "#17181A" }}
          >
            {title}
          </div>
        </div>
        {!!researches.length && <ViewAllButton onClick={onClickViewAll} />}
      </div>
      {!researches.length ? (
        <div className="flex items-center justify-center w-full h-full py-4 md:py-10">
          <ResearchEmpty />
        </div>
      ) : (
        <>
          <div
            key={`${currentPage}`}
            className="flex flex-col gap-3 mt-3 overflow-hidden transition-transform duration-500 sm:flex-row animate-slide-left"
            ref={observedDiv}
          >
            {researchData.map((research) => (
              <ResearchItem
                key={`research-item-${research.id}`}
                allItemCount={itemsPerPage}
                title={isThaiLanguage() ? research.title_th : research.title_en}
                reserachCategory={
                  isThaiLanguage()
                    ? research.research_category.name_th
                    : research.research_category.name_en
                }
                date={getDateNormalFormat(
                  research.post_date
                    ? research.post_date.toString()
                    : research.updated_at.toString()
                )}
                imageUrl={research.thumbnail_image?.url}
                onClick={() => onClickItem(research.id)}
              />
            ))}
          </div>
          <div className="flex flex-row justify-center mt-3 gap-x-1">
            {renderIndicators()}
          </div>
        </>
      )}
    </div>
  );
};

export default ResearchCarousel;
