import { AccountType } from "../../../enum/account";
import { AccountInfo } from "../AccountInfo";
import { AccountMenuItem } from "../AccountMenuItem";

export const AccountFRACType = ({
  accountNo = "",
  accountType,
  onOpenStreaming,
}: {
  accountNo?: string;
  accountType?: AccountType;
  onOpenStreaming: () => void;
}) => {
  const renderFRACAccount = () => {
    return <AccountMenuItem type="streaming" onClick={onOpenStreaming} />;
  };

  return (
    <div
      data-testid="account-frac-type"
      className="flex flex-row flex-wrap p-5 pb-0 gap-y-4"
    >
      <AccountInfo accountNo={accountNo} accountType={accountType} />
      <div className="flex flex-row flex-1 gap-4 pb-8 pl-6 overflow-hidden overflow-x-auto">
        {renderFRACAccount()}
      </div>
    </div>
  );
};
