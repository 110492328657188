export const getScaleColor = ({
  colors,
  dataLength,
}: {
  colors: string[];
  dataLength: number;
}) => {
  const tempColors: string[] = [];
  let colorsLevel: number = 0;

  for (let index = 0; index < dataLength; index++) {
    if (colorsLevel > colors.length - 1) {
      colorsLevel = 0;
    }
    tempColors.push(colors[colorsLevel]);
    colorsLevel++;
  }

  return tempColors;
};

export const colorChartOfDoughnutStock = [
  "#AD1026",
  "#F81D19",
  "#E96423",
  "#FBFE30",
  "#FCC23D",
];

export const colorChartOfDoughnutMarket = [
  "#C43260",
  "#BF2AE4",
  "#8C30A5",
  "#BC9DF2",
  "#580DE2",
];
