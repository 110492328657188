import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Title } from "../components/Title";
import { INVESTMENT_TOOLS } from "../const/invesment-tool";
import { useHandleAPIStateContext } from "../context/handle-api-state";
import { useUserContext } from "../context/user/user.context";
import { getErrorMessageGetDataFail } from "../helper/modal.message";
import { openWindow, openWindowWithBlankPage } from "../helper/open.window";
import SSOSTTLink from "../helper/sso.stt.link";
import { isThaiLanguage } from "../i18n/helper";
import { IHandleModal } from "../interfaces/handle.modal";
import { ISpecialMenu, ISubMenu } from "../interfaces/menu";
import SSODBSVService from "../services/sso-dbsv.service";
import UserService from "../services/user.service";

const InvestmentTools = () => {
  const { investmentToolsSubMenu } = useUserContext();

  const {
    setGetDataEror,
  }: { setGetDataEror: (getDataError: IHandleModal) => void } =
    useHandleAPIStateContext();

  const { t: translate } = useTranslation();
  const [partnerToolsLink, setPartnerToolsLink] = useState<SSOSTTLink>(
    new SSOSTTLink({ userref: "" })
  );

  useEffect(() => {
    new UserService().getUserInfo().then((userInfo) => {
      setPartnerToolsLink(new SSOSTTLink({ userref: userInfo.userref }));
    });
  }, []);

  const handleInvestmentToolsAction = async (tool: string) => {
    const ssodbsvService = new SSODBSVService();
    const functor = {
      [INVESTMENT_TOOLS.TECHNICAL_INSIGHT.name]:
        ssodbsvService.getBrainBoxTILink,
      [INVESTMENT_TOOLS.FUNDAMENTAL_INSIGHT.name]:
        ssodbsvService.getBrainBoxVALink,
      [INVESTMENT_TOOLS.BLOCK_TRADE.name]: ssodbsvService.getBlockTradeLink,
      [INVESTMENT_TOOLS.WEALTHBOX.name]: ssodbsvService.getWealthBoxLink,
      [INVESTMENT_TOOLS.ONLINE_TRADING_WEBSITE.name]:
        ssodbsvService.getOnlineTgradingWebsiteLink,
      [INVESTMENT_TOOLS.M_TRADING_TH.name]:
        ssodbsvService.getMTradingWebsiteLink,
    };
    try {
      const link = await functor[tool]();
      openWindowWithBlankPage({ link, isPopup: false });
    } catch (e) {
      setGetDataEror(getErrorMessageGetDataFail(translate));
    }
  };

  const isShowPartnerTools = investmentToolsSubMenu().partnerTools.length > 0;

  function buildDigitalServiceSubmenu() {
    return investmentToolsSubMenu().digitalServices.map((subMenu, index) => {
      return renderInvestmentToolsMenu({
        key: `digital-service-item-${index}`,
        isSpecialMenu: subMenu.isSpecialMenu!,
        specialMenu: subMenu.menuDetail as ISpecialMenu,
        subMenu: subMenu.menuDetail as ISubMenu,
        OnClickDefaultItem: () =>
          handleInvestmentToolsAction((subMenu.menuDetail as ISubMenu).name),
      });
    });
  }

  function buildOverseasTradingPlatformSubmenu() {
    return investmentToolsSubMenu().overseaTradingPlatform.map(
      (subMenu, index) => {
        return renderInvestmentToolsMenu({
          key: `overseas-trading-item-${index}`,
          isSpecialMenu: subMenu.isSpecialMenu!,
          specialMenu: subMenu.menuDetail as ISpecialMenu,
          subMenu: subMenu.menuDetail as ISubMenu,
          OnClickDefaultItem: () =>
            handleInvestmentToolsAction((subMenu.menuDetail as ISubMenu).name),
        });
      }
    );
  }

  function buildMobileAppSubmenu() {
    return investmentToolsSubMenu().mutualFundsTradningPlatform.map(
      (subMenu, index) => {
        return renderInvestmentToolsMenu({
          key: `mobile-app-item-${index}`,
          isSpecialMenu: subMenu.isSpecialMenu!,
          specialMenu: subMenu.menuDetail as ISpecialMenu,
          subMenu: subMenu.menuDetail as ISubMenu,
          OnClickDefaultItem: () =>
            handleInvestmentToolsAction((subMenu.menuDetail as ISubMenu).name),
        });
      }
    );
  }

  function buildPartnerToolsSubmenu() {
    return investmentToolsSubMenu().partnerTools.map((subMenu, index) => {
      return renderInvestmentToolsMenu({
        key: `partners-tools-item-${index}`,
        isSpecialMenu: subMenu.isSpecialMenu!,
        specialMenu: subMenu.menuDetail as ISpecialMenu,
        subMenu: subMenu.menuDetail as ISubMenu,
        OnClickDefaultItem: () => {
          partnerToolsLink.openLink({
            pageName: (subMenu.menuDetail as ISubMenu).pageName!,
            isAdditionalLang: (subMenu.menuDetail as ISubMenu)
              .isAdditionalLang!,
            isPopup: (subMenu.menuDetail as ISubMenu).isPopUp ?? false,
          });
        },
      });
    });
  }

  function renderInvestmentToolsMenu({
    key,
    isSpecialMenu,
    specialMenu,
    subMenu,
    OnClickDefaultItem,
  }: {
    key: string;
    isSpecialMenu: boolean;
    specialMenu: ISpecialMenu;
    subMenu: ISubMenu;
    OnClickDefaultItem: () => void;
  }) {
    return isSpecialMenu ? (
      <InvestmentItem
        key={key}
        specialMenu={specialMenu}
        isSpecialMenu={true}
        onClick={() => {
          specialMenu.isPopup
            ? openWindowWithBlankPage({ link: specialMenu.url })
            : openWindow({ link: specialMenu.url });
        }}
      />
    ) : (
      <InvestmentItem
        key={key}
        subMenu={subMenu}
        isSpecialMenu={false}
        onClick={OnClickDefaultItem}
      />
    );
  }

  return (
    <div className="flex flex-col p-4 gap-y-6">
      <div className="flex flex-col p-4 card-bg gap-y-3">
        <div id="investment-tools-digital-services">
          <Title title="DBSV Overseas Trading Platform" />
          <div className="flex flex-wrap justify-start mt-2 gap-y-2">
            {buildOverseasTradingPlatformSubmenu()}
          </div>
        </div>
        <div className="border-t border-gray-100"></div>
        <div id="investment-tools-mobile-app">
          <Title title="DBSV Mutual Funds Trading Platform" />
          <div className="flex flex-wrap justify-start mt-2 gap-y-2">
            {buildMobileAppSubmenu()}
          </div>
        </div>
        <div className="border-t border-gray-100"></div>
        <div id="investment-tools-digital-services">
          <Title title="DBS Digital Services" />
          <div className="flex flex-wrap justify-start mt-2 gap-y-2">
            {buildDigitalServiceSubmenu()}
          </div>
        </div>
      </div>
      {isShowPartnerTools && (
        <div className="p-4 card-bg">
          <Title title="Partners Tools" />
          <div className="flex flex-wrap justify-start mt-2 gap-y-2 sm:space-x-2">
            {buildPartnerToolsSubmenu()}
          </div>
        </div>
      )}
    </div>
  );
};

const InvestmentItem = ({
  subMenu,
  specialMenu,
  isSpecialMenu,
  onClick,
}: {
  subMenu?: ISubMenu;
  specialMenu?: ISpecialMenu;
  isSpecialMenu: boolean;
  onClick: () => void;
}) => {
  return (
    <div
      className="flex flex-col items-center cursor-pointer sm:p-4"
      onClick={onClick}
    >
      <div className="flex flex-col items-center px-4 py-2 duration-700 w-36 hover:bg-gray-200 hover:rounded-md">
        <img
          src={isSpecialMenu ? specialMenu?.imageIcon : subMenu?.imagePath}
          alt=""
          className="h-16 mb-2"
        />
        <div className="hidden text-sm font-light text-center text-gray-600 whitespace-pre lg:flex">
          {isSpecialMenu
            ? isThaiLanguage()
              ? specialMenu?.titleTH
              : specialMenu?.titleEN
            : subMenu?.name}
        </div>
      </div>
    </div>
  );
};

export default InvestmentTools;
