/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import ErrorComponent from "../components/Error";
import { login } from "../helper/login";
import { useEffect } from "react";
import { useHandleAPIStateContext } from "../context/handle-api-state";
import { initIHandleModal } from "../helper/modal.message";

const Unauthorize = () => {
  const { t: translate } = useTranslation();
  const { getDataError, setGetDataEror } = useHandleAPIStateContext();

  useEffect(() => {
    if (getDataError.isShow) {
      setGetDataEror({ ...initIHandleModal });
    }
  }, []);

  return (
    <div className="flex flex-col items-center justify-center mt-20 md:mt-32 lg:mt-40">
      <ErrorComponent
        header={translate("error.head")}
        errorCode="401"
        title={translate("error.unauthorized.title")}
        description={translate("error.unauthorized.description")}
        ctaButton={
          <div className="flex justify-center">
            <button
              onClick={() => login()}
              className="px-4 py-2 mt-8 font-bold text-white bg-red-500 rounded focus:outline-none"
            >
              {translate("error.unauthorized.cta")}
            </button>
          </div>
        }
      />
    </div>
  );
};

export default Unauthorize;
