import { useTranslation } from "react-i18next";
import { SVG_CANCEL, SVG_SEARCH } from "../const/svg.icon";

const SearchKeyword = ({
  keyword,
  onKeywordInputChange,
  onSearch,
  refInput,
  setKeyword,
}: {
  onSearch: (e: React.FormEvent) => void;
  refInput: React.RefObject<HTMLInputElement>;
  keyword: string;
  onKeywordInputChange: (e: React.ChangeEvent) => void;
  setKeyword: (keyword: string) => void;
}) => {
  const { t: translate } = useTranslation();

  return (
    <form onSubmit={onSearch} className="flex">
      <div className="relative flex">
        <input
          id="keyword-input"
          ref={refInput}
          style={{ height: 42 }}
          className="px-4 border border-gray-300 focus:outline-none caret-red"
          placeholder={translate("research_archive.keyword")}
          value={keyword}
          onChange={onKeywordInputChange}
        />
        <div
          className={`cursor-pointer ${
            keyword ? "absolute right-2 top-3" : "hidden"
          }`}
          onClick={() => {
            setKeyword("");
            refInput.current?.focus();
          }}
        >
          {SVG_CANCEL}
        </div>
        <button
          type="submit"
          className="flex items-center px-2 py-1 text-white bg-red-500 cursor-pointer focus:outline-none hover:bg-red-600"
        >
          {SVG_SEARCH}
        </button>
      </div>
    </form>
  );
};

export default SearchKeyword;
