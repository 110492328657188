import { IConfirmationReportList } from "../interfaces/confirmation.report.lists";
import { IDerivSTTHeaderNonBsb } from "../interfaces/contract.note";
import { IMonthlyReport } from "../interfaces/monthly.report.list";

export function filterAccount(
  listAccount: Array<
    IConfirmationReportList | IMonthlyReport | IDerivSTTHeaderNonBsb
  >,
  accounts: string[]
) {
  for (const confirmation of listAccount) {
    if (!accounts.some((account) => account === confirmation.accountNo)) {
      accounts.push(confirmation.accountNo);
    }
  }
  return accounts;
}
