export const SVG_CHEVRON_DOWN = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6"
    id="dynamic-dropdown-icon"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      id="dynamic-dropdown-icon-path"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19 9l-7 7-7-7"
    />
  </svg>
);

export const SVG_CHEVRON_DOWN_FILLED = (
  <svg
    className="h-4 w-4"
    id="dynamic-dropdown-icon"
    viewBox="0 0 30 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15 26L29.7224 0.5H0.277568L15 26Z" fill="#FF0000" />
  </svg>
);

export const SVG_CANCEL = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={`h-5 w-5 absolute right-10 self-center text-gray-500`}
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M6 18L18 6M6 6l12 12"
    />
  </svg>
);

export const SVG_CHEVRON_LEFT = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M15 19l-7-7 7-7"
    />
  </svg>
);

export const SVG_CHEVRON_LEFT_THIN = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M15 19l-7-7 7-7"
    />
  </svg>
);

export const SVG_CHEVRON_RIGHT = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="relative h-6 w-6"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9 5l7 7-7 7"
    />
  </svg>
);

export const SVG_CANCEL_CIRCLE = (
  <svg
    className="h-7 w-7"
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 41C8.972 41 0 31.8037 0 20.5C0 9.1963 8.972 0 20 0C31.028 0 40 9.1963 40 20.5C40 31.8037 31.028 41 20 41Z"
      fill="#F44336"
    />
    <path d="M10 17.4258H30V23.5758H10V17.4258Z" fill="white" />
  </svg>
);

export const SVG_PLUS = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 4v16m8-8H4"
    />
  </svg>
);

export const SVG_SEARCH = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
    />
  </svg>
);
