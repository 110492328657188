import { useHistory } from "react-router-dom";
import { getPublicCMSUrl } from "../../helper/cms";
import { getDateInFormat } from "../../helper/date.time";
import { isThaiLanguage } from "../../i18n/helper";
import { IResearch } from "../../interfaces/research";
import { getPathResearchView } from "../../pages/Research/helper";

const ResearchItem = ({
  research,
  isEService = false,
}: {
  research: IResearch;
  isEService?: boolean;
}) => {
  const history = useHistory();

  return (
    <div
      className="border-b mb-4 pb-6 cursor-pointer animate-up-2"
      onClick={() => {
        history.push(getPathResearchView(research.id, isEService));
      }}
    >
      <div
        style={{
          backgroundImage: `url(${getPublicCMSUrl(
            research?.thumbnail_image?.url
          )}), url('images/default_banner_research.png')`,
        }}
        className="bg-center bg-cover sm:h-40 h-52 w-full rounded-lg border"
      ></div>
      <div className="text-xs text-gray-400 p-2">
        {getDateInFormat(
          research?.post_date
            ? research?.post_date.toString()
            : research?.updated_at.toString(),
          "dd MMM yyyy"
        )}
      </div>
      <div className="px-1">
        {isThaiLanguage() ? research.title_th : research.title_en}
      </div>
    </div>
  );
};

export default ResearchItem;
