import { useTranslation } from "react-i18next";
import OverseasTradingPortfolioModel from "../../../../models/overseas-trading-portfolio.model";
import { IOverseasPortfolio } from "../../../../services/overseas-trading";
import DoughnutChart from "../../../DoughnutChart";
import LegendChart from "../../../DoughnutChart/LegendChart";

const AssetAllocateChart = ({
  portfolio,
  viewMode,
}: {
  portfolio: IOverseasPortfolio;
  viewMode: "Stock" | "Market";
}) => {
  const { t: translate } = useTranslation();

  return (
    <>
      <DoughnutChart
        title={translate("overseasPortfolio.percentAssetAllocation")}
        chartData={{
          data: new OverseasTradingPortfolioModel(
            portfolio
          ).getChartDataAssetAllocationByViewMode(viewMode),
          isPercent: true,
          title: "Overseas Equity Portfolio",
          totalPortValue: new OverseasTradingPortfolioModel(
            portfolio
          ).getTotalAmount(),
        }}
        height="20rem"
      />
      <div className="flex items-center justify-center pb-4">
        <LegendChart
          data={new OverseasTradingPortfolioModel(
            portfolio
          ).getChartDataAssetAllocationByViewMode(viewMode)}
        />
      </div>
    </>
  );
};

export default AssetAllocateChart;
