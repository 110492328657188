import {
  IAverageScore,
  IResearchRating,
  IResearchRatingRequest,
} from "../interfaces/research.pdf.view";
import httpClient from "./http";

class ResearchService {
  private http = httpClient();

  getResearchRating = async (researchId: number): Promise<IResearchRating> => {
    return this.http
      .get<IResearchRating>(`research/${researchId}/score`)
      .then((res) => res.data);
  };

  rateResearch = async (
    data: IResearchRatingRequest
  ): Promise<IResearchRating> => {
    return this.http.post("research/review", data).then((res) => res.data);
  };

  getAverageScore = async (researchId: number): Promise<IAverageScore> => {
    return this.http(`research/${researchId}/average-score`).then(
      (res) => res.data
    );
  };
}

export default ResearchService;
